<template>
    <form @submit.prevent="filterTable">
        <div class="row">
            <div class="col-12 col-md-2">
                        <label class="akkurate-dark" for="type">Status</label>
                        <multiselect class="mt-2 field-container" v-model="form.status" :options="status_option"></multiselect>
                      </div>
                      <div class="col-12 col-md-4">
                      <label class="akkurate-dark" for="search_term">Search Term</label>
                          <b-form-group
                            id="search_term"
                            label-for="search_term">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="form.search_term"
                            placeholder="Search by firstname, lastname, phone number, email">
                          </b-form-input>
                          </b-form-group>
                    </div>
          
                    <div class="col-12 col-md-2">
                      <label class="akkurate-dark" for="from_date">From Date</label>
                          <b-form-group
                          id="from_date"
                          label-for="from_date">
                          <b-form-input
                          class="mb-3 mt-2 field-container fields"
                          type="date"
                          v-model="form.from_date"
                          ></b-form-input>
                          </b-form-group>
                    </div>
                   <div class="col-12 col-md-2">
                      <label class="akkurate-dark" for="to_date">To Date</label>
                        <b-form-group
                        id="to_date"
                        label-for="to_date">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="date"
                        v-model="form.to_date"
                        ></b-form-input>
                        </b-form-group>
                   </div>
        
            <div class="d-grid gap-2 mt-auto col-12 col-md-2">
                <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100 mb-3">Search</b-button>
            </div>
         
      </div>

    </form>
    
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
    components:{
        Multiselect
    },
    props:['form','filterTable'],
    data() {
        return {
            status_option: ['Active', 'Inactive',  "Dormant", "Closed"],
        }
    }
}

</script>
<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
     <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
