<template>
    <div id="wrapper">
            <SidebarView :loan="true" :loan-scheme="true" :loan-visible="true" />
            <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
              <TopbarView />
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                      <!-- begin loader -->
                      <!-- <beat-loader-component
                      v-if="isLoading"
                      :isLoading="isLoading"
                      ></beat-loader-component> -->
                      <!-- end loader -->
                  </div>
                  <div class="col-12">
                    <div class="card card-shape home-box">
                      <div class="card-header py-3 d-flex flex-row align-items-center">
                        <h6 class="m-0 fw-bold text-green">Create Loan Scheme</h6>
                      </div>
                      <div class="card-body">
                        <form class="form" method="POST" @submit.prevent="storeData">
                          <scheme-form :form="form" :add_or_edit="add_or_edit" :approval_options="approval_options" :branch_options="branch_options"></scheme-form>
                          <hr />
                          <div class="text-center py-3">
                            <button type="submit" :disabled="isSaving" class="btn akkurate-green-btn text-white btn-size my-2 me-2">Save</button>
                            <button type="reset" class="btn akkurate-gray-btn text-white btn-size my-2 me-2">Reset</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
       
            </div>
          <Footer></Footer>
          </div>
            <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
          </div>
    </template>

<script>

// import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
// import UsersForm from "./../forms/UsersForm.vue";
import SchemeForm from "../form/SchemeForm.vue";
import ApiService from "@/core/services/api.service";
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue'


export default {
  components: {
    name: 'UsersCreate',
    // BeatLoaderComponent,
    // UsersForm,
    SidebarView,
    TopbarView,
    Footer,
    SchemeForm
  },
  created(){
    this.getSchemeDropdown();
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ path: '/login' });
    }
    // this.getUserOptions();
  },
  data() {
    return {
      ...this.initialState(),
      approval_options:[],
      branch_options:[],
    };
  },
  methods: {
    async getSchemeDropdown() {
      await ApiService.get('/loans/loanProductSchemes/dropdown')
        .then((response) => {
          this.approval_options = response.data?.loan_approval_levels;
          this.branch_options = response.data?.branches;
        }).catch((error) => {
          console.log(error);
        })
    },
    storeData() {
      this.isSaving = true;
      this.$Progress.start();
      if(this.form.branch)
      {
        this.form.branch_id = this.form.branch.id;
      }

      if(this.form.loan_approval_level)
      {
        this.form.loan_approval_levels = [];
        this.form.loan_approval_level.forEach(element => {
          // console.log(element)
          this.form.loan_approval_levels.push(element.id)
        });
      }
      ApiService.post("loans/loanProductSchemes", this.form)
        .then((response) => {
          this.isLoadingSaving = false;
          this.$Progress.finish();
          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.isSaving = false;
          this.form = this.initialState().form;
        }).catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          if (error.response.data.errors) {
            this.form.errors = error.response.data.errors;
          }
        });
    },
    initialState() {
      return {
        form: {
          name: "",
          interest_rate: null,
          min_principal: null,
          max_principal: null,
          min_duration: null,
          max_duration: null,
          min_membership_duration: null,
          min_share_balance: null,
          loan_processing_fee: null,
          loan_premium_plan: null,
          // branches:[],
          branch: null,
          // loan_approval_levels: [
          //   1,
          //   2,
          //   3,
          // ],
          loan_approval_level: [],
          loan_approval_levels: [],
          status: 1,
          supervisor_approval_limit: null,
          branch_approval_limit: null,
          errors: []
        },

        isLoading: true,
        isSaving: false,
        add_or_edit: 'add',
      };
    },
  },
};
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>