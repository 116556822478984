<template>
    <form @submit.prevent="filterTable">
             <div class="row">
                <div class="col">
                    <label class="akkurate-dark" for="type">User</label>
                    <multiselect class="mt-2 field-container" :custom-label="customLabel" v-model="form.user" :options="user_options"></multiselect>
                </div>
                <div class="col">
                    <label class="akkurate-dark" for="type">Region</label>
                    <multiselect class="mt-2 field-container" v-model="form.region" label="name" :options="region_options"></multiselect>
                </div>

                <div class="col">
                    <label class="akkurate-dark" for="type">Status</label>
                    <multiselect class="mt-2 field-container" v-model="form.status" label="name" :options="status_options"></multiselect>
                </div>
               
        
                <div class="mt-auto col">
                    <b-button type="submit" class="akkurate-green-btn btn-size w-100">Search</b-button>
                </div>
         
      </div>

    </form>
    
</template>

<script>
import ApiService from '@/core/services/api.service'
import Multiselect from 'vue-multiselect'
export default {
    components: {
        Multiselect
    },
    props: ['form', 'filterTable'],
    data() {
        return {
            user_options: [],
            region_options: [],
            status_options: [
                { 'name': "Active", 'value': 1 },
                { 'name': "Inactive", 'value': 0  }
            ]
        }
    },
    mounted() {
        this.getBranchDropDown();
    },
    methods: {
        async getBranchDropDown() {
            await ApiService.get('/settings/branches/dropdown')
                .then((response) => {
                    this.user_options = response.data?.users;
                    this.region_options = response.data?.regions;
                }).catch((error) => {

                })
        },
        customLabel({ first_name, surname }) {
            return `${first_name}  ${surname}`
        }
    }
}

</script>
<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
