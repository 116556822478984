<template>
    <div id="wrapper">
      <SidebarView :members="true" :add-member="true" :member-visible="true" />
      <div class="d-flex flex-column" id="content-wrapper">
        <div id="content">
          <TopbarView />
          <form @submit.prevent="submitMember()" :add_or_edit="add_or_edit">
            <div class="container-fluid">
              <div class="row pb-2">
                <div class="col-md-3">
                  <button type="submit" class="btn akkurate-green-btn text-white btn-size my-2 me-2">
                    Update
                  </button>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12 col-md-12">
                  <div class="card card-shape home-box">
                    <div
                      class="card-header py-3 d-flex flex-row align-items-center"
                    >
                      <h6 class="m-0 fw-bold text-green">Personal Details</h6>
                    </div>
                    <div class="card-body">
                      <personal-details :form="form" :add_or_edit="add_or_edit" @responseFront="handleResponseFront" @responseBack="handleResponseBack"  
                      @responsePassportPic="responsePassportPic" @responseSignaturePic="responseSignaturePic" ></personal-details>
                    </div>
                  </div>
                </div>
                </div>  

            <div class="row mb-4">
              <div class="col-12 col-md-12">
                <div class="card card-shape home-box">
                  <div
                    class="card-header py-3 d-flex flex-row align-items-center"
                  >
                    <h6 class="m-0 fw-bold text-green">Employer Details</h6>
                  </div>
                  <div class="card-body">
                    <employer-details :form="form" :add_or_edit="add_or_edit"></employer-details>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12 col-md-12">
                <div class="card card-shape home-box">
                  <div
                    class="card-header py-3 d-flex flex-row align-items-center"
                  >
                    <h6 class="m-0 fw-bold text-green">Financial Information</h6>
                  </div>
                  <div class="card-body">
                    <financial-information :form="form" :add_or_edit="add_or_edit"></financial-information>
                  </div>
                </div>
              </div>
            </div>
              <div class="row mt-2">
                <div class="col-12 mt-2 mt-md-0 col-md-12">
                  <div class="card card-shape home-box">
                    <div
                      class="card-header py-3 d-flex flex-row align-items-center"
                    >
                      <h6 class="m-0 fw-bold text-green">Address</h6>
                    </div>
                    <div class="card-body">
                      <address-view
                        :region_options="regions"
                        :form="form"
                        :branch_options="branches"
                        :add_or_edit="add_or_edit"
                      ></address-view>
                    </div>
                    <hr />
                    <div class="text-center py-3">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="col-md-3">
                    <button type="submit" class="btn akkurate-green-btn text-white btn-size my-2 me-2">
                      Update
                    </button>
                  </div>
                </div>
              </div>
  
            </div>
          </form>
        </div>
        <footer></footer>
      </div>
    </div>
  </template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import Footer from "@/views/main/components/Footer.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import AddressView from "../forms/Address";
import PersonalDetails from "../forms/PersonalDetails";
import EmployerDetails from "../forms/EmployerDetails";
import FinancialInformation from "../forms/FinancialInformation";
// import AddAccount from "../forms/Modal/AddAccount";
// import NextOfKin from "../forms/Modal/NextOfKin.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: "MemberEdit",
  mounted() {
    this.loadPageOptions();
    this.mountMemberData();
  },
  components: {
    SidebarView,
    Footer,
    TopbarView,
    AddressView,
    PersonalDetails,
    // AddAccount,
    // NextOfKin,
    EmployerDetails,
    FinancialInformation,
  },
  data() {
    return {
      form: {
        accounts: [],
        nextofkins: [],
        first_name: "",
        middlename: null,
        last_name: "",
        title: "",
        email: "",
        mobile: "",
        gender: "",
        date_of_birth: "",
        external_code: "",
        marital_status: "",
        address: "",
        digital_address: "", //not required
        home_town: "", //not required
        landmark: "",
        region: {},
        branch: {},
        employer_name: null, //not required
        ghana_card_number: null, //not required
        status: "",
        errors: [],
        gh_card_back_display: null,
        gh_card_front_display: null,
        passport_pic_display: null,
        signature_display: null,
        employer_address: null,
        employer_mobile: null,
        occupation: null,
        employee_position: null,
        source_of_funds: null,
        bankers_name: null,
        bankers_address: null,
        bankers_location: null,
        monthly_salary: null,
        signature_image_path: null,
        signature_image_name: null,
        passport_pic_path: null,
        passport_pic_name: null,
        note: null,
        mandate: null,
        customer_type: null,
        bank_account_number: null,
      },
      regions: [],
      branches: [],
      account_types: [],
      add_or_edit: 'edit',
      front_card_path: "",
      front_card_name: "",
      back_card_path: "",
      back_card_name: "",
    };
  },

  methods: {
    async loadPageOptions() {
      this.$Progress.start();
      await ApiService.get("/customers/dropdown")
        .then((response) => {
          this.account_types = response.data.account_types;
          this.regions = response.data.regions;
          this.branches = response.data.branches;
          this.isLoading = false;
          // $emit('coa-head-saved', response.data)
          this.$Progress.finish();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$Progress.fail();
        });
    },
    async mountMemberData() {
      await ApiService.get(`/customers/view/${this.$route.params.id}`)
        .then((response) => {
          // console.log(response.data.data);
          let data = response.data.data;
          this.form.title = data.title;
          this.form.first_name = data.first_name;
          this.form.middlename = data.middlename;
          this.form.last_name = data.last_name;
          this.form.gender = data.gender;
          this.form.date_of_birth = data.date_of_birth;
          this.form.marital_status = data.marital_status;
          this.form.email = data.email;
          this.form.mobile = data.mobile;
          this.form.ghana_card_number = data.ghana_card_number;
          this.form.status = data.status;
          this.form.staff_id = data.staff_id;
          this.form.branch = data.branch;
          this.form.region = data.region
          this.form.address = data.address;
          this.form.digital_address = data.digital_address;
          this.form.home_town = data.home_town;
          this.form.external_code = data.external_code;
          this.form.customer_type = data.customer_type;
          this.form.bank_account_number = data.bank_account_number;
          this.form.mandate = data.mandate;
          this.form.note = data.note;
          this.form.employer_name = data.employer_name;
          this.form.employer_address = data.employer_address;
          this.form.employer_mobile = data.employer_mobile;
          this.form.occupation = data.occupation;
          this.form.employee_position = data.employee_position;
          this.form.source_of_funds = data.source_of_funds;
          this.form.bankers_name = data.bankers_name;
          this.form.bankers_address = data.bankers_address;
          this.form.bankers_location = data.bankers_location;
          this.form.monthly_salary = data.monthly_salary;


          this.form.unapproved_withdrawal_max_amount = data.unapproved_withdrawal_max_amount;
          if(data.gh_card_back_path)
          {
            this.form.gh_card_back_display = data.gh_card_back_path;
          }
          
          if(data.gh_card_front_path)
          {
            this.form.gh_card_front_display = data.gh_card_front_path;
          }

          if(data.passport_pic_path)
          {
            this.form.passport_pic_display = data.passport_pic_path;
          }

          if(data.signature_image_path)
          {
            this.form.signature_display = data.signature_image_path;
          }
        }).catch((error) => {

        })

    },
    async submitMember() {
      this.isSaving = true;
      this.$Progress.start();


      this.form.gh_card_front_path = this.front_card_path;
      this.form.gh_card_front_name = this.front_card_name;
      this.form.gh_card_back_path = this.back_card_path;
      this.form.gh_card_back_name = this.back_card_name;

      await ApiService.put(`/customers/update/${this.$route.params.id}`, this.form)
        .then((response) => {
          this.isSaving = false;
          this.$Progress.finish();
          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          setTimeout(() => {
            window.location.href = "/members";
          },'1000');
         
         
        })
        .catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          this.form.errors = [];
          if (error.response?.data?.errors) {
            console.log(error.response.data.errors);
            this.form.errors = error.response.data.errors;
          }
          // console.log(error.response.data)
        });
    },
    handleResponseFront(message) {
      // console.log(message);
      this.front_card_path = message.front_ghana_path;
      this.front_card_name = message.front_ghana_name;
    },
    handleResponseBack(message) {
      // console.log(message);
      this.back_card_path = message.back_ghana_path;
      this.back_card_name = message.back_ghana_name;
    },
    responseSignaturePic(obj) {
      // console.log(obj);
      this.form.signature_image_path = obj.signature_image_path;
      this.form.signature_image_name = obj.signature_image_name;
    },
    responsePassportPic(obj) {
      // console.log(obj);
      this.form.passport_pic_path = obj.passport_pic_path;
      this.form.passport_pic_name = obj.passport_pic_name;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>