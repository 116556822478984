<template>
    <div id="wrapper">
      <SidebarView :accType="true" :addAccType="true" :accTypeVisible="true" />
      <div class="d-flex flex-column" id="content-wrapper">
        <div id="content">
          <TopbarView />
          <b-container>
            <div class="row">
                <div class="py-3 d-flex flex-row align-items-center justify-content-between">
                 <div class="col-12">
                  <div class="card card-shape home-box">
                    <div class="card-header py-3 d-flex flex-row align-items-center">
                      <h6 class="m-0 fw-bold text-green">Account Type</h6>
                    </div>
                    <div class="card-body">
                      <form class="form" @submit.prevent="storeData">
                        <account-type-form :form="form" :add_or_edit="add_or_edit" errors="errors"></account-type-form>
                        <hr />
                        <div class="text-center py-3">
                          <button type="submit" :disabled="isSaving" class="btn akkurate-green-btn text-white btn-size my-2 me-2">Save</button>
                          <button type="reset" class="btn akkurate-gray-btn text-white btn-size my-2 me-2">Reset</button>
                        </div>
                      </form>
                    </div>
                  </div>
              </div>
              </div>
            </div>
          </b-container>
        </div>
        <footer></footer>
      </div>
     
    </div>
  </template>
<script>

import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";

import AccountTypeForm from "../form/AccountTypeForm.vue";
import ApiService from "@/core/services/api.service";

import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue'

export default {
  components: {
    name: 'CreateAccountType',
    BeatLoaderComponent,
    AccountTypeForm,
    SidebarView,
    TopbarView,
    Footer
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ path: '/login' });
    }
  },
  // data() {
  //   return 
  //   {
  //         // this.initialState(),
  //         roles: [],
  //   };
  // },
  data() {
    return {
      ...this.initialState(),
      roles: []
    };
  },
  methods: {
    storeData() {
      this.isSaving = true;
      this.$Progress.start();
      console.log(this.form);
      ApiService.post("/accounts/account-types/store", this.form)
        .then((response) => {
          console.log(response);
          this.isLoadingSaving = false;
          this.$Progress.finish();

          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.isSaving = false;
          // this.form.reset();
          this.form = this.initialState().form;
        })
        .catch((error) => {
          console.log(error);
          this.isSaving = false;
          this.$Progress.fail();
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          }
        });
    },
    initialState() {
      return {
        form: {
          name: "",
          base_type: "",
          withdrawal_allowed: "1",
          deposit_allowed: "1",
          minimum_balance: "",
          send_sms: "1",
          daily_deposit_amount_limit: "4100",
          daily_deposit_frequency_limit: "100",
          daily_withdrawal_amount_limit: "5000",
          daily_withdrawal_frequency_limit: "100",
          unapproved_withdrawal_max_amount:"",
          coa_head:"",
        },
        isLoading: true,
        isSaving: false,
        add_or_edit: 'add',
        errors: []
      };
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>