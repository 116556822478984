<template>
    <div id="wrapper">
            <SidebarView :fixed_deposit="true"/>
            <div class="d-flex flex-column" id="content-wrapper">
                <div id="content">
                    <TopbarView />
                    <div class="container">
                        <div class="card card-shape home-box">
                            <div class="card-header py-3 d-flex flex-row align-items-center">
                                <h6 class="m-0 fw-bold text-green">Standing Order</h6>
                            </div>
                            <div class="card-body" style="background-color: #eaeff2; border-bottom: 1px solid rgb(1, 134, 115);">
                                <basic-search :filter="filter" :basicSearch="basicSearch" :action="action"></basic-search>
                            </div>
                            <div class="card-body">
                                <search :form="form"></search>
                            </div>
                            <div class="card-body">
                                <advance-search @adv_search="advanceSearch"></advance-search>
                            </div>
                            <div class="card-body">
                                <div>
                                    <vue-good-table
                                        :isLoading.sync="isLoading"
                                        :pagination-options="{
                                            enabled: true,
                                            perPage: serverParams.perPage,
                                        }"
                                        :rows="rows"
                                        :columns="columns">
                                        <!-- Add your custom delete button column -->
                                        <template slot="table-row" slot-scope="props">
                                            <span class="d-flex" v-if="props.column.field == 'action'">
                                                <a title="View" class="btn btn-sm akkurate-warning text-white">
                                                <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span> 
                                                </a>
                                                <approval :data="props.row"></approval>
                                                <a title="Reverse" class="btn btn-sm akkurate-danger-btn text-white">
                                                    <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'exchange-alt']" /></span>
                                                </a>
                                            </span>
                                        </template>
                                    </vue-good-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer></footer>
            </div>
            <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
    </template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import BasicSearch from './form/BasicSearch.vue'
import Search from './form/Search.vue'
import AdvanceSearch from './form/AdvanceSearch.vue';
import ApiService from '@/core/services/api.service';
import Approval from './form/modal/Approval.vue'
export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        BasicSearch,
        Search,
        AdvanceSearch,
        Approval
        // DepositForm
    },
    data() {
        return {
            form:{
                source_account:"",
                source_coa:"",
                destination_account:"",
                destination_coa:"",
                amount:"",
                circle:"",
                start_date:"",
                end_date:"",
            },
            filter: {
                code: "",
                search: "",
            },
            advSearch: {},
            action: {
                search_btn: "Search",
                disabled_search: false,
            },
            columns: [
                {
                    label: 'SD Code',
                    field: 'id',
                },
                {
                    label: 'Status',
                    field: 'status',
                    type: 'string',
                },
                {
                    label: 'Amount',
                    field: 'amount',
                    type: 'string',
                },
                {
                    label: 'Start Date',
                    field: 'start_date',
                    type: 'string',
                },
                {
                    label: 'End Date',
                    field: 'end_date',
                    type: 'string',
                },
                {
                    label: 'Duration',
                    field: 'duration',
                    type: 'string',
                },
                {
                    label: 'Circle',
                    field: 'circle',
                    type: 'string',
                },
                {
                    label: 'SRC.Product',
                    field: 'source',
                    type: 'string',
                },
                {
                    label: 'DEST.Product',
                    field: 'destination',
                    type: 'string',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            isLoading: false,
            totalRecords: 0,
            serverParams: {
                columnFilters: {
                },
                sort: [
                    {
                        field: '',
                        type: ''
                    }
                ],
                page: 1,
                perPage: 20
            },

        }
    },
    created() {
        this.loadItems();
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.loadItems();
        },

        onSortChange(params) {
            this.updateParams({
                sort: [{
                    type: params.sortType,
                    field: this.columns[params.columnIndex].field,
                }],
            });
            this.loadItems();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },
        async loadItems() {
            this.isLoading = true;
            await ApiService.query('/standing-orders/orders', {
                params: {
                    "branch": [], //Eg ["branch1", "branch2"]
                    "startDate": "", // Eg. "2023-12-28"
                    "endDate": "", // Eg. 2023-12-28
                    "status": "" // Eg. "pending"
                }
            }).then((response) => {
                // console.log(response.data.data);
                // console.log(response);
                this.rows = response.data.data.map((index) => {
                    return {
                        id : index.id,
                        status : index.status,
                        amount: index.amount,
                        start_date: index.start_date,
                        end_date: index.end_date,
                        duration: this.calculateDuration(new Date(index.start_date), new Date(index.end_date)),
                        circle : index.circle,
                        source: index.source_coa?.id,
                        destination: index.destination_coa?.id,

                    }
                });
                this.isLoading = false;
            }).catch((error) => {
                this.isLoading = false;

            })
        },
        advanceSearch(data) {
            this.advSearch = data;
            // console.log(this.advSearch);
            this.loadItems();
        },
        basicSearch() {
            this.getSingleSD();
        },
       async getSingleSD(){
           this.action.search_btn = "Processing...."
            this.action.disabled_search = true;
            await ApiService.get(`/standing-orders/order/${this.filter.code}`)
                .then((response) => {
                    // console.log(response.data.data);
                    response.data.data.map((index) => {
                        this.form.source_account = index.source_account?.account_number;
                        this.form.source_coa = index.source_coa?.name
                        this.form.destination_account = index.destination_account?.account_number;
                        this.form.destination_coa = index.destination_coa?.name;
                        this.form.amount = index.amount;
                        this.form.circle = index.circle;
                        this.form.start_date = index.start_date;
                        this.form.end_date = index.end_date
                    });
                    // console.log(this.form);

                    // let payment = this.form.payment;
                    // this.form = response.data.data
                    // let first_name = this.form?.customer?.first_name != null ? this.form?.customer?.first_name : "";
                    // let last_name = this.form?.customer?.last_name != null ? this.form?.customer?.last_name : "";
                    // this.form.customer_name = first_name + ' ' + last_name;
                    // this.form.payment = payment;
                    this.action.search_btn = "Search"
                    this.action.disabled_search = false;
                }).catch((error) => {
                    this.action.search_btn = "Search"
                    this.action.disabled_search = false;
                    console.log(error);
                })
        },
        calculateDuration(startDate, endDate) {
            // Convert both dates to milliseconds
            const startMillis = startDate.getTime();
            const endMillis = endDate.getTime();

            // Calculate the difference in milliseconds
            let durationMillis = Math.abs(endMillis - startMillis);

            // Calculate the duration in days, hours, minutes, and seconds
            const millisecondsInDay = 1000 * 60 * 60 * 24;
            const days = Math.floor(durationMillis / millisecondsInDay);
            durationMillis %= millisecondsInDay;
            // const hours = Math.floor(durationMillis / (1000 * 60 * 60));
            // durationMillis %= (1000 * 60 * 60);
            // const minutes = Math.floor(durationMillis / (1000 * 60));
            // durationMillis %= (1000 * 60);
            // const seconds = Math.floor(durationMillis / 1000);

            // Return an object with the duration components
            return days;
            // return {
            //     days: days,
            //     hours: hours,
            //     minutes: minutes,
            //     seconds: seconds
            // };
        }
    }

}

</script>