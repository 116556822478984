UsersIndex

<template>
<div id="wrapper">
        <SidebarView :voucher="true" :voucher-index="true" :voucher-visible="true"/>
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="container-fluid">
                    
             <!-- begin row  -->
            <div class="row">
                <div class="col-lg-12">
                  <form @submit.prevent="filterTable">
                    <div class="row">
                      <div class="col">
                        <label class="akkurate-dark" for="search_term">Search Term</label>
                          <b-form-group
                          id="search_term"
                          label-for="search_term">
                          <b-form-input
                          class="mb-3 mt-2 field-container fields"
                          type="text"
                          placeholder="Search by beneficairy name, code"
                          ></b-form-input>
                          </b-form-group>
                      </div>
                      <div class="col">
                        <label class="akkurate-dark small" for="branch">Branch</label>
                        <multiselect 
                            class="mt-2 field-container" 
                            v-model="form.branch" 
                            :options="branch_options"
                            track-by="name"
                            @input="onBranchChange"
                            :multiple="true"
                            placeholder="Select branch"
                            selectLabel=""
deselectLabel=""
                            label="name">
                        </multiselect>
                      </div>
                      <div class="col">
                        <label class="akkurate-dark" for="type">Status</label>
                        <multiselect
                         class="mt-2 field-container"
                         v-model="form.status"
                         :options="status_options"
                         @input="onStatusChange"
                         :multiple="true"
                         placeholder="Select Status"
                         selectLabel=""
deselectLabel="">
                        </multiselect>
                      </div>
                      <div class="d-grid gap-2 mt-auto col-12 col-md-2">
                        <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100 mb-3">Search</b-button>
                      </div>
         
                    </div>

                  </form>
                    
                    <div class="my-3">
                        <b-button class="btn btn-sm akkurate-green-btn" href="/voucher/create" variant="primary">
                            <span class="akkurate-small"> <i class="fas fa-plus"></i> Add Voucher</span>
                        </b-button>
                    </div>

              <div class="card card-shape home-box">
                <div class="card-header py-3 d-flex flex-row align-items-center">
                  <h6 class="m-0 fw-bold text-green">All Vouchers</h6>
                </div>
                <div class="card-body">
                <vue-good-table
                styleClass="vgt-table bordered table-dropdown"
                mode="remote"
                ref="voucherTable"
                :columns="columns"
                :rows="rows"
                :line-numbers="true"
                :isLoading.sync="isLoading"
                :totalRows="totalRecords"
                :sort-options="{
                  enabled: false
                }"
                  :pagination-options="{
                    enabled: true,
                    perPage: serverParams.perPage,
                  }"
                  @on-search="onSearch"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  
                >
                <!-- Add your custom delete button column -->
                <template slot="table-row" slot-scope="props">
                  <span class="d-flex" v-if="props.column.field == 'action'">
                      <!-- <a title="View" class="btn btn-sm akkurate-warning text-white">
                        <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span> 
                      </a> -->
                      <voucher-view :data="props.row"></voucher-view>
                      <a v-if="props.row.status != 'REJECTED' && props.row.status != 'APPROVED' && props.row.status != 'DISBURSED'" title="Approve" class="btn btn-sm akkurate-green-btn text-white" :href="`/voucher/approve/${props.row.id}`">
                        <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'check']" /></span> 
                      </a>
                      <!-- <a title="Reject" class="btn btn-sm akkurate-danger text-white">
                        <span class="akkurate-small"><font-awesome-icon  :icon="['fas', 'ban']" /></span>
                      </a> -->
                      <reject :data="props.row" @rejected="rejected"></reject>

                      <b-button v-if="props.row.status == 'APPROVED'" :disabled="isDisbursing" title="Disburse" @click="disburseVoucher(props.row.id)" class="btn btn-sm akkurate-blue text-white">
                        <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'exchange-alt']" /></span> 
                      </b-button>
                  </span>
                </template> 
                </vue-good-table>
                </div>
              </div>
              </div>
            </div>
            <!-- end row  -->
            </div>
            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
</div>

</template>

<script>

// import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";

import ApiService from "@/core/services/api.service";

import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import Multiselect from 'vue-multiselect'
import Reject from '../forms/modal/Reject.vue'
import VoucherView from '../view/VoucherView'

import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
// import $ from 'jquery';
// import 'datatables.net';
// import config from '@/config.js'
// import JwtService from "@/core/services/jwt.service";

export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    Multiselect,
    Reject,
    VoucherView
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ path: '/login' });
    }
  },

  data() {
    return {
      totalRecords: 0,
      searchFilter: "",
      isLoading: false,
      form: {
        status: '',
        branch: '',
      },
      isDisbursing:false,
      branch_arr: [],
      status_arr: [],

      status_options: ['PENDING', 'APPROVED', 'REJECTED', 'DISBURSED'],
      branch_options: [],
      columns: [
        {
          label: 'Beneficiary Name',
          field: 'beneficiary_name',
          type: 'text',
        },
        {
          label: 'Voucher Type',
          field: 'type',
          type: 'text',
        },
        {
          label: 'Branch',
          field: 'branch.name',
          type: 'text',
        },
        {
          label: 'Amount Requested',
          field: 'amount_requested',
          type: 'text',
        },
        {
          label: 'Narration',
          field: 'narration',
          type: 'text',
        },
        {
          label: 'Status',
          field: 'status',
          type: 'text',
        },
        {
          label: 'Action',
          field: 'action',

          html: true,
          //   formatFn: this.renderActions,
        },
      ],
      rows: [],
      serverParams: {
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: [
          {
            field: '', // example: 'name'
            type: '' // 'asc' or 'desc'
          }
        ],
        page: 1, // what page I want to show
        perPage: 20 // how many items I'm showing per page
      },
    };
  },
  async created() {
    this.loadItems();
    this.getVoucherDropdown();
  },
  methods: {

    async getVoucherDropdown() {
      this.$Progress.start();
      await ApiService.get('/vouchers/dropdown')
        .then((response) => {
          this.$Progress.finish();
          this.branch_options = response.data.branches;
        }).catch((error) => {
          this.$Progress.fail();
          console.log(error);
        })
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    onSearch(event) {
      // console.log(event)
      this.loadItems(event.searchTerm)
    },
    filterTable() {
      this.loadItems();
    },
    // load items is what brings back the rows from server
    async loadItems(searchFilter = null) {
      //   getFromServer(this.serverParams).then(response => {
      //      this.totalRecords = response.totalRecords;
      //      this.rows = response.rows;
      //   });
      this.isLoading = true;
      // console.log(this.onBranchChange());
      await ApiService.post("/vouchers/paginate",
        {
          "page": this.serverParams.page,
          "per_page": this.serverParams.perPage,
          "filter": searchFilter,
          "branch_ids": this.branch_arr, //Eg. 1, 2, etc
          "statuses": this.status_arr
        })
        .then(response => {
          // console.log(response.data.data);
          this.rows = response.data.data;
          this.isLoading = false
          // this.per_page = response.data.meta.per_page;
          // console.log(response.data.meta.per_page)
          this.totalRecords = response.data.meta.total;

        }).catch(() => {
          // console.log(error);
          this.isLoading = false
        })
        .finally(() => {
          // this.isLoading = false
          this.isLoading = false
        });
    },
    rejected() {
      this.loadItems();
    },

    onBranchChange() {
      this.branch_arr = [];
      if (this.form.branch.length > 0) {
        this.form.branch.map((index) => {
          this.branch_arr.push(index.id);
        });
      }

    },

    onStatusChange() {
      this.status_arr = [];
      if (this.form.status.length > 0) {
        this.form.status.map((index) => {
          this.status_arr.push(index);
        });
      }
      // console.log(this.status_arr);

    },
    async disburseVoucher(index) {
      this.$Progress.start();
      this.isDisbursing = true;
      // console.log(this.form);
      await ApiService.post(`/vouchers/disburse/${index}`)
        .then((response) => {
          this.isDisbursing = false;
          this.$Progress.finish();
          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.loadItems();
        })
        .catch((error) => {
          console.log(error);
          this.isDisbursing = false;
          this.$Progress.fail();
          // if (error.response.data.errors) {
          //     this.form.errors = error.response.data.errors;
          // }
        });
    },
    exportToExcel() {
      const workbook = XLSX.utils.book_new();

      // Get the table data
      const tableData = this.$refs.userTable.filteredRows[0].children;

      // Convert the table data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Generate the Excel file buffer
      const excelBuffer = XLSX.write(workbook, {
        type: 'array',
        bookType: 'xlsx'
      });

      // Save the Excel file
      FileSaver.saveAs(
        new Blob([excelBuffer], { type: 'application/octet-stream' }),
        'table_data.xlsx'
      );
    },
  }
};
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

