<template>
    <div id="wrapper">
      <SidebarView :reports="true" />
      <div class="d-flex flex-column" id="content-wrapper">
        <div id="content">
          <TopbarView />
          <div class="container">
            <div class="card card-shape home-box">
              <div class="card-header py-3">    
                <div class="row align-items-center">
                  <div class="col-6">
                      <h6 class="m-0 fw-bold text-green">Voucher Reports</h6>
                  </div>
                  <div class="col-6 text-end">
                    <report-filter
                    :form="form"
                    @filterAction="filterAction"
                    :filter="filter"
                  ></report-filter>
                    <b-button @click="printPDF" class="me-2 btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon :icon="['fas', 'book']"/></b-button>
                    <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon :icon="['fas', 'download']"/></b-button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row" ref="pdfContent">
                  <div class="col-12 col-md-12">
                    <statement-institution
                      :institution="institution"
                      :current_date="false"
                    ></statement-institution>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8"></div>
                  <div class="col-md-4 text-end">
                    <span style="font-weight: bold" v-if="start_date && end_date">From {{ start_date }} to {{ end_date }}</span>
                  </div>
                </div>

                <div class="" style="text-align: center;">
                          <div class="fw-bold akkurate-green">VOUCHER REPORT</div>
                </div>
                <div v-if="isBusy" class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
                <div class="row">
                <div class="col-12 col-md-12 trans_details">
                  <b-table
                    id="chart-of-account"
                    :items="items"
                    :fields="fields"
                    striped
                    responsive="sm"
                    :per-page="perPage"
                    :busy.sync="isBusy"
                    :current-page="currentPage"
                    small
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <!-- <template #custom-foot>
                      <b-tr>
                        <b-th colspan="2"></b-th>
                        <b-th>TOTAL</b-th>
                        <b-th>{{ getDrTotal }}</b-th>
                        <b-th>{{ getCrTotal }}</b-th>
                        <b-th></b-th>
                        <b-th>{{ getTotal }}</b-th>
                      </b-tr>
                    </template> -->
                  </b-table>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-end">
                  <b-pagination
                    class="text-end"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="daily-control-tb"
                    text-align="right"
                  >
                  </b-pagination>
                  <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
                </div>
              </div>
                    

              </div>
            </div>
          </div>
        </div>
        <footer></footer>
      </div>
      <a class="d-inline scroll-to-top" href="#page-top"
        ><i class="fas fa-angle-up"></i
      ></a>
    </div>
  </template>
  <script>
  import SidebarView from "@/views/main/components/Sidebar.vue";
  import TopbarView from "@/views/main/components/Topbar.vue";
  import Footer from "@/views/main/components/Footer.vue";
  import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
  import ApiService from "@/core/services/api.service";
  import Multiselect from "vue-multiselect";
  import html2pdf from "html2pdf.js";
  import ReportFilter from "@/views/main/pages/reports/form/ReportFilter.vue";
  export default {
    components: {
      SidebarView,
      TopbarView,
      Footer,
      StatementInstitution,
      Multiselect,
      ReportFilter
    },
    data() {
      return {
        filter: {
          branches: [],
          voucher_status: [],
        },
        form: {
          start_date: this.getOneMonthEailer(),
          end_date: new Date().toISOString().substr(0, 10),
          branches: [],
          products: [],
          branch_options: [],
          product_options: [],
          users: [],
          users_options: [],
          status: [],
          coa: [],
          coa_options: [],
          terms: "Periodic",
          terms_options: ["YTD", "Periodic"],
          categories: [],
        },
        branch_arr: [],
        status_arr: [],
        search: "Search",
        isBusy: false,
        isSearching: false,
        selected_num: "10",
        numbers_options: [10, 50, 100, 500],
        isLoadingInstitution: false,
        institution: "",
        fields: [
          {
            key: "code",
            label: "Code",
          },
          {
            key: "beneficiary_name",
            label: "Beneficiary",
          },
          {
            key: "beneficiary_phone",
            label: "Phone",
          },
          {
            key: "cheque_number",
            label: "Cheque",
          },
          {
            key: "amount_requested",
            label: "Requested amount",
          },

          {
            key: "amount_approved",
            label: "Approved amount",
          },
          {
            key: "branch",
            label: "Branch",
          },
          {
            key: "status",
            label: "Status",
          },

        ],
        perPage: 20,
        currentPage: 1,
        items: [],
        serverParams: {
          // a map of column filters example: {name: 'john', age: '20'}
          columnFilters: {
          },
          sort: [
            {
              field: '', // example: 'name'
              type: '' // 'asc' or 'desc'
            }
          ],
          page: 1, // what page I want to show
          perPage: 20 // how many items I'm showing per page
        },
      };
    },
    computed: {
      rows() {
        return this.items.length;
      },
      sumLoanBalance() {
        let total = 0;
        for (let i = 0; i < this.items.length; i++) {
          total = this.items[i].loan_balance;
        }
        return total;
      },
      sumSavingBalance() {
        let total = 0;
        for (let i = 0; i < this.items.length; i++) {
          total = this.items[i].saving_balance;
        }

        return total;
      },
    },

    created() {
      this.getInstitution();
      this.getVoucherListing();
      this.getProductDropdown();
    },
    methods: {
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },

      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
      },

      onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage });
        this.loadItems();
      },

      onSortChange(params) {
        this.updateParams({
          sort: [{
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          }],
        });
        this.loadItems();
      },

      onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
      },
      onSearch(event) {
        // console.log(event)
        this.loadItems(event.searchTerm)
      },
      filterTable() {
        this.loadItems();
      },
      async getProductDropdown() {
        this.isBusy = true;
        this.search = "Processing...";
        await ApiService.query("/reports/controls/products/dropdown")
          .then((response) => {
            // console.log(response.data);
            this.isBusy = false;
            this.search = "Search";
            console.log(response.data.branches)
            this.form.branch_options = response.data.branches;
            // this.form.product_options = response.data.products;
            // this.form.coa_options = response.data.coa_subheads;
          })
          .catch((error) => {
            // console.log(error);
            this.isBusy = false;
            this.search = "Search";
          });
      },
      filterAction() {
        this.getVoucherListing();
        console.log(this.form);
        // this.changePerPageNumber(this.perPage);
        // this.form.start_date = 
        // this.getBalanceSheets();
      },
      async getVoucherListing() {
        this.items = [];
        this.isBusy = true;
        this.search = "Processing...";
        this.isSearching = true;
        await ApiService.post("/vouchers/paginate", {
          "page": this.serverParams.page,
          "per_page": this.serverParams.perPage,
          "start_date": this.form.start_date,
          "end_date": this.form.end_date,
          "filter": "",
          "branch_ids": this.form.branches.map(index => { return index.id }), //Eg. 1, 2, etc
          "statuses": this.form.status
        })
          .then((response) => {
            console.log(response);
            this.isBusy = false;
            this.search = "Search"
            this.isSearching = false;
            var res = response.data?.data;
            console.log(res);
            res.map((index) => {
              const data = {
                code: index.code,
                beneficiary_name: index.beneficiary_name,
                beneficiary_phone: index.beneficiary_phone,
                cheque_number: index.cheque_number,
                amount_requested: index.amount_requested,
                amount_approved: index.amount_approved,
                status: index.status,
                branch: index.branch.name,

              }
              this.items.push(data);
            });
          })
          .catch((error) => {
            this.isBusy = false;
            this.search = "Search"
            this.isSearching = false;
            console.log(error);
          });
      },
      downloadPDF() {
        const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        // console.log("here");
        const pdfOptions = {
          margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
          filename: "statement.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: "avoid-all" },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf()
          .from(content)
          .set(pdfOptions)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var insit_name = this.institution.name;
            var insit_tele = this.institution.telephone;
            var insit_email = this.institution.email;
            var insit_motto = this.institution.motto;

            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(
                [
                  "For Inquiries, Please Contact " +
                  insit_name +
                  ", Customer Service on " +
                  insit_tele +
                  "",
                  "or email us on " + insit_email + " " + insit_motto + "",
                  "Page " + i + " of " + totalPages,
                ],
                pdf.internal.pageSize.getWidth() / 2,
                pdf.internal.pageSize.getHeight() - 0.6,
                { align: "center" }
              );
            }
          })
          .save();
      },
      printPDF() {
        const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        // console.log("here");
        const pdfOptions = {
          margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
          filename: "statement.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: "avoid-all" },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf()
          .from(content)
          .set(pdfOptions)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var insit_name = this.institution.name;
            var insit_tele = this.institution.telephone;
            var insit_email = this.institution.email;
            var insit_motto = this.institution.motto;

            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(
                [
                  "For Inquiries, Please Contact " +
                  insit_name +
                  ", Customer Service on " +
                  insit_tele +
                  "",
                  "or email us on " + insit_email + " " + insit_motto + "",
                  "Page " + i + " of " + totalPages,
                ],
                pdf.internal.pageSize.getWidth() / 2,
                pdf.internal.pageSize.getHeight() - 0.6,
                { align: "center" }
              );
            }
            // Get the blob of the PDF
            return pdf.output("blob");
          })
          .then((pdfBlob) => {
            // Create a URL for the PDF blob
            var pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the URL in a new tab
            const printWindow = window.open(
              pdfUrl,
              "_blank",
              "width=800,height=600"
            );
            printWindow.onload = () => {
              printWindow.print();
              URL.revokeObjectURL(pdfUrl);
            };
          })
          .catch((error) => {
            console.error("Error generating PDF:", error);
          });
      },
      filterTable() {
        this.getReceiptListing();
      },
      changePerPageNumber(data) {
        this.perPage = data;
        this.getReceiptListing();
      },
      async getInstitution() {
        this.isLoadingInstitution = true;
        await ApiService.get("/settings/institution")
          .then((response) => {
            this.institution = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoadingInstitution = false;
          });
      },
    },
  };
</script>