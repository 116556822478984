<template>
<div class="row ps-2" >
        <div class="col-10 col-md-10">
            <div class="w-100 p-2" :class="[report_style == 'deposit' ? 'akkurate-deposit-border' : 'akkurate-withdrawal-border']">
                <p class="fw-bold mb-3 report-l-text">{{ title }}</p>
                <p class="mb-0 report-s-text text-uppercase">{{ institution?.name }}</p>
                <p class="mb-0 report-s-text">{{ institution?.postal }}</p>
                <p class="mb-0 report-s-text">TEL: {{ institution?.telephone }}/ MOB:{{ institution?.mobile }}</p>
                <p class="mb-0 report-s-text">EMAIL: {{ institution?.email }}</p>
            </div>
        </div>
        <div class="col-md-2 col-2">
            <div class="d-flex align-items-center justify-content-center">
            <img class="img-fluid img-visibility " :src="require('@/assets/img/ggbccu_logo.png')">
            </div>
            <div class="text-center">
                <p class="m-0 fw-bold fst-italic motto-small">{{ institution?.motto }}</p>
            </div>
        </div>
    </div>
</template>

<script>

// import config from '@/config'
export default {
    props: ['institution','title','report_style'],
//     data() {
//     return {
//       config: config
//     };
//   }
}
</script>