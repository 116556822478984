<template>
  <div class="d-inline-flex">
    <!-- <b-button  
       size="sm" 
       class="fw-semibold akkurate-green-btn" v-b-modal.add-account><span class="akkurate-small"><i class="fas fa-plus"></i> Add Acounts</span></b-button> -->
    <b-button
      class="btn-sm me-2 akkurate-green-btn akkurate-small" title="Filter" v-b-modal.report-filter>
      <font-awesome-icon :icon="['fas', 'filter']" />
    </b-button>
    <b-modal id="report-filter" ref="modal" size="lg" @ok="handleOk">
      <template #modal-title>
        <p class="akkurate-green small fw-bold">Filter</p>
      </template>
      <div class="row mb-3">
        <div class="col-12 col-md-6 mb-3">
          <label class="akkurate-dark small" for="start_date">Start Date</label>
          <b-form-group
            id="start_date"
            label-for="start_date"
            class="akkurate-dark">
            <b-form-input
              :disabled="disable_dates"
              id="start_date"
              class="mt-2 field-container fields"
              type="date"
              v-model="form.start_date"
              placeholder="Start Date"
            >
            </b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label class="akkurate-dark small" for="end_date">End Date</label>
          <b-form-group
            id="end_date"
            label-for="end_date"
            class="akkurate-dark"
          >
            <b-form-input
              :disabled="disable_dates"
              id="end_date"
              class="mt-2 field-container fields"
              type="date"
              v-model="form.end_date"
              placeholder="End Date"
            >
            </b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6 mb-3" v-if="filter.branches">
          <label class="akkurate-dark small" for="branch">Branches</label>
          <b-form-group id="branch" label-for="branch" class="akkurate-dark">
            <multiselect
              v-model="form.branches"
              :options="form.branch_options"
              :multiple="true"
              placeholder="Select branches"
              label="name"
              selectLabel=""
              deselectLabel=""
              class="mt-2 field-container"
              track-by="name">
              <!-- <template slot="selection">
                <span class="multiselect__single" v-if="form.branches.length">{{ form.branches.length }} options selected</span>
              </template> -->
            </multiselect>
            <!-- <div class="akkurate-small">{{ getBranch }}</div> -->
          </b-form-group>
        </div>

        <div class="col-12 col-md-6 mb-3" v-if="categories_options">
          <label class="akkurate-dark small" for="categories">Categories</label>
          <b-form-group id="categories" label-for="categories" class="akkurate-dark">
            <multiselect
              v-model="form.categories"
              :options="categories_options"
              :multiple="true"
              placeholder="Select Categories"
              class="mt-2 field-container"
              >
            </multiselect>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6" v-show="filter.products">
          <label class="akkurate-dark small" for="branch">Products</label>
          <b-form-group id="branch" label-for="branch" class="akkurate-dark">
            <multiselect
              v-model="form.products"
              :options="form.product_options"
              :multiple="true"
              placeholder="Select products"
              label="name"
              class="mt-2 field-container"
              track-by="id">
            </multiselect>
            <!-- <div class="akkurate-small">{{ getProduct }}</div> -->
          </b-form-group>
        </div>

        <div class="col-12 col-md-6" v-show="filter.users">
              <label class="akkurate-dark small" for="branch">Users</label>
              <b-form-group id="branch" label-for="branch" class="akkurate-dark">
                <multiselect
                v-model="form.users"
                :options="form.users_options"
                :multiple="true"
                placeholder="Select products"
                label="first_name"
                class="mt-2 field-container"
                track-by="first_name">
                <template slot="selection">
                    <span class="multiselect__single" v-if="form.users.length">{{ form.users.length }} options selected</span>
                </template>
                </multiselect>
                <div class="akkurate-small">{{ getUsers }}</div>
              </b-form-group>
        </div>
        <div class="col-12 col-md-6" v-show="filter.status || filter.voucher_status">
              <label class="akkurate-dark small" for="branch">Status</label>
              <b-form-group id="branch" label-for="branch" class="akkurate-dark">
                <multiselect
                v-model="form.status"
                :options="filter.status ? status_options : voucher_status_option"
                :multiple="true"
                class="mt-2 field-container"
                placeholder="select status">
                <template slot="selection">
                    <span class="multiselect__single" v-if="form.status.length">{{ form.status.length }} options selected</span>
                </template>
                </multiselect>
                <div class="akkurate-small">{{ getStatus }}</div>
              </b-form-group>
        </div>
        <div class="col-12 col-md-6 mb-3" v-show="filter.condition">
          <label class="akkurate-dark small" for="start_date">Condition</label>
          <div class="row mt-2">
          <div class="col-4 pe-0">
            <multiselect class="field-container" v-model="form.oprand" :options="options" placeholder="select"></multiselect>
          </div>
          <div class="col-8 ps-0">
            <b-form-input
              id="amount"
              class="field-container fields"
              type="text"
              v-model="form.amount"
              placeholder="Amount">
            </b-form-input>
          </div>
          
          
          </div>
        </div>
        <div class="col-12 col-md-6" v-show="filter.coa">
          <label class="akkurate-dark small" for="coa_subhead">Chart of Account</label>
          <b-form-group id="branch" label-for="branch" class="akkurate-dark">
            <multiselect
              v-model="form.coa"
              :options="form.coa_options"
              :multiple="!is_single_coa"
              placeholder="Select Coa Subhead(s)"
              :custom-label="coaSelectLabel"
              name="coa_subheads"
              class="mt-2 field-container"
              track-by="id">
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6" v-show="filter.terms">
          <label class="akkurate-dark small" for="type">Type</label>
          <b-form-group id="type" label-for="type" class="akkurate-dark">
            <multiselect
              class="field-container mt-2 mb-3"
              v-model="form.terms"
              name="terms"
              placeholder="Search or select a Terms"
              :options="form.terms_options"
              @select="changeTerms"
            ></multiselect>
          </b-form-group>
        </div>
      </div>
      <template #modal-footer="{ ok }">
        <div class="w-100">
          <b-button
            @click="$bvModal.hide('report-filter')"
            type="button"
            class="akkurate-grey-btn btn-size float-right border-0">
            Close
          </b-button>
          <b-button
            @click="ok()"
            type="submit"
            class="akkurate-green-btn btn-size me-2 float-right border-0">
            Search
          </b-button>
          
         
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
// import ApiService from "@/core/services/api.service";
export default {
  name: "ReportsFilter",
  // props: ["form","filter","categories_options","is_single_coa"],
  props: {
    form: {
      required: false
    },
    filter: {
      required: false
    },
    categories_options: {
      required: false,
    },
    is_single_coa: {
      required: false,
      default: false
    },
    current_financial_year: {
      required: false,
      default: null
    }
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      status_options: ["ACTIVE", "INACTIVE", "DORMANT"],
      voucher_status_option: ['PENDING', 'APPROVED', 'REJECTED', 'DISBURSED'],
      options: ['>', '<', '='],
      disable_dates: false,
    }
  },
  computed: {
    getProduct() {
      if (this.form.products.length > 0) {
        const result = this.form.products.map((index) => {
          return index.name;
        });
        // console.log("here");
        return result.join(" | "); // Join array elements with ", "
      } else {
        return "";
      }

    },
    getBranch() {
      if (this.form.branches.length > 0) {
        const result = this.form.branches.map((index) => {
          return index.name;
        });
        // console.log("here");
        return result.join(" | "); // Join array elements with ", "
      } else {
        return "";
      }

    },
    getUsers() {
      if (this.form.users.length > 0) {
        const result = this.form.users.map((index) => {
          return index.first_name;
        });
        // console.log("here");
        return result.join(" | "); // Join array elements with ", "
      } else {
        return "";
      }

    },
    getCOASubhead() {
      if (this.form.coa.length > 0) {
        const result = this.form.coa.map((index) => {
          return index.name;
        });
        // console.log(result);
        return result.join(" | "); // Join array elements with ", "
      } else {
        return "";
      }

    },
    getStatus() {
      if (this.form.status.length > 0) {
        const result = this.form.status.map((index) => {
          return index;
        });
        // console.log("here");
        return result.join(" | "); // Join array elements with ", "
      } else {
        return "";
      }

    },
  },
  methods: {
    changeTerms(value) {
      // this.form.terms = value;
      // console.log(value)
      if (value == "YTD" && this.current_financial_year) {
        this.form.start_date = this.current_financial_year.start_date
        this.form.end_date = this.current_financial_year.end_date
        this.disable_dates = true;
      }
      else {
        this.disable_dates = false;
      }
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    resetModal() {
      this.form.bracnhes = "";
      this.form.product = "";
      this.form.start_date = new Date().toISOString().substr(0, 10);
      this.form.end_date = new Date().toISOString().substr(0, 10);
    },
    coaSelectLabel({ name, code, coabranch }) {
      let select_label = `${code ? code : ""} - ${name}`
      if (coabranch) {
        select_label += `- ${coabranch.name}`
      }
      return select_label;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.isSaving = true;
        this.$emit("filterAction");
        this.$bvModal.hide("report-filter");
      });
      // Hide the modal manually
    },
  },
};
</script>