
UsersIndex

<template>
<div id="wrapper">
        <SidebarView :users="true" :user-visible="true" :all-users="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
            <div class="container-fluid">
              <!-- begin row  -->
              <div class="row">
                <div class="col-lg-12">
                  <!-- begin loader -->
                  <!-- <beat-loader-component
                    v-if="isLoading"
                    :isLoading="isLoading"
                  ></beat-loader-component> -->
                  <!-- end loader -->
                  <div>
              <div class="py-3 d-flex flex-row align-items-center justify-content-between">
                  <b-button  size="sm" class="fw-semibold akkurate-green-btn" type="button" href="/users/create">
                      <span class="akkurate-small"><i class="fas fa-plus"></i> Add User</span>
                    </b-button>
                    <b-link @click="exportToExcel" class="fw-semibold float-end me-3 akkurate-green"> <font-awesome-icon :icon="['fas', 'book']" /></b-link> 
                 </div>
            
              <div class="card card-shape home-box">
                <div class="card-header py-3 d-flex flex-row align-items-center">
                  <h6 class="m-0 fw-bold text-green">All Users</h6>
                </div>
                <div class="card-body">
                <vue-good-table
                styleClass="vgt-table bordered"
                mode="remote"
                ref="userTable"
                :columns="columns"
                :rows="users"
                :line-numbers="true"
                :isLoading.sync="isLoading"
                :totalRows="totalRecords"
                :search-options="{
                  enabled: true,
                  placeholder: 'Search by first name, last name, username, email, old account number',
                  trigger: 'enter'

                }"
                :sort-options="{
                  enabled: false
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: serverParams.perPage,
                }"
                
                  @on-search="onSearch"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  
                >
                 <!-- Add your custom delete button column -->
                 <template slot="table-row" slot-scope="props">
                  <span class="d-flex" v-if="props.column.field == 'action'">
                      <!-- <a title="View" class="btn btn-sm akkurate-warning text-white" @click="confirmDeleteUser(props.row)">
                          <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span> 
                      </a> -->
                      <users-view :data="props.row"></users-view>
                      <a title="Edit" class="btn btn-sm akkurate-primary border-0 text-white" :href="`/users/${props.row.id}/edit`">
                      <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'edit']" /></span> 
                      </a>
                      <a title="Delete" class="btn btn-sm akkurate-danger-btn border-0 text-white" @click="confirmDeleteUser(props.row)">
                        <span class="akkurate-small"><font-awesome-icon  :icon="['fas', 'trash']" /></span>
                      </a>
                  </span>
                  <span v-else-if="props.column.field == 'roles'">
                    <div>{{ props.row.roles['0'].display_name }}</div>
                  </span>
                </template>
              </vue-good-table>
              </div>
              </div>
            
          
            </div>
          </div>
        </div>
        <!-- end row  -->
                </div>
            </div>
            <Footer></Footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>

</template>

<script>

// import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";

// import UsersForm from "./../forms/UsersForm.vue";
import ApiService from "@/core/services/api.service";

import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';

import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
// import $ from 'jquery';
// import 'datatables.net';
// import config from '@/config.js'
// import JwtService from "@/core/services/jwt.service";
import UsersView from '../view/UsersView.vue'

export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    UsersView
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ path: '/login' });
    }
    // this.getUserOptions();
  },
  // data() {
  //   return 
  //   {
  //         // this.initialState(),
  //         roles: [],
  //   };
  // },
  data() {
    return {
      totalRecords: 0,
      searchFilter: "",
      isLoading: true,
      columns: [
        {
          label: 'First Name',
          field: 'first_name',
          type: 'text',
        },
        {
          label: 'Surname',
          field: 'surname',
          type: 'text',
        },
        {
          label: 'Username',
          field: 'username',
          type: 'text',
        },
        {
          label: 'Mobile',
          field: 'phone_number',
        },
        {
          label: 'Email',
          field: 'email',
          type: 'email',
        },
        {
          label: 'Role(s)',
          field: 'roles',
          type: 'text',
        },
        {
          label: 'Branch',
          field: 'branch.name',
          type: 'text',
        },
        {
          label: 'Action',
          field: 'action',

          html: true,
          //   formatFn: this.renderActions,
        },
      ],
      users: [],
      serverParams: {
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: [
          {
            field: '', // example: 'name'
            type: '' // 'asc' or 'desc'
          }
        ],
        page: 1, // what page I want to show
        perPage: 20 // how many items I'm showing per page
      },
    };
  },
  async created() {
    this.loadItems();
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    onSearch(event) {
      // console.log(event)
      this.loadItems(event.searchTerm)
    },
    // load items is what brings back the rows from server
    async loadItems(searchFilter = null) {
      //   getFromServer(this.serverParams).then(response => {
      //      this.totalRecords = response.totalRecords;
      //      this.rows = response.rows;
      //   });
      this.isLoading = true;
      await ApiService.post("users/paginate",
        {
          "page": this.serverParams.page,
          "per_page": this.serverParams.perPage,
          "filter": searchFilter,
        })
        .then(response => {
          // console.log(response.data.data)
          this.users = response?.data?.data;
          // this.per_page = response.data.meta.per_page;
          // console.log(response.data.meta.per_page)
          this.totalRecords = response.data?.meta?.total;

        }).catch(() => {
          // console.log(error);
        })
        .finally(() => {
          // this.isLoading = false
        });
    },

    confirmDeleteUser(user) {

      swal.fire({
        title: "Disable User",
        text: `Are you sure you want to disable ${user.username}?`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        allowOutsideClick: false
      }).then(result => {
        if (result.value) {
          //   window.location.reload();
        }
      });

    },
    exportToExcel() {
      const workbook = XLSX.utils.book_new();

      // Get the table data
      const tableData = this.$refs.userTable.filteredRows[0].children;

      // Convert the table data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Generate the Excel file buffer
      const excelBuffer = XLSX.write(workbook, {
        type: 'array',
        bookType: 'xlsx'
      });

      // Save the Excel file
      FileSaver.saveAs(
        new Blob([excelBuffer], { type: 'application/octet-stream' }),
        'table_data.xlsx'
      );
    }
  }
  // methods: {
  //   getUserOptions() {
  //     this.$Progress.start();
  //     this.isLoading = true;
  //     // axios
  //     //   .get("roles/dropdown")

  //     $(this.$refs.dataTable).DataTable({
  //       serverSide: true,
  //       ajax: {
  //         url: config.api_url + '/users/paginate', // Replace with your server-side endpoint URL
  //         type: 'POST',
  //         beforeSend: function (xhr) {
  //           // Set your authorization header here
  //           xhr.setRequestHeader('Authorization', 'Bearer ' + JwtService.getToken());
  //         },
  //         data: function (data) {

  //           //   this.isLoading = false;
  //           // Customize the request parameters as needed
  //           //   data.page = data.start / data.length + 1;
  //           //   data.pageSize = data.length;
  //           // Add any additional parameters you need, such as sorting information
  //         },
  //         dataSrc: function (json) {
  //           // Handle the server's response here
  //           // Update the DataTables table with the received data
  //           return json.data;
  //         },
  //       },
  //       columns: [
  //         // Define your table columns here
  //         // { data: 'id', title: 'ID' },
  //         { data: 'first_name', title: 'First Name' },
  //         { data: 'surname', title: 'Sur Name' },
  //         { data: 'email', title: 'Email' },
  //         { data: 'username', title: 'Username' },
  //         // Add more columns as needed
  //       ],
  //     });


  //   },
  // }
};
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

