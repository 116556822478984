<template>
    <div class="row">
           <div class="col-12 col-md-6">
               <!-- <label class="akkurate-dark" for="address">Address <star></star></label>
               <b-form-group>
              <b-form-textarea
               id="address"
               placeholder="Enter Address..."
               rows="3"
               max-rows="6"
               required
               ></b-form-textarea>
               </b-form-group> -->
               <b-form-group
                label-for="address"
                class="mb-2">
                <template #label>
                  <label class="akkurate-dark" for="address">Address <star></star></label>
                </template>
                  <b-form-textarea
                  class="mt-2 fields"
                  id="address"
                  v-model="form.address"
                  placeholder="Enter Address..."
                ></b-form-textarea>
              </b-form-group>
              
           </div>
           <div class="col-12 col-md-6">
              <label class="akkurate-dark" for="digital_address">Digital Address</label>
               <b-form-group
               id="digital_address"
               label-for="digital_address">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter Digital Address"
               v-model="form.digital_address"
               ></b-form-input>
               <errors v-if="form.errors.digital_address">
                  {{ form.errors.digital_address[0] }}
              </errors>
               </b-form-group>
           </div>
           <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="region">Region <star></star></label>
            <b-form-group
                id="region"
                label-for="region">
                <multiselect class="mt-2 field-container" 
                v-model="form.region" 
                :options="region_options"
                 label="name">
                </multiselect>
                <errors v-if="form.errors.regions">
                  {{ form.errors.regions[0] }}
                </errors>
            </b-form-group>
           </div>
           <div class="col-12 col-md-6">
           <label class="akkurate-dark" for="home_town">Home Town</label>
               <b-form-group
               id="home_town"
               label-for="home_town">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter Home Town"
               v-model="form.home_town"
               ></b-form-input>
               <errors v-if="form.errors.home_town">
                  {{ form.errors.home_town[0] }}
                </errors>
               </b-form-group>
           </div>
           <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="branch">Branch <star></star></label>
            <b-form-group
                id="branch"
                label-for="branch">
                <multiselect class="mt-2 field-container" 
                label="name" 
                v-model="form.branch"
                :options="branch_options">
                </multiselect>
                <errors v-if="form.errors.branch">
                  {{ form.errors.branch[0] }}
                </errors>
            </b-form-group>
           </div>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "AddressView",
  props: ['form','region_options','branch_options','add_or_edit'],
  components: {
    Multiselect
  },
}
</script>