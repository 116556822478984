<template>
  <!-- begin row  -->
  <div id="wrapper">
        <SidebarView :add-users="true" :user-visible="true" :users="true" />
        <div class="d-flex flex-column" id="content-wrapper">
        <div id="content">
          <TopbarView />
          <div class="container">
            <div class="row" v-if="isLoading">
              <div class="col-12">
                  <!-- begin loader -->
                  <beat-loader-component
                  
                  :isLoading="isLoading"
                  ></beat-loader-component>
                  <!-- end loader -->
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-12">
                <div class="card card-shape home-box">
                  <div class="card-header py-3 d-flex flex-row align-items-center">
                    <h6 class="m-0 fw-bold text-green">Edit User</h6>
                  </div>
                  <div class="card-body">
                    <form class="form" method="POST" @submit.prevent="updateUser">
                      <users-form :roles="roles" :form="form" :add_or_edit="add_or_edit" :branch_options="branches"></users-form>
                      <hr />
                      <div class="py-3">
                        <button type="submit" :disabled="isSaving" class="btn akkurate-green-btn text-white btn-size my-2 me-2">Update</button>
                        <!-- <button type="reset" class="btn akkurate-gray-btn text-white btn-size my-2 me-2">Reset</button> -->
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
        </div>
      <Footer></Footer>
      </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
</div>

  <!-- end row  -->
</template>

<script>
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import UsersForm from "./../forms/UsersForm.vue";
import ApiService from "@/core/services/api.service";

export default {
  components: { BeatLoaderComponent, UsersForm,SidebarView, TopbarView },
  // props: ["id"],
   mounted() {
     this.getUserJson();
  },
  data() {
    return {
      // Create a new form instance
      form: {
        // first_name: "",
        // last_name: "",
        // email: "",
        // phone_number: "",
        // username: "",
        // service_station:"",
        // omc_name:"",
        // location:"",
        // region:"",
        // address: "",
        // selected_role: {},
        // password: "",
        // password_confirmation: "",
        // active:"",
        // role_name:"",
        first_name: "",
        surname: "",
        middle_name: "",
        email: "",
        phone_number: "",
        username: "",
        address: "",
        selected_roles: [],
        password: "",
        password_confirmation: "",
        status: 1,
        role_name: "",
        branch: {},
        errors: []
      },
      isLoading: true,
      roles: [],
      branches: [],
      isSaving: false,
      add_or_edit: 'edit',
    };
  },
  methods: {
    getUserJson() {
      this.$Progress.start();
      this.isLoading = true;

      ApiService.get("/users/show/" + this.$route.params.id)
        .then((response) => {
          this.branches = response.data.branches;
          // console.log(response.data.user.roles[0]);
          this.roles = response.data.roles;
          // this.form.fill(response.data.user);
          this.form.first_name = response.data.user.first_name;
          this.form.surname = response.data.user.surname;
          this.form.middle_name = response.data.user.middle_name;
          this.form.email = response.data.user.email;
          this.form.phone_number = response.data.user.phone_number;
          this.form.username = response.data.user.username;
          this.form.address = response.data.user.address;
          this.form.status = response.data.user.is_active;
          // this.form.branch.id = response.data.user.branch_id;
          // this.form.branch = 
          
          //go thru the list of branches and select the branch that matches the user's branch
          this.form.branch = this.branches.find(branch => branch.id == response.data.user.branch_id);

          this.form.selected_roles = response.data.user.roles;
          this.isLoading = false;
          this.$Progress.finish();
          this.isLoading = false;
        }).catch((error) => {

          this.isLoading = false;
          this.$Progress.fail();
        });
    },
    updateUser() {
      this.isSaving = true;
      this.$Progress.start();

      ApiService.put("/users/update/" + this.$route.params.id, this.form)
        .then((response) => {
          // this.isLoadingSaving = false;
          this.$Progress.finish();

          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.isSaving = false;
          // this.form.reset();
          // this.form = this.initialState().form;
        })
        .catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          if (error.response.data.errors) {
            this.form.errors = error.response.data.errors;
          }
        });

    },
    
  },
};
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

