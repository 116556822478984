<template>
    <div>
       <b-button  size="sm" class="fw-semibold akkurate-green-btn" v-b-modal.add-nextofkin><span class="akkurate-small"><i class="fas fa-plus"></i> Add Next of Kin</span></b-button>
       <b-modal
        id="add-nextofkin"
        title="Add Next of Kin"
        ref="modal"
        size="lg"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk">
           <form ref="form" @submit.stop.prevent="handleSubmit" >
               <div class="row">
                <div class="col-md-6">
                       <b-form-group
                           label-for="surname"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="surname">Surname <star></star></label>
                           </template>
                           <b-form-input
                               id="surname"
                               v-model="form.surname"
                               v-validate="{ required: true }"
                               :state="validateState('surname')"
                               name="surname"
                               class="mt-2 field-container fields"
                               type="text"
                               required
                           ></b-form-input>
                           <b-form-invalid-feedback >
                               The Surname field is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.surname">
                                   {{ form.errors.surname[0] }}
                           </errors>
                       </b-form-group>
                   </div>
                   <div class="col-md-6">
                       <b-form-group
                           label-for="othername"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="othername">Other Name(s) <star></star></label>
                           </template>
                           <b-form-input
                               id="othername"
                               v-model="form.othername"
                               v-validate="{ required: true }"
                               :state="validateState('othername')"
                               name="othername"
                               class="mt-2 field-container fields"
                               type="text"
                               required
                           ></b-form-input>
                           <b-form-invalid-feedback >
                               The Surname field is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.othername">
                                   {{ form.errors.othername[0] }}
                           </errors>
                       </b-form-group>
                   </div>
                   
                   <div class="col-md-6">
                       <b-form-group
                           label-for="email"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="email">Email </label>
                           </template>
                           <b-form-input
                               id="email"
                               v-model="form.email"
                               name="email"
                               class="mt-2 field-container fields"
                               type="email"
                               required
                           ></b-form-input>
                           <b-form-invalid-feedback >
                               The Email field is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.email">
                                   {{ form.errors.email[0] }}
                           </errors>
                       </b-form-group>
                   </div>
                   <div class="col-12 col-md-6">
                        <label class="akkurate-dark" for="dob">Date of Birth </label>
                        <b-form-group
                        id="dob"
                        label-for="dob">
                        <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="date"
                        v-model="form.dob"
                        required
                        ></b-form-input>
                        <errors v-if="form.errors.dob">
                            {{ form.errors.dob[0] }}
                            </errors>
                        </b-form-group>
                    </div>
                   <div class="col-md-6">
                       <b-form-group
                           label-for="phone"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="phone">Phone Number <star></star></label>
                           </template>
                           <b-form-input
                               id="phone"
                               v-model="form.phone"
                               v-validate="{ required: true }"
                               :state="validateState('phone')"
                               name="phone"
                               class="mt-2 field-container fields"
                               type="number"
                               required
                           ></b-form-input>
                           <b-form-invalid-feedback >
                               The Phone number field is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.phone">
                                   {{ form.errors.phone[0] }}
                           </errors>
                       </b-form-group>
                   </div>
                   <div class="col-md-6">
                       <b-form-group
                           label-for="relation"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="relation">Relation <star></star></label>
                           </template>
                           <b-form-input
                               id="relation"
                               v-model="form.relation"
                               v-validate="{ required: true }"
                               :state="validateState('relation')"
                               name="relation"
                               class="mt-2 field-container fields"
                               type="text"
                               required
                           ></b-form-input>
                           <b-form-invalid-feedback >
                               The Relations field is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.relation">
                                   {{ form.errors.relation[0] }}
                           </errors>
                       </b-form-group>
                   </div>
                   <div class="col-12 col-md-6">
                        <b-form-group
                            label-for="address"
                            class="mb-2"
                            >
                            <template #label>
                            <label class="akkurate-dark" for="address">Address </label>
                            </template>
                            <b-form-textarea
                            class="mt-2 fields"
                            id="address"
                            name="address"
                            v-model="form.address"
                            placeholder="Enter Address..."
                            ></b-form-textarea>
                            <b-form-invalid-feedback >
                               The Address field is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.address">
                                   {{ form.errors.address[0] }}
                           </errors>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-6">
                        <b-form-group
                            label-for="address"
                            class="mb-2">
                            <template #label>
                            <label class="akkurate-dark" for="percentage_share">Percentage Share </label>
                            </template>
                            <b-form-input
                               id="percentage_share"
                               v-model="form.percentage_share"
                               v-validate="{ required: true }"
                               :state="validateState('percentage_share')"
                               name="percentage_share"
                               class="mt-2 field-container fields"
                               type="number"
                               required
                           ></b-form-input>
                            <b-form-invalid-feedback >
                               The Percentage Share field is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.percentage_share">
                                   {{ form.errors.percentage_share[0] }}
                           </errors>
                        </b-form-group>
                    </div>
                  
                   
               </div>
           </form>
          <template #modal-footer="{ ok }">
               <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
               <b-button @click="$bvModal.hide('add-nextofkin')" type="button" class="akkurate-grey-btn me-2 float-right">Close</b-button>
               <b-button @click="ok()" type="submit" class="akkurate-green-btn me-2 float-right">Save</b-button>     
               </div>
           </template>
           
       </b-modal>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
    name: "AddAccount",
    components: {
        Multiselect,
    },
    data() {
        return {
            form: {
                othername: "",
                surname: "",
                email: "",
                dob: "",
                phone: "",
                relation: "",
                address: "",
                percentage_share: "",
                errors: [],
            },
            isSaving: false,
            add_or_edit: "add",
        }
    },
    methods: {
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        resetModal() {
            this.form.othername = '';
            this.form.surname = '';
            this.form.dob = '';
            this.form.email = '';
            this.form.phone = '';
            this.form.relation = '';
            this.form.percentage_share = '';
            this.form.address = '';;
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }
                
                this.$emit('addNextOfKin', this.form)
                this.$bvModal.hide('add-nextofkin')

            });
            // Hide the modal manually

        }
    }
}
</script>