<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container" >
          <div class="card card-shape home-box">
            <div class="card-header py-3">
              <div class="row align-items-center">

                <div class="col-6">

                  <h6 class="m-0 fw-bold text-green">COA Heads Report</h6>
                </div>
                <div class="col-6 d-flex justify-content-end">

                  <report-filter
                      :form="form"
                      @filterAction="getBalanceSheets"
                      :filter="filter"
                      :current_financial_year="financial_year"
                    >
                  </report-filter>
                  <div class="" style="margin-right: 4px">

                      <b-button @click="exportToExcel" class="akkurate-green-btn download-btn float-right"><span class="small"><font-awesome-icon :icon="['fas', 'file-excel']" /> Excel</span></b-button>     
                  </div>
                  <div class="">

                      <b-button @click="exportToPDF" class="akkurate-green-btn download-btn float-right"><span class="small"><font-awesome-icon :icon="['fas', 'save']" /> PDF</span></b-button>     
                  </div>
                </div>
                </div>
                <div class="row">
                  <div class="col-6"></div>
                <!-- <div class="col-6 text-end">
                  <b-button @click="printPDF" class="me-2 btn-sm akkurate-green-btn akkurate-small" title="Print"><font-awesome-icon :icon="['fas', 'book']"/></b-button>
                  <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small" title="Download"><font-awesome-icon :icon="['fas', 'download']"/></b-button>
                </div> -->
              </div>
            </div>
            <div class="card-body" ref="pdfContent">
              <div class="row" >
                <div class="col-12 col-md-12">
                  <statement-institution
                    :institution="institution"
                    :current_date="false"
                  ></statement-institution>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8"></div>
                <div class="col-md-4 text-end">
                  <span style="font-weight: bold" v-if="start_date && end_date">From {{ start_date }} to {{ end_date }}</span>
                </div>
              </div>
              <div class="" style="text-align: center;">
                        <div class="fw-bold akkurate-green">COA HEADS SUMMARY</div>
              </div>
              <div v-if="isBusy" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                  <div v-else id="balance-sheet" class="row">
                    <div class="col-12 my-2">
                    </div>
                    <div class="col-12 col-md-12 px-0 trans_details">
                      <b-table-simple responsive outlined>
                        <!-- <b-thead>
                          <b-tr>
                            <b-th class="text-center fs-6 akkurate-green" colspan="2"><span>INCOMES</span></b-th>
                          </b-tr>
                        </b-thead> -->

                        <b-thead>
                            <b-tr>
                              <b-th class="" ><span>GL Code</span></b-th>
                              <b-th class="" ><span>Head Name</span></b-th>
                              <b-th class="" ><span>Type</span></b-th>
                              <!-- <b-th class="" ><span>Balance (GHS)</span></b-th> -->
                              <b-th class="" ><span>DR (GHS)</span></b-th>
                              <b-th class="" ><span>CR (GHS)</span></b-th>
                            </b-tr>
                          </b-thead>
                        <b-tbody>
                          <b-tr v-for="(value, item) in heads" :key="item" class="list-item">
                            <b-td  >{{ value.code }}</b-td>
                            <b-td  >{{ value.display_name }}</b-td>
                            <b-td  >{{ value.headtype?.name }}</b-td>
                            
                            <!-- <b-td>
                              <span  v-if="value.transactions.length > 0">
                                {{  Number(value.transactions[0].balance ? value.transactions[0].balance.toFixed(2) : 0).toLocaleString()  }}
                              </span>
                              <span  v-else>
                                0
                              </span>
                            </b-td> -->
                            <b-td>
                              <span >
                                {{  (displayNumber(value.adjusted_dr_amount)) }}
                              </span>
                            </b-td>
                            <b-td>
                              <span >
                                {{  (displayNumber(value.adjusted_cr_amount))}}
                              </span>
                            </b-td>
                            <b-td>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                        <!-- <b-tfoot>
                          <b-tr>
                            <b-th colspan="3">Total (GHS)</b-th>
                            <b-th>{{total_amount}}</b-th>
                          </b-tr>
                        </b-tfoot> -->

                        <tfoot>
                            <tr>
                                <th  scope="col">TOTAL: </th>
                                <th scope="col"  >DIFFERENCE: GHS {{ Number(difference ? difference.toFixed(2) : 0).toLocaleString()   }}</th>
                                <th scope="col"  ></th>
                                <th scope="col">{{  Number(total_debit ? total_debit.toFixed(2) : 0).toLocaleString()   }}</th>
                                <th scope="col">{{  Number(total_credit ? total_credit.toFixed(2) : 0).toLocaleString()   }}</th>
                            </tr>
                        </tfoot>
                      </b-table-simple>
                    </div>
                    
                  </div>
                  <!-- <div class="row">
                    <div class="col-12 col-md-12">
                      <b-table-simple responsive outlined>

                          <b-tbody>
                              <b-tr>
                            <b-th>Total (GHS)</b-th>
                            <b-th>{{ Number(grant_total_incomes ? grant_total_incomes.toFixed(2) : 0).toLocaleString()  }}</b-th>
                          </b-tr>
                        </b-tbody>
                        
                      </b-table-simple>
                    </div>
                  </div> -->
              </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"
      ><i class="fas fa-angle-up"></i
    ></a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import html2pdf from "html2pdf.js";
import ReportFilter from "@/views/main/pages/reports/form/ReportFilter.vue";
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    StatementInstitution,
    Multiselect,
        ReportFilter,
  },
  data() {
    return {
      financial_year: null,
      heads: [],
      profit_or_loss: 0,
      filter:{
        branch:"",
        start_date: "",
        end_date: "",
        year:"",
      },
      search:"Search",
      grant_total_expenses: 0,
      grant_total_incomes: 0,
      isBusy: false,
      isSearching:false,
      selected_num: "10",
      numbers_options: [10, 50, 100, 500],
      isLoadingInstitution: false,
      institution: "",
      start_date: "",
      end_date: "",
      perPage: 20,
      currentPage: 1,
      items: [],
      total_debit: 0,
      total_credit: 0,
      difference: 0,
      form: {
            start_date: this.getBeginningOfYear(),
            end_date: new Date().toISOString().substr(0, 10),
            branches: [],
            products: [],
            branch_options: [],
            product_options: [],
            users: [],
            users_options: [],
            status: [],
            coa: [],
            coa_options: [],
            terms: "Periodic",
            terms_options: ["YTD", "Periodic"],
            categories: [],
        },
        filter: {
            branches: true,
            products: false,
            users: false,
            status: false,
            condition: false,
            coa: false,
            terms: true,
        },
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    sumLoanBalance(){
      let total = 0;
      for(let i = 0; i < this.items.length; i++){
        total = this.items[i].loan_balance;
      }
      return total;
    },
    total_amount(){
      let total = 0;
      for(let i = 0; i < this.heads.length; i++){
        total += this.heads[i].transactions.length > 0 ? this.heads[i].transactions[0].balance : 0;
      }
      return total;
    },
    sumSavingBalance(){
      let total = 0;
      for(let i = 0; i < this.items.length; i++){
        total = this.items[i].saving_balance;
      }
      
      return total;
    },
    //assets total
  //   sumAssetsTotal(){
  //     let total = 0;
  //     for(let i = 0; i < this.incomes.length; i++){
  //       total = this.incomes[i].amount;
  //     }
  //     return total;
  //   },
  },

  created() {
    this.getInstitution();
    this.getBalanceSheets();
  },
  methods: {
    async getBalanceSheets() {
      this.items = [];
      this.isBusy = true;
      this.search = "Processing...";
      this.isSearching = true;
      await ApiService.post("/reports/financials/coa/coaheads/summary", this.form)
        .then((response) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          // var res = response.data?.data;
          var res = response.data;
          this.heads = res.heads;
          // this.grant_total_incomes = res.grant_total_incomes;
          // this.grant_total_expenses = res.grant_total_expenses;
          // this.profit_or_loss = this.grant_total_incomes - this.grant_total_expenses;
          // this.start_date = res.current_financial_year.start_date;
          // this.end_date = res.current_financial_year.end_date;
          this.total_debit = res.total_debit
          this.total_credit = res.total_credit
          this.difference = res.difference
          this.form.branch_options = response.data.branches;
        })
        .catch((error) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          console.log(error);
        });
    },
    
    exportToPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            const pdfOptions = {
                margin: 10,
                filename: 'COAHeadsReport_'+ this.getFormattedDateTime() + '.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            };
            // html2pdf().from(content).set(pdfOptions).outputPdf((pdf) => {

            // });
            html2pdf().from(content).set(pdfOptions).save();
        },

        exportToExcel() {
            const workbook = XLSX.utils.book_new();
            
            const tableData = this.heads.map((index) => {
                return {
                    code: index.code,
                    name: index.display_name,
                    type: index.headtype?.name,
                    dr_amount: index.adjusted_dr_amount,
                    cr_amount: index.adjusted_cr_amount,
                }
            });

            // Convert the table data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(tableData);

            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Generate the Excel file buffer
            const excelBuffer = XLSX.write(workbook, {
                type: 'array',
                bookType: 'xlsx'
            });

            // Save the Excel file
            FileSaver.saveAs(
                new Blob([excelBuffer], { type: 'application/octet-stream' }),
                'Coa_Heads_Summary_' + this.getFormattedDateTime() +  '.xlsx'
            );
        },
    filterTable() {
      this.getReceiptListing();
    },
    changePerPageNumber(data) {
      this.perPage = data;
      this.getReceiptListing();
    },
    async getInstitution() {
      this.isLoadingInstitution = true;
      await ApiService.get("/settings/institution")
        .then((response) => {
          this.institution = response.data.data;
          this.financial_year = response.data.financial_year;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingInstitution = false;
        });
    },
  },
};
</script>