<template>
    <div class="container px-5">
        <div class="row">
        <div class="col-md-6">
            <div class="col-12 text-center mb-3">
                <h6 class="akkurate-dark fw-bold" for="account_number">Source Account</h6>
            </div>
            <div class="col-12">
                <div class="row mb-3">
                    <div class="col-4 col-md-4 d-flex align-items-center">
                        <label class="akkurate-dark" for="account_number">Account Number</label>
                    </div>
                    <div class="col">
                        <b-form-group
                            id="account_number"
                            label-for="account_number">
                            <b-form-input
                            class="field-container fields"
                            type="text"
                            placeholder="Enter Account Number"
                            v-model="form.source_account"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-4 col-md-4 d-flex align-items-center">
                        <label class="akkurate-dark" for="full_name">Full Name</label>
                    </div>
                    <div class="col">
                        <b-form-group
                            id="full_name"
                            label-for="full_name">
                            <b-form-input
                            class="field-container fields"
                            type="text"
                            placeholder="Enter Full Name"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-4 col-md-4 d-flex align-items-center">
                        <label class="akkurate-dark" for="product">Product</label>
                    </div>
                    <div class="col">
                        <b-form-group
                            id="product"
                            label-for="product">
                            <multiselect 
                             class="field-container"
                             v-model="form.source_coa" 
                             :options="product_options">
                            </multiselect>
                        </b-form-group>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-4 col-md-4 d-flex align-items-center">
                        <label class="akkurate-dark" for="amount">Amount</label>
                    </div>
                    <div class="col">
                        <b-form-group
                            id="amount"
                            label-for="amount">
                            <b-form-input
                            class="field-container fields"
                            type="text"
                            placeholder="Enter Amount"
                            v-model="form.amount"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-4 col-md-4 d-flex align-items-center">
                        <label class="akkurate-dark" for="circle">Circle</label>
                    </div>
                    <div class="col">
                        <b-form-group
                            id="circle"
                            label-for="circle">
                            <multiselect
                             class="field-container" 
                             v-model="form.circle" 
                             :options="circle_options">
                            </multiselect>
                        </b-form-group>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-md-6">
            <div class="col-12 text-center mb-3">
                <h6 class="akkurate-dark fw-bold" for="account_number">Destination Account</h6>
            </div>
            <div class="col-12">
                <div class="row mb-3">
                    <div class="col-4 col-md-4 d-flex align-items-center">
                        <label class="akkurate-dark" for="account_number">Account Number</label>
                    </div>
                    <div class="col">
                        <b-form-group
                            id="account_number"
                            label-for="account_number">
                            <b-form-input
                            class="field-container fields"
                            type="text"
                            placeholder="Enter Account Number"
                            v-model="form.destination_account"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-4 col-md-4 d-flex align-items-center">
                        <label class="akkurate-dark" for="full_name">Full Name</label>
                    </div>
                    <div class="col">
                        <b-form-group
                            id="full_name"
                            label-for="full_name">
                            <b-form-input
                            class="field-container fields"
                            type="text"
                            placeholder="Enter Full Name"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-4 col-md-4 d-flex align-items-center">
                        <label class="akkurate-dark" for="product">Product</label>
                    </div>
                    <div class="col">
                        <b-form-group
                            id="product"
                            label-for="product">
                            <multiselect 
                            class="field-container" 
                            v-model="form.destination_coa" 
                            :options="product_options"></multiselect>
                        </b-form-group>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-4 col-md-4 d-flex align-items-center">
                        <label class="akkurate-dark" for="start_date">Start Date</label>
                    </div>
                    <div class="col">
                        <b-form-group
                            id="start_date"
                            label-for="start_date">
                            <b-form-input
                            class="field-container fields"
                            type="date"
                            v-model="form.start_date"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-4 col-md-4 d-flex align-items-center">
                        <label class="akkurate-dark" for="end_date">End Date</label>
                    </div>
                    <div class="col">
                        <b-form-group
                            id="end_date"
                            label-for="end_date">
                            <b-form-input
                            class="field-container fields"
                            type="date"
                            v-model="form.end_date"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>

            </div>
        </div>
        

        <div class="mt-auto col-12 text-end">
            <b-button href="" class="akkurate-green-btn mb-3 btn-size">Add Order</b-button>
        </div>
        </div>
    </div>
    
</template>
<script>
import Multiselect from 'vue-multiselect'
export default{
props:['form'],
components:{
    Multiselect
},
data(){
    return{
        product:"Saving",
        product_options:["Saving","Current"],
        circle:"Daily",
        circle_options:["Daily","Weekly", "Monthly","Yearly"],

    }
}
}
</script>