<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container">
          <div class="card card-shape home-box">
            <div class="card-header py-3">
              <div class="row align-items-center">
                <div class="col-6">
                    <h6 class="m-0 fw-bold text-green">Member Statistic</h6>
                </div>
                <div class="col-6 text-end">
                  <b-button @click="generateExcel" class="btn-sm me-2 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'file-excel']"/>
                  </b-button>
                  <b-button @click="printPDF" class="me-2 btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon :icon="['fas', 'book']" /></b-button>
                  <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon :icon="['fas', 'download']" /></b-button>
                </div>
              </div>

            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-form-select
                    v-model="selected_num"
                    @change="changePerPageNumber"
                    :options="numbers_options"
                  ></b-form-select>
                </div>
                </div>
                <div class="row" ref="pdfContent">
                  <div class="col-12 col-md-12">
                    <statement-institution
                      :institution="institution"
                      :title="'MEMBER STATISTIC'"
                    ></statement-institution>
                  </div>
                  <div class="col-12 col-md-12">
                    <beat-loader-component
                      v-if="isLoading"
                      :isLoading="isLoading"
                      ></beat-loader-component>
                  </div>
                  <div class="col-12 col-md-12 trans_details">
                    <b-table-simple responsive v-for="(item, index) in items" :key="index" ref="tableRef">
                      <b-thead>
                        <b-tr>
                          <b-th sticky-column>ACCOUNT TYPE</b-th>
                          <b-th class="w-25 text-uppercase">{{ item.account_type }}</b-th>
                          <b-th>MALE</b-th>
                          <b-th>FEMALE</b-th>
                          <b-th>TOTAL</b-th>
                          <b-th>ACTIVE</b-th>
                          <b-th>INACTIVE</b-th>
                          <b-th>DORMANT</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-th sticky-column>INDIVIDUAL</b-th>
                          <b-td>{{ item.individual.individual_total_count }}</b-td>
                          <b-td>{{ item.individual.individual_male_total_count }}</b-td>
                          <b-td>{{ item.individual.individual_female_total_count }}</b-td>
                          <b-td class="column-static">{{ formatNumber(item.individual.individual_total_value) }}</b-td>
                          <b-td>{{ item.individual.individual_total_active }}</b-td>
                          <b-td>{{ item.individual.individual_total_inactive }}</b-td>
                          <b-td>{{ item.individual.individual_total_dormant }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th sticky-column>GROUP</b-th>
                          <b-td>{{ item.group.group_total_count }}</b-td>
                          <b-td>{{ item.group.group_male_total_count }}</b-td>
                          <b-td>{{ item.group.group_female_total_count }}</b-td>
                          <b-td class="column-static">{{ formatNumber(item.group.group_total_value) }}</b-td>
                          <b-td>{{ item.group.group_total_active }}</b-td>
                          <b-td>{{ item.group.group_total_inactive }}</b-td>
                          <b-td>{{ item.group.group_total_dormant }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th sticky-column>CORPORATE</b-th>
                          <b-td>{{ item.corporate.corporate_total_count }}</b-td>
                          <b-td>{{ item.corporate.corporate_male_total_count }}</b-td>
                          <b-td>{{ item.corporate.corporate_female_total_count }}</b-td>
                          <b-td class="column-static">{{ formatNumber(item.corporate.corporate_total_value) }}</b-td>
                          <b-td>{{ item.corporate.corporate_total_active }}</b-td>
                          <b-td>{{ item.corporate.corporate_total_inactive }}</b-td>
                          <b-td>{{ item.corporate.corporate_total_dormant }}</b-td>
                        </b-tr>
                      </b-tbody>
                      <b-tfoot>
                        <b-tr>
                          <b-th sticky-column>TOTAL</b-th>
                          <b-th>{{ item.individual.individual_total_count + item.group.group_total_count + item.corporate.corporate_total_count }}</b-th>
                          <b-th>{{ item.individual.individual_male_total_count + item.group.group_male_total_count + item.corporate.corporate_male_total_count }}</b-th>
                          <b-th>{{ item.individual.individual_female_total_count + item.group.group_female_total_count + item.corporate.corporate_female_total_count }}</b-th>
                          <b-th>{{ formatNumber(item.individual.individual_total_value + item.group.group_total_value + item.corporate.corporate_total_value) }}</b-th>
                          <b-th>{{ item.individual.individual_total_active + item.group.group_total_active + item.corporate.corporate_total_active }}</b-th>
                          <b-th>{{ item.individual.individual_total_inactive + item.group.group_total_inactive + item.corporate.corporate_total_inactive }}</b-th>
                          <b-th>{{ item.individual.individual_total_dormant + item.group.group_total_dormant + item.corporate.corporate_total_dormant }}</b-th>
                        </b-tr>
                      </b-tfoot>
                    </b-table-simple>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-end">
                    <b-pagination
                      class="text-end"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      align="right"
                    ></b-pagination>
                    <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"
      ><i class="fas fa-angle-up"></i
    ></a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import html2pdf from "html2pdf.js";
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import ExcelJS from 'exceljs';

export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    StatementInstitution,
    Multiselect,
    BeatLoaderComponent
  },
  data() {
    return {
      filter: {
        branch: "",
        start_date: "",
        end_date: "",
        year: "",
      },
      search: "Search",
      isBusy: false,
      isSearching: false,
      isLoading: false,
      selected_num: "10",
      numbers_options: [10, 50, 100, 500],
      isLoadingInstitution: false,
      institution: "",
      fields: [
        {
          key: "account_type",
          label: "Account Type",
        },
        {
          key: "gender",
          label: "Gender",
        },
        {
          key: "product",
          label: "Product",
        },
        {
          key: "status",
          label: "Account Status",
        },

        {
          key: "balance",
          label: "Total Balance",
        },
      ],
      perPage: 20,
      currentPage: 1,
      items: [],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },

  created() {
    this.getInstitution();
    this.getMemberStatistic();
  },
  methods: {
    async getMemberStatistic() {
      this.items = [];
      this.isLoading = true;
      this.search = "Processing..."
      this.isSearching = true;
      await ApiService.get("/reports/controls/member/statistics", {
        params: {
          branch: "",
          startDate: this.filter.start_date,
          endDate: this.filter.end_date,
          year: this.filter.year,
        }
      })
        .then((response) => {
          this.isLoading = false;
          this.search = "Search"
          this.isSearching = false;
          this.items = response.data.accounts;
          console.log(response.data.accounts);
          // let res = response.data?.data;
          // res.map((index) => {
          //   index.accounts.map((acc) => {
          //     const data = {
          //       account_type: index.customer_type,
          //       gender: index.gender,
          //       product: acc.account_type?.name,
          //       status: acc.status,
          //       balance: acc.account_balance,
          //     };
          //     //  console.log(data);
          //     this.items.push(data);
          //   });
          // });
        })
        .catch((error) => {
          this.isLoading = false;
          this.search = "Search"
          this.isSearching = false;
          console.log(error);
        }).finally(() => {
          this.isLoading = false;
        })
    },
    downloadPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
        })
        .save();
    },
    printPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
          // Get the blob of the PDF
          return pdf.output("blob");
        })
        .then((pdfBlob) => {
          // Create a URL for the PDF blob
          var pdfUrl = URL.createObjectURL(pdfBlob);

          // Open the URL in a new tab
          const printWindow = window.open(
            pdfUrl,
            "_blank",
            "width=800,height=600"
          );
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
          };
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    },
    filterTable() {
      this.getMemberStatistic();
    },
    changePerPageNumber(data) {
      this.perPage = data;
      this.getMemberStatistic();
    },
    async getInstitution() {
      this.isLoadingInstitution = true;
      await ApiService.get("/settings/institution")
        .then((response) => {
          this.institution = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingInstitution = false;
        });
    },

    async generateExcel() {
      const fileUrl = '/REPORT_TEMPLATE.xlsx'; // Path to the file in the public folder

      try {
        // Fetch the Excel file from the public URL
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();

        // Load the file into ExcelJS
        const wb = new ExcelJS.Workbook();
        await wb.xlsx.load(arrayBuffer);

        // Access the first sheet
        const ws = wb.getWorksheet(1); // Get the first worksheet

        // Define header row
        const header = [
          'ACCOUNT TYPE',
          'COUNT', // Use a placeholder if you want to set it dynamically later
          'MALE',
          'FEMALE',
          'TOTAL',
          'ACTIVE',
          'INACTIVE',
          'DORMANT'
        ];



        // Add header row starting from row 13
        const startingRow = 13;
        let headerRow = ws.getRow(startingRow);
        headerRow.values = header;
        headerRow.font = { bold: true };
        headerRow.commit(); // Commit the row to the worksheet

        // Add individual data rows
        let currentRow = startingRow + 1;
        this.items.forEach((item) => {
          const individualRow = ws.getRow(currentRow);
          individualRow.values = [
            item.account_type + ' (Individual)',
            item.individual.individual_total_count,
            item.individual.individual_male_total_count,
            item.individual.individual_female_total_count,
            this.formatNumber(item.individual.individual_total_value),
            item.individual.individual_total_active,
            item.individual.individual_total_inactive,
            item.individual.individual_total_dormant,
          ];
          individualRow.commit(); // Commit the individual data row to the worksheet
          currentRow++; // Move to the next row
        });

        // Add a blank row for separation
        const blankRow = ws.getRow(currentRow);
        blankRow.values = [];
        blankRow.commit(); // Commit the blank row to the worksheet
        currentRow++; // Move to the next row

        // Add group data rows
        this.items.forEach((item) => {
          const corporateRow = ws.getRow(currentRow);
          corporateRow.values = [
            item.account_type + ' (GROUP)',
            item.group.group_total_count,
            item.group.group_male_total_count,
            item.group.group_female_total_count,
            this.formatNumber(item.group.group_total_value),
            item.group.group_total_active,
            item.group.group_total_inactive,
            item.group.group_total_dormant,
          ];
          corporateRow.commit(); // Commit the group data row to the worksheet
          currentRow++; // Move to the next row
        });

        // Add a blank row for separation
        const emptyRow = ws.getRow(currentRow);
        emptyRow.values = [];
        emptyRow.commit(); // Commit the blank row to the worksheet
        currentRow++; // Move to the next row

        // Add group data rows
        this.items.forEach((item) => {
          const groupRow = ws.getRow(currentRow);
          groupRow.values = [
            item.account_type + ' (CORPORATE)',
            item.corporate.corporate_total_count,
            item.corporate.corporate_male_total_count,
            item.corporate.corporate_female_total_count,
            this.formatNumber(item.corporate.corporate_total_value),
            item.corporate.corporate_total_active,
            item.corporate.corporate_total_inactive,
            item.corporate.corporate_total_dormant,
          ];
          groupRow.commit(); // Commit the group data row to the worksheet
          currentRow++; // Move to the next row
        });

        // Add a blank row for separation
        const finalemptyRow = ws.getRow(currentRow);
        finalemptyRow.values = [];
        finalemptyRow.commit(); // Commit the blank row to the worksheet
        currentRow++; // Move to the next row

        // Add group data rows
        this.items.forEach((item) => {
          const totalRow = ws.getRow(currentRow);
          totalRow.values = [
            item.account_type + ' (TOTAL)',
            item.corporate.corporate_total_count + item.group.group_total_count + item.individual.individual_total_count,
            item.corporate.corporate_male_total_count + item.group.group_male_total_count + item.individual.individual_male_total_count,
            item.corporate.corporate_female_total_count + item.group.group_female_total_count + item.individual.individual_female_total_count,
            this.formatNumber(item.corporate.corporate_total_value) + this.formatNumber(item.group.group_total_value) + this.formatNumber(item.individual.individual_total_value),
            item.corporate.corporate_total_active + item.group.group_total_active + item.individual.individual_total_active,
            item.corporate.corporate_total_inactive + item.group.group_total_inactive + item.individual.individual_total_inactive,
            item.corporate.corporate_total_dormant + item.group.group_total_dormant + item.individual.individual_total_dormant,
          ];
          totalRow.commit(); // Commit the group data row to the worksheet
          currentRow++; // Move to the next row
        });


        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a download link and simulate a click to download the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Modified_REPORT_TEMPLATE.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
      }
    }

  },
};
</script>

<style scoped>
.column-static {
  width: 150px !important
}
</style>