<template>
    <div id="wrapper">
      <SidebarView
        :loan="true"
        :repayLoan="true"
        :loanVisible="true"
      />
      <div class="d-flex flex-column" id="content-wrapper">
        <div id="content">
          <TopbarView />
  
          <div class="container-fluid">
            <div>
    <form @submit.prevent="submitFormFilters">
    <div class="row mb-4">
      
           <div class="col-12 col-md-2">
             <label class="akkurate-dark" for="mobile">Phone number/ Code</label>
                <b-form-group
                id="mobile"
                label-for="mobile"
                class="akkurate-dark">
                <b-form-input
                id="mobile" 
                class="mt-2 field-container fields"
                type="text" 
                v-model="form.mobile"
                placeholder="Customer Code/ Phone No"
                
                >
                </b-form-input>
            </b-form-group>
           </div>
           <div class="col-12 col-md-1">
             <label class="akkurate-dark" for="name">Ref. ID:</label>
                <b-form-group
                id="name"
                label-for="name"
                class="akkurate-dark">
                <b-form-input
                id="name" 
                class="mt-2 field-container fields"
                type="text" 
                v-model="form.code"
                placeholder="Reference ID"
                >
                </b-form-input>
            </b-form-group>
           </div>
           <div class="col-12 col-md-2">
             <label class="akkurate-dark" for="old_account">Loan Number</label>
                <b-form-group
                id="loan_number"
                label-for="loan_number"
                class="akkurate-dark">
                <b-form-input
                id="loan_number" 
                class="mt-2 field-container fields"
                type="text" 
                v-model="form.loan_number"
                placeholder="Loan Number"
                >
                </b-form-input>
            </b-form-group>
           </div>
           <div class="col-12 col-md-2">
             <label class="akkurate-dark" for="source">Source</label>
                <b-form-group
                id="source"
                label-for="source"
                class="akkurate-dark">
                <multiselect class="mt-2 field-container" 
                v-model="form.source" 
                :options="sources"
                placeholder="Select Source"
                selectLabel=""
                deselectLabel=""
                label="name"
                >
                </multiselect>
            </b-form-group>
           </div>
          <div class="col-md-2" >
            <label class="akkurate-dark" for="start_date">From Date</label>
                <b-form-group
                label-for="start_date"
                class="akkurate-dark">
                <b-form-input
                id="start_date" 
                class="field-container fields"
                type="date" 
                v-model="form.start_date"
                >
                </b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-2" >
            <label class="akkurate-dark" for="end_date">To Date</label>
                <b-form-group
                label-for="end_date"
                class="akkurate-dark">
                <b-form-input
                id="end_date" 
                class="field-container fields"
                type="date" 
                v-model="form.end_date"
                >
                </b-form-input>
            </b-form-group>
          </div>

           <div class="d-grid gap-2 col-12 col-md-1 mt-auto">
             <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100">Search</b-button>
           </div>
    </div>
    </form>
</div>

            <div class="row">
              <div class="col-md-12">
                <!-- <transaction-form
                  :form="form"
                  @submitFormFilters="submitFormFilters"
                ></transaction-form> -->
              </div>
            </div>
            <div class="card card-shape home-box">
              <div class="card-header py-3 d-flex flex-row align-items-center">
                <h6 class="m-0 fw-bold text-green">All Loan Repayments</h6>
              </div>
              <div class="card-body">
                <div>
                  <vue-good-table
                    styleClass="vgt-table bordered"
                    mode="remote"
                    ref="allAccountTable"
                    :columns="trans_columns"
                    :rows="rows"
                    :isLoading.sync="isLoading"
                    :totalRows="totalRecords"
                    :pagination-options="{
                      enabled: true,
                      perPage: serverParams.perPage,
                      setCurrentPage: serverParams.page,
                    }"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span class="d-flex" v-if="props.column.field == 'action'">
                        <!-- <div title="Print" class="dropdown no-arrow">
                          <a
                            title="Print"
                            class="btn btn-sm akkurate-gray-btn text-white dropdown-toggle"
                            id="dropdownMenu"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span class="akkurate-small">
                              <font-awesome-icon :icon="['fas', 'book']" />
                            </span>
                          </a>
                          <div
                            class="dropdown-menu shadow animated--fade-in"
                            aria-labelledby="dropdownMenu"
                          >
                            <div class="dropdown-header">Printout</div>
                            <button
                              v-if="props.row.trans_status == 'COMPLETED'"
                              class="dropdown-item"
                              @click="printReceipt(props.row)"
                            >
                              Print receipts
                            </button>
                            <button
                              @click="chequePrintout(props.row)"
                              v-if="
                                props.row.transaction_mode == 'Cheque' &&
                                props.row.transaction_type == 'Withdrawal'
                              "
                              class="dropdown-item"
                            >
                              Cheque print
                            </button>
                            <button
                              @click="chequeTemplate(props.row)"
                              v-if="
                                props.row.transaction_mode == 'Cheque' &&
                                props.row.transaction_type == 'Withdrawal'
                              "
                              class="dropdown-item"
                            >
                              Cheque voucher
                            </button>
                          </div>
                        </div> -->
                        <!-- <all-trans-view
                          :key="props.row.id + 'View'"
                          :data="props.row"
                          :transaction="'View'"
                        ></all-trans-view> -->
  
                        <a
                          v-if="
                            props.row.is_reversed == 0 &&
                            props.row.trans_status == 'COMPLETED'
                          "
                          title="Reverse"
                          class="btn btn-sm akkurate-primary text-white"
                          @click="reverseLoanRepaymt(props.row)"
                        >
                          <span class="akkurate-small"
                            ><font-awesome-icon :icon="['fas', 'backward']"
                          /></span>
                        </a>
  
                      </span>
  
                      <span class="d-flex" v-else-if="props.column.field == 'user'">
                        {{ props.row.created_user?.first_name }} {{ props.row.created_user?.surname }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer></footer>
      </div>
      <a class="d-inline scroll-to-top" href="#page-top"
        ><i class="fas fa-angle-up"></i
      ></a>
  
      <!-- <view-transaction-modal
        :showViewTransaction="showViewTransaction"
      ></view-transaction-modal> -->
      <cash-deposit
        ref="showCashDeposit"
        :report_data="report_data"
      ></cash-deposit>
      <cash-withdrawal
        ref="showCashWithdrawal"
        :report_data="report_data"
      ></cash-withdrawal>
      <cheque-deposit
        ref="showChequeDeposit"
        :report_data="report_data"
      ></cheque-deposit>
      <cheque-withdrawal
        ref="showChequeWithdrawal"
        :report_data="report_data"
      ></cheque-withdrawal>
      <momo-deposit
        ref="showMomoDeposit"
        :report_data="report_data"
      ></momo-deposit>
      <momo-withdrawal
        ref="showMomoWithdrawal"
        :report_data="report_data"
      ></momo-withdrawal>
      <direct-debit-deposit
        ref="showDirectDebitDeposit"
        :report_data="report_data"
      ></direct-debit-deposit>
      <direct-debit-withdrawal
        ref="showDirectDebitWithdrawal"
        :report_data="report_data"
      ></direct-debit-withdrawal>
      <cheque-printout
        ref="showChequePrintout"
        :report_data="report_data"
      ></cheque-printout>
      <cheque-template
        ref="showChequeTemplate"
        :report_data="report_data"
      ></cheque-template>
    </div>
  </template>
  <script>
  import SidebarView from "@/views/main/components/Sidebar.vue";
  import TopbarView from "@/views/main/components/Topbar.vue";
  import Footer from "@/views/main/components/Footer.vue";
  // import TransactionForm from "./form/TransactionForm.vue";
  import ApiService from "@/core/services/api.service";
  // import ViewTransactionModal from "./modals/ViewTransactionModal.vue";
  // import AllTransView from "./view/AllTransView.vue";
  import ChequeDeposit from "@/views/main/pages/transactions/reports/cheque/ChequeDeposit.vue";
  import ChequeWithdrawal from "@/views/main/pages/transactions/reports/cheque/ChequeWithdrawal.vue";
  import CashDeposit from "@/views/main/pages/transactions/reports/cash/CashDeposit.vue";
  import CashWithdrawal from "@/views/main/pages/transactions/reports/cash/CashWithdrawal.vue";
  import MomoDeposit from "@/views/main/pages/transactions/reports/momo/MomoDeposit.vue";
  import MomoWithdrawal from "@/views/main/pages/transactions/reports/momo/MomoWithdrawal.vue";
  import DirectDebitDeposit from "@/views/main/pages/transactions/reports/direct-debit/DirectDebitDeposit.vue";
  import DirectDebitWithdrawal from "@/views/main/pages/transactions/reports/direct-debit/DirectDebitWithdrawal.vue";
  import ChequePrintout from "../reports/printout/ChequePrintout.vue";
  import ChequeTemplate from "../reports/printout/ChequeTemplate.vue";
  import Multiselect from 'vue-multiselect'
  export default {
    components: {
      Multiselect,
      SidebarView,
      TopbarView,
      Footer,
      // TransactionForm,
      // ViewTransactionModal,
      // AllTransView,
      CashDeposit,
      CashWithdrawal,
      ChequeDeposit,
      ChequeWithdrawal,
      MomoDeposit,
      MomoWithdrawal,
      DirectDebitDeposit,
      DirectDebitWithdrawal,
      ChequePrintout,
      ChequeTemplate,
    },
    data() {
      return {
        ...this.initialState(),
        sources: [{name: 'USSD', id: "USSD"}, {name: 'WEB', id: "WEB"}, {name: 'MOBILE BANKER', id: "Mobile_Banker"}],
        trans_columns: [
          {
            label: "Reference",
            field: "code",
          },
          {
            label: "Full name",
            //field: 'customer.first_name',
            field: this.getFullname,
            title: this.getFullname,
          },
          {
            label: "Amount",
            field: "trans_amount",
          },
          {
            label: "Loan Number",
            field: "account.loan.loan_number",
          },
          {
            label: "Phone number",
            field: "customer.mobile",
          },
          {
            label: "Ext. Ref",
            field: "external_reference",
          },
          {
            label: "Status",
            field: "trans_status",
          },
          {
            label: "Date",
            //field: 'created_at',
            // field: this.getCreatedAt,
            field: 'trans_date'
          },
          {
            label: "Source",
            field: "source",
          },
          {
            label: "User",
            field: "user",
          },
          {
            label: "Action",
            field: "action",
            html: true,
            //   formatFn: this.renderActions,
          },
        ],
        rows: [],
        serverParams: {
          columnFilters: {},
          sort: [
            {
              field: "", // example: 'name'
              type: "", // 'asc' or 'desc'
            },
          ],
          page: 1, // what page I want to show
          perPage: 20, // how many items I'm showing per page
        },
      };
    },
    mounted() {
      this.getAccountDropdown();
      this.getInsistution();
      this.loadTransactions();
    },
    methods: {
      submitFormFilters() {
        //this.serverParams.page = 1;
        //currentPage
        this.updateParams({ page: 1 });
        this.loadTransactions();
      },
      updateParams(newProps) {
        //console.log("new parms", newProps)
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
  
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadTransactions();
      },
  
      onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage });
        this.loadTransactions();
      },
  
      onSortChange(params) {
        this.updateParams({
          sort: [
            {
              type: params.sortType,
              field: this.columns[params.columnIndex].field,
            },
          ],
        });
        this.loadTransactions();
      },
  
      onColumnFilter(params) {
        this.updateParams(params);
        this.loadTransactions();
      },
      async getAccountDropdown() {
        await ApiService.get("/accounts/create/dropdown")
          .then((response) => {
            // console.log(response);
            this.form.branch_option = response.data.branches;
            this.form.acc_types_option = response.data.account_types;
          })
          .catch((error) => {
            // console.log(error);
          });
      },
      async getInsistution() {
        await ApiService.get("/settings/institution")
          .then((response) => {
            // console.log(response);
            this.institution = response.data.data;
          })
          .catch((error) => {
            // console.log(error);
          });
      },
      async loadTransactions(searchFilter = null) {
        this.isLoading = true;
        var branch_id = "";
        var acc_id = "";
        if (this.form.branch != null) {
          branch_id = this.form.branch.id;
        }
        if (this.form.acc_types != null) {
          acc_id = this.form.acc_types.id;
        }
  
        var source = null
        if(this.form.source){
          source = this.form.source["id"];
        }
  
        this.$Progress.start();
        this.isLoading = true;
        await ApiService.post("/loans/repayments/all", {
          page: this.serverParams.page,
          per_page: this.serverParams.perPage,
          filter: this.form.account_number,
          branch_id: branch_id,
          account_type_id: acc_id,
          loan_number: this.form.loan_number,
          mobile: this.form.mobile,
          start_date: this.form.start_date,
          end_date: this.form.end_date,
          status: this.form.status, //Inactive,	Active, Dormant, Closed
          code: this.form.code,
          transaction_type: this.form.transaction_type,
          external_reference: this.form.external_reference,
          source: source
        })
          .then((response) => {
            // console.log(response.data.data);
            this.rows = response.data.data;
            this.totalRecords = response.data.meta.total;
            // this.all_data = response.data.data;
            this.$Progress.finish();
          })
          .catch((error) => {
            // console.log(error);
            this.isLoading = false;
            this.$Progress.fail();
          })
          .finally(() => {
            this.isLoading = false;
            this.$Progress.finish();
          });
      },
  
      filterTable() {
        this.loadTransactions();
      },
      initialState() {
        return {
          totalRecords: 0,
          showViewTransaction: false,
          searchFilter: "",
          form: {
            source: null,
            branch: null,
            branch_option: [],
            acc_types: "",
            acc_types_option: [],
            mobile: "",
            loan_number: "",
            start_date: "",
            end_date: "",
            status: "",
            code: "",
            external_reference: "",
            transaction_type: "",
            status_option: ["Active", "Inactive", "Dormant", "Closed"],
          },
          isLoading: true,
          isSaving: false,
          add_or_edit: "add",
          report_data: {},
          institution: {},
        };
      },
  
      reverseLoanRepaymt(trans) {
        swal
          .fire({
            title: "Reverse Loan Repayment",
            text: `Are you sure you want to reverse loan repayment transaction id ${trans.code}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes Reverse !!!",
            allowOutsideClick: true,
          })
          .then((result) => {
            if (result.value) {
              //   window.location.reload();
              let url = "/loans/transactions/reverse";
  
              this.$Progress.start();
              ApiService.post(url, {
                code: trans.code,
                transaction_id: trans.id,
                source: "Web",
              })
                .then((respp) => {
                  // console.log(respp);
                  //this.isLoading = false;
                  this.$Progress.finish();
                  toast.fire({
                    icon: "success",
                    title: respp.data.message,
                  });
  
                  this.loadTransactions();
                })
                .catch((error) => {
                  this.$Progress.fail();
                });
            }
          });
      },
      viewTransaction(trans) {
        // console.log("Veeeew");
        this.showViewTransaction = true;
      },
      getFullname(trans) {
        return `${trans.customer.first_name} ${trans.customer.last_name}`;
      },
      getCreatedAt(trans) {
        return this.removeAfterDot(trans.created_at);
      },
      approveWithdrawal(trans) {
        swal
          .fire({
            title: "Approve Withdrawal!",
            // title: "Successfull Saved",
            text: `Are you sure you want to approve withdrawal transaction ${trans.code}?`,
            // text: 'CODE : 14345664565',
            confirmButtonColor: "#018673",
            icon: "info",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: true, // Show the "Cancel" button
            confirmButtonText: "Yes", // Customize the text for the "OK" button
            cancelButtonText: "No", // Text for the custom button
            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
            customClass: {
              confirmButton:
                "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
              cancelButton: "btn akkurate-ash modal-btn-width text-white", // Custom class for the custom button
            },
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await ApiService.post("/transactions/withdrawals/approve", {
                transaction_id: trans.id,
                code: trans.code,
              })
                .then((respp) => {
                  toast.fire({
                    icon: "success",
                    title: respp.data.message,
                  });
  
                  this.loadTransactions();
                })
                .catch((error) => {
                  if (error.response.data.message) {
                    toast.fire({
                      icon: "error",
                      title: error.response.data.message,
                    });
                  } else {
                    toast.fire({
                      icon: "error",
                      title: "An error occurred",
                    });
                  }
                })
                .finally((resp) => {
                  this.isLoading = false;
                });
            } else if (result.isDismissed) {
            }
          });
      },
      removeAfterDot(inputString) {
        if (inputString.includes(".")) {
          return inputString.split(".")[0];
        }
        return inputString; // If there's no dot, return the original string
      },
      // approveWithdrawal(data)
      // {
      //     swal.fire({
      //                     title: "Confirm Withdrawal Aproval",
      //                     // text: 'CODE : ' + response.data.transaction.code,
      //                     text: 'Are you sure you want to approve this withdrawal?',
      //                     confirmButtonColor: "#018673",
      //                     icon: 'question',
      //                     allowOutsideClick: false,
      //                     allowEscapeKey: false,
      //                     showCancelButton: true, // Show the "Cancel" button
      //                     confirmButtonText: 'Yes', // Customize the text for the "OK" button
      //                     cancelButtonText: 'No', // Text for the custom button
      //                     buttonsStyling: false, // Disable SweetAlert2 styling for buttons
      //                     customClass: {
      //                         confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
      //                         cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
      //                     }
      //                 }).then((result) => {
      //                     console.log(result)
      //                     // this.$Progress.start();
      //                     if (result.isConfirmed) {
      //                         this.isLoading = true;
      //                         // this.getAccountStatement();
      //                         // // this.isLoading = false;
      //                         // this.$Progress.finish();
  
      //                         this.loadTransactions();
      //                         // this.$bvModal.hide(String(this.loan_no + this.statement));
      //                     } else if (result.isDismissed) {
      //                         this.isLoading = false;
      //                         // this.$Progress.finish();
  
      //                     }
      //                 })
      // },
      rejectWithdrawal(data) {
        swal
          .fire(
            {
              title: "Confirm Withdrawal Rejection",
              // text: 'CODE : ' + response.data.transaction.code,
              text: "Are you sure you want to reject this withdrawal?",
              confirmButtonColor: "#018673",
              icon: "question",
              type: "input",
              allowOutsideClick: false,
              allowEscapeKey: false,
              showCancelButton: true, // Show the "Cancel" button
              confirmButtonText: "Yes", // Customize the text for the "OK" button
              cancelButtonText: "No", // Text for the custom button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton: "btn btn-danger modal-btn-width text-white me-2", // Custom class for the "OK" button
                cancelButton: "btn akkurate-ash modal-btn-width text-white", // Custom class for the custom button
              },
            },
            function (inputValue) {
              if (inputValue === null) return false;
  
              if (inputValue === "") {
                // swal.showInputError("You need to write something!");
                return false;
              }
  
              // swal("Nice!", "You wrote: " + inputValue, "success");
            }
          )
          .then((result) => {
            console.log("THEN");
            // this.$Progress.start();
            if (result.isConfirmed) {
              this.isLoading = true;
              // this.getAccountStatement();
              // // this.isLoading = false;
              // this.$Progress.finish();
  
              this.loadTransactions();
              // this.$bvModal.hide(String(this.loan_no + this.statement));
            } else if (result.isDismissed) {
              this.isLoading = false;
              // this.$Progress.finish();
            }
          });
      },
      printReceipt(data) {
        // console.log(data.item);
        this.report_data = {
          trans_date: data.trans_date,
          account_number: data.account?.account_number,
          fullname: data.customer?.first_name + " " + data.customer?.last_name,
          transaction_mode: data.transaction_mode,
          transaction_type: data.transaction_type,
          amount: data.trans_amount,
          cheque_no: "not avaliable",
          bank_name: "not avaliable",
          branch_name: data.branch,
          narration: data.narration,
          institution: this.institution,
          receipt_no: data.id,
          teller: this.getTeller,
          denomination: data.denominations,
          mobile: data.mobile,
          network: data.network,
          external_reference: data.external_reference,
          momo_reference: data.momo_reference,
        };
        switch (data.transaction_mode) {
          case "Cash":
            if (data.transaction_type == "Deposit") {
              this.$refs.showCashDeposit.showModal();
              // console.log(this.form);
            } else if (data.transaction_type == "Withdrawal") {
              this.$refs.showCashWithdrawal.showModal();
            }
  
            break;
          case "Cheque":
            if (data.transaction_type == "Deposit") {
              this.$refs.showChequeDeposit.showModal();
              // console.log(this.form);
            } else if (data.transaction_type == "Withdrawal") {
              this.$refs.showChequeWithdrawal.showModal();
            }
            break;
          case "Momo":
            if (data.transaction_type == "Deposit") {
              this.$refs.showMomoDeposit.showModal();
              // console.log(this.form);
            } else if (data.transaction_type == "Withdrawal") {
              this.$refs.showMomoWithdrawal.showModal();
            }
            break;
          case "Direct Debit":
            if (data.transaction_type == "Deposit") {
              this.$refs.showDirectDebitDeposit.showModal();
              // console.log(this.form);
            } else if (data.transaction_type == "Withdrawal") {
              this.$refs.showDirectDebitWithdrawal.showModal();
            }
            break;
        }
      },
      chequePrintout(data) {
        this.report_data = {
          trans_date: data.trans_date,
          fullname: data.customer?.first_name + " " + data.customer?.last_name,
          amount: data.trans_amount,
          institution: this.institution,
        };
        this.$refs.showChequePrintout.showModal();
      },
      chequeTemplate(data) {
        this.report_data = {
          trans_date: data.trans_date,
          fullname: data.customer?.first_name + " " + data.customer?.last_name,
          amount: data.trans_amount,
          // institution: this.institution,
        };
        this.$refs.showChequeTemplate.showModal();
      },
    },
  };
  </script>
  