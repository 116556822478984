<template>
  <div class="">
    <div class="row mb-3">
      <div class="col-md-6">
        <div>
          <b-button @click="downloadFile" :class="[deposit_or_withdrawal == 'Deposit' ? 'akkurate-green-btn' : 'akkurate-red-btn']">Download Excel Format</b-button>
        </div>
      </div>
      <div class="col-md-6">
        <!-- <div class="text-end">
          <b-button class="akkurate-green-btn btn-size">Search</b-button>
        </div> -->
      </div>
    </div>
    <div class="row mb-4">
      <!-- <div class="col">
        <label class="akkurate-dark small" for="transaction_type"
          >Transaction type</label
        >
        <multiselect
          class="mt-2 field-container"
          v-model="form.transaction_type"
          :options="transaction_option"
          track-by="transaction_type"
          placeholder="Select Type"
          disabled
          selectLabel=""
                deselectLabel=""
        >
        </multiselect>
      </div> -->
      <div class="col-md-4">
        <label class="akkurate-dark small" for="debit" v-if="deposit_or_withdrawal=='Deposit'">GL Debit <star></star></label>
        <label class="akkurate-dark small" v-else for="debit">GL Credit<star></star></label>
        <multiselect
          class="mt-2 field-container"
          v-model="form.gl_debit"
          :options="gl_options"
          :custom-label="fullGlName"
          placeholder="Select COA Account"
          selectLabel=""
          deselectLabel=""
          @select="onSelect"
        >
        </multiselect>
      </div>

      <!-- <div class="col">
        <label class="akkurate-dark small" for="transaction_date"
          >Transaction Date</label
        >
        <b-form-group id="transaction_date" label-for="transaction_date">
          <b-form-input
            class="mt-2 field-container fields"
            type="date"
            v-model="form.transaction_date"
            required>
          </b-form-input>
        </b-form-group>
      </div> -->

      <!-- <div class="col">
        <label class="akkurate-dark small" for="description">Description</label>
        <b-form-group
          id="description"
          label-for="description"
          class="mt-2 akkurate-dark">
          <b-form-textarea
            id="textarea"
            placeholder="Enter Description..."
            rows="3"
            max-rows="6"
            v-model="form.description"
          ></b-form-textarea>
        </b-form-group>
      </div> -->

      <div class="col-md-4">
        <label class="akkurate-dark small" for="phone">Choose File<star></star></label>
        <input
          type="file"
          class="form-control mt-2"
          @change="handleFileUpload"
          ref="fileInput"
          accept=".xlsx,.xls"
        />
        <!-- <div v-if="jsonData">
          <pre>{{ jsonData }}</pre>
        </div> -->
        <!-- <div class="mt-2">
          <b-button class="btn-size w-75" :class="[deposit_or_withdrawal == 'Deposit' ? 'akkurate-green-btn' : 'akkurate-red-btn']" @click="transferData">Transfer</b-button>
        </div> -->
      </div>

      <!-- <div class="mt-auto col-12 col-md-2 mb-1">
          <b-button class="akkurate-green-btn akkurate-auth-size w-100" @click="filterTable"> <span class="small"><font-awesome-icon :icon="['fas', 'search']" /> Search</span></b-button>
        </div> -->
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import * as XLSX from "xlsx";
import config from '@/config.js'
export default {
  components: {
    Multiselect,
  },
  props: ["form","gl_options","deposit_or_withdrawal"],
  data() {
    return {
      transaction_option: ["Deposit", "Withdraw"],
      // jsonData: null,
      //   debit_option: ["Account One", "Account Two"],
      //   credit_option: ["Account One", "Account Two"],
    };
  },
  methods: {
    downloadFile() {
      // Replace 'example.pdf' with the filename you want to download from your public directory
      const fileName = 'BULK_DEPOSITS_UPLOAD_TEMPLATE.xlsx';

      // console.log(fileName);  
      
      // Construct the URL to the file in the public directory
      const fileUrl = `${config.base_url}/assets/${fileName}`;

    //   console.log(fileUrl);

      // Create a link element
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);
    },
    fullGlName({ code, name, coabranch }) {
            var f_name = `${code} - ${name}`

            if(coabranch){
                f_name += ` - ${coabranch.name}`
            }
            return f_name;
    },
    onSelect (option) {
        // console.log(option);
        if(this.jsonArray && this.jsonArray.length > 0){
            this.jsonArray.forEach((element, index) => {
                element['gl_debit'] = option.code + ' - ' + option.name;
            });
            
            this.jsonArray = this.jsonArray;
            this.$emit('showExcelUploads', this.jsonArray);
        }

    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          // Convert to JSON
          const headers = jsonData[0];
          const jsonDataArray = [];
          for (let i = 1; i < jsonData.length; i++) {
            const row = jsonData[i];
            const obj = {};
            for (let j = 0; j < headers.length; j++) {
              const key = headers[j].replace(/\s/g, "_").toLowerCase(); // Replace spaces with underscores
              let value = null;
              if(row[j])
              {
                value = String(row[j]); // Convert value to string
              }
              obj[key] = value;

              if(this.form.gl_debit)
              {
                obj["gl_debit"] = this.form.gl_debit.code + ' - ' + this.form.gl_debit.name;
              
              }
            }
            jsonDataArray.push(obj);
          }
          this.jsonArray = jsonDataArray;
          console.log(this.jsonArray);
          // this.jsonData = JSON.stringify(jsonDataArray, null, 2);
          this.$emit('showExcelUploads', jsonDataArray);

        };
        reader.readAsArrayBuffer(file);
      }
      else
      {
        this.$emit('showExcelUploads', []);
      }
    },
    transferData(){

      this.$refs.fileInput.value = null;
      this.$emit('saveBulkDeposit', this.jsonArray);

    }
  },
};
</script>