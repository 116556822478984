<template>
    <div>
        <b-modal 
            :id="String(data.id + status)" 
            size="sm"
            @shown="modalShown"
            @ok="handleOk">
            <template #modal-title>
                <div class="akkurate-green fw-bold">Change Loan Status</div>
            </template>
            <div class="row">
                    <div class="col-12">
                        <label class="akkurate-dark small" for="loan_number">Loan Number</label>
                        <b-form-group
                            id="loan_number"
                            label-for="loan_number">
                            <b-form-input
                                id="loan_number"
                                name="loan_number"
                                class="mt-2 field-container fields"
                                type="text"
                                v-model="form.loan_number"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-12">
                        <label class="akkurate-dark small" for="status">Status</label>
                        <b-form-group
                            id="loan-re-date"
                            label-for="loan-re-date">
                            <multiselect 
                                class="mt-2 field-container" 
                                v-model="form.status" 
                                :options="status_options">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <div class="col-12">
                        <label class="akkurate-dark small" for="reason">Reason</label>
                        <b-form-group
                            id="reason"
                            label-for="reason">
                            <b-form-textarea
                            class="mt-2 fields"
                            id="reason"
                            v-model="form.reason"
                            placeholder="Enter Reason..."
                            ></b-form-textarea>
                        </b-form-group>
                    </div>
            
            </div>
            
            <template #modal-footer="{ ok }">
                <div class="w-100">
                <!-- begin loader -->
                <!-- <beat-loader-component 
                class="float-left"
                v-if="isLoading"
                :isLoading="isLoading"
                ></beat-loader-component> -->
                <!-- end loader -->
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button @click="ok()" class="akkurate-green-btn me-2 btn-size float-right">Save</b-button>     
                </div>
            </template>
       </b-modal>
      
       
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import ApiService from "@/core/services/api.service"
export default {
    props:['data','status'],
    components: {
        Multiselect
    },
    data() {
        return {
            ...this.initialState(),
            status_options: ["Pending", "Under Review", "Pending Loan Committee Approval", "Pending Sub Committee Approval", "Pending Chairman Approval", "Pending Disbursement", "Approved", "Released", "Running", "Closed", "Overdue", "Rejected", "Refinanced"],

        }
    },
    methods: {
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            this.$validator.validateAll().then(result => {
                if (!result) {
                    // console.log(result);
                    return;
                }
                this.changeLoanStatus();



            });
            // Hide the modal manually

        },
        async changeLoanStatus() {
            this.isSaving = true;
            this.$Progress.start();
            await ApiService.post('/loans/changeStatus', this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    // this.form.reset();
                    this.form = this.initialState().form;
                }).catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    if (error.response.data.errors) {
                        this.form.errors = error.response.data.errors;
                    }
                })
        },
        modalShown(){
            if(this.data?.id != 'app_status'){
                this.form.loan_number = this.data?.loan?.loan_number;
            }
        },
        initialState() {
            return {
                form: {
                    loan_number: "",
                    status: "",
                    reason:"",
                }

            }
        }
    }

}
</script>