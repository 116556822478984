<template>
    <div>
       <b-button  size="sm" class="fw-semibold akkurate-green-btn" v-b-modal.add-account><span class="akkurate-small"><i class="fas fa-plus"></i> Add Acounts</span></b-button>
       <b-modal
        id="add-account"
        title="Add Account"
        ref="modal"
        size="lg"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        >
           <form ref="form" @submit.stop.prevent="handleSubmit">
               <div class="row">
                   <div class="col-md-6">
                       <b-form-group
                           label-for="dr_amount"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="dr_amount">Debit Amount <star></star></label>
                           </template>
                           <b-form-input
                               id="dr_amount"
                               v-model="form.dr_amount"
                               v-validate="{ required: true }"
                               :state="validateState('dr_amount')"
                               name="dr_amount"
                               class="mt-2 field-container fields"
                               type="number"
                               required
                           ></b-form-input>
                           <b-form-invalid-feedback >
                               The Direct Amount is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.dr_amount">
                                   {{ form.errors.dr_amount[0] }}
                               </errors>
                       </b-form-group>
                   </div>

                   <div class="col-md-6">
                       <b-form-group
                           label-for="cr_amount"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="cr_amount">Credit Amount <star></star></label>
                           </template>
                           <b-form-input
                               id="cr_amount"
                               v-model="form.cr_amount"
                               v-validate="{ required: true }"
                               :state="validateState('cr_amount')"
                               name="cr_amount"
                               class="mt-2 field-container fields"
                               type="number"
                               required
                           ></b-form-input>
                           <b-form-invalid-feedback >
                               The Credit Amount is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.dr_amount">
                                   {{ form.errors.dr_amount[0] }}
                               </errors>
                       </b-form-group>
                   </div>

                   <div class="col-md-6">
                       <b-form-group
                           label-for="debit_gl"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="debit_gl"> Debit GL <star></star></label>
                           </template>
                           <multiselect 
                            id="debit_gl"
                            class="mt-2 field-container" 
                            v-model="form.debit_gl" 
                            :options="coa_subhead_options"
                            :state="validateState('debit_gl')"
                            name="debit_gl"
                            label="name"
                            required
                           ></multiselect>
                           <b-form-invalid-feedback >
                               The Debit GL is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.debit_gl">
                                   {{ form.errors.debit_gl[0] }}
                               </errors>
                       </b-form-group>
                   </div>
                   
                   <div class="col-md-6">
                       <b-form-group
                           label-for="credit_gl"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark" for="credit_gl">Credit GL <star></star></label>
                           </template>
                           <multiselect 
                            id="credit_gl"
                            class="mt-2 field-container" 
                            v-model="form.credit_gl" 
                            :options="coa_subhead_options"
                            :state="validateState('credit_gl')"
                            name="credit_gl"
                            label="name"
                            required
                           ></multiselect>
                           <b-form-invalid-feedback >
                               The Credit GL is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.credit_gl">
                                   {{ form.errors.credit_gl[0] }}
                               </errors>
                       </b-form-group>
                   </div>
                   
               </div>
           </form>
          <template #modal-footer="{ ok }">
               <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
               <b-button @click="$bvModal.hide('add-account')" type="button" class="akkurate-grey-btn btn-size float-right border-0">Close</b-button>
               <b-button @click="ok()" type="submit" class="akkurate-green-btn btn-size me-2 float-right border-0">Add</b-button>     
               </div>
           </template>
           
       </b-modal>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
// import ApiService from "@/core/services/api.service";
export default {
   name: "AddAccount",
   props: ['coa_subhead_options'],
   components: {
       Multiselect,
   },
//    mounted() {
//        this.loadPageOptions();
//    },
   data() {
       return {
           form: {
               dr_amount: "",
               cr_amount:"",
               coa_subhead:"",
               errors: [],
           },
           // account_types: [],
        //    isSaving: false,
           add_or_edit: "add",
       }
   },
   methods: {
       validateState(ref) {
           if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
               return !this.veeErrors.has(ref);
           }
           return null;
       },
       resetModal() {
           this.form.dr_amount = ''
           this.form.cr_amount = ''
           this.form.coa_subhead = ''
           this.form.errors = []
       },
       handleOk(bvModalEvent) {
           // Prevent modal from closing
           bvModalEvent.preventDefault()
           // Trigger submit handler
           this.handleSubmit()
       },
    //    loadPageOptions() {
    //        this.$Progress.start();
    //        this.isLoading = true;
    //        ApiService.get("/vouchers/dropdown")
    //            .then((response) => {
    //                console.log(response.data);
    //                this.isLoading = false;
    //                // $emit('coa-head-saved', response.data)
    //                this.$Progress.finish();
    //            })
    //            .catch((error) => {
    //                this.isLoading = false;
    //                this.$Progress.fail();
    //            });
    //    },
       handleSubmit() {
           // Exit when the form isn't valid
           this.$validator.validateAll().then(result => {
               if (!result) {
                   return;
               }
            //    console.log("Saving....")
               this.isSaving = true;
               // this.$Progress.start();
               this.form.errors = [];
            //    console.log(this.form);

               this.$emit('addAccount', this.form)
               this.$bvModal.hide('add-account')

           });
           // Hide the modal manually

       }
   }
}
</script>