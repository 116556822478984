<template>
    <div class="w-100">
       <b-button class="akkurate-green-btn" v-b-modal.advance_search>Advanced Search</b-button>
       <b-modal 
       id="advance_search"
        size="lg"
        @ok="handleOk">
        <template #modal-title>
            <p class="m-0 akkurate-green fw-bold">Advanced Search</p>
        </template>
        <div class="row">
            <!-- <div class="col-md-6">
                <label class="akkurate-dark" for="end_date">Branch</label>
                <b-form-group
                    label-for="branch"
                    class="mb-3">
                    <multiselect class="field-container" v-model="adv_search.branch" :options="branch_options"></multiselect>
                </b-form-group>
            </div> -->
            <!-- <div class="col-md-6">
                <label class="akkurate-dark" for="end_date">Status</label>
                <b-form-group
                    label-for="status"
                    class="mb-3">
                    <multiselect class="field-container" v-model="adv_search.status" :options="status_options"></multiselect>
                </b-form-group>
            </div> -->
        <div class="col-md-6">
            <label class="akkurate-dark" for="code">Branch</label>
            <b-form-group
            id="loan-re-date"
            label-for="loan-re-date">
            <multiselect class="mt-2 field-container" v-model="adv_search.branch" :options="branch_options"></multiselect>
            </b-form-group>
        </div>
        <div class="col-md-6">
            <label class="akkurate-dark" for="status">Status</label>
            <b-form-group
                label-for="status"
                class="mb-3">
                <multiselect class="mt-2 field-container" v-model="adv_search.status" :options="status_options"></multiselect>
            </b-form-group>
        </div>
        <div class="col-md-6">
            <label class="akkurate-dark" for="start_date">Start Date</label>
            <b-form-group
                label-for="start_date"
                class="mb-3">
                <b-form-input
                    id="start_date"
                    v-model="adv_search.start_date"
                    name="start_date"
                    class="mt-2 field-container fields"
                    type="date"
                    required
                ></b-form-input>
            </b-form-group>
        </div>
        <div class="col-md-6">
            <label class="akkurate-dark" for="end_date">End Date</label>
            <b-form-group
                label-for="end_date"
                class="mb-3">
                <b-form-input
                    id="end_date"
                    v-model="adv_search.end_date"
                    name="end_date"
                    class="mt-2 field-container fields"
                    type="date"
                    required
                ></b-form-input>
            </b-form-group>
        </div>
        <div class="col-md-6">
                <label class="akkurate-dark" for="customer_code">Customer Code</label>
                <b-form-group
                    label-for="customer_code"
                    class="mb-3">
                    <b-form-input
                        id="customer_code"
                        v-model="adv_search.customer_code"
                        name="customer_code"
                        class="mt-2 field-container fields"
                        type="text"
                    ></b-form-input>
                </b-form-group>
         </div>
       
        

        </div>
        <template #modal-footer="{ ok }">
               <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
                <!-- <b-button @click="$bvModal.hide('advance_search')" type="button" class="akkurate-grey-btn btn-size float-right border-0">Close</b-button> -->
                <b-button @click="ok()" type="submit" class="akkurate-green-btn btn-size me-2 float-right border-0">Search</b-button>     
               </div>
           </template>

       </b-modal>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
    components: {
        Multiselect
    },
    data() {
        return {
            adv_search: {
                branch: "",
                start_date: "",
                end_date: "",
                status: "",
                customer_code:"",
            },
            status_options: ["Running", "Pending", "Redeemed"],
            branch_options:['Testing'],
        }
    },
    methods:{
        handleOk(){
           this.$emit('adv_search',this.adv_search);
        }
    }
}
</script>