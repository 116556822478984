<template>
<div id="wrapper">
        <SidebarView :loan="true" :loan-cal="true" loan-visible="true"/>
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="container mb-3">
                    <div class="card card-shape home-box">
                        <div class="card-header py-3">
                            <div class="row">
                                <div class="col-12 col-md-3">
                                    <h6 class="m-0 fw-bold text-green">Loan Calculator</h6>
                                </div>
                                <div class="col-12 col-md-9 text-md-end">
                                    <span><small><a class=" btn text-decoration-none akkurate-link" href="/loans/application">Apply For Loan</a></small></span>
                                </div>

                            </div>
                        </div>
                        <div class="card-body">
                            <form @submit.prevent="loadItems()">
                                <loan-form :form="form"></loan-form>
                                <div class="d-flex justify-content-end mt-auto col-12 mt-3">
                                <b-button type="submit" :disabled="isCalculating" class="akkurate-green-btn btn-size mb-3">{{ calculate }}</b-button>
                            </div> 
                            </form>
                           
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="card card-shape home-box">
                        <div class="card-header py-3 d-flex flex-row align-items-center">
                            <h6 class="m-0 fw-bold text-green">Loan Scheduler</h6>
                        </div>
                        <div class="card-body">
                            <!-- <loan-form></loan-form> -->
                            <h6 class="text-center my-3">LOAN TERMS</h6>
                            <div>
                                <vue-good-table
                                :columns="term_columns"
                                :rows="term_rows">
                                <template slot="table-row" slot-scope="props">
                                    <span v-if="props.column.field == 'interest_rate_percentage'">
                                        {{ props.row.interest_rate * 100 }}
                                    </span>
                                </template>
                            </vue-good-table>
                            </div>
                           <div class="row my-4">
                            <div class="col-12 col-md-7">
                                <h6 class="text-center text-md-end">LOAN REPAYMENT SCHEDULE </h6>
                            </div>
                            <div class="col-12 col-md-5 text-center text-md-end">
                                <a class="mx-3" href=""><i class="fas fa-print akkurate-link"></i></a>
                                <a href=""><i class="fas fa-book akkurate-link"></i></a>
                               

                            </div>

                           </div>


                            <!-- <div class="d-flex justify-content-center">
                                <h6 class="text-center my-4">LOAN REPAYMENT SCHEDULE </h6>
                               
                            </div> -->
                            
                            
                            <div>
                                <vue-good-table
                                :columns="repayment_columns"
                                :rows="repayment_rows"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
</div>
</template>

<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import LoanForm from '../forms/LoanForm.vue'
import ApiService from '@/core/services/api.service'
export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        LoanForm
    },
    data() {
        return {
            isLoading:false,
            isCalculating: false,
            calculate:"Calculate",
            form: {
                principal_amount: "",
                interest_rate: "",
                interest_rate_frequency: "",
                interest_method: 'straight-line',
                tenor: "",
                tenor_interval: "",
                repayment_cycle: "",
                interest_start_date: "",
                fixed_interest_rate: "",
                fixed_interest_rate_frequency: "",
                fixed_interval: "", //['Days', 'Weeks', 'Months', 'Years']
                fixed_duration: "",
                variable_interest_rate: "",
                variable_interest_rate_frequency: "", //['Daily', 'Weekly', 'Monthly', 'Annually']
                variable_interval: "", //['Days', 'Weeks', 'Months', 'Years']
                variable_duration: ""
            },
            term_columns: [
                {
                    label: 'Interest Rate',
                    field: 'interest_rate_percentage',
                    type: "number"
                },
                {
                    label: 'Interest Rate Per Interval',
                    field: 'interest_rate_per_interval',
                    type: "number"
                },
                {
                    label: 'Principal Amount',
                    field: 'principal_amount',
                    type: "number"
                },
                {
                    label: 'Number of Payments',
                    field: 'number_of_payments',
                    type: 'number',
                },
                {
                    label: 'Payment',
                    field: 'payment',
                    type: 'number',
                },
                {
                    label: 'Total Payment',
                    field: 'total_payment',
                    type: 'number',
                },
                {
                    label: 'Total Interest',
                    field: 'total_interest',
                    type: 'number',
                },
            ],
            term_rows: [],
            repayment_columns: [
                {
                    label: '#',
                    field: 'payment_number',
                    type: "string"
                },
                {
                    label: 'Payment Date',
                    field: 'payment_date',
                    type: 'string',
                },
                {
                    label: 'Starting Balance',
                    field: 'starting_balance',
                    type: 'number',
                },
                {
                    label: 'Payment',
                    field: 'payment',
                    type: 'number',
                },
                {
                    label: 'Principal Amount',
                    field: 'principal',
                    type: 'number',
                },
                {
                    label: 'Interest',
                    field: 'interest',
                    type: 'number',
                },
                {
                    label: 'Ending Balance',
                    field: 'ending_balance',
                    type: 'number',
                },
            ],
            repayment_rows: [],
        }
    },
    methods: {
        // load items is what brings back the rows from server
        async loadItems() {
            this.isLoading = true
            this.isCalculating = true,
            this.calculate = "Processing..."

            this.term_rows = [];
            this.repayment_rows = [];
            
            await ApiService.post('/loans/loanCalculator', this.form)
                .then(response => {
                    this.term_rows.push(response.data.data);
                    response.data?.data?.amortization_schedule.map((index) => {
                        this.repayment_rows.push(index);
                    })
                    console.log(this.repayment_rows);
                    this.isLoading = false;
                    this.isCalculating = false,
                    this.calculate = "Calculate"
                }).catch((error) => {
                    console.log(error)
                    this.isLoading = false;
                    this.isCalculating = false,
                    this.calculate = "Calculate"
                });
        }
    }

}

</script>