<template>
    <div id="wrapper">
            <SidebarView :transaction="true"  :transVisible="true" :withdrawalTransaction="true" />
            <div class="d-flex flex-column" id="content-wrapper">
                <div id="content">
                    <TopbarView ref="topbar_ref" />
                    <div class="container-fluid">
                        <div class="card card-shape home-box">
                            <div class="card-header py-3 d-flex flex-row align-items-center">
                                <h6 class="m-0 fw-bold text-danger">Withdrawal</h6>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <filter-form :form="form" :filterTable="filterTable"></filter-form>
                                    </div>
                               <div class="col-md-12">
                                <vue-good-table
                                    styleClass="vgt-table striped"
                                        mode="remote"
                                        ref="allAccountTable"
                                        :columns="columns"
                                        :rows="rows"
                                        :isLoading.sync="isLoading"
                                        :totalRows="totalRecords"
                                        :sort-options="{
                                            enabled: false
                                        }"
                                        :pagination-options="{
                                            enabled: true,
                                            perPage: serverParams.perPage,
                                        }"
                                        
                                        @on-search="onSearch"
                                        @on-page-change="onPageChange"
                                        @on-sort-change="onSortChange"
                                        @on-column-filter="onColumnFilter"
                                        @on-per-page-change="onPerPageChange">
                                        <!-- Add your custom delete button column -->
                                        <template slot="table-row" slot-scope="props">
                                        <span v-if="props.column.field == 'member_fullname'">
                                                <span>{{ props.row.customer?.first_name }} {{ props.row.customer?.last_name }}</span> 
                                        </span>
                                        <span v-else>
                                            {{ props.formattedRow[props.column.field] }}
                                        </span>
                                        <span class="d-flex" v-if="props.column.field == 'action'">
                                            <!-- <a title="View" class="btn btn-sm akkurate-warning text-white broder-0" :href="`/members/view/${props.row.customer?.id}`">
                                                <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span> 
                                            </a> -->

                                            <b-button :key="props.row.id + 'ViewMember'" class="btn btn-sm akkurate-warning text-white broder-0" id="show-btn" @click="showCustomerViewModal(props.row.customer.id)">
                                                <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span> 
                                            </b-button>

                                            <!-- <div class="dropdown no-arrow" v-if="props.row.status == 'ACTIVE' || props.row.status == 'Active'">
                                                <a title="Deposit" class="btn btn-sm akkurate-teal text-white dropdown-toggle" id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'money-bill-transfer']" /></span>
                                                </a>
                                                <div class="dropdown-menu shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenu">
                                                    <div class="dropdown-header">Deposit</div>
                                                    <cash @reloadAccounts="loadItems" :key="props.row.id + 'Deposit'" :data="props.row" :transaction="'Cash Deposit'" :institution="institution" :user="user"></cash>
                                                    <cheque @reloadAccounts="loadItems" :key="props.row.id + 'Deposit1'"  :data="props.row" :transaction="'Cheque Deposit'" :institution="institution" :user="user" :coa_subheads="coa_subheads"></cheque>
                                                    <momo @reloadAccounts="loadItems" :key="props.row.id + 'Deposit2'"  :data="props.row" :transaction="'Mobile Money Deposit'" :institution="institution" :user="user"></momo>
                                                    <direct-debit @reloadAccounts="loadItems" :key="props.row.id + 'Deposit3'"  :data="props.row" :transaction="'Direct Debit Deposit'" :institution="institution" :user="user"></direct-debit>
                                                </div>
                                            </div> -->
                                            <div class="dropdown no-arrow" v-if="props.row.status == 'ACTIVE' || props.row.status == 'Active'">
                                                <a title="Withdraw" class="btn btn-sm akkurate-danger-btn text-white dropdown-toggle" id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'money-bill']" /> </span>
                                                </a>
                                                <div class="dropdown-menu shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenu">
                                                    <div class="dropdown-header">Withdraw</div>
                                                    <cash @reloadAccounts="loadItems();reloadTellerCoa()" :key="props.row.id + 'Withdraw'"  :data="props.row" :transaction="'Cash Withdrawal'" :institution="institution" :user="user"></cash>
                                                    <cheque @reloadAccounts="loadItems" :key="props.row.id + 'Withdraw1'" :data="props.row" :transaction="'Cheque Withdrawal'" :institution="institution" :user="user" :coa_subheads="coa_subheads"></cheque>
                                                    <momo @reloadAccounts="loadItems" :key="props.row.id + 'Withdraw2'" :data="props.row" :transaction="'Mobile Money Withdrawal'" :institution="institution" :user="user"></momo>
                                                    <direct-debit @reloadAccounts="loadItems" :key="props.row.id + 'Withdraw3'" :data="props.row" :transaction="'Direct Debit Withdrawal'" :institution="institution" :user="user"></direct-debit>
                                                </div>
                                            </div>
                                            
                                            <div title="Transfer" class="dropdown no-arrow" v-if="props.row.status == 'ACTIVE' || props.row.status == 'Active'">
                                                <a title="Transfer" class="btn btn-sm akkurate-blue text-white dropdown-toggle" id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'share']" /></span>
                                                </a>
                                                <div class="dropdown-menu shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenu">
                                                    <div class="dropdown-header">Transfer</div>
                                                    <internal :key="props.row.id + 'Transfer'" @reloadAccounts="loadItems();" :data="props.row" :transaction="'Inter Account Transfer'"></internal>
                                                    <inter-union :key="props.row.id + 'Transfer1'" :data="props.row" :transaction="'Inter-Union Deposit'"></inter-union>
                                                    <gip :key="props.row.id + 'Transfer2'" :data="props.row" :transaction="'GIP Deposit'"></gip>
                                                </div>
                                            </div>
                                            
                                            <!-- <div class="dropdown no-arrow d-flex">
                                                <account-statement :data="props.row" :institution="institution" :user="user"></account-statement>
                                            </div>   -->
                                            <div title="Account Statement" class="dropdown no-arrow">
                                                <a title="Account Statement" class="btn btn-sm akkurate-gray-btn text-white dropdown-toggle" id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'book']" /></span>
                                                </a>
                                                <div class="dropdown-menu shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenu">
                                                    <div class="dropdown-header">Statements</div>
                                                    
                                                    <a class="dropdown-item" v-b-modal="String(props.row?.id + 'statement_preview')">Account Entries</a>
                                                    <account-entries :key="props.row.id + 'Statements'" :data="props.row" :institution="institution" :user="user"></account-entries>

                                                    <a class="dropdown-item" v-b-modal="String(props.row?.id + 'account_statement')">Account Statement</a>
                                                    <account-statement :key="props.row.id + 'Statements1'" :data="props.row" :institution="institution" :user="user"></account-statement>
                                                    
                                                </div>
                                            </div>

                                        </span>
                                        </template>
                                </vue-good-table>
                               </div>
                             </div>                                   
                            </div>
                        </div>

                        <b-modal size="xl" id="bv-modal-customer-view" hide-footer>
                            <member-view :id="member_id"></member-view>
                        </b-modal>
                    </div>
                </div>
                <footer></footer>
            </div>
            <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
    </template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import FilterForm from '../form/FilterForm.vue'
import Cash from '../form/modal/Cash.vue'
import Cheque from '../form/modal/Cheque.vue'
import Momo from '../form/modal/Momo.vue'
import DirectDebit from '../form/modal/DirectDebit.vue'
import Internal from '../form/modal/Internal.vue'
import InterUnion from '../form/modal/InterUnion.vue'
import Gip from '../form/modal/Gip.vue'
import ApiService from "@/core/services/api.service";
import AccountEntries from '@/views/main/pages/member/accounts/entries/AccountEntries.vue'
import AccountStatement from '@/views/main/pages/member/accounts/statement/AccountStatement.vue'
import MemberView from '@/views/main/pages/member/view/MemberView.vue'
export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        FilterForm,
        Cash,
        Cheque,
        Momo,
        DirectDebit,
        Internal,
        InterUnion,
        Gip,
        AccountEntries,
        AccountStatement,
        MemberView,
    },
    data() {
        return {
            member_id: "",
            ...this.initialState(),
            user:"",
            coa_subheads:"",
            institution:"",
            columns: [
                {
                    label: 'Account number',
                    field: 'account_number',
                    // width: '180px',
                },
                {
                    label: 'Product',
                    field: 'account_type.name',
                    // width: '10px',
                },
                {
                    label: 'Balance',
                    field: 'account_balance',
                    // width: '150px',
                },

                {
                    label: 'Member',
                    field: 'member_fullname',
                    // width: '140px',
                },
                {
                    label: 'Mobile',
                    field: 'customer.mobile',
                    // width: '180px',
                },
                {
                    label: 'Branch',
                    field: 'branch.name',
                    // width: '180px',
                },
                {
                    label: 'Status',
                    field: 'status',
                    // width: '180px',
                },
                {
                    label: 'Action',
                    field: 'action',
                    // width: '440px',
                },
            ],
            rows: [],
            serverParams: {
                // a map of column filters example: {name: 'john', age: '20'}
                columnFilters: {
                },
                sort: [
                    {
                        field: '', // example: 'name'
                        type: '' // 'asc' or 'desc'
                    }
                ],
                page: 1, // what page I want to show
                perPage: 20 // how many items I'm showing per page
            },
        }
    },
    mounted() {
        this.getDepositDropdown();
        this.getInsistution();
    },
    methods: {

        showCustomerViewModal(id) {
            this.member_id = id;
            this.$bvModal.show('bv-modal-customer-view')
        },
        async getDepositDropdown() {
            this.$Progress.start();
            await ApiService.get("/transactions/deposits/dropdown")
                .then((response) => {
                    // console.log(response);
                    this.form.branch_option = response.data?.branches
                    this.form.acc_types_option = response.data?.account_types
                    this.user = response.data?.user;
                    this.coa_subheads = response.data?.coa_subheads;
                    this.isLoading = false;
                    this.$Progress.finish();
                }).catch((error) => {
                    this.$Progress.fail();
                    this.isLoading = false;
                })
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.loadItems();
        },

        onSortChange(params) {
            this.updateParams({
                sort: [{
                    type: params.sortType,
                    field: this.columns[params.columnIndex].field,
                }],
            });
            this.loadItems();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },
        onSearch(event) {
            this.loadItems(event.searchTerm)
        },
        reloadTellerCoa(){
            // this.$emit('reloadTellerCoa');
            
            this.$refs.topbar_ref.fetchUserProfile();
        },
        async loadItems(searchFilter = null) {
            var branch_id = "";
            var acc_id = "";
            if (this.form.branch != null) {
                branch_id = this.form.branch.id;
            }
            if (this.form.acc_types != null) {
                acc_id = this.form.acc_types.id;
            }
            this.$Progress.start();
            this.isLoading = true;
            await ApiService.post("/accounts/paginate",
                {
                    "page": this.serverParams.page,
                    "per_page": this.serverParams.pagePage,
                    "filter": this.form.account_number,
                    "branch_id": branch_id,
                    "account_type_id": acc_id,
                    "account_number": this.form.account_number,
                    "phone_number": this.form.phone,
                    "start_date": this.form.start_date,
                    "end_date": this.form.end_date,
                    "status": this.form.status //Inactive,	Active, Dormant, Closed
                })
                .then((response) => {
                    this.rows = response.data.data;
                    this.totalRecords = response.data.meta.total;
                    this.all_data = response.data.data;
                    this.isLoading = false;
                    this.$Progress.finish();
                }).catch((error) => {
                })
        },
        filterTable() {
            this.loadItems();
        },
       
        async getInsistution() {
            await ApiService.get('/settings/institution').then((response) => {
                this.institution = response.data.data;
            }).catch((error) => {
            })
        },
        initialState() {
            return {
                totalRecords: 0,
                searchFilter: "",
                form: {
                    branch: null,
                    branch_option: [],
                    acc_types: "",
                    acc_types_option: [],
                    phone: "",
                    account_number: "",
                    start_date: "",
                    end_date: "",
                    status: "",
                    status_option: ['ACTIVE', 'INACTIVE', 'DORMANT', 'CLOSED'],
                    all_data: "",
                },
                isLoading: true,
                isSaving: false,
                add_or_edit: 'add',
            };
        },
    }
}

</script>