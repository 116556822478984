<template>
  <b-nav class="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar static-top">
    <div class="container-fluid">
      <b-button
        v-if="getRole != 'Mobile Banker'"
        variant="light"
        v-on:click="sidebarToggleTop"
        class="me-md-3"
        style="border: 1px solid #ccc">
        <i class="fas fa-bars" style="color: rgb(1, 138, 118)"></i>
      </b-button>
      
      <b-button v-else
        variant="light"
        v-b-toggle.my-collapse
        class="me-md-3"
        style="border: 1px solid #ccc">
        <i class="fas fa-bars" style="color: rgb(1, 138, 118)"></i>
      </b-button>
      
     

      <ul class="navbar-nav flex-nowrap ms-auto">
        <div class="row align-items-center pe-3">
          <div class="col-12 col-md-12">
            <p title="Closing Balance"
              class="mb-0 mx-2 akkurate-small pointer akkurate-green">
              Current Balance : <span class="pe-1">GHS</span>{{ user_coa.amount }}
            </p>
            <!-- <div class="row akkurate-green">
                        <div class="col-md-8 d-none d-md-block pe-0 text-end">
                            <p class="mb-0 small">Current Balance</p>
                        </div>
                        <div class="col-md-4">
                            <p title="Closing Balance" class="mb-0 small pointer"><span class="pe-1">GHS</span>{{user_coa.amount}}</p>
                        </div>
                    </div> -->
            <!-- <div class="row akkurate-danger">
                        <div class="col-md-8 d-none d-md-block pe-0 text-end">
                            <p class="mb-0 small">Closing Balance</p>
                        </div>
                        <div class="col-md-4">
                            <p title="Closing Balance" class="mb-0 small pointer"><span class="pe-1">GHS</span>500</p>
                        </div>
                    </div> -->
          </div>
        </div>
        <div class="d-none d-sm-block topbar-divider"></div>
        <!-- User settings and Logout -->
        <b-nav-item-dropdown class="no-arrow" right>
          <template #button-content>
            <div class="d-flex">
              <div class="mx-3">
                <img
                  class="border rounded-circle img-profile"
                  :src="require('@/assets/img/avatar.jpeg')"
                />
              </div>
              <div class="flex-column">
                <div class="d-none d-lg-block text-gray-600 small">
                  {{ getFullName }}
                </div>
                <div class="d-none d-lg-block user-roles text-gray-600 small">
                  {{ getRole }}
                </div>
              </div>
            </div>

            <!-- <span class="d-none d-lg-inline me-2 text-gray-600 small">{{ retrived.first_name }} {{ retrived.middle_name }} {{ retrived.surname }}</span>
                        <img class="border rounded-circle img-profile" src="../../../assets/img/avatar.jpeg"> -->
          </template>
          <b-dropdown-item href="/users/profile">
            <i class="fas fa-user fa-sm fa-fw me-2 text-gray-400"></i
            >&nbsp;Profile</b-dropdown-item
          >
          <!-- <b-dropdown-item href="#"> <i class="fas fa-cogs fa-sm fa-fw me-2 text-gray-400"></i>&nbsp;Settings</b-dropdown-item>
                    <b-dropdown-item href="#"> <i class="fas fa-list fa-sm fa-fw me-2 text-gray-400"></i>&nbsp;Activity log</b-dropdown-item> -->
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item v-on:click="logout">
            <i class="fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400"></i>&nbsp;Logout</b-dropdown-item>
        </b-nav-item-dropdown>
      </ul>
    </div>
  </b-nav>
</template>
<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "TopbarView",
  data() {
    return {
      userProfile: {},
      user_coa: {},
    };
  },
  mounted() {
    let token = localStorage.getItem("token");
    if (!token) {
      this.$router.push({ path: "/login" });
    }
    this.fetchUserProfile();
  },
  computed: {
    getFullName() {
      const first_name =
        this.userProfile?.first_name != undefined
          ? this.userProfile?.first_name
          : " ";
      const last_name =
        this.userProfile?.surname != undefined
          ? this.userProfile?.surname
          : " ";
      return first_name + " " + last_name;
    },
    getRole() {
      // if(this.userProfile && this.userProfile.roles && this.userProfile?.roles.length > 1){
      //     return this.userProfile?.roles.map(role => role.display_name).join(', ');
      // }
      if (this.userProfile?.roles != undefined) {
        return this.userProfile?.roles[0]?.display_name;
      }
    },
  },

  methods: {
    sidebarToggleTop: function () {
      const sidebar = document.querySelector(".sidebar");
      if (sidebar) {
        if (sidebar.classList.contains("toggled")) {
          sidebar.classList.remove("toggled");
        } else {
          sidebar.classList.add("toggled");
        }
        this.$emit("mobileToggle");
      } else {
        console.error("Sidebar element not found");
      }
    },
    logout() {
      localStorage.clear();
      this.$router.push({ path: "/login" });
    },
    async fetchUserProfile() {
      try {
        const response = await ApiService.get("users/profile");
        this.userProfile = response?.data?.user;
        this.user_coa = response?.data?.coa;
        // console.log(this.userProfile)
      } catch (error) {
        console.error("Error fetching user profile", error);
      }
    },
  },
};
</script>
<style scoped>
.mobile-text-xl {
  font-size: 11px;
}
.pointer {
  cursor: pointer;
}
</style>