<template>
    <form @submit.prevent="filterTable">
        <div class="row mb-4">
            <div class="col-12 col-md-2">
            <label class="akkurate-dark small" for="branch">Branch</label>
                <multiselect class="mt-2 field-container" 
                    v-model="form.branch" 
                    :options="form.branch_option"
                    track-by="name"
                    placeholder="Select one"
                    selectLabel=""
                    deselectLabel=""
                    label="name">
                </multiselect>
            </div>
            <div class="col-12 col-md-2">
                <label class="akkurate-dark small" for="acc_types">Account type </label>
                <multiselect class="mt-2 field-container"
                    v-model="form.acc_types"
                    :options="form.acc_types_option"
                    track-by="name"
                    placeholder="Select one"
                    selectLabel=""
                    deselectLabel=""    
                    label="name">
                </multiselect>
            </div>
            <div class="col-12 col-md-2">
                <label class="akkurate-dark small" for="phone">Phone number/ Code</label>
                    <b-form-group
                    id="phone"
                    label-for="phone"
                    class="akkurate-dark">
                    <b-form-input
                    id="phone" 
                    class="mt-2 field-container fields"
                    type="text" 
                    v-model="form.phone"
                    placeholder="Customer Code/ Phone No"
                    >
                    </b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-2">
                <label class="akkurate-dark small" for="account_number">Account number</label>
                    <b-form-group
                    id="account_number"
                    label-for="account_number"
                    class="akkurate-dark">
                    <b-form-input
                    id="account_number" 
                    class="mt-2 field-container fields"
                    type="text" 
                    v-model="form.account_number"
                    placeholder="Enter Account number"
                    >
                    </b-form-input>
                </b-form-group>
            </div>
            <!-- <div class="col-12 col-md-2">
                <label class="akkurate-dark" for="loan-re-date">Start Date</label>
                    <b-form-group
                    id="loan-re-date"
                    label-for="loan-re-date">
                    <b-form-input
                    class="mt-2 field-container fields"
                    type="date"
                    v-model="form.start_date"
                    required
                    ></b-form-input>
                    </b-form-group>
            </div>
            <div class="col-12 col-md-2">
                <label class="akkurate-dark" for="loan-re-date">End Date</label>
                    <b-form-group
                    id="loan-re-date"
                    label-for="loan-re-date">
                    <b-form-input
                    class="mt-2 field-container fields"
                    type="date"
                    v-model="form.end_date"
                    required
                    ></b-form-input>
                    </b-form-group>
            </div> -->
            <div class="col-12 col-md-2">
                <label class="akkurate-dark small" for="type">Status</label>
                <multiselect class="mt-2 field-container" v-model="form.status" :options="form.status_option" 
                    selectLabel=""
                    deselectLabel="">
                </multiselect>
            </div>
            <div class="mt-auto col-12 col-md-2 mb-1">
                <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100"> <span class="small"><font-awesome-icon :icon="['fas', 'search']" /> Search</span></b-button>
            </div>
        </div>
    </form>

</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
    components: {
        Multiselect
    },
    props: ['form','filterTable'],
    data() {
        return {
            transaction_option: ['Deposit', 'Withdrawal'],
            debit_option: ['Account One', 'Account Two'],
            credit_option: ['Account One', 'Account Two'],

        }
    },
}

</script>