<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container">
          <div class="card card-shape home-box">
            <div class="card-header py-3">
              <div class="row align-items-center">
                <div class="col-6">
                  <h6 class="m-0 fw-bold text-green">Product Balance TERM</h6>
                </div>
                <div class="col-6 text-end">
                  <b-button @click="exportToExcel" class="btn-sm me-2 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'file-excel']"/>
                  </b-button>
                  <report-filter :form="form" @filterAction="filterAction" :filter="filter"></report-filter>
                  <b-button
                    @click="printPDF"
                    class="btn-sm me-2 akkurate-green-btn akkurate-small"
                  >
                    <font-awesome-icon :icon="['fas', 'book']" />
                  </b-button>
                  <b-button
                    @click="downloadPDF"
                    class="btn-sm akkurate-green-btn akkurate-small"
                  >
                    <font-awesome-icon :icon="['fas', 'download']" />
                  </b-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-form-select
                    v-model="selected_num"
                    @change="changePerPageNumber"
                    :options="numbers_options"
                  ></b-form-select>
                </div>
              </div>
              <div class="row" ref="pdfContent">
                <div class="col-12 col-md-12">
                  <statement-institution
                    :institution="institution"
                    :title="'PRODUCT BALANCE TERM'"
                  ></statement-institution>
                </div>
                <div class="col-12 col-md-12 trans_details">
                  <b-table
                    id="daily-control-tb"
                    :items="items"
                    :fields="fields"
                    striped
                    responsive="sm"
                    :per-page="perPage"
                    :busy.sync="isBusy"
                    :current-page="currentPage"
                    small
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-end">
                  <b-pagination
                    class="text-end"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="daily-control-tb"
                    align="right"
                  >
                  </b-pagination>
                  <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top">
      <i class="fas fa-angle-up"></i>
    </a>
  </div>
</template>
  <script>
  import SidebarView from "@/views/main/components/Sidebar.vue";
  import TopbarView from "@/views/main/components/Topbar.vue";
  import Footer from "@/views/main/components/Footer.vue";
  import ApiService from "@/core/services/api.service";
  import html2pdf from "html2pdf.js";
  import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
  import Multiselect from "vue-multiselect";
  import ReportFilter from "../form/ReportFilter.vue";
  export default {
    components: {
      SidebarView,
      TopbarView,
      Footer,
      StatementInstitution,
      Multiselect,
      ReportFilter,
    },
    mounted() {
      this.getCustomerProduct();
      this.getInstitution();
      this.getProductDropdown();
    },
    computed: {
      rows() {
        return this.items.length;
      },
    },

    data() {
      return {
        form: {
          start_date: this.getBeginningOfYear(),
          end_date: new Date().toISOString().substr(0, 10),
          branches: [],
          products: [],
          branch_options: [],
          product_options: [],
          users: [],
          users_options: [],
          status: [],
          coa: [],
          coa_options: [],
          terms: "Periodic",
          terms_options: ["YTD", "Periodic"],
          categories: [],
        },
        filter: {
          branches: true,
          products: true,
          users: true,
          status: true,
          condition: true,
          terms: false,
          coa: false,
          voucher_status: false
        },
        search: "Search",
        isBusy: false,
        isSearching: false,
        gender: "",
        gender_options: ["Male", "Female"],
        selected_num: "10",
        numbers_options: [10, 50, 100, 500, "All"],
        isLoadingInstitution: false,
        institution: "",
        fields: [
          {
            key: "customer",
            label: "Acct. Name",
          },
          {
            key: "account_number",
            label: "Acct. Number",
          },
          {
            key: "product",
            label: "Product",
          },
          {
            key: "balance",
            label: "Balance",
          },
          {
            key: "status",
            label: "Acc. Status",
          },
        ],
        perPage: 20,
        currentPage: 1,
        items: [],
      };
    },
    methods: {
      async getProductDropdown() {
        this.isBusy = true;
        this.search = "Processing...";
        await ApiService.query("/reports/controls/products/dropdown")
          .then((response) => {
            // console.log(response.data);
            this.isBusy = false;
            this.search = "Search";
            this.form.branch_options = response.data.branches;
            this.form.product_options = response.data.products;
          })
          .catch((error) => {
            console.log(error);
            this.isBusy = false;
            this.search = "Search";
          });
      },
      async getCustomerProduct() {
        this.isBusy = true;
        this.search = "Processing...";
        this.isSearching = true;
        await ApiService.query("/reports/controls/product/balance/term", {
          params: {
            startDate: this.form.start_date,
            endDate: this.form.end_date,
            product_type_ids: this.form.products.map((index) => { return index.id }),
            branch_ids: this.form.branches.map((index) => { return index.id }),
            status: this.form.status,
            amount: this.form.amount,
            oprand: this.form.oprand,
            term: "", //['periodic','YTD]
          },
        })
          .then((response) => {
            this.items = [];
            this.isBusy = false;
            this.search = "Search";
            this.isSearching = false;
            this.$Progress.start();
            console.log(response.data);
            this.items = response.data.accounts.map(index => {
              const first_name = index.customer?.first_name != undefined ? index.customer?.first_name : ""
              const middle_name = index.customer?.middle_name != undefined ? index.customer?.middle_name : ""
              const last_name = index.customer?.first_name != undefined ? index.customer?.last_name : ""
              const full_name = first_name + ' ' + middle_name + ' ' + last_name;
              return {
                customer: full_name,
                account_number: index.account_number,
                product: index.account_type.name,
                balance: index.account_balance,
                status: index.status,
              }
            })
          })
          .catch((error) => {
            this.isBusy = false;
            this.isSearching = false;
            this.search = "Search";
            console.log(error);
            this.$Progress.fail();
          })
          .finally(() => {
            this.$Progress.finish();
          });
      },
      downloadPDF() {
        const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        // console.log("here");
        const pdfOptions = {
          margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
          filename: "statement.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: "avoid-all" },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf()
          .from(content)
          .set(pdfOptions)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var insit_name = this.institution.name;
            var insit_tele = this.institution.telephone;
            var insit_email = this.institution.email;
            var insit_motto = this.institution.motto;

            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(
                [
                  "For Inquiries, Please Contact " + insit_name + ", Customer Service on " + insit_tele + "", "or email us on " + insit_email + " " + insit_motto + "", "Page " + i + " of " + totalPages,
                ],
                pdf.internal.pageSize.getWidth() / 2,
                pdf.internal.pageSize.getHeight() - 0.6,
                { align: "center" }
              );
            }
          }).save();
      },
      printPDF() {
        const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        // console.log("here");
        const pdfOptions = {
          margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
          filename: "statement.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: "avoid-all" },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf()
          .from(content)
          .set(pdfOptions)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var insit_name = this.institution.name;
            var insit_tele = this.institution.telephone;
            var insit_email = this.institution.email;
            var insit_motto = this.institution.motto;

            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(
                [
                  "For Inquiries, Please Contact " + insit_name + ", Customer Service on " + insit_tele + "", "or email us on " + insit_email + " " + insit_motto + "", "Page " + i + " of " + totalPages,
                ],
                pdf.internal.pageSize.getWidth() / 2,
                pdf.internal.pageSize.getHeight() - 0.6,
                { align: "center" }
              );
            }
            // Get the blob of the PDF
            return pdf.output("blob");
          })
          .then((pdfBlob) => {
            // Create a URL for the PDF blob
            var pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the URL in a new tab
            const printWindow = window.open(
              pdfUrl,
              "_blank",
              "width=800,height=600"
            );
            printWindow.onload = () => {
              printWindow.print();
              URL.revokeObjectURL(pdfUrl);
            };
          })
          .catch((error) => {
            console.error("Error generating PDF:", error);
          });
      },
      filterAction() {
        this.changePerPageNumber(this.perPage);
      },
      changePerPageNumber(data) {
        if (data == "All") {
          this.perPage = this.items.length;
          this.getCustomerProduct();
        } else {
          this.perPage = data;
          this.getCustomerProduct();
        }
      },
      async getInstitution() {
        this.isLoadingInstitution = true;
        await ApiService.get("/settings/institution")
          .then((response) => {
            this.institution = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoadingInstitution = false;
          });
      },
      getOneMonthBeforeDate() {
        // Get today's date
        let currentDate = new Date();

        // Remove one month to the current date
        currentDate.setMonth(currentDate.getMonth() - 1);

        // Format the date as 'YYYY-MM-DD'
        return currentDate.toISOString().substr(0, 10);
      },
      async generateExcel() {
        const fileUrl = '/REPORT_TEMPLATE.xlsx'; // Path to the file in the public folder

        try {
          // Fetch the Excel file from the public URL
          const response = await fetch(fileUrl);
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
          }
          const arrayBuffer = await response.arrayBuffer();

          // Load the file into ExcelJS
          const wb = new ExcelJS.Workbook();
          await wb.xlsx.load(arrayBuffer);

          // Access the first sheet
          const ws = wb.getWorksheet(1); // Get the first worksheet

          // Define header row
          const header = [
            "Acct. Name",
            "Acct. Number",
            "Product",
            "Balance",
            "Acc. Status",
          ];

          // Add header row starting from row 13
          const startingRow = 13;
          let headerRow = ws.getRow(startingRow);
          headerRow.values = header;
          headerRow.font = { bold: true };
          headerRow.commit(); // Commit the row to the worksheet

          // Add individual data rows
          let currentRow = startingRow + 1;

          // Process each item
          this.items.forEach(item => {
            const row = ws.getRow(currentRow);
            // Map item values to the corresponding header columns
            row.values = [
              item.customer,
              item.account_number,
              item.product,
              item.balance,
              item.status,
            ];
            row.commit();
            currentRow++;
          });

          const buffer = await wb.xlsx.writeBuffer();
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          // Create a download link and simulate a click to download the file
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'Modified_REPORT_TEMPLATE.xlsx';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (err) {
          console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
        }
      }
    },
  };
</script>
  
  <style>

  /* Customize the pagination page number color */
  .pagination {
    --bs-pagination-active-bg: rgb(1, 134, 115) !important;
    --bs-pagination-active-border-color: rgb(1, 134, 115) !important;
    --bs-pagination-focus-box-shadow: none !important;
  }

  .page-link {
    color: rgb(1, 134, 115) !important;
  }

  .page-link:hover {
    color: rgb(1, 134, 115) !important;
  }

  .page-link.active,
  .active>.page-link {
    color: #fff !important;
  }
</style>