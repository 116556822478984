<template>
    <div id="wrapper">
      <SidebarView :reports="true" />
      <div class="d-flex flex-column" id="content-wrapper">
        <div id="content">
          <TopbarView />
          <div class="container-fluid">
            <div class="card card-shape home-box">
              <div class="card-header py-3">
                <div class="row align-items-center">
                  <div class="col-10">
                      <h6 class="m-0 fw-bold text-green">NOTE TO THE RECEIPTS AND PAYMENTS</h6>
                      </div>  
                    <div class="col-2" style="display: flex; justify-content: flex-end">
                    <report-filter
                    :form="form"
                    @filterAction="filterAction"
                    :filter="filter"
                    :current_financial_year="current_financial_year"
                    ></report-filter>
                    </div>
                  </div>
                  <div class="row">
                  <!-- <div class="col-6 text-end">
                    <b-button @click="printPDF" class="me-2 btn-sm akkurate-green-btn akkurate-small" title="Print"><font-awesome-icon :icon="['fas', 'book']"/></b-button>
                    <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small" title="Download"><font-awesome-icon :icon="['fas', 'download']"/></b-button>
                  </div> -->
                </div>
              </div>
              <div class="card-body">
                <div class="row" ref="pdfContent">
                  <div class="col-12 col-md-12">
                    <statement-institution
                      :institution="institution"
                      :current_date="false"
                    ></statement-institution>
                  </div>
                  <div class="col-12">

                  </div>
                  <div class="col-12 col-md-12 trans_details">
                    
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-8"></div>
                  <div class="col-md-4 text-end">
                    <span style="font-weight: bold" v-if="form.start_date && form.end_date">From: {{ form.start_date }} to {{ form.end_date }}</span>
                  </div>
                </div>
                <div class="" style="text-align: center;">
                          <div class="fw-bold akkurate-green">NOTE TO THE RECEIPTS AND PAYMENTS</div>
                </div>
                <div v-if="isBusy" class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                </div>
                    <div v-else id="balance-sheet" class="row">
                      <div class="col-12 my-2">
                      </div>
                      <div class="col-6 col-md-6 px-0 trans_details">
                        <b-table-simple responsive outlined>
                          <b-thead>
                            <b-tr>
                              <b-th class="text-center fs-6 akkurate-green" colspan="3"><span>RECEIPTS</span></b-th>
                            </b-tr>
                          </b-thead>
                          <b-thead>
                            <b-tr>
                              <b-th class="" ><span>COA</span></b-th>
                              <b-th class="" ><span>NARRATION</span></b-th>
                              <b-th class="" ><span>DR (GHS)</span></b-th>
                              <b-th class="" ><span>DATE</span></b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="(value, item) in receipts" :key="item" class="list-item">
                              <b-td >{{ value.coasubhead.code }} - {{ value.coasubhead.name }}</b-td>
                              <b-td  >{{ value.transaction_description }}</b-td>
                              <b-td>
                                <span >
                                  {{ Number(value.dr_amount ? value.dr_amount.toFixed(2) : 0).toLocaleString() }}
                                </span>
                              </b-td>
                              <b-td >{{ value.transaction_date }}</b-td>
                              
                            </b-tr>
                          </b-tbody>
                          <!-- <b-tfoot>
                            <b-tr>
                              <b-th>Total Assets</b-th>
                            </b-tr>
                          </b-tfoot> -->
                        </b-table-simple>
                      </div>
                      <div class="col-6 col-md-6 px-0 trans_details">
                        <b-table-simple responsive outlined>
                          <b-thead>
                            <b-tr>
                              <b-th class="text-center fs-6 akkurate-green" colspan="3"><span>PAYMENTS</span></b-th>
                            </b-tr>
                          </b-thead>
                          <b-thead>
                            <b-tr>
                              <b-th class="" ><span>COA</span></b-th>
                              <b-th class="" ><span>NARRATION</span></b-th>
                              <b-th class="" ><span>CR (GHS)</span></b-th>
                              <b-th class="" ><span>DATE</span></b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="(value, item) in payments" :key="item" class="list-item">
                              <b-td  >{{ value.coasubhead.code }} - {{ value.coasubhead.name }}</b-td>
                              <b-td  >{{ value.transaction_description }}</b-td>
                              
                              <b-td>
                                <span >
                                  {{ Number(value.cr_amount ? value.cr_amount.toFixed(2) : 0).toLocaleString() }}
                                </span>
                              </b-td>
                              <b-td >{{ value.transaction_date }}</b-td>
                              
                            </b-tr>
                          </b-tbody>
                          <!-- <b-tfoot>
                            <b-tr>
                              <b-th>Total Assets</b-th>
                              <b-th>{{ sumAssetsTotal }}</b-th>
                            </b-tr>
                          </b-tfoot> -->
                        </b-table-simple>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 col-md-6">
                        <b-table-simple responsive outlined>
                          
                          <b-tfoot>
                            <b-tr>
                              <b-th>Total Receipts</b-th>
                              <b-th>GHS {{ displayNumber(grand_total_receipts) }}</b-th>
                            </b-tr>
                          </b-tfoot>
                        </b-table-simple>
                      </div>
                      <div class="col-6 col-md-6">
                        <b-table-simple responsive outlined>
                          
                          <b-tfoot>
                            <b-tr>
                              <b-th>Total Payments</b-th>
                              <b-th>GHS {{ displayNumber(grand_total_payments) }}</b-th>
                              <b-th></b-th>
                            </b-tr>
                          </b-tfoot>
                        </b-table-simple>
                      </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <footer></footer>
      </div>
      <a class="d-inline scroll-to-top" href="#page-top"
        ><i class="fas fa-angle-up"></i
      ></a>
    </div>
  </template>
  <script>
  import SidebarView from "@/views/main/components/Sidebar.vue";
  import TopbarView from "@/views/main/components/Topbar.vue";
  import Footer from "@/views/main/components/Footer.vue";
  import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
  import ApiService from "@/core/services/api.service";
  import Multiselect from "vue-multiselect";
  import html2pdf from "html2pdf.js";
  import ReportFilter from "@/views/main/pages/reports/form/ReportFilter.vue";
  export default {
    components: {
      SidebarView,
      TopbarView,
      Footer,
      StatementInstitution,
      Multiselect,
      ReportFilter
    },
    data() {
      return {
        // filter:{
        //   branch:"",
        //   start_date: "",
        //   end_date: "",
        //   year:"",
        // },
        filter: {
          branches: false,
          products: false,
          users: false,
          status: false,
          condition: false,
          coa: false,
          terms: true,
        },
        current_financial_year: {},
        form: {
          start_date: this.getOneMonthEailer(),
          end_date: new Date().toISOString().substr(0, 10),
          branches: [],
          products: [],
          branch_options: [],
          product_options: [],
          users: [],
          users_options: [],
          status: [],
          coa: [],
          coa_options: [],
          terms: "Periodic",
          terms_options: ["YTD", "Periodic"],
          categories: [],
        },
        receipts: [],
        payments: [],
        search: "Search",
        grand_total_receipts: 0,
        grand_total_payments: 0,
        grant_total_liabilities: 0,
        grant_total_surpluses: 0,
        grant_total_assets: 0,
        isBusy: false,
        isSearching: false,
        selected_num: "10",
        numbers_options: [10, 50, 100, 500],
        isLoadingInstitution: false,
        institution: "",
        start_date: "",
        end_date: "",
        perPage: 20,
        currentPage: 1,
        items: [],
      };
    },
    computed: {
      rows() {
        return this.items.length;
      },
      sumLoanBalance() {
        let total = 0;
        for (let i = 0; i < this.items.length; i++) {
          total = this.items[i].loan_balance;
        }
        return total;
      },
      sumSavingBalance() {
        let total = 0;
        for (let i = 0; i < this.items.length; i++) {
          total = this.items[i].saving_balance;
        }

        return total;
      },
      //assets total
      sumAssetsTotal() {
        let total = 0;
        for (let i = 0; i < this.assets_items.length; i++) {
          total = this.assets_items[i].amount;
        }
        return total;
      },
    },

    created() {
      this.getInstitution();
      this.getBalanceSheets();
    },
    methods: {

      filterAction() {
        // this.changePerPageNumber(this.perPage);
        // this.form.start_date = 
        this.getBalanceSheets();
      },
      async getBalanceSheets() {
        this.items = [];
        this.isBusy = true;
        this.search = "Processing...";
        this.isSearching = true;
        await ApiService.post("/reports/financials/receipts-payments", this.form)
          .then((response) => {
            this.isBusy = false;
            this.search = "Search"
            this.isSearching = false;
            // var res = response.data?.data;
            var res = response.data;
            this.receipts = res.receipts;
            this.payments = res.payments;

            this.grand_total_receipts = res.receipts_total;
            this.grand_total_payments = res.payments_total;
            this.start_date = res.current_financial_year.start_date;
            this.end_date = res.current_financial_year.end_date;
            this.current_financial_year = res.current_financial_year
          })
          .catch((error) => {
            this.isBusy = false;
            this.search = "Search"
            this.isSearching = false;
            console.log(error);
          });
      },
      downloadPDF() {
        const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        // console.log("here");
        const pdfOptions = {
          margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
          filename: "statement.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: "avoid-all" },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf()
          .from(content)
          .set(pdfOptions)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var insit_name = this.institution.name;
            var insit_tele = this.institution.telephone;
            var insit_email = this.institution.email;
            var insit_motto = this.institution.motto;

            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(
                [
                  "For Inquiries, Please Contact " +
                  insit_name +
                  ", Customer Service on " +
                  insit_tele +
                  "",
                  "or email us on " + insit_email + " " + insit_motto + "",
                  "Page " + i + " of " + totalPages,
                ],
                pdf.internal.pageSize.getWidth() / 2,
                pdf.internal.pageSize.getHeight() - 0.6,
                { align: "center" }
              );
            }
          })
          .save();
      },
      printPDF() {
        const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        // console.log("here");
        const pdfOptions = {
          margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
          filename: "statement.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          pagebreak: { mode: "avoid-all" },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf()
          .from(content)
          .set(pdfOptions)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            var insit_name = this.institution.name;
            var insit_tele = this.institution.telephone;
            var insit_email = this.institution.email;
            var insit_motto = this.institution.motto;

            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(
                [
                  "For Inquiries, Please Contact " +
                  insit_name +
                  ", Customer Service on " +
                  insit_tele +
                  "",
                  "or email us on " + insit_email + " " + insit_motto + "",
                  "Page " + i + " of " + totalPages,
                ],
                pdf.internal.pageSize.getWidth() / 2,
                pdf.internal.pageSize.getHeight() - 0.6,
                { align: "center" }
              );
            }
            // Get the blob of the PDF
            return pdf.output("blob");
          })
          .then((pdfBlob) => {
            // Create a URL for the PDF blob
            var pdfUrl = URL.createObjectURL(pdfBlob);

            // Open the URL in a new tab
            const printWindow = window.open(
              pdfUrl,
              "_blank",
              "width=800,height=600"
            );
            printWindow.onload = () => {
              printWindow.print();
              URL.revokeObjectURL(pdfUrl);
            };
          })
          .catch((error) => {
            console.error("Error generating PDF:", error);
          });
      },
      filterTable() {
        this.getReceiptListing();
      },
      changePerPageNumber(data) {
        this.perPage = data;
        this.getReceiptListing();
      },
      async getInstitution() {
        this.isLoadingInstitution = true;
        await ApiService.get("/settings/institution")
          .then((response) => {
            this.institution = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoadingInstitution = false;
          });
      },
    },
  };
</script>