<template>
    <div class="row">
        <div class="col">
        <b-form-group id="denomin" label-for="denomin">
        <multiselect
            id="denom_amount"
            v-model="form.denom_amount"
            :options="denom_options"
            name="denom_amount"
            @select="handleInput"
            :allow-empty="false"
            selectLabel=""
deselectLabel=""
            class="mt-2 field-container"
            required
        ></multiselect>
        </b-form-group>
        </div>

        <div class="col">
        <b-form-group
            label-for="no_of_den"
            class="mb-2">
            <!-- <template #label>
            <label class="akkurate-dark small" for="no_of_den">No of Denomin <star></star></label>
            </template> -->
            <b-form-input
                id="no_of_den"
                name="no_of_den"
                v-model="form.denom_frequency"
                @input="handleInput"
                class="mt-2 field-container fields"
                type="text"
            ></b-form-input>
            <b-form-invalid-feedback >
                The No Of Den field is required
            </b-form-invalid-feedback>
        </b-form-group>
        </div>

        <div class="col">
        <b-form-group
            label-for="total"
            class="mb-2">
            <!-- <template #label>
            <label class="akkurate-dark small" for="total">Total</label>
            </template> -->
            <b-form-input
                id="total"
                name="total"
                class="mt-2 field-container fields"
                v-model="form.sub_total"
                type="text"
                disabled
            ></b-form-input>
        </b-form-group>
        </div>
        <div class="col mt-auto">
            <a class="akkurate-danger-btn btn text-white mb-2" type="button" @click="removeField" ><span class="small"> <i class="fas fa-trash"></i></span></a> 
        </div>
        </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
    name: "Denomination",
    components: {
        Multiselect,
    },
    props: ["form","denom_options","transaction"],
    // data() {
    //     return {
    //         demomin_option: ['1', '2', '5', '10', '20', '50', '100', '200'],
    //     }
    // },
    methods:{
        handleInput(){
            var sub_total = Number(this.form.denom_amount) * Number(this.form.denom_frequency);
            this.form.sub_total = sub_total;
        },
        removeField(){
            this.$emit('removeBtn', 'index');
        }
        

    }
}
</script>