<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container">
          <div class="card card-shape home-box">
            <div class="card-header py-3">
              <div class="row align-items-center">
                <div class="col-6">
                  <h6 class="m-0 fw-bold text-green">Single Chart of Account</h6>
                </div>
                <div class="col-6 text-end">
                  <report-filter
                    :form="form"
                    @filterAction="filterAction"
                    :filter="filter"
                    :is_single_coa="true"
                  ></report-filter>
                  <b-button
                    @click="printPDF"
                    class="btn-sm me-2 akkurate-green-btn akkurate-small"
                    title="Print"
                  >
                    <font-awesome-icon :icon="['fas', 'book']" />
                  </b-button>
                  <b-button
                    @click="downloadPDF"
                    class="btn-sm akkurate-green-btn akkurate-small"
                    title="Download"
                  >
                    <font-awesome-icon :icon="['fas', 'download']" />
                  </b-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-form-select
                    v-model="selected_num"
                    @change="changePerPageNumber"
                    :options="numbers_options"
                  ></b-form-select>
                </div>
              </div>
              <div class="row" ref="pdfContent">
                <div class="col-12 col-md-12">
                  <statement-institution
                    :institution="institution"
                    :current_date="false"
                  ></statement-institution>
                </div>
              </div>
            <div class="row">
              <div class="col-md-4 col-4 bd-highlight">
                  <p v-if="form.coa && form.coa.name" class="mt-2 akkurate-green fw-bold mt-auto p-2 bd-highlight">{{form.coa.code}} - {{form.coa.name}} Transactions</p>
                  <p v-else class="mt-2 akkurate-green fw-bold mt-auto p-2 bd-highlight">Chart of Account Transactions</p>
              </div>

              <div class="col-md-4 col-4 ">
              </div>  
              <div class="col-md-4 col-4 text-center bd-highlight">
                  <span class="mt-2">From: <strong>{{ form.start_date }}</strong>  To: <strong>{{ form.end_date }}</strong> </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-4 bd-highlight">
                  </div>

              <div class="col-md-4 col-4 ">
              </div>  
              <div class="col-md-4 col-4 text-center bd-highlight d-flex" style="flex-direction: column;">
                  <span class="mt-1" style="text-align: left;">Opening Bal.: <strong>GHS {{ getOpeningBal }}</strong> </span>
                  <span class="mt-1" style="text-align: left;">Closing Bal.: <strong>GHS {{ getTotal }}</strong> </span>
              </div>
            </div>
              <div class="row">
                <div class="col-12 col-md-12 trans_details">
                  <b-table
                    id="chart-of-account"
                    :items="items"
                    :fields="fields"
                    striped
                    responsive="sm"
                    :per-page="perPage"
                    :busy.sync="isBusy"
                    :current-page="currentPage"
                    small
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <template #custom-foot>
                      <b-tr>
                        <b-th colspan="2"></b-th>
                        <b-th>TOTAL</b-th>
                        <b-th>{{ getDrTotal }}</b-th>
                        <b-th>{{ getCrTotal }}</b-th>
                        <b-th></b-th>
                        <b-th>{{ getTotal }}</b-th>
                      </b-tr>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-end">
                  <b-pagination
                    class="text-end"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="daily-control-tb"
                    text-align="right"
                  >
                  </b-pagination>
                  <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top">
      <i class="fas fa-angle-up"></i>
    </a>
  </div>
</template>
  <script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import ApiService from "@/core/services/api.service";
import html2pdf from "html2pdf.js";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import Multiselect from "vue-multiselect";
import ReportFilter from "../form/ReportFilter.vue";
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    StatementInstitution,
    Multiselect,
    ReportFilter,
  },
  props: ["id"],
  mounted() {
    // this.getCustomerProduct();
    this.getInstitution();
    this.getProductDropdown();
  },
  computed: {
    rows() {
      return this.items.length;
    },
    getDrTotal() {
      let sum = 0;
      this.items.forEach((index) => {
        sum += Number(index.dr_amount);
      });
      return sum;
    },
    getCrTotal() {
      let sum = 0;
      this.items.forEach((index) => {
        sum += Number(index.cr_amount);
      });
      return sum;
    },
    getTotal() {
      return this.getDrTotal - this.getCrTotal;
    },
    getOpeningBal() {
      let opening_bal = 0;
      if (this.items.length > 0) {
        opening_bal = this.items[0].current_amount;
      }

      return opening_bal;
    },
  },

  data() {
    return {
      form: {
        start_date: this.getOneMonthEailer(),
        end_date: new Date().toISOString().substr(0, 10),
        branches: [],
        products: [],
        branch_options: [],
        product_options: [],
        users: [],
        users_options: [],
        status: [],
        coa: [],
        coa_options: [],
        terms: "YTD",
        terms_options: ["YTD", "Periodic"],
        categories: [],
      },
      filter: {
        branches: true,
        products: false,
        users: false,
        status: false,
        condition: false,
        coa: true,
        terms: true,
      },
      search: "Search",
      isBusy: false,
      isSearching: false,
      gender: "",
      gender_options: ["Male", "Female"],
      selected_num: "10",
      numbers_options: [10, 50, 100, 500, "All"],
      isLoadingInstitution: false,
      institution: "",
      categories_options: ['STANDARD','PRODUCT', 'USER'],
      fields: [
        {
          key: "transaction_date",
          label: "Transaction Date",
        },
        {
          key: "transaction_description",
          label: "Description",
        },
        {
          key: "branch.name",
          label: "Branch",
        },
        {
          key: "dr_amount",
          label: "DR",
        },
        {
          key: "cr_amount",
          label: "CR",
        },
        {
          key: "previous_amount",
          label: "Prev. Balance (GHS)",
        },
        {
          key: "current_amount",
          label: "Balance (GHS)",
        },
      ],
      perPage: 20,
      currentPage: 1,
      items: [],
    };
  },
  methods: {
    async getProductDropdown() {
      this.isBusy = true;
      this.search = "Processing...";
      await ApiService.query("/reports/controls/products/dropdown")
        .then((response) => {
          // console.log(response.data);
          this.isBusy = false;
          this.search = "Search";
          this.form.branch_options = response.data.branches;
          this.form.product_options = response.data.products;
          this.form.coa_options = response.data.coa_subheads;
        })
        .catch((error) => {
          // console.log(error);
          this.isBusy = false;
          this.search = "Search";
        });
    },
    async getCustomerProduct() {
      this.isBusy = true;
      this.search = "Processing...";
      this.isSearching = true;
      // let coa_subhead = this.form.coa.map((index) => {
      //   return index.id;
      // });

      let coa_subhead = [];
      if(this.form.coa)
      {
        coa_subhead[0] = this.form.coa.id
      }
      const branches = this.form.branches.map((index) => {
        return index.id;
      });
      const categories = this.form.categories.map((value) => {
        return value;
      });
      // console.log(this.form.terms);
      await ApiService.query("/reports/financials/coa/single", {
        params: {
          startDate: this.form.start_date,
          endDate: this.form.end_date,
          coa: coa_subhead,
          branches: branches,
          categories: categories,
          terms: this.form.terms, //["YTD","Periodic"]
        },
      })
        .then((response) => {
          // console.log(response.data);
          this.items = [];
          this.isBusy = false;
          this.search = "Search";
          this.isSearching = false;
          this.$Progress.start();
          // this.items = response.data.map((index) => {
          //   return {
          //     trans_date: index.trans_date,
          //     description: index.description,
          //     coa_subheads: index.coa_subheads,
          //     cr_amount: index.cr_amount,
          //     dr_amount: index.dr_amount,
          //     balance: index.balance,
          //     previous_amount: index.previous_amount,
          //   };
          // });
          let coa_trans = response.data.data;
          // this.items = response.data.data;
          let balance_bf = 0;

          if(coa_trans && coa_trans.length && coa_trans.length > 0)
          {
            balance_bf = coa_trans[0].previous_amount ?? 0;
            let increase_by = coa_trans.coasubhead?.coahead?.headtype?.increase_by;
            let bf_cr_amount = ""
            let bf_dr_amount = ""
            
            if(increase_by == "Credit")
            {
              let amt = balance_bf;
              //if amount is positive, it is a credit
              if(amt >= 0)
              {
                bf_cr_amount = balance_bf;
              }
              else
              {
                // convert to positive number

                bf_dr_amount = balance_bf * -1;
              }
            }
            else
            {
              let amt = balance_bf;
              //if amount is positive, it is a debit
              if(amt >= 0)
              {
                bf_dr_amount = balance_bf;
              }
              else
              {
                bf_cr_amount = balance_bf * -1;
              }
            }

            let newObj = { transaction_date: this.form.start_date, transaction_description: "BALANCE B/F", coa_subheads: "", cr_amount:bf_cr_amount, dr_amount: bf_dr_amount, current_amount: balance_bf, previous_amount: "" };

            coa_trans.unshift(newObj);
          }
          this.items = coa_trans;
          // console.log(response);
        })
        .catch((error) => {
          this.isBusy = false;
          this.isSearching = false;
          this.search = "Search";
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.$Progress.finish();
        });
    },
    downloadPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                  insit_name +
                  ", Customer Service on " +
                  insit_tele +
                  "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
        })
        .save();
    },
    getOneMonthEailer() {
      let currentDate = new Date();
      // Subtract one month
      currentDate.setMonth(currentDate.getMonth() - 1);
      // Convert the date to ISO string and extract only the date part
      let oneMonthEarlier = currentDate.toISOString().substr(0, 10);
      return oneMonthEarlier;
    },
    printPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                  insit_name +
                  ", Customer Service on " +
                  insit_tele +
                  "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
          // Get the blob of the PDF
          return pdf.output("blob");
        })
        .then((pdfBlob) => {
          // Create a URL for the PDF blob
          var pdfUrl = URL.createObjectURL(pdfBlob);

          // Open the URL in a new tab
          const printWindow = window.open(
            pdfUrl,
            "_blank",
            "width=800,height=600"
          );
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
          };
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    },
    filterAction() {
      this.changePerPageNumber(this.perPage);
    },
    changePerPageNumber(data) {
      if (data == "All") {
        this.perPage = this.items.length;
        this.getCustomerProduct();
      } else {
        this.perPage = data;
        this.getCustomerProduct();
      }
    },
    async getInstitution() {
      this.isLoadingInstitution = true;
      await ApiService.get("/settings/institution")
        .then((response) => {
          this.institution = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingInstitution = false;
        });
    },
    getOneMonthBeforeDate() {
      // Get today's date
      let currentDate = new Date();

      // Remove one month to the current date
      currentDate.setMonth(currentDate.getMonth() - 1);

      // Format the date as 'YYYY-MM-DD'
      return currentDate.toISOString().substr(0, 10);
    },
  },
};
</script>
  
  <style scoped>
/* Customize the pagination page number color */
.pagination {
  --bs-pagination-active-bg: rgb(1, 134, 115) !important;
  --bs-pagination-active-border-color: rgb(1, 134, 115) !important;
  --bs-pagination-focus-box-shadow: none !important;
}
.page-link {
  color: rgb(1, 134, 115) !important;
}
.page-link:hover {
  color: rgb(1, 134, 115) !important;
}
.page-link.active,
.active > .page-link {
  color: #fff !important;
}
</style>