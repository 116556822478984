<template>
    <div>
        <b-modal :id="String(data.id + 'account_statement')" size="xl" @shown="modalShown">
            <template #modal-title>
                <p class="m-0 fw-bold text-green">Account Statement</p>
            </template>
            <div class="container mb-3">
                <div class="row">
                    <form @submit.prevent="filterAccountStatement">
                        <statement-filter :filter="filter" :is-searching="isSearching" :search="search"></statement-filter>
                    </form>
                </div>
             </div>
             <hr/>

            <div class="container text-start" ref="pdfContent">
                <div class="row">
                    <div class="col-md-4 col-4 d-flex align-items-center flex-column bd-highlight">
                        <img class="img-fluid img-visibility statement-logo" :src="require('@/assets/img/ggbccu_logo.png')">
                        <p class="mt-2 akkurate-green fw-bold mt-auto p-2 bd-highlight">STATEMENT OF ACCOUNT</p>
                    </div>
                    <div class="d-flex align-items-end flex-column bd-highlight col-md-8 col-8 text-end">
                        <p class="fw-bold text-uppercase mb-0">{{ institution?.name }}</p>
                        <p class="fw-bold">{{ institution?.motto }}</p>
                        <p class="mb-0 akkurate-small">{{ institution?.postal }}</p>
                        <p class="mb-0 akkurate-small">{{ institution?.telephone }}</p>
                        <p class="akkurate-small">{{ institution?.email }}</p>
                        <p class="fw-bold text-end mt-auto p-2 bd-highlight text-uppercase">{{ new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).format(Date.now()) }}</p>
                    </div> 
                </div>
                <hr class="mt-0 akkurate-danger"/>
                <div class="row">
                <div class="col-md-5">
                    <h6 class="akkurate-dark report-l-text">ACCOUNT DETAILS</h6>
                    <hr class="w-100 mt-0"/>
                    <div class="row akkurate-small">
                        <div class="col-md-4 fw-bold akkurate-grey report-s-text">
                            Customer Name
                        </div>
                        <div class="col-md-8">
                            {{ getFullNameAndID }}
                        </div>
                        <div class="col-md-4 fw-bold akkurate-grey report-s-text">
                            Account Name
                        </div>
                        <div class="col-md-8">
                            {{ form.account_type?.name }}
                        </div>
                        <div class="col-md-4 fw-bold akkurate-grey report-s-text">
                           Account Number
                        </div>
                        <div class="col-md-8">
                            {{ form.account_number }}
                        </div>
                        <div class="col-md-4 fw-bold akkurate-grey report-s-text">
                           Branch
                        </div>
                        <div class="col-md-8">
                           {{ form.branch?.name }}
                        </div>
                        <div class="col-md-4 fw-bold akkurate-grey report-s-text">
                           Currency
                        </div>
                        <div class="col-md-8 report-s-text">
                           {{ institution?.currency_code }}
                        </div>
                    </div>
                </div>
                <div class="col-md-3"></div>
                <div class="col-md-4">
                    <h6 class="akkurate-dark report-l-text">ACCOUNT SUMMARY</h6>
                    <hr class="w-100 mt-0"/>
                    <div class="row akkurate-small">
                        <div class="col-md-5 fw-bold akkurate-grey report-s-text">
                           Opening Balance
                        </div>
                        <div class="col-md-7">
                            {{ form.opening_balance }}
                        </div>
                        <div class="col-md-5 fw-bold akkurate-grey report-s-text">
                           Total Debit
                        </div>
                        <div class="col-md-7">
                            {{ form.total_debits }}
                        </div>
                        <div class="col-md-5 fw-bold akkurate-grey report-s-text">
                            Total Credit
                        </div>
                        <div class="col-md-7">
                            {{ form.total_credits }}
                        </div>
                        <hr class="w-100 mt-0 mb-2"/>
                        <div class="col-md-5 fw-bold akkurate-grey report-s-text">
                          Closing Balance
                        </div>
                        <div class="col-md-7 fw-bold report-s-text">
                            {{ form.closing_balance }}
                        </div>
                    </div>
                </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6">
                        <h6 class="report-l-text">ACCOUNT TRANSACTIONS</h6>
                    </div>
                    <div class="col-md-6 text-end">
                        <h6 class="report-l-text"><span class="fw-bold">From:</span> {{ filter.start_date }}  <span class="fw-bold">To:</span> {{ filter.end_date }}</h6>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-12 trans_details">
                        <b-table striped small :busy.sync="isBusy" :items="items" :fields="fields">
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                                </div>
                            </template>
                            <template #cell(debit)="data">
                                <p class="mb-0" v-if="data.item.transaction_type == 'Withdrawal'">{{ data.item.trans_amount }}</p>
                            </template>
                            <template #cell(credit)="data">
                                <p class="mb-0" v-if="data.item.transaction_type == 'Deposit'">{{ data.item.trans_amount }}</p>
                            </template>
                            <template #cell(trans_status)="data">
                                <span class="badge " :class="data.item.trans_status=='COMPLETED'? 'bg-success': (data.item.trans_status=='REVERSED'? 'bg-danger': 'bg-warning') ">{{ data.item.trans_status }}</span>
                            </template>
                        </b-table>
                    </div>

                </div>
                <div class="col-md-12 text-center">
                    *********************************
                    END OF STATEMENT 
                    *********************************
                </div>
               
               
            </div>
            <template #modal-footer>
                <div class="w-100">
                <!-- begin loader -->
                <!-- <beat-loader-component 
                class="float-left"
                v-if="isLoading"
                :isLoading="isLoading"
                ></beat-loader-component> -->
                <!-- end loader -->
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button @click="printPDF" type="button" class="akkurate-green-btn download-btn ms-2 float-right"><span class="small"><font-awesome-icon :icon="['fas', 'book']" /> Print</span></b-button>
                <b-button @click="downloadPDF" class="akkurate-green-btn download-btn float-right"><span class="small"><font-awesome-icon :icon="['fas', 'save']" /> Download</span></b-button>     
            </div>
            </template>
        
        </b-modal>
    </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import StatementFilter from './form/StatementFilter.vue'
import html2pdf from "html2pdf.js";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
export default {

    props: ['data', 'institution','user'],
    components: {
        StatementFilter,
        StatementInstitution
    },
    data() {
           return {
            filter: {
                account_number: "",
                start_date: this.getOneMonthBeforeDate(),
                end_date: new Date().toISOString().substr(0, 10),
            },
            frontImgUrl: "",
            backImgUrl: "",
            imageLoaded: false,
            form: {
                customer: [],
                account_type: [],
                branch: [],
            },
            total_credit: 0,
            total_debit: 0,
            fields: [
                { key: 'trans_date', label: 'Date' },
                { key: 'transaction_mode', label: 'Mode' },
                { key: 'trans_status', label: 'Status' },
                { key: 'narration', label: 'Description' },
                { key: 'debit', label: 'Debit' },
                { key: 'credit', label: 'Credit' },
                { key: 'current_balance', label: 'Balance' },
            ],
            items: [],
            search: "Search",
            isSearching: false,
            isBusy: false,

        }
    },
    computed: {
        getFullNameAndID() {
            let first_name = this.form.customer.first_name != null ? this.form.customer.first_name : "";
            let middlename = this.form.customer.middlename != null ? this.form.customer.middlename : "";
            let last_name = this.form.customer.last_name != null ? this.form.customer.last_name : "";
            let code = this.form.customer.code != null ? this.form.customer.code : "";
            return first_name + ' ' + middlename + ' ' + last_name + ' - ' + code;

        }
    },
    methods: {
        async getAccountStatement() {
            this.isSearching = true;
            this.search = "Processing..."
            this.isBusy = true;
            this.filter.account_number = this.data.account_number;
            await ApiService.query(`/accounts/statement/${this.filter.account_number}`, {
                params: {
                    start_date: this.filter.start_date,
                    end_date: this.filter.end_date,
                }
            })
                .then((response) => {
                    this.isSearching = false;
                    this.search = "Search"
                    this.isBusy = false;
                    this.form = response.data.account;
                    this.items = response.data.account.transactions;
                    // this.handleImageLoad();
                }).catch((error) => {
                    this.isSearching = false;
                    this.search = "Search"
                    this.isBusy = false;
                });
        },
        handleImageLoad() {
            this.imageLoaded = true;
            this.frontImgUrl = this.details.account?.customer?.gh_card_front_path;
            this.backImgUrl = this.details.account?.customer?.gh_card_back_path;
        },
        downloadPDF() {

            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            // console.log("here");
            const pdfOptions = {
                margin: [0.3, 0.1, 1.2, 0.1],
                filename: 'statement.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            };
            html2pdf()
                .from(content)
                .set(pdfOptions)
                .toPdf()
                .get('pdf')
                .then((pdf) => {
                    var totalPages = pdf.internal.getNumberOfPages();
                    var insit_name = this.institution.name;
                    var insit_tele = this.institution.telephone;
                    var insit_email = this.institution.email;
                    var insit_motto = this.institution.motto;

                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(150);
                        pdf.text(["For Inquiries, Please Contact " + insit_name + ", Customer Service on " + insit_tele + "", "or email us on " + insit_email + " " + insit_motto + "", "Page " + i + " of " + totalPages], pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 0.6, { align: "center" });
                    }
                }).save();

        },
        printPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            // console.log("here");
            const pdfOptions = {
                margin: [0.3, 0.1, 1.2, 0.1],
                filename: 'statement.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            };
            html2pdf()
                .from(content)
                .set(pdfOptions)
                .toPdf()
                .get('pdf')
                .then((pdf) => {
                    var totalPages = pdf.internal.getNumberOfPages();
                    var insit_name = this.institution.name;
                    var insit_tele = this.institution.telephone;
                    var insit_email = this.institution.email;
                    var insit_motto = this.institution.motto;

                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(150);
                        pdf.text(["For Inquiries, Please Contact " + insit_name + ", Customer Service on " + insit_tele + "", "or email us on " + insit_email + " " + insit_motto + "", "Page " + i + " of " + totalPages], pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 0.6, { align: "center" });
                    }
                    // Get the blob of the PDF
                    return pdf.output("blob");
                })
                .then((pdfBlob) => {
                    // Create a URL for the PDF blob
                    var pdfUrl = URL.createObjectURL(pdfBlob);

                    // Open the URL in a new tab
                    const printWindow = window.open(pdfUrl, '_blank', 'width=800,height=600');
                    printWindow.onload = () => {
                        printWindow.print();
                        URL.revokeObjectURL(pdfUrl);
                    };
                })
                .catch((error) => {
                    console.error("Error generating PDF:", error);
                });
        },
        filterAccountStatement() {
            this.getAccountStatement();
        },
        modalShown() {
            this.getAccountStatement();
        },
        getOneMonthBeforeDate() {
            // Get today's date
            let currentDate = new Date();

            // Remove one month to the current date
            currentDate.setMonth(currentDate.getMonth() - 1);

            // Format the date as 'YYYY-MM-DD'
            return currentDate.toISOString().substr(0, 10);
        },


    }
}
</script>