<template>
  <div id="wrapper">
    <Sidebar :mobile="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <Topbar />
        <MobileMenu />
        <div class="container-fluid">
          <div class="col-12">
            <h4 class="akkurate-dark">Member Registration</h4>
          </div>
          <hr />
          <form @submit.prevent="handleSubmit">
            <div class="row">
              <div class="col-12 text-center">
                <p class="akkurate-danger">{{ error_message }}</p>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark" for="title"
                  >Title <star></star
                ></label>
                <b-form-group id="title" label-for="title">
                  <multiselect
                    class="mt-2 field-container"
                    v-model="form.title"
                    :options="title_options"
                  ></multiselect>
                </b-form-group>
                <errors v-if="errors?.title">
                  {{ errors?.title[0] }}
                </errors>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark" for="first_name"
                  >First Name <star></star
                ></label>
                <b-form-group id="first_name" label-for="first_name">
                  <b-form-input
                    class="mb-3 mt-2 field-container fields"
                    type="text"
                    v-model="form.first_name"
                    placeholder="Enter First Name"
                    required
                  >
                  </b-form-input>
                  <errors v-if="errors?.first_name">
                    {{ errors?.first_name[0] }}
                  </errors>
                </b-form-group>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark" for="middlename"
                  >Middle Name</label>
                <b-form-group id="middlename" label-for="middlename">
                  <b-form-input
                    class="mb-3 mt-2 field-container fields"
                    type="text"
                    v-model="form.middlename"
                    placeholder="Enter middle name"
                  >
                  </b-form-input>
                  <errors v-if="errors?.middlename">
                    {{ errors?.middlename[0] }}
                  </errors>
                </b-form-group>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark" for="last_name"
                  >Last Name <star></star
                ></label>
                <b-form-group id="last_name" label-for="last_name">
                  <b-form-input
                    class="mb-3 mt-2 field-container fields"
                    type="text"
                    v-model="form.last_name"
                    placeholder="Enter Last Name"
                    required
                  >
                  </b-form-input>
                  <errors v-if="errors?.last_name">
                    {{ errors?.last_name[0] }}
                  </errors>
                </b-form-group>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark" for="gender"
                  >Gender <star></star
                ></label>
                <b-form-group id="first_name" label-for="gender">
                  <multiselect
                    class="mt-2 field-container"
                    v-model="form.gender"
                    :options="gender_options"
                  ></multiselect>
                  <errors v-if="errors.gender">
                    {{ errors.gender[0] }}
                  </errors>
                </b-form-group>
              </div>

              <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark" for="marital_status"
                  >Marital Status <star></star
                ></label>
                <b-form-group id="marital_status" label-for="gender">
                  
                  <multiselect class="field-container mb-3 mt-2 " 
                  v-model="form.marital_status" 
                  selectLabel=""
                  deselectLabel=""       
                  :options="marital_options"></multiselect>
                  <errors v-if="errors.marital_status">
                    {{ errors.marital_status[0] }}
                  </errors>
                </b-form-group>
              </div>

              <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark" for="customer_type"
                  >Customer Type <star></star
                ></label>
                <b-form-group id="customer_type" label-for="gender">
                  <multiselect
                    class="mt-2 field-container"
                    v-model="form.customer_type"
                    :options="customer_types"
                  ></multiselect>
                  <errors v-if="errors.customer_type">
                    {{ errors.customer_type[0] }}
                  </errors>
                </b-form-group>
              </div>


              <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark" for="branch"
                  >Branch<star></star
                ></label>
                <b-form-group id="branch" label-for="branch">
                  <multiselect 
                    label="name" 
                    class="mt-2 field-container"
                    v-model="form.branch"
                    :options="branch_options"
                  ></multiselect>
                  <errors v-if="errors.branch">
                    {{ errors.branch[0] }}
                  </errors>
                </b-form-group>
              </div>

              <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark" for="customer_type"
                  >Status <star></star
                ></label>
                <b-form-group id="customer_type" label-for="gender">
                  
                  <multiselect class="field-container mb-3 mt-2 " 
                  v-model="form.status" 
                  selectLabel=""
                  deselectLabel=""       
                  :options="status_options"></multiselect>
                  <errors v-if="errors.status">
                    {{ errors.status[0] }}
                  </errors>
                </b-form-group>
              </div>
              <div class="col-12 col-md-6">
                <label class="akkurate-dark" for="dob"
                  >Date of Birth <star></star
                ></label>
                <b-form-group id="dob" label-for="dob">
                  <b-form-input
                    class="mb-3 mt-2 field-container fields"
                    type="date"
                    v-model="form.date_of_birth"
                  ></b-form-input>
                  <errors v-if="errors?.date_of_birth">
                    {{ errors?.date_of_birth[0] }}
                  </errors>
                </b-form-group>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark" for="mobile"
                  >Telephone <star></star
                ></label>
                <b-form-group id="mobile" label-for="mobile">
                  <b-form-input
                    class="mb-3 mt-2 field-container fields"
                    type="text"
                    v-model="form.mobile"
                    placeholder="Enter Mobile Number"
                  >
                  </b-form-input>
                  <errors v-if="errors?.mobile">
                    {{ errors?.mobile[0] }}
                  </errors>
                </b-form-group>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark" for="account"
                  >Product <star></star
                ></label>
                <b-form-group id="account" label-for="account">
                  <multiselect
                    class="mt-2 field-container"
                    v-model="form.account"
                    :options="account_types"
                    placeholder="Select Product"
                    label="name"
                    track-by="name"
                    :preselect-first="true"
                  ></multiselect>
                  <errors v-if="errors?.accounts">
                    {{ errors?.accounts[0] }}
                  </errors>
                </b-form-group>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark small" for="address"
                  >Address <star></star
                ></label>
                <b-form-group id="address" label-for="address">
                  <b-form-input
                    class="mb-3 mt-2 field-container fields"
                    type="text"
                    v-model="form.address"
                    placeholder="Enter Address"
                  >
                  </b-form-input>
                  <errors v-if="errors?.address">
                    {{ errors?.address[0] }}
                  </errors>
                </b-form-group>
              </div>
               <!-- <div class="col-12 col-md-6 mb-3">

                  <label class="akkurate-dark" for="status">Current Ghana Card (Front)</label>
                  <b-form-group
                      id="status"
                      label-for="status">
                      <img :src="form.gh_card_front_display" style="height:200px" />
                  </b-form-group>
              </div> -->
              <!-- <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark" for="status">Current Ghana Card (Back)</label>
                <b-form-group
                      id="status"
                      label-for="status">
                  <img :src="form.gh_card_back_display" style="height:200px" />
                </b-form-group>
              </div> -->
              
              <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark small" for="landmark"
                  >Nearest Landmark</label>
                <b-form-group id="landmark" label-for="landmark">
                  <b-form-input
                    class="mb-3 mt-2 field-container fields"
                    type="text"
                    v-model="form.landmark"
                    placeholder="Enter Nearest Landmark"
                  >
                  </b-form-input>
                  <errors v-if="errors?.landmark">
                    {{ errors?.landmark[0] }}
                  </errors>
                </b-form-group>
              </div>


              <div class="col-12 col-md-6 mb-3">
                <label class="akkurate-dark small" for="ghana_card_number"
                  >Ghana Card No <star></star
                ></label>
                <b-form-group
                  id="ghana_card_number"
                  label-for="ghana_card_number"
                >
                  <b-form-input
                    class="mb-3 mt-2 field-container fields"
                    type="text"
                    v-model="form.ghana_card_number"
                    placeholder="Enter Ghana Card No"
                  >
                  </b-form-input>
                  <errors v-if="errors?.ghana_card_number">
                    {{ errors?.ghana_card_number[0] }}
                  </errors>
                </b-form-group>
              </div>

            <div class="col-12 col-md-6 mb-3">
              <label v-if="add_or_edit == 'add'"  class="akkurate-dark" for="gh_card_front">Ghana Card Front (Max 2mb)</label>
              <label v-else class="akkurate-dark" for="gh_card_front">Update Ghana Card (Front)</label>  
              <div class="">
                    <input accept="image/*" type="file" @change="handleFrontCard" />
                    <!-- <span v-if="add_or_edit == 'add'" class="akkurate-small">Ghana Card (Front)</span>
                    <span v-else class="akkurate-small">Update Ghana Card (Front)</span> -->
                </div>
              <div v-if="selectedFrontFile" class="text-center col-12 col-md-6 mb-3">
                <label class="akkurate-dark small">Preview : {{ frontCardName }}</label>
                  <img class="imageCardPreviewWrapper" :src="previewFrontUrl" alt="File Preview" />
                  <b-button type="button" @click="uploadFrontFile" :disabled="front_upload_processing" class="akkurate-green-btn">
                      <b-spinner v-if="front_spin_visible" small></b-spinner>
                      <i v-if="front_success_visible" class="fas fa-check loading"></i>
                      <i v-if="front_text_visible" class="fas fa-upload loading"></i> {{ front_upload }}
                  </b-button>
              </div>
              <errors v-if="errors?.gh_card_front_path">
                    {{ errors?.gh_card_front_path[0] }}
                  </errors>
            </div>


            <div class="col-12 col-md-6 mt-4">
              <label v-if="add_or_edit == 'add'"  class="akkurate-dark" for="gh_card_back">Ghana Card Back (Max 2mb)</label>
              <label v-else class="akkurate-dark" for="gh_card_back">Update Ghana Card (Back)</label>  

              <div class="">
                    <input accept="image/*" type="file" @change="handleBackCard" />
                    <!-- <span v-if="add_or_edit == 'add'" class="akkurate-small"></span>
                    <span  v-else class="akkurate-small"></span> -->
                </div>
                <div v-if="selectedBackFile" class="text-center">
                  <label class="akkurate-dark small">Preview : {{ backCardName }}</label>
                    <img class="imageCardPreviewWrapper" :src="previewBackUrl" alt="File Preview" />
                    <b-button type="button" @click="uploadBackFile" :disabled="back_upload_processing" class="akkurate-green-btn">
                        <b-spinner v-if="back_spin_visible" small></b-spinner>
                        <i v-if="back_success_visible" class="fas fa-check loading"></i>
                        <i v-if="back_text_visible" class="fas fa-upload loading"></i> {{ back_upload }}
                    </b-button>
                </div>
              <errors v-if="errors?.gh_card_back_path">
                    {{ errors?.gh_card_back_path[0] }}
                  </errors>
            </div>
            
              <div class="col-12 col-md-6 mb-3">
                <div class="akkurate-dark small">Choose File (Max 2mb)</div>
                <div class="border mt-2">
                  <label class="m-0 label akkurate-green-btn">
                    <input
                      class="d-none"
                      type="file"
                      @change="handleFileChange($event)"
                    />
                    <span class="akkurate-small">Passport picture (Max 2mb)</span>
                  </label>
                  <span class="ms-2">{{ form.passport_pic_name }}</span>
                </div>
                <div>
                  <!-- <p class="small mt-2">Preview</p> -->
                  <div v-if="previewData">
                    <img
                      class="imageCardPreviewWrapper"
                      :src="previewData"
                      alt="File Preview"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-4">
                <div class="d-grid gap-2">
                  <b-button
                    type="submit"
                    :disabled="isLoading"
                    class="fw-semibold akkurate-auth-size akkurate-green-btn"
                  >
                    Save
                  </b-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"
      ><i class="fas fa-angle-up"></i
    ></a>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import Topbar from "@/views/main/components/Topbar.vue";
import Sidebar from "@/views/main/components/Sidebar.vue";
import MobileMenu from "../../../components/MobileMenu.vue";
import ApiService from "@/core/services/api.service";

export default {
  components: {
    MobileMenu,
    Topbar,
    Sidebar,
    Multiselect,
  },
  data() {
    return {
      isLoading: false,
      status_options: ['Pending', 'Active', 'Inactive', 'Dormant', 'Closed'],
      errors: {},
      error_message: "",
      add_or_edit: 'add',
      selectedFrontFile: null,
      selectedPassportPicFile: null,
      previewFrontUrl: null,
      frontCardName: "",
      selectedBackFile: null,
      selectedSignatureFile: null,
      previewBackUrl: null,
      backCardName: "",
      signatureCardName: "",
      selectedSignFile: null,
      previewSignUrl: null,
      signatureName: "",
      front_upload: "Click To Upload",
      back_upload: "Click To Upload",
      signature_upload: "Click To Upload",
      front_upload_processing: false,
      front_spin_visible: false,
      front_success_visible: false,
      front_text_visible: false,
      back_upload_processing: false,
      signature_upload_processing: false,
      back_spin_visible: false,
      signature_spin_visible: false,
      back_success_visible: false,
      back_text_visible: false,
      signature_success_visible: false,
      signature_text_visible: false,
      passport_pic_card: null,
      passportpic_upload: "Click To Upload",
      passportpic_spin_visible: false,
      passportpic_text_visible: false,
      passportpic_upload_processing: false,
      previewPassportUrl: null,
      passportpic_success_visible: false,
      previewPassportPicUrl: null,
      previewSignatureUrl: null,
      signature_card: null,
      back_ghana_card: null,
      form: {
        title: "",
        first_name: "",
        middlename: "",
        last_name: "",
        mobile: "",
        accounts: [],
        account: "",
        address: "",
        landmark: "",
        ghana_card_number: "",
        passport_pic_name: "",
        passport_pic_path: "",
        gender: "",
        customer_type: "",
        status: "Active",
        // errors: [],

        date_of_birth: "",
        gh_card_back_path: "",
        gh_card_front_path: "",
        gh_card_front_path: "",
        gh_card_front_name: "",
        // gh_card_front_display: "",
        // gh_card_back_display: "",
        // front_card_path: "",
        // front_card_name: "",
        // back_card_path: "",
        // back_card_name: "",
        // front_upload_processing: false,
        // front_spin_visible: false,
        // front_success_visible: false,
        // front_text_visible: false,
        // back_upload_processing: false,
        // back_spin_visible: false,
        // back_success_visible: false,
        // back_text_visible: false,
        // back_ghana_card: null,


        frontCardName: "",


        backCardName: "",
      },
      previewData: "",
      title_options: ["Mr", "Mrs", "Miss", "Master", "Dr", "Prof"],
      account_types: [],
      gender_options: ["Male", "Female"],
      customer_types: ['INDIVIDUAL', 'GROUP', 'CORPORATE', 'IN_TRUST'],
      marital_options: ['Single', 'Married', 'Divorced', 'Widow'],
      branch_options: [],
    };
  },
  mounted() {
    this.loadPageOptions();
  },
  methods: {
    loadPageOptions() {
      // this.$Progress.start();
      // this.isLoading = true;
      ApiService.get("/customers/dropdown")
        .then((response) => {
          this.account_types = response.data.account_types;
          // this.region_options = response.data.regions;
          this.branch_options = response.data.branches;
          this.isLoading = false;
          // $emit('coa-head-saved', response.data)
          this.$Progress.finish();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$Progress.fail();
        });
    },
    handleFileChange(event) {
      var file = event.target.files[0];
      if (file) {
        // this.$set(this.picImports, index, {
        //   picture_name: file ? file.name : "No file chosen",
        //   previewData: file ? URL.createObjectURL(file) : null,
        // });
        this.form.passport_pic_name = file ? file.name : "No file chosen";
        this.passport_pic_card = file;
        this.previewData = file ? URL.createObjectURL(file) : null;
        this.uploadPassportPic();
      }
    },
    handleFrontCard(event) {
      this.front_ghana_card = event.target.files[0];
      if (this.front_ghana_card) {
        this.selectedFrontFile = this.front_ghana_card;
        this.frontCardName =  this?.front_ghana_card.name;
        this.previewFrontFile(this.front_ghana_card);
        this.uploadFrontFile();
      }
    },
    previewFrontFile(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.previewFrontUrl = reader.result;
      };
    },
    handleBackCard(event) {
      this.back_ghana_card = event.target.files[0];
      if (this.back_ghana_card) {
        this.selectedBackFile = this.back_ghana_card;
        // this.backCardName = this?.back_ghana_card.name;
        this.previewBackFile(this.back_ghana_card);
        this.uploadBackFile();
      }
    },
    previewBackFile(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.previewBackUrl = reader.result;
      };
    },
    handleSubmit() {
      this.isLoading = true;
      this.$Progress.start();
      var account = this.form.account;
      this.form.accounts = [];
      if(this.form?.account)
      {
        var ac = {account_type: account}
        // this.form.accounts[0]['account_type'] = account
        this.form.accounts.push(ac)
      }
      ApiService.post("/customers/store", this.form)
        .then((response) => {
          this.errors = {}
          this.error_message = ""
          this.$Progress.finish();
          // toast.fire({
          //   icon: "success",
          //   title: response.data.message,
          // });
          this.isLoading = false;
          // this.form = this.initiateState().form;

          swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            allowOutsideClick: false
          }).then(result => {
            if (result.value) {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          });

        })
        .catch((error) => {
          this.isLoading = false;
          this.$Progress.fail();
          // this.form.errors = error.response.data.message;
          this.errors = error.response?.data?.errors;
          this.error_message = error.response?.data?.message;
        });
    },

    async uploadFrontFile() {
      const data = new FormData();
      data.append('file', this.front_ghana_card);
      this.front_upload = "Processing..."
      this.front_spin_visible = true;
      this.front_text_visible = false;
      this.front_upload_processing = true
      await ApiService.post('/images/store', data)
        .then((response) => {
          this.front_upload = response.data.success;
          this.front_spin_visible = false;
          this.front_text_visible = false;
          // this.form.front_ghana_card = null;
          this.front_upload_processing = true
          // this.previewFrontUrl = null;
          // this.$emit('responseFront', {
          //   front_ghana_path: response.data.path,
          //   front_ghana_name: response.data.name,
          // });
          this.form.gh_card_front_path = response.data.path;
          this.gh_card_front_name = response.data.name;

        }).catch((error) => {
          this.front_upload_processing = false
        })
    },
    async uploadBackFile() {
      const data = new FormData();
      data.append('file', this.back_ghana_card);
      this.back_upload = "Processing..."
      this.back_spin_visible = true;
      this.back_text_visible = false;
      this.back_upload_processing = true
      await ApiService.post('/images/store', data)
        .then((response) => {
          this.back_upload = response.data.success;
          this.back_spin_visible = false;
          this.back_text_visible = false;
          // this.form.front_ghana_card = null;
          this.back_upload_processing = true
          // this.previewBackUrl = null;
          // this.$emit('responseBack', {
          //   back_ghana_path: response.data.path,
          //   back_ghana_name: response.data.name,
          // });
          this.form.gh_card_back_path = response.data.path;
          this.form.gh_card_back_name = response.data.name;
          

        }).catch((error) => {
          this.back_upload_processing = false
        })
    },
    async uploadPassportPic() {
      const data = new FormData();
      data.append('file', this.passport_pic_card);
      this.passportpic_upload = "Processing..."
      this.passportpic_spin_visible = true;
      this.passportpic_text_visible = false;
      this.passportpic_upload_processing = true
      await ApiService.post('/images/store', data)
        .then((response) => {
          this.passportpic_upload = response.data.success;
          this.passportpic_spin_visible = false;
          this.passportpic_text_visible = false;
          // this.form.front_ghana_card = null;
          this.passportpic_upload_processing = true

          // this.previewPassportUrl = null;
          // this.$emit('responsePassportPic', {
          //   passport_pic_path: response.data.path,
          //   passport_pic_name: response.data.name,
          // });
          this.form.passport_pic_path = response.data.path
          this.form.passport_pic_name = response.data.name;

        }).catch((error) => {
          this.passportpic_upload_processing = false
          this.passportpic_upload = error.response.data.message;
          this.passportpic_spin_visible = false;
        })
    },
  },
};
</script>