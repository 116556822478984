<template>
    <form @submit.prevent="storeGaurantor">
        <div class="row" v-for="(guarantor, key) in guarantors" :key="key">
            <div class="col-md-2">
                <b-form-group label-for="loan_id" class="mb-3">
                    <template #label>
                        <label class="akkurate-dark small" for="account_number">Loan ID</label>
                    </template>
                    <b-form-input id="loan_id" name="loan_id" class="mt-2 field-container fields" type="text"
                        v-model="getLoanNumber" disabled></b-form-input>
                </b-form-group>
            </div>
            <div class="col-md-2">
                <b-form-group label-for="account_number" class="mb-3">
                    <template #label>
                        <label class="akkurate-dark small" for="account_number">Account #</label>
                    </template>
                    <!-- <b-form-input @input="onInput($event, guarantor)"  id="account_number" name="account_number" class="mt-2 field-container fields"
                        type="text" v-model="guarantor.account_number" :disabled="key==0" ></b-form-input> -->
                    <b-form-input @input="onInput(guarantor)"  id="account_number" name="account_number" class="mt-2 field-container fields"
                        type="text" v-model="guarantor.account_number" :disabled="key==0" ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-md-2">
                <b-form-group label-for="fullname" class="mb-3">
                    <template #label>
                        <label class="akkurate-dark small" for="fullname">Fullname</label>
                    </template>
                    <b-form-input id="fullname" name="fullname" class="mt-2 field-container fields" type="text"
                        :value="getFullName(guarantor)" disabled></b-form-input>
                </b-form-group>
            </div>

            <div class="col-md-1">
                <b-form-group label-for="balance" class="mb-3">
                    <template #label>
                        <label class="akkurate-dark small" for="balance">Balance</label>
                    </template>
                    <b-form-input id="balance" name="balance" class="mt-2 field-container fields" type="text"
                        :value="guarantor.account_balance" disabled></b-form-input>
                </b-form-group>
            </div>

            <div class="col-md-2">
                <b-form-group label-for="product" class="mb-3">
                    <template #label>
                        <label class="akkurate-dark small" for="product">Product</label>
                    </template>
                    <b-form-input id="product" name="product" class="mt-2 field-container fields" type="text"
                     :value="guarantor?.account_type?.name"   disabled></b-form-input>
                </b-form-group>
            </div>

            <div class="col-md-2">
                <b-form-group label-for="gua_amt" class="mb-3">
                    <template #label>
                        <label class="akkurate-dark small" for="gua_amt">Guaranteed Amount</label>
                    </template>
                    <b-form-input  id="gua_amt" name="gua_amt" class="mt-2 field-container fields" type="number" step="any"
                        v-model="guarantor.amount"></b-form-input>
                </b-form-group>
            </div>

            <div class="col-md-1" v-if="key!==0">

                <button type="button" class="btn akkurate-red-btn text-white " @click="removeGuarantor(guarantor)">
                    <font-awesome-icon class="text-white" :icon="['fas', 'trash']" /></button>
            </div>

            <!-- <div class="col-md-4">
            <b-form-group
                label-for="entry_date"
                class="mb-3">
                <template #label>
                    <label class="akkurate-dark small" for="entry_date">Entry Date</label>
                </template>
                <b-form-input
                    id="entry_date"
                    name="entry_date"
                    class="mt-2 field-container fields"
                    type="text"
                    
                ></b-form-input>
            </b-form-group>
            </div> -->

        </div>

        <div class="text-end py-3">
                <!-- <button type="submit" class="btn akkurate-green-btn text-white btn-size my-2 me-2">Post
                    Application</button> -->
                <button type="button" class="btn akkurate-green-btn text-white btn-size my-2 me-2" @click="addMoreGuarantors">
                    Add More Guarantors</button>
            </div>
    </form>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
export default {
    props: ['account_info', 'loan_number', 'guarantors'],
    components: {
        Multiselect
    },
    data() {
        return {
            ...this.initialState(),
            full_name: "",
            debounceTimer: null,
        }
    },
    computed: {
        getLoanNumber() {
            this.form.loan_id = this.loan_number;
            // console.log(this.form.loan_id);
            return this.form.loan_id
        }
    },
    methods: {
        storeGaurantor() {
            ApiService.post('/loans/loanGuarantors', this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();

                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    // this.form.reset();
                    this.form = this.initialState().form;

                }).catch((response) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    if (error.response.data.errors) {
                        // this.form.errors = error.response.data.errors;
                        console.log(error)
                    }
                })
        },
        getFullName(guarantor) {
            const first_name = guarantor.customer?.first_name != undefined ? guarantor.customer?.first_name : "";
            const middle_name = guarantor.customer?.middle_name != undefined ? guarantor.customer?.middle_name : "";
            const last_name = guarantor.customer?.last_name != undefined ? guarantor.customer?.last_name : "";
            return first_name + ' ' + middle_name + ' ' + last_name;
        },
        addMoreGuarantors()
        {
            this.$emit('addMoreGuarantors');
        },
        removeGuarantor(guarantor)
        {
            this.$emit('removeGuarantor', guarantor);
        },
        // onInput(input, guarantor) {
        //     // console.log(input)
        //     // console.log(guarantor)
        //     if(input)
        //     {
        //         clearTimeout(this.debounceTimer);
        //         this.debounceTimer = setTimeout(this.$emit("getGuarantorAccount",input, guarantor), 1000);
        //     }
        //     else
        //     {
        //         // this.products = [];
        //     }
        // },
        onInput(guarantor) {
            // console.log(input)
            // console.log(guarantor)
            if(guarantor)
            {
                clearTimeout(this.debounceTimer);
                // this.debounceTimer = setTimeout(this.getGuarantorAccount(guarantor), 1000);
                this.debounceTimer = setTimeout(() => this.getGuarantorAccount(guarantor), 1000);
    
            }
            else
            {
                // this.products = [];
            }
        },
        getGuarantorAccount(guarantor)
        {
            this.$emit("getGuarantorAccount",guarantor)
        },
        initialState() {
            return {
                form: {
                    loan_id: "",
                    account_number: "",
                    amount: "",
                    entry_date: Date.now()
                }
            }
        }
    }

}
</script>