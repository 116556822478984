var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wrapper"}},[_c('SidebarView',{attrs:{"loan":true,"loan-scheme":true,"loan-visible":true}}),_c('div',{staticClass:"d-flex flex-column",attrs:{"id":"content-wrapper"}},[_c('div',{attrs:{"id":"content"}},[_c('TopbarView'),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"my-3"},[_c('b-button',{staticClass:"btn btn-sm akkurate-green-btn",attrs:{"href":"/loans/scheme/create","variant":"primary"}},[_c('span',{staticClass:"akkurate-small"},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Add Scheme")])])],1),_c('div',{staticClass:"card card-shape home-box"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{ref:"memberTable",attrs:{"styleClass":"vgt-table bordered table-dropdown","mode":"remote","columns":_vm.columns,"rows":_vm.rows,"isLoading":_vm.isLoading,"totalRows":_vm.totalRecords,"sort-options":{
                                    enabled: false
                                },"pagination-options":{
                                    enabled: true,
                                        perPage: _vm.serverParams.perPage,
                                }},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-search":_vm.onSearch,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',{staticClass:"d-flex"},[_c('scheme-view',{attrs:{"data":props.row}}),_c('a',{staticClass:"btn btn-sm akkurate-primary text-white broder-0",attrs:{"title":"Edit","href":`/loans/scheme/${props.row.id}/edit`}},[_c('span',{staticClass:"akkurate-small"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'edit']}})],1)]),_c('a',{staticClass:"btn btn-sm akkurate-danger-btn text-white broder-0",attrs:{"title":"Edit"},on:{"click":function($event){return _vm.confirmDeleteUser(props.row)}}},[_c('span',{staticClass:"akkurate-small"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'trash']}})],1)])],1):_vm._e()]}}])})],1)])])])])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3 d-flex flex-row align-items-center"},[_c('h6',{staticClass:"m-0 fw-bold text-green"},[_vm._v("All Schemes")])])
}]

export { render, staticRenderFns }