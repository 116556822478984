<template>
    <div id="wrapper">
            <SidebarView :memacc="true" :memaccVisible="true" :addMemAcc="true" />
            <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
              <TopbarView />
              <div class="container">
                <div class="row">
                  <div class="col-12">
                      <!-- begin loader -->
                        <!-- <beat-loader-component
                        v-if="isLoading"
                        :isLoading="isLoading"
                        ></beat-loader-component> -->
                      <!-- end loader -->
                  </div>
                  <div class="col-12">
                    <div class="card card-shape home-box">
                      <div class="card-header py-3 d-flex flex-row align-items-center">
                        <h6 class="m-0 fw-bold text-green">Create Account</h6>
                      </div>
                      <div class="card-body">
                        <b-form @submit.stop.prevent="saveData">
                          <account-form :member_options="member_options" :account_options="account_options" :form='form' :add_or_edit="add_or_edit"></account-form>
                          <hr />
                          <div class="text-center py-3">
                            <button type="submit" :disabled="isSaving" class="btn akkurate-green-btn text-white btn-size my-2 me-2">Save</button>
                            <button type="reset" class="btn akkurate-gray-btn text-white btn-size my-2 me-2">Reset</button>
                          </div>
                        </b-form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <Footer></Footer>
          </div>
          <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
        </div>
    
    </template>
    
<script>

import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";

import AccountForm from "./../forms/AccountForm.vue";
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue'
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";



export default {
  name: 'AccountCreate',
  components: {
    BeatLoaderComponent,
    AccountForm,
    SidebarView,
    TopbarView,
    Footer,
    Multiselect,
  },
  data() {
    return {
      ...this.initialState(),
      account_options:[],
      member_options:[],
    };
  },

  mounted() {
    this.getAccountTypes();
  },

  methods: {
    async getAccountTypes() {
      this.$Progress.start();
      await ApiService.get("/accounts/create/dropdown")
        .then((response) => {
          this.isLoadingSaving = false;
          this.$Progress.finish();
          this.account_options = response.data.account_types;
          this.member_options = response.data.members;

          // console.log(response.data.account_types);
          // this.form = this.initialState().form;
        })
        .catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          if (error.response.data.errors) {
            this.form.errors = error.response.data.errors;
          }
        });
    },
    async saveData() {
      this.isSaving = true;
      this.$Progress.start();
      await ApiService.post("/accounts/store",this.form)
        .then((response) => {
          this.isLoadingSaving = false;
          this.$Progress.finish();

          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.isSaving = false;
          // this.form.reset();
          this.form = this.initialState().form;
        })
        .catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          if (error.response.data.errors) {
            this.form.errors = error.response.data.errors;
          }
        });


    },

    initialState() {
      return {
        form: {
          account_type: "",
          account_option: [],
          balance: "",
          status: "Active",
          account_balance: 0,
          comment: "",
          member: "",
          member_option: [],
          status_option: ["Active", "Inactive","Dormant", "Closed"],
          errors: [],
        },
        isLoading: true,
        isSaving: false,
        add_or_edit: 'add',
      };
    },
  }


};
</script>
    
<!-- New step!
Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
    
    