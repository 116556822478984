<template>
    <div id="wrapper">
            <SidebarView :reports="true"/>
            <div class="d-flex flex-column" id="content-wrapper">
                <div id="content">
                    <TopbarView />
                    <b-container>
                        <div class="row ">

                            <div class="d-flex justify-content-end">

                                <report-filter
                                  :form="form"
                                  @filterAction="getCoaTrialReport"
                                  :filter="filter"
                                  :current_financial_year="financial_year"
                                ></report-filter>
                                <!-- <b-link @click="exportToExcel" class="fw-semibold float-end me-3 akkurate-green"> <font-awesome-icon :icon="['fas', 'file-excel']" /></b-link>  -->
                                    <div class="" style="margin-right: 4px">

                                        <b-button @click="exportToExcel" class="akkurate-green-btn download-btn float-right"><span class="small"><font-awesome-icon :icon="['fas', 'file-excel']" /> Excel</span></b-button>     
                                    </div>
                                    <div class="">

                                        <b-button @click="exportToPDF" class="akkurate-green-btn download-btn float-right"><span class="small"><font-awesome-icon :icon="['fas', 'save']" /> PDF</span></b-button>     
                                    </div>
                            </div>
        
                        </div>
                        <div>
        <!-- <a title="Trial Balance Year To Date" class="btn btn-sm akkurate-ash text-white" v-b-modal="String(data.id)"> -->
        <!-- <a title="Trial Balance Year To Date" class="btn btn-sm akkurate-ash text-white">
            <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'book']" /></span>
        </a> -->
            
        <!-- <b-modal :id="String(data.id)" size="xl"> -->
        <!-- <b-modal  size="xl"> -->

            <div class="container text-start" ref="pdfContent">
                <div class="row">
                    <div class="col-md-3 col-3 d-flex align-items-center flex-column bd-highlight">
                        <img :src="institution.logo ?? '@/assets/img/user-avater.jpg'" style="height:180px" class="img-fluid" alt="statement-of-account"/>
                        <p class="mt-2 akkurate-green fw-bold mt-auto p-2 bd-highlight">TRIAL BALANCE</p>
                    </div>
                    <div class="d-flex align-items-end flex-column bd-highlight col-md-9 col-9 text-end">
                        <p class="fw-bold text-uppercase mb-0">{{ institution.name }}</p>
                        <p class="fw-bold">{{ institution.motto }}</p>
                        <p class="mb-0 akkurate-small">{{ institution.postal}}</p>
                        <p class="mb-0 akkurate-small">{{ institution.telephone }}</p>
                        <p class="akkurate-small">{{ institution.email }}</p>
                        <!-- <p class="fw-bold text-end mt-auto p-2 bd-highlight ">{{ form.start_date}} To {{ form.end_date }}</p> -->
                        <span class="mt-2">From: <strong>{{ form.start_date }}</strong>  To: <strong>{{ form.end_date }}</strong> </span>
                    </div> 
                </div>
                <hr class="mt-0 akkurate-danger"/>
                <div class="row">
                    <div class="col-md-6">
                    <h6 class="akkurate-dark">Account Details</h6>
                    <hr class="w-100 mt-0"/>
                    <div class="row akkurate-small">
                        <div class="col-md-6 fw-bold akkurate-grey">
                            Opening Balances
                        </div>
                        <div class="col-md-6">
                            {{ Number(opening_balances).toLocaleString()  }}
                        </div>
                        <div class="col-md-6 fw-bold akkurate-grey">
                            Total Debit
                        </div>
                        <div class="col-md-6">
                            {{ Number(total_debit).toLocaleString() }}
                        </div>
                        <div class="col-md-6 fw-bold akkurate-grey">
                            Total Credit
                        </div>
                        <div class="col-md-6">
                            {{ Number(total_credit).toLocaleString() }}
                        </div>
                        <div class="col-md-6 fw-bold akkurate-grey">
                            Closing Balance
                        </div>
                        <div class="col-md-6">
                            {{ Number(closing_balance).toLocaleString() }}
                        </div>
                        <div class="col-md-6">
                        </div>
                    </div>
                </div>
                </div>

                <div class="row mt-5">
                    <div class="col-md-6">
                        <h6>ACCOUNTS</h6>
                    </div>
                    <div class="col-md-6 text-end">
                    </div>
                </div>

                <div class="" v-if="isLoading">
                    <beat-loader-component :isLoading="isLoading"></beat-loader-component>
                </div>
                
                <div class="row" v-else><div>
                    <!-- <b-table striped hover small :items="coa_subheads" :fields="fields"></b-table> -->
                    <table class="table table-bordered table-striped table-hover table-sm">
                        <thead>
                            <tr>
                                <th scope="col">Glcode: </th>
                                <th scope="col">COA Name</th>
                                <th scope="col">COA Head</th>
                                <th scope="col">DR (GHS)</th>
                                <th scope="col">CR (GHS)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in coa_subheads" :key="item.id">
                                <td>{{ item.code }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item?.coahead?.display_name }}</td>
                                <!-- <td>{{ Number(item.dr_amount).toLocaleString()  }}</td>
                                <td> {{ Number(item.cr_amount).toLocaleString()  }}</td> -->
                                <td>{{ displayNumber(item.adjusted_dr_amount) }}</td>
                                <td> {{ displayNumber(item.adjusted_cr_amount) }}</td>
                            </tr>
                        </tbody>
                        <!-- footer -->

                        <tfoot>
                            <tr>
                                <th  scope="col">TOTAL: </th>
                                <th scope="col"  >DIFFERENCE: GHS {{ Number(difference ? difference.toFixed(2) : 0).toLocaleString()   }}</th>
                                <th scope="col"  ></th>
                                <th scope="col">{{  Number(total_debit ? total_debit.toFixed(2) : 0).toLocaleString()   }}</th>
                                <th scope="col">{{  Number(total_credit ? total_credit.toFixed(2) : 0).toLocaleString()   }}</th>
                            </tr>
                        </tfoot>

                    </table>

                    <!-- <b-table striped hover small :items="items" :fields="footer_fields"></b-table> -->

                    <!-- bootstrap table -->
                    <table class="table table-bordered table-striped table-hover table-sm">
                        <!-- <thead>
                            <tr>
                                <th colspan="4" scope="col">Totals: </th>
                                <th scope="col" colspan="4">Differences GHS {{ difference ? difference.toFixed(2) : 0  }}</th>
                                <th scope="col">DR: GHS {{ total_debit ? total_debit.toFixed(2) : 0  }}</th>
                                <th scope="col">CR: GHS {{ total_credit ? total_credit.toFixed(2) : 0  }}</th>
                            </tr>
                        </thead> -->
                        <!-- <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td>{{ item.glcode }}</td>
                                <td>{{ item.coa_name }}</td>
                                <td>{{ item.dr }}</td>
                                <td>{{ item.cr }}</td>
                            </tr>
                        </tbody> -->
                    </table> 
                </div>

                </div>

               
                
               
            </div>
            
        
    </div>
                    </b-container> 
                   
                </div>
                <footer></footer>
            </div>
            <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
        </div>
    </template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import ApiService from "@/core/services/api.service";
import html2pdf from "html2pdf.js";
import config from './../../../../../../config.js'
import ReportFilter from "@/views/main/pages/reports/form/ReportFilter.vue";
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        ReportFilter,
        BeatLoaderComponent,
    },

    // props: ['data'],
    async created() {
        await this.getInsistution();
        this.getCoaTrialReport();
    },
    data() {
        return {
            isLoading: false,
            financial_year: null,
            form: {
            start_date: this.getBeginningOfYear(),
            end_date: new Date().toISOString().substr(0, 10),
            branches: [],
            products: [],
            branch_options: [],
            product_options: [],
            users: [],
            users_options: [],
            status: [],
            coa: [],
            coa_options: [],
            terms: "Periodic",
            terms_options: ["YTD", "Periodic"],
            categories: [],
        },
        filter: {
            branches: true,
            products: false,
            users: false,
            status: false,
            condition: false,
            coa: false,
            terms: true,
        },
            frontImgUrl: "",
            backImgUrl: "",
            imageLoaded: false,
            institution:{
                name:"",
                address:"",
                postal:"",
                currency:"",
                motto:"",
                currency_code:"",
                logo: ""
            },
            opening_balances: 0,
            coa_subheads: [],
            total_debit: 0,
            difference: 0,
            total_credit: 0,
            closing_balance: 0,
            fields: [{ key: 'code', label: 'Glcode' }, { key: 'name', label: 'COA Name' }, { key: 'dr_amount', label: 'DR' }, { key: 'cr_amount', label: 'CR' }],
            footer_fields: [{ key: 'coa_name', label: 'Totals' }, { key: 'dr', label: 'DR' }, { key: 'cr', label: 'CR' }],
            items: [],

        }
    },
    computed: {
        getFullName() {
            // const first_name = this.details?.account?.customer?.first_name != undefined ? this.details?.account?.customer?.first_name : "";
            // const middle_name = this.details?.account?.customer?.middle_name != undefined ? this.details?.account?.customer?.middle_name : "";
            // const last_name = this.details?.account?.customer?.last_name != undefined ? this.details?.account?.customer?.last_name : "";
            // const first_name = this.data.customer.first_name != undefined ? this.data.customer.first_name : "";
            // const middle_name = this.data.customer.middle_name != undefined ? this.data.customer.middle_name : "";
            // const last_name = this.data.customer.last_name != undefined ? this.data.customer.last_name : "";
            // return first_name + ' ' + middle_name + ' ' + last_name;

        },
    },
    methods: {
        async getCoaTrialReport() {

            this.isLoading = true;
            //  this.search = "processing";
            //  this.isSearching = true;
            await ApiService.post("/reports/financials/trial", this.form).then((response) => {
                this.coa_subheads = response.data.coa_subheads;
                this.opening_balances = response.data.opening_balances;
                this.total_debit = response.data.total_debit;
                this.total_credit = response.data.total_credit;
                this.difference = response.data.difference;
                this.closing_balance = response.data.closing_balance;
                this.form.branch_options = response.data.branches;
                
            }).catch((error) => {
            })
            .finally(() => {
                this.isLoading = false;
            });
        },
        // handleImageLoad() {
        //     this.imageLoaded = true;
        //     this.frontImgUrl = 'https://ggbccuapi.akkuratebank.com/' + this.details.account?.customer?.gh_card_front_path;
        //     this.backImgUrl = 'https://ggbccuapi.akkuratebank.com/' + this.details.account?.customer?.gh_card_back_path;
        // },
        exportToPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            const pdfOptions = {
                margin: 10,
                filename: 'TrialBalance_'+ this.getFormattedDateTime() + '.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            };
            // html2pdf().from(content).set(pdfOptions).outputPdf((pdf) => {

            // });
            html2pdf().from(content).set(pdfOptions).save();
        },
        generatePDF() {
            // Define the element to convert to a PDF
            const element = this.$refs.pdfContent; // Replace with your element's ID or selector

            // Define the options for html2pdf
            const pdfOptions = {
                margin: 10,
                filename: 'document.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
                debug: true, // Enable debugging
            };
            html2pdf().from(element).set(pdfOptions).outputPdf('blob').then((pdf) => {
                const blob = new Blob([pdf], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const printWindow = window.open(url, '_blank', 'width=800,height=600');
                printWindow.onload = () => {
                    printWindow.print();
                    URL.revokeObjectURL(url);
                };
            });
        },
        async getInsistution(){
           await ApiService.get('/settings/institution').then((response) => {
                this.institution = response.data.data;
                this.institution.logo = config.base_url + "/" + this.institution.logo;
                this.financial_year = response.data.financial_year;
            }).catch((error) => {
            })
        },

        exportToExcel() {
            const workbook = XLSX.utils.book_new();

            // Get the table data
            // const tableData = this.$refs.memberTable.filteredRows[0].children;
            // const tableData = this.$refs.memberTable.filteredRows[0].children.map((index) => {
            //     return {
            //         account_type:index.account_type?.name,
            //         account_balance: index.account_balance,
            //         account_number: index.account_number,
            //         member_name: index.customer.first_name+''+index.customer.last_name,
            //         mobile: index.customer.mobile,
            //         status: index.status,
            //         created_at: index.created_at,
            //     }
            // });
            const tableData = this.coa_subheads.map((index) => {
                return {
                    code: index.code,
                    name: index.name,
                    // dr_amount: index.coasubheadstransactions_sum_dr_amount ? index.coasubheadstransactions_sum_dr_amount : Number(0.00),
                    // cr_amount: index.coasubheadstransactions_sum_cr_amount ? index.coasubheadstransactions_sum_cr_amount : Number(0.00),
                    dr_amount: index.adjusted_dr_amount,
                    cr_amount: index.adjusted_cr_amount,
                }
            });

            // Convert the table data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(tableData);

            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Generate the Excel file buffer
            const excelBuffer = XLSX.write(workbook, {
                type: 'array',
                bookType: 'xlsx'
            });

            // Save the Excel file
            FileSaver.saveAs(
                new Blob([excelBuffer], { type: 'application/octet-stream' }),
                'Trial_Balance_' + this.getFormattedDateTime() +  '.xlsx'
            );
        },
        

    }
   

}

</script>