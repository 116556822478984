<template>
    <div >
        <form class="row mb-4" @submit.prevent="filterTable">
           <div class="col-12 col-md-3">
           <label class="akkurate-dark small" for="branch">Branch</label>
           <multiselect class="mt-2 field-container" 
           v-model="form.branch" 
           :options="form.branch_option"
           track-by="name"
           placeholder="Select one"
           selectLabel=""
deselectLabel=""
           label="name">
           </multiselect>
           </div>
           <div class="col-12 col-md-3">
            <label class="akkurate-dark small" for="acc_types">Account type </label>
            <multiselect class="mt-2 field-container"
                v-model="form.acc_types"
                :options="form.acc_types_option"
                track-by="name"
                placeholder="Select one"
                selectLabel=""
deselectLabel=""
                label="name">
            </multiselect>
           </div>
           <div class="col-12 col-md-3">
             <label class="akkurate-dark small" for="phone">Phone number/Code</label>
                <b-form-group
                id="phone"
                label-for="phone"
                class="akkurate-dark">
                <b-form-input
                id="phone" 
                class="mb-3 mt-2 field-container fields"
                type="text" 
                v-model="form.phone"
                placeholder="Enter Phone No"
                >
                </b-form-input>
            </b-form-group>
           </div>
           <div class="col-12 col-md-3">
             <label class="akkurate-dark small" for="account_number">Account number</label>
                <b-form-group
                id="account_number"
                label-for="account_number"
                class="akkurate-dark">
                <b-form-input
                id="account_number" 
                class="mb-3 mt-2 field-container fields"
                type="text" 
                v-model="form.account_number"
                placeholder="Enter Account number">
                </b-form-input>
            </b-form-group>
           </div>
           <div class="col-12 col-md-3">
            <label class="akkurate-dark small" for="loan-re-date">Start Date</label>
                <b-form-group
                id="loan-re-date"
                label-for="loan-re-date">
                <b-form-input
                class="mt-2 field-container fields"
                type="date"
                v-model="form.start_date"
                ></b-form-input>
                </b-form-group>
           </div>
           <div class="col-12 col-md-3">
            <label class="akkurate-dark small" for="loan-re-date">End Date</label>
                <b-form-group
                id="loan-re-date"
                label-for="loan-re-date">
                <b-form-input
                class="mt-2 field-container fields"
                type="date"
                v-model="form.end_date"
                ></b-form-input>
                </b-form-group>
           </div>
           <div class="col-12 col-md-3">
            <label class="akkurate-dark small" for="type">Status</label>
            <multiselect class="mt-2 field-container" v-model="form.status" :options="form.status_option"></multiselect>
           </div>
           <div class="mb-1 mt-auto col-12 col-md-3">
             <b-button class="akkurate-green-btn akkurate-auth-size w-100" type="submit"><span class="small"><font-awesome-icon :icon="['fas', 'search']" /> Search</span></b-button>
           </div>
        </form>
    </div>

</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
    components: {
        Multiselect
    },
    props: ['form','filterTable'],
    data() {
        return {
            status_options: ['Cash', 'Cheque'],

        }
    },
}

</script>