<template>
    <div class="row">
        <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="title">Title <star></star></label>
            <b-form-group
                id="title"
                label-for="title">
                <multiselect class="mt-2 field-container" v-model="form.title" :options="title_options"></multiselect>
            </b-form-group>
          </div>
           <div class="col-12 col-md-6">
           <label class="akkurate-dark" for="first_name">First Name <star></star></label>
               <b-form-group
               id="first_name"
               label-for="first_name">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter First Name"
                v-model="form.first_name"
               ></b-form-input>
               <errors v-if="form.errors.first_name">
                  {{ form.errors.first_name[0] }}
                </errors>
               </b-form-group>
           </div>
           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="middlename">Middle Name</label>
               <b-form-group
               id="middlename"
               label-for="middlename">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter Middle Name"
                v-model="form.middlename"
               ></b-form-input>
               <errors v-if="form.errors.middlename">
                  {{ form.errors.middlename[0] }}
                </errors>
               </b-form-group>
           </div>
           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="last_name">Surname <star></star></label>
               <b-form-group
               id="last_name"
               label-for="last_name">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter Surname"
                v-model="form.last_name"
               required
               ></b-form-input>
               <errors v-if="form.errors.last_name">
                  {{ form.errors.last_name[0] }}
                </errors>
               </b-form-group>
           </div>
           <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="gender">Gender <star></star></label>
            <b-form-group
                id="first_name"
                label-for="first_name">
                <multiselect class="mt-2 field-container" v-model="form.gender" :options="gender_options"></multiselect>
                <errors v-if="form.errors.gender">
                  {{ form.errors.gender[0] }}
                </errors>
            </b-form-group>
           </div>
          
           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="dob">Date of Birth <star></star></label>
               <b-form-group
               id="dob"
               label-for="dob">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="date"
                v-model="form.date_of_birth"
               required
               ></b-form-input>
               <errors v-if="form.errors.date_of_birth">
                  {{ form.errors.date_of_birth[0] }}
                </errors>
               </b-form-group>
           </div>

           <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="marital_status">Marital Status <star></star></label>
            <b-form-group
                id="marital_status"
                label-for="marital_status">
                <multiselect class="mt-2 field-container" v-model="form.marital_status" :options="marital_options"></multiselect>
                <errors v-if="form.errors.marital_status">
                  {{ form.errors.marital_status[0] }}
                </errors>
            </b-form-group>
           </div>
           
          
           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="email">Email</label>
               <b-form-group
               id="email"
               label-for="email">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="email"
               placeholder="Enter Email Address"
                v-model="form.email"
               ></b-form-input>
               <errors v-if="form.errors.email">
                  {{ form.errors.email[0] }}
                </errors>
               </b-form-group>
           </div>
           
           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="phone">Phone <star></star></label>
               <b-form-group
               id="phone"
               label-for="phone">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="number"
               placeholder="Enter Phone Number Eg. 0244411100"
                v-model="form.mobile"
               required
               ></b-form-input>
               <errors v-if="form.errors.mobile">
                  {{ form.errors.mobile[0] }}
                </errors>
               </b-form-group>
           </div>
            
           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="phone">Old Code</label>
               <b-form-group
               id="phone"
               label-for="phone">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter Old Code"
                v-model="form.external_code"
               ></b-form-input>
               <errors v-if="form.errors.external_code">
                  {{ form.errors.external_code[0] }}
                </errors>
               </b-form-group>
           </div>

           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="unapproved_withdrawal_max_amount">Withdraw max threshold</label>
               <b-form-group
               id="unapproved_withdrawal_max_amount"
               label-for="unapproved_withdrawal_max_amount">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="number"
               placeholder="Enter Withdraw max threshold"
                v-model="form.unapproved_withdrawal_max_amount"
               ></b-form-input>
               <errors v-if="form.errors.unapproved_withdrawal_max_amount">
                  {{ form.errors.unapproved_withdrawal_max_amount[0] }}
                </errors>
               </b-form-group>
           </div>

            <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="ghana-card">Ghana Card Number</label>
               <b-form-group
               id="ghana-card"
               label-for="ghana-card">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter Ghana Card"
                v-model="form.ghana_card_number"
               ></b-form-input>
               <errors v-if="form.errors.ghana_card_number">
                  {{ form.errors.ghana_card_number[0] }}
                </errors>
               </b-form-group>
           </div>

           <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="status">Status <star></star></label>
            <b-form-group
                id="status"
                label-for="status">
                <multiselect class="field-container mb-3 mt-2 " 
                :disabled="add_or_edit == 'add'" 
                v-model="form.status" 
                selectLabel=""
                deselectLabel=""       
                :options="status_options"></multiselect>
                <errors v-if="form.errors.status">
                  {{ form.errors.status[0] }}
                </errors>
            </b-form-group>
           </div>

           <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="customer_type">Customer Type <star></star></label>
            <b-form-group
                id="customer_type"
                label-for="customer_type">
                <multiselect class="mt-2 field-container"
                v-model="form.customer_type" 
                selectLabel=""
                deselectLabel=""       
                :options="customer_types"></multiselect>
                <errors v-if="form.errors.customer_type">
                  {{ form.errors.customer_type[0] }}
                </errors>
            </b-form-group>
           </div>
           <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="mandate">Mandate</label>
            <b-form-group
                id="mandate"
                label-for="mandate">
                <multiselect class=" mt-2 field-container"
                v-model="form.mandate" 
                selectLabel=""
                deselectLabel=""       
                :options="mandates"></multiselect>
                <errors v-if="form.errors.mandate">
                  {{ form.errors.mandate[0] }}
                </errors>
            </b-form-group>
           </div>

           <!-- <div class="col-6 col-md-6">
                <label class="label">
                    <span v-if="add_or_edit == 'add'" class="akkurate-small">Passport Picture</span>
                    <span v-else class="akkurate-small">Update Passport Picture</span>
                </label>
                <input type="file" class="form-control" alt="File Preview" />
            </div> -->
            <div class="col-6 col-md-6">
              <label v-if="add_or_edit == 'add'"  class="akkurate-dark" for="status">Passport Picture</label>
              <label v-else class="akkurate-dark" for="status">Update Passport Picture</label>
                  
                <div class="">
                    <input accept="image/*" type="file" @change="handlePassportPic" />
                    <!-- <span v-if="add_or_edit == 'add'" class="akkurate-small">Click To Upload Passport Picture</span>
                    <span v-else class="akkurate-small">Click To Update Passport Picture</span> -->
                </div>
                <div v-if="selectedPassportPicFile" class="text-center">
                  <label class="akkurate-dark small">Preview :</label>
                    <img class="imageCardPreviewWrapper" :src="previewPassportPicUrl" alt="File Preview" />
                    <b-button type="button" @click="uploadPassportPic" :disabled="passportpic_upload_processing" class="akkurate-green-btn">
                        <b-spinner v-if="passportpic_spin_visible" small></b-spinner>
                        <i v-if="passportpic_success_visible" class="fas fa-check loading"></i>
                        <i v-if="passportpic_text_visible" class="fas fa-upload loading"></i> {{ passportpic_upload }}
                    </b-button>
                </div>  
            </div>

           <div class="row mt-4" v-if="add_or_edit == 'edit'">
            <div class="col-md-3">

            <label class="akkurate-dark" for="status">Current Passport Picture</label>
            <b-form-group
                id="status"
                label-for="status">
                <img :src="form.passport_pic_display" style="height:200px" />
            </b-form-group>
            </div>
              <div class="col-md-3">

                  <label class="akkurate-dark" for="status">Current Ghana Card (Front)</label>
                  <b-form-group
                      id="status"
                      label-for="status">
                      <img :src="form.gh_card_front_display" style="height:200px" />
                  </b-form-group>
              </div>
              <div class="col-md-3">
                <label class="akkurate-dark" for="status">Current Ghana Card (Back)</label>
                <b-form-group
                      id="status"
                      label-for="status">
                  <img :src="form.gh_card_back_display" style="height:200px" />
                </b-form-group>
              </div>

              <div class="col-md-3">
                <label class="akkurate-dark" for="status">Current Signature</label>
                <b-form-group
                      id="status"
                      label-for="status">
                  <img :src="form.signature_display" style="height:200px" />
                </b-form-group>
              </div>
           </div>
           <div class="row " >
            <div class="col-6 col-md-6 mt-4">
              <label v-if="add_or_edit == 'add'"  class="akkurate-dark" for="gh_card_front">Ghana Card (Front) (Max. 2.5MB)</label>
              <label v-else class="akkurate-dark" for="gh_card_front">Update Ghana Card (Front)</label>  
              <div class="">
                    <input accept="image/*" type="file" @change="handleFrontCard" />
                    <!-- <span v-if="add_or_edit == 'add'" class="akkurate-small">Ghana Card (Front)</span>
                    <span v-else class="akkurate-small">Update Ghana Card (Front)</span> -->

                    <errors v-if="form.errors.gh_card_front_path">
                          {{ form.errors.gh_card_front_path[0] }}
                      </errors>
                </div>
                <div v-if="selectedFrontFile" class="text-center">
                  <label class="akkurate-dark small">Preview : {{ frontCardName }}</label>
                    <img class="imageCardPreviewWrapper" :src="previewFrontUrl" alt="File Preview" />
                    <b-button type="button" @click="uploadFrontFile" :disabled="front_upload_processing" class="akkurate-green-btn">
                        <b-spinner v-if="front_spin_visible" small></b-spinner>
                        <i v-if="front_success_visible" class="fas fa-check loading"></i>
                        <i v-if="front_text_visible" class="fas fa-upload loading"></i> {{ front_upload }}
                    </b-button>
                </div>  
            </div>
            <div class="col-6 col-md-6 mt-4">
              <label v-if="add_or_edit == 'add'"  class="akkurate-dark" for="gh_card_back">Ghana Card (Back) (Max. 2.5MB)</label>
              <label v-else class="akkurate-dark" for="gh_card_back">Update Ghana Card (Back)</label>  

              <div class="">
                    <input accept="image/*" type="file" @change="handleBackCard" />

                    <errors v-if="form.errors.gh_card_back_path">
                          {{ form.errors.gh_card_back_path[0] }}
                      </errors>
                    <!-- <span v-if="add_or_edit == 'add'" class="akkurate-small"></span>
                    <span  v-else class="akkurate-small"></span> -->
                </div>
                <div v-if="selectedBackFile" class="text-center">
                  <label class="akkurate-dark small">Preview : {{ backCardName }}</label>
                    <img class="imageCardPreviewWrapper" :src="previewBackUrl" alt="File Preview" />
                    <b-button type="button" @click="uploadBackFile" :disabled="back_upload_processing" class="akkurate-green-btn">
                        <b-spinner v-if="back_spin_visible" small></b-spinner>
                        <i v-if="back_success_visible" class="fas fa-check loading"></i>
                        <i v-if="back_text_visible" class="fas fa-upload loading"></i> {{ back_upload }}
                    </b-button>
                </div>  
            </div>

            <div class="col-6 col-md-6 mt-4">
              <label v-if="add_or_edit == 'add'"  class="akkurate-dark" for="gh_card_back">Signature (Max. 2.5MB)</label>
              <label v-else class="akkurate-dark" for="gh_card_back">Update Signature</label>  

              <div class="">
                    <input accept="image/*" type="file" @change="handleSignatureCard" />
                </div>
                <div v-if="selectedSignatureFile">
                  <label class="akkurate-dark small">Preview : {{ signatureCardName }}</label>
                    <img class="imageCardPreviewWrapper" :src="previewSignatureUrl" alt="File Preview" />
                    <b-button type="button" @click="uploadSignature" :disabled="signature_upload_processing" class="akkurate-green-btn">
                        <b-spinner v-if="signature_spin_visible"></b-spinner>
                        <i v-if="signature_success_visible" class="fas fa-check loading"></i>
                        <i v-if="signature_text_visible" class="fas fa-upload loading"></i> {{ signature_upload }}
                    </b-button>
                </div>  
            </div>

           </div>
           
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import ApiService from "@/core/services/api.service";
export default {
  name: "PersonalDetails",
  props: ['form', 'roles', 'add_or_edit'],
  components: {
    Multiselect
  },
  data() {
    return {
      title: '',
      title_options: ['Mr', 'Mrs','Miss','Master', 'Dr', 'Prof'],
      gender_value: '',
      external_code: '',
      gender_options: ['Male', 'Female'],
      marital_value: '',
      marital_options: ['Single', 'Married', 'Divorced', 'Widow'],
      status: '',
      status_options: ['Pending', 'Active', 'Inactive', 'Dormant', 'Closed'],
      customer_types: ['INDIVIDUAL', 'GROUP', 'CORPORATE', "IN_TRUST"],
      mandates: ["ONLY_PRINCIPAL_SIGNATORY", "PRINCIPAL_SIGNATORY_AND_ANY_OTHER_ONE",  
            "ANY_OTHER_TWO_OF_THE_SIGNATORIES"],
      selectedFrontFile: null,
      selectedPassportPicFile: null,
      previewFrontUrl: null,
      frontCardName: "",
      selectedBackFile: null,
      selectedSignatureFile: null,
      previewBackUrl: null,
      backCardName: "",
      signatureCardName: "",
      selectedSignFile: null,
      previewSignUrl: null,
      signatureName: "",
      front_upload: "Click To Upload",
      back_upload: "Click To Upload",
      signature_upload: "Click To Upload",
      front_upload_processing: false,
      front_spin_visible: false,
      front_success_visible: false,
      front_text_visible: false,
      back_upload_processing: false,
      signature_upload_processing: false,
      back_spin_visible: false,
      signature_spin_visible: false,
      back_success_visible: false,
      back_text_visible: false,
      signature_success_visible: false,
      signature_text_visible: false,
      passport_pic_card: null,
      passportpic_upload: "Click To Upload",
      passportpic_spin_visible: false,
      passportpic_text_visible: false,
      passportpic_upload_processing: false,
      previewPassportUrl: null,
      passportpic_success_visible: false,
      previewPassportPicUrl: null,
      previewSignatureUrl: null,
      signature_card: null,
      back_ghana_card: null,
    }
  },
  methods: {
    handleFrontCard(event) {
      this.front_ghana_card = event.target.files[0];
      // console.log(this.form.frontGhanaCard);
      if (this.front_ghana_card) {
        this.selectedFrontFile = this.front_ghana_card;
        // this.frontCardName =  this?.front_ghana_card.name;
        this.previewFrontFile(this.front_ghana_card);
        this.uploadFrontFile();
      }
    },
    previewFrontFile(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.previewFrontUrl = reader.result;
      };
    },
    handlePassportPic(event) {
      this.passport_pic_card = event.target.files[0];
      // console.log(this.form.frontGhanaCard);
      if (this.passport_pic_card) {
        this.selectedPassportPicFile = this.passport_pic_card;
        // this.frontCardName =  this?.passport_pic_card.name;
        this.previewPassportPic(this.passport_pic_card);
        this.uploadPassportPic();
      }
    },
    previewPassportPic(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.previewPassportPicUrl = reader.result;
      };
    },
    handleBackCard(event) {
      this.back_ghana_card = event.target.files[0];
      // console.log(this.back_ghana_card);
      if (this.back_ghana_card) {
        this.selectedBackFile = this.back_ghana_card;
        // this.backCardName = this?.back_ghana_card.name;
        this.previewBackFile(this.back_ghana_card);
        this.uploadBackFile();
      }
    },
    handleSignatureCard(event) {
      this.signature_card = event.target.files[0];
      if (this.signature_card) {
        this.selectedSignatureFile = this.signature_card;
        this.previewSignatureFile(this.signature_card);
        this.uploadSignature();
      }
    },
    previewBackFile(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.previewBackUrl = reader.result;
      };
    },
    previewSignatureFile(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.previewSignatureUrl = reader.result;
      };
    },
    // handleSignCard(event) {
    //   this.uploads.signature = event.target.files[0];
    //   if (this.uploads.signature) {
    //     this.selectedSignFile = this.uploads.signature;
    //     // this.signatureName = this?.signature.name;
    //     this.previewSignFile(this.uploads.signature);
    //   }
    // },
    // previewSignFile(file) {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onload = () => {
    //     this.previewSignUrl = reader.result;
    //   };
    // },
    // timeout(ms) {
    //   return new Promise(resolve => setTimeout(resolve, ms));
    // },
    async uploadFrontFile() {
      const data = new FormData();
      data.append('file', this.front_ghana_card);
      this.front_upload = "Processing..."
      this.front_spin_visible = true;
      this.front_text_visible = false;
      this.front_upload_processing = true
      await ApiService.post('/images/store', data)
        .then((response) => {
          console.log(response.data);
          this.front_upload = response.data.success;
          this.front_spin_visible = false;
          this.front_text_visible = false;
          // this.form.front_ghana_card = null;
          this.front_upload_processing = true
          // this.previewFrontUrl = null;
          this.$emit('responseFront', {
            front_ghana_path: response.data.path,
            front_ghana_name: response.data.name,
          });

        }).catch((error) => {
          this.front_upload_processing = false
          console.log(error);
        })
    },
    async uploadBackFile() {
      const data = new FormData();
      data.append('file', this.back_ghana_card);
      this.back_upload = "Processing..."
      this.back_spin_visible = true;
      this.back_text_visible = false;
      this.back_upload_processing = true
      await ApiService.post('/images/store', data)
        .then((response) => {
          console.log(response.data);
          this.back_upload = response.data.success;
          this.back_spin_visible = false;
          this.back_text_visible = false;
          // this.form.front_ghana_card = null;
          this.back_upload_processing = true
          // this.previewBackUrl = null;
          this.$emit('responseBack', {
            back_ghana_path: response.data.path,
            back_ghana_name: response.data.name,
          });

        }).catch((error) => {
          this.back_upload_processing = false
          console.log(error);
        })
    },
    async uploadPassportPic() {
      const data = new FormData();
      data.append('file', this.passport_pic_card);
      this.passportpic_upload = "Processing..."
      this.passportpic_spin_visible = true;
      this.passportpic_text_visible = false;
      this.passportpic_upload_processing = true
      await ApiService.post('/images/store', data)
        .then((response) => {
          // console.log(response.data);
          this.passportpic_upload = response.data.success;
          this.passportpic_spin_visible = false;
          this.passportpic_text_visible = false;
          // this.form.front_ghana_card = null;
          this.passportpic_upload_processing = true

          this.previewPassportUrl = null;
          this.$emit('responsePassportPic', {
            passport_pic_path: response.data.path,
            passport_pic_name: response.data.name,
          });

        }).catch((error) => {
          console.log(error)
          this.passportpic_upload_processing = false
          this.passportpic_upload = error.response.data.message;
          this.passportpic_spin_visible = false;
          console.log(error);
        })
    },

    async uploadSignature() {
      const data = new FormData();
      data.append('file', this.signature_card);
      this.signature_upload = "Processing..."
      this.signature_spin_visible = true;
      this.signature_text_visible = false;
      this.signature_upload_processing = true
      await ApiService.post('/images/store', data)
        .then((response) => {
          // console.log(response.data);
          this.signature_upload = response.data.success;
          this.signature_spin_visible = false;
          this.signature_text_visible = false;
          this.signature_upload_processing = true

          // this.previewSignatureUrl = null;
          this.$emit('responseSignaturePic', {
            signature_image_path: response.data.path,
            signature_image_name: response.data.name,

          });

        }).catch((error) => {
          // console.log(error)
          this.signature_upload_processing = false
          this.signature_upload = error.response.data.message;
          this.signature_spin_visible = false;
          // console.log(error);
        })
    },
  }
}
</script>