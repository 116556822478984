<template>
  <b-modal ref="showChequeTemplate" size="xl" @shown="modalShown">
    <template #modal-title>
      <div class="fw-bold akkurate-green">Cheque Template</div>
    </template>
    <div class="container-fluid" ref="pdfContent">
      <div class="row">
        <div class="col-6 col-md-6 mt-5">
            <div class="text-capitalize">{{ numberToWords(100) }}</div>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <!-- begin loader -->
        <!-- <beat-loader-component 
             class="float-left"
             v-if="isLoading"
             :isLoading="isLoading"
             ></beat-loader-component> -->
        <!-- end loader -->
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          @click="generatePDF"
          type="button"
          class="akkurate-green-btn download-btn ms-2 float-right"
          ><span class="small"
            ><font-awesome-icon :icon="['fas', 'book']" /> Print</span
          ></b-button
        >
        <b-button
          @click="exportToPDF"
          class="akkurate-green-btn download-btn float-right"
          ><span class="small"
            ><font-awesome-icon :icon="['fas', 'save']" /> Download</span
          ></b-button
        >
      </div>
    </template>
  </b-modal>
</template>
<script>
import html2pdf from "html2pdf.js";
export default {
  props: ["report_data"],
  methods: {
    showModal() {
      this.$refs["showChequeTemplate"].show();
    },
    hideModal() {
      this.$refs["showChequeTemplate"].hide();
    },
    exportToPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: 10,
        filename: "generated.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      };
      // html2pdf().from(content).set(pdfOptions).outputPdf((pdf) => {

      // });
      html2pdf().from(content).set(pdfOptions).save();
    },
    generatePDF() {
      // Define the element to convert to a PDF
      const element = this.$refs.pdfContent; // Replace with your element's ID or selector
      // console.log(elementToConvert);

      // Define the options for html2pdf
      const pdfOptions = {
        margin: 10,
        filename: "document.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
        debug: true, // Enable debugging
      };
      html2pdf()
        .from(element)
        .set(pdfOptions)
        .outputPdf("blob")
        .then((pdf) => {
          const blob = new Blob([pdf], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          // console.log(pdf);
          const printWindow = window.open(
            url,
            "_blank",
            "width=800,height=600"
          );
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(url);
          };
        });
    },
    numberToWords(number) {
      const units = [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
      ];
      const teens = [
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
      ];
      const tens = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];

      if (number === 0) return "zero";

      function convertLessThanThousand(n) {
        let word = "";
        if (
          n == 100 ||
          n == 200 ||
          n == 300 ||
          n == 400 ||
          n == 500 ||
          n == 600 ||
          n == 700 ||
          n == 800 ||
          n == 900
        ) {
          word += units[Math.floor(n / 100)] + " hundred ";
          n %= 100;
        } else if (n > 100) {
          word += units[Math.floor(n / 100)] + " hundred and ";
          n %= 100;
        }
        if (n >= 20) {
          word += tens[Math.floor(n / 10)] + " ";
          n %= 10;
        }
        if (n > 0) {
          if (n < 10) word += units[n] + " ";
          else word += teens[n - 10] + " ";
        }
        return word.trim();
      }

      let result = "";
      let billions = Math.floor(number / 1000000000);
      let millions = Math.floor((number % 1000000000) / 1000000);
      let thousands = Math.floor((number % 1000000) / 1000);
      let remainder = number % 1000;

      if (billions > 0) {
        result += convertLessThanThousand(billions) + " billion ";
      }
      if (millions > 0) {
        result += convertLessThanThousand(millions) + " million ";
      }
      if (thousands > 0) {
        result += convertLessThanThousand(thousands) + " thousand ";
      }
      if (remainder > 0) {
        result += convertLessThanThousand(remainder);
      }

      return result.trim();
    },
  },
};
</script>
<style scoped>
.printout-logo-size {
  height: 80px;
}
.line-one {
  border-bottom: 1px solid #929191;
}
.date-border {
  border: 1px solid #929191;
  width: 150px;
  height: 30px;
}
.amount-space {
  background-color: #f0fdb5;
  width: 250px;
  height: 50px;
}
.signature-line {
  border-bottom: 1px solid #929191;
}
</style>