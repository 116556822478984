<template>
    <div class="w-100">
       <b-button class="akkurate-green-btn" v-b-modal.advance_search>Advanced Search</b-button>
       <b-modal 
       id="advance_search"
        size="lg"
        @ok="handleOk">
        <template #modal-title>
            <p class="m-0 akkurate-green fw-bold">Advanced Search</p>
        </template>
        <div class="row">
            <div class="col-md-6">
                <label class="akkurate-dark" for="end_date">Branch</label>
                <b-form-group
                    label-for="branch"
                    class="mb-3">
                    <multiselect class="field-container" v-model="adv_search.branch" :options="branch_options"></multiselect>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <label class="akkurate-dark" for="end_date">Status</label>
                <b-form-group
                    label-for="status"
                    class="mb-3">
                    <multiselect class="field-container" v-model="adv_search.status" :options="status_options"></multiselect>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <label class="akkurate-dark" for="end_date">Start Date</label>
                <b-form-group
                    label-for="from_date"
                    class="mb-3">
                    <b-form-input
                        id="from_date"
                        v-model="adv_search.from_date"
                        name="from_date"
                        class="mt-2 field-container fields"
                        type="date"
                        required
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <label class="akkurate-dark" for="end_date">End Date</label>
                <b-form-group
                    label-for="to_date"
                    class="mb-3">
                    <b-form-input
                        id="to_date"
                        v-model="adv_search.to_date"
                        name="to_date"
                        class="mt-2 field-container fields"
                        type="date"
                        required
                    ></b-form-input>
                </b-form-group>
            </div>

        </div>
        <template #modal-footer="{ ok }">
               <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
                <!-- <b-button @click="$bvModal.hide('advance_search')" type="button" class="akkurate-grey-btn btn-size float-right border-0">Close</b-button> -->
                <b-button @click="ok()" type="submit" class="akkurate-green-btn btn-size me-2 float-right border-0">Search</b-button>     
               </div>
           </template>

       </b-modal>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
    components: {
        Multiselect
    },
    data() {
        return {
            adv_search: {
                branch: "",
                status: "",
                start_date: "",
                end_date: "",
            },
            branch_options: [],
            status_options: [],
        }
    },
    methods:{
        handleOk(){
            this.$emit('adv_search', this.adv_search);
        }
    }
}
</script>