<template>
    <div>
       <b-modal 
        :id="String(data.id + writeoff)"
        ref="modal"
        size="xl"
        @ok="handleOk"
       >
       <template #modal-title>
        <div class="akkurate-green fw-bold">Loan Write Off</div>

       </template>
        <div class="row">
        <div class="col-12">
            <div class="row">
                <form @submit.prevent="filterLoan">
                  <div class="row">
                        <!-- <div class="col-12 col-md-4">
                           <b-form-group
                           id="loan-re-date"
                           label-for="loan-re-date">
                           <multiselect class="mt-2 field-container" v-model="loan" :options="loan_options"></multiselect>
                           </b-form-group>
                        </div> -->
                        <div class="col-12 col-md-8">
                              <b-form-group
                              id="loan_code"
                              label-for="loan_code">
                              <b-form-input
                              class="mb-3 mt-2 field-container fields"
                              type="text"
                              placeholder="Enter Loan Code"
                              v-model="filter.loan_code"
                              required
                              ></b-form-input>
                              </b-form-group>
                        </div>
                        <div class="mt-auto col">
                        <b-button type="submit" :disabled="isSearching" class="akkurate-green-btn akkurate-auth-size w-100 mb-3">{{ search }}</b-button>
                        </div>
                     </div>
               </form>
            </div>
            <div class="card card-shape home-box">
                <div class="card-header py-3">
                        <div class="row">
                           <div class="col-12 col-md-12">
                              <h6 class="m-0 fw-bold text-green">Loan Account Preview</h6>
                           </div>
                        </div>
                     </div>
                     <div class="card-body" style="background-color:#eaeff2">
                        <div class="row">
                           <div class="col-3 col-md-3">
                              <img class="img-fluid img-visibility  w-100 h-100" src="@/assets/img/user-avater.jpg">
                           </div>
                           <div class="col-3 col-md-3">
                              <div class="row">
                                 <div class="col-12 col-md-12">
                                    <p class="fw-bold">{{ loan.account?.customer?.last_name }}</p>
                                 </div>
                                 <!-- <div class="col-7 col-md-7">
                                    <p class="mb-0 fw-bold"></p>
                                    <p class="mb-0 fw-bold">Tenure</p>
                                    <p class="mb-0 fw-bold">Interest Rate</p>
                                    <p class="mb-0 fw-bold">Interest Method</p>
                                    <p class="mb-0 fw-bold">Interest Expected</p>
                                    <p class="mb-0 fw-bold">Interest Paid</p>
                                    <p class="mb-0 fw-bold">Interest Due</p>

                                 </div> -->
                                 <div class="col-12 col-md-12 small">
                                    <div class="d-flex"> 
                                       <p class="mb-0 fw-bold me-3">Status</p>
                                       <p class="mb-0">{{ loan.status }}</p>
                                    </div>
                                    <div class="d-flex">
                                       <p class="mb-0 fw-bold me-3">Tenure</p>
                                       <p class="mb-0">{{ loan.tenor }}</p>
                                    </div>
                                    <div class="d-flex">
                                       <p class="mb-0 fw-bold me-3">Interest Rate</p>
                                       <p class="mb-0">{{ loan.interest_rate }}</p>
                                    </div>
                                    <div class="d-flex">
                                       <p class="mb-0 fw-bold me-3">Interest Method</p>
                                       <p class="mb-0">{{ loan.interest_method }}</p>
                                    </div>
                                    <div class="d-flex">
                                       <p class="mb-0 fw-bold me-3">Interest Expected</p>
                                       <p class="mb-0">{{ loan.interest_balance }}</p>
                                    </div>
                                    <div class="d-flex">
                                       <p class="mb-0 fw-bold me-3">Interest Balance</p>
                                       <p class="mb-0">{{ loan.interest_balance - loan.interest_paid }}</p>
                                    </div>
                                    <div class="d-flex">
                                       <p class="mb-0 fw-bold me-3">Interest Paid</p>
                                       <p class="mb-0">{{ loan.interest_paid }}</p>
                                    </div>
                                    <div class="d-flex">
                                       <p class="mb-0 fw-bold me-3">Interest Due</p>
                                       <p class="mb-0">{{ loan.interest_due }}</p>
                                    </div>
                                 </div>
                              
                              </div>
                           </div>
                           <div class="col-3 col-md-3 small">
                              <div class="row h-100">
                                 <div class="col-md-12">
                                    <div> <span class="fw-bold">Loan Type:</span> {{ loan?.loan_product.name }}</div>
                                    <div class="small"> <span class="fw-bold">Disbursement Date:</span> {{ loan.start_date }}</div>
                                 </div>
                                 <div class="col-md-12 mt-auto">
                                    <div class="row">
                                       <div class="col-md-6 fw-bold">Principal</div>
                                       <div class="col-md-6">{{ loan.principal_amount }}</div>
                                       <div class="col-md-6 fw-bold">Principal Paid</div>
                                       <div class="col-md-6">{{ loan.principal_paid }}</div>
                                       <div class="col-md-6 fw-bold">Principal Bal</div>
                                       <div class="col-md-6">{{ loan.principal_balance }}</div>
                                       <div class="col-md-6 fw-bold">Principal Due</div>
                                       <div class="col-md-6">{{ loan.principal_due }}</div>

                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-3 col-md-3 small">
                              <div class="row h-100">
                                 <div class="col-md-12">
                                    <div> <span class="fw-bold">Loan Code:</span> {{ loan.loan_number }}</div>
                                    <div> <span class="fw-bold">Last Payment Date:</span> {{ loan.last_payment }}</div>
                                    <div> <span class="fw-bold">Delinquency:</span> {{ this.loan.delinquent }}</div>
                                 </div>
                                 <div class="col-md-12 mt-auto">
                                    <div class="row">
                                       <!-- <div class="col-md-6 fw-bold">Share Bal</div>
                                       <div class="col-md-6">{{ loan?.loan_product.min_share_balance }}</div> -->
                                       <div class="col-md-6 fw-bold">Loan Bal</div>
                                       <div class="col-md-6">{{ loan.loan_balance }}</div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
            
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="amount">Amount</label>
                        <b-form-group
                        id="amount"
                        label-for="amount">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="number"
                            placeholder="Enter Amount"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="loan-re-date">Loan Kind</label>
                        <b-form-group
                            id="loan-re-date"
                            label-for="loan-re-date">
                        <multiselect class="mt-2 field-container" v-model="payment" :options="payment_options"></multiselect>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="loan-re-date">Loan Scheme</label>
                        <b-form-group
                            id="loan-re-date"
                            label-for="loan-re-date">
                        <multiselect class="mt-2 field-container" v-model="payment" :options="payment_options"></multiselect>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="amount">Interest Rate</label>
                        <b-form-group
                        id="amount"
                        label-for="amount">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="number"
                            placeholder="Enter Interest Rate"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="loan-re-date">Interest Method</label>
                        <b-form-group
                            id="loan-re-date"
                            label-for="loan-re-date">
                        <multiselect class="mt-2 field-container" v-model="payment" :options="payment_options"></multiselect>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="loan-re-date">Interest Rate Frequency</label>
                        <b-form-group
                            id="loan-re-date"
                            label-for="loan-re-date">
                        <multiselect class="mt-2 field-container" v-model="payment" :options="payment_options"></multiselect>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="amount">Tenure</label>
                        <b-form-group
                        id="amount"
                        label-for="amount">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="number"
                            placeholder="Enter Tenure"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="amount">Interest Start Date</label>
                        <b-form-group
                        id="amount"
                        label-for="amount">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="date"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="maturity">Maturity Date</label>
                        <b-form-group
                        id="maturity"
                        label-for="maturity">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="date"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="amount">Tenure Interval</label>
                        <b-form-group
                        id="amount"
                        label-for="amount">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="number"
                            placeholder="Enter Tenure"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="loan-re-date">Interest Rate Frequency</label>
                        <b-form-group
                            id="loan-re-date"
                            label-for="loan-re-date">
                        <multiselect class="mt-2 field-container" v-model="payment" :options="payment_options"></multiselect>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="amount">No. Of Payment</label>
                        <b-form-group
                        id="amount"
                        label-for="amount">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="number"
                            placeholder="Enter Tenure"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="amount">Expected Interest</label>
                        <b-form-group
                        id="amount"
                        label-for="amount">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="number"
                            placeholder="Enter Tenure"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="amount">Penalty Interest Rate %</label>
                        <b-form-group
                        id="amount"
                        label-for="amount">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="number"
                            placeholder="Enter Tenure"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="loan_reason">Reason For Loan</label>
                        <b-form-group
                            id="loan_reason"
                            label-for="loan_reason">
                        <multiselect class="mt-2 field-container" v-model="payment" :options="payment_options"></multiselect>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="loan_reason">GL Debit</label>
                        <b-form-group
                            id="loan_reason"
                            label-for="loan_reason">
                        <multiselect class="mt-2 field-container" v-model="payment" :options="payment_options"></multiselect>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="amount">Reason For Write-Off</label>
                        <b-form-group
                        id="amount"
                        label-for="amount">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="number"
                            placeholder="Enter Tenure"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark small" for="apv_doc">Upload Aprroval Document</label>
                        <input type="file" class="form-control mt-2" id="apv_doc">
                    </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        <template #modal-footer="{ ok }">
               <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
               <b-button @click="ok()" type="submit" class="akkurate-green-btn btn-size me-2 float-right">Write-Off Loan</b-button>     

            </div>
           </template>
       </b-modal>
       <!-- <cash-repay :show-modal="showModal"></cash-repay> -->
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import ApiService from "@/core/services/api.service";
// import CashRepay from '../reports/Cash.vue'
export default {
    name: "ChargeTerms",
    props: ['data','writeoff'],
    components: {
        Multiselect,
    },
    data() {
        return {
            filter: {
                loan_code: "",
            },
            loan: {
                status: "",
                tenor: "",
                interest_rate: "",
                interest_method: "",
                expected_interest: "",
                interest_balance: "",
                interest_paid: "",
                interest_balance: "",
                loan_number: "",
                principal_amount: "",
                principal_paid: "",
                principal_balance: "",
                principal_due: "",
                loan_product: "",
                account: "",
                start_date: "",
                last_payment: "",
                interest_due: "",

            },
            search: "Search",
            isSearching: false,
            isSaving: false,
            total: "",
            payment: "Cash",
            payment_options: ["Cash", "Momo"],

            fields: [
                { key: 'payment_terms', label: 'Payment Terms' },
                { key: 'after_payments', label: 'After Payment' },
            ],
            items: [
                { payment_terms: { title: 'Interest Paid', value: '1,543.00' }, after_payments: { title: 'Interest Outstanding', value: '1,543.00' } },
                { payment_terms: { title: 'Principal Paid', value: '457.00' }, after_payments: { title: 'Principal Outstanding', value: '1,543.00' } },
                { payment_terms: { title: 'Total Repayment', value: '2,000.00' }, after_payments: { title: 'Total Outstanding', value: '1,543.00' } },
            ]
        }
    },
    methods: {
        filterLoan() {
            this.search = "processing...";
            this.isSearching = true;
            ApiService.get(`/loans/${this.filter.loan_code}`)
                .then((response) => {
                    // console.log(response.data.data)
                    this.loan = response.data.data;
                    this.search = "Search";
                    this.isSearching = false;
                }).catch((error) => {
                    this.search = "Search";
                    this.isSearching = false;
                    if (error.response.status == 404) {
                        swal.fire({
                            icon: "error",
                            title: error.response.statusText,
                            text: "Something went wrong!",
                            showCancelButton: false, // Show the "Cancel" button
                            confirmButtonText: 'OK', // Customize the text for the "OK" button
                            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                            customClass: {
                                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                            }
                            // footer: '<a href="#">Why do I have this issue?</a>'
                        });
                    }
                })
        },
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            this.$validator.validateAll().then(result => {
                if (!result) {
                    // console.log(result);
                    return;
                }
                swal.fire({
                    title: 'Success',
                    text: 'Transaction Successfully Saved',
                    confirmButtonColor: "#018673",
                    icon: 'success',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showCancelButton: true, // Show the "Cancel" button
                    confirmButtonText: 'OK', // Customize the text for the "OK" button
                    cancelButtonText: 'Print', // Text for the custom button
                    buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                    customClass: {
                        confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$bvModal.hide('loan_repayment');
                    } else if (result.isDismissed) {
                        //  this.showModal = true; 
                        // let action = this.data.account_number + this.transaction
                        // let value = action.split(" ");
                        // if (value[1] == "Deposit") {
                        //    this.$bvModal.hide(this.data.account_number + this.transaction);
                        //    this.showDeposit = true;
                        //    this.report_data = {
                        //       'amount': response.data.transaction.trans_amount,
                        //       'full_name': this.data.customer.first_name + ' ' + this.data.customer.last_name,
                        //       'denomination': this.form.denominations,
                        //       'account_no': this.data.account_number,
                        //       'trans_date': response.data.transaction.trans_date,

                        //    }

                        // } else if (value[1] == "Withdrawal") {
                        //    this.$bvModal.hide(this.data.account_number + this.transaction);
                        //    this.showWithdrawal = true;
                        //    this.report_data = {
                        //       'amount': this.amount,
                        //       'full_name': this.data.customer.first_name + ' ' + this.data.customer.last_name,
                        //       'denomination': this.fields,
                        //       'account_no': this.data.account_number
                        //    }
                        // }
                        // this.$refs.cashDeposit.mergedResults();
                    }
                });
                // this.isSaving = true;
                // swal.fire({
                //     title: 'Transaction Successfully Saved',
                //     text: 'References No: 58518111476',
                //     confirmButtonColor: "#018673",
                //     icon: 'success',
                //     allowOutsideClick: false,
                //     allowEscapeKey: false,
                //     showCancelButton: true, // Show the "Cancel" button
                //     confirmButtonText: 'OK', // Customize the text for the "OK" button
                //     cancelButtonText: 'Print', // Text for the custom button
                //     buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                //     customClass: {
                //         confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                //         cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                //     }
                // }).then((result) => {
                //     // if (result.isConfirmed) {
                //     //     this.$bvModal.hide(this.data.account_number + this.transaction);
                //     // } else if (result.isDismissed) {
                //     //     let action = this.data.account_number + this.transaction
                //     //     let value = action.split(" ");
                //     //     if (value[1] == "Deposit") {
                //     //         this.$bvModal.hide(this.data.account_number + this.transaction);
                //     //         this.showDeposit = true;
                //     //         this.report_data = {
                //     //             'amount': this.amount,
                //     //             'full_name': this.data.customer.first_name + ' ' + this.data.customer.last_name,
                //     //             'denomination': this.fields,
                //     //             'account_no': this.data.account_number
                //     //         }

                //     //     } else if (value[1] == "Withdrawal") {
                //     //         this.$bvModal.hide(this.data.account_number + this.transaction);
                //     //         this.showWithdrawal = true;
                //     //         this.report_data = {
                //     //             'amount': this.amount,
                //     //             'full_name': this.data.customer.first_name + ' ' + this.data.customer.last_name,
                //     //             'denomination': this.fields,
                //     //             'account_no': this.data.account_number
                //     //         }
                //     //     }
                //     //     // this.$refs.cashDeposit.mergedResults();
                //     // }
                // });


            });
            // Hide the modal manually

        },
    }
}
</script>