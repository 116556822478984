<template>
    <div>

        <img class="img-fluid img-visibility statement-logo" src="../../../assets/img/ggbccu_logo.png">
        <!-- <img class="img-fluid img-visibility statement-logo" :src="config.institution_logo_path"> -->
        <!-- <img class="img-fluid img-visibility statement-logo" :src="config.institution_logo_path"> -->
    </div>

</template>

<script>
import config from '@/config.js'
export default {
    // logoPath: require('@/' + this.config.institution_logo_path),
    // init() {
    //     // console.log('logoPath', this.logoPath);
    //     this.config = config;
    // },
    props:['institution'],
    data() {
    return {
      config: config
    }
    },
    computed: {
        // logoPath() {
        //     return require('@/' + this.config.institution_logo_path);
        // }
    }
}
</script>