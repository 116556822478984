<template>
  <ul v-if="!mobile" ref="sidebar" class="navbar-nav sidebar sidebar-dark accordion akkurate-dark-gradient">
    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="/">
      <!-- <div class="sidebar-brand-icon">
                <font-awesome-icon :icon="['fas', 'layer-group']" />
            </div> -->
      <!-- image -->
      <img height="50px" src="../../../assets/img/logo.png" />
      <div class="sidebar-brand-text mx-3">AKKURATEBanc</div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider mt-0" />

    <!-- Nav Item - Dashboard -->
    <li class="nav-item" :class="{ active: home }">
      <a class="nav-link" href="/">
        <font-awesome-icon :icon="['fas', 'house-damage']" /><span>Home</span>
      </a>
    </li>

    <hr class="sidebar-divider" />

    <div class="sidebar-heading">Member Management</div>

    <!-- Memebers -->
    <b-nav-item v-b-toggle.collapse-3 :class="{ active: members }">
      <font-awesome-icon :icon="['fas', 'users']" /><span>Members</span>
      <b-collapse id="collapse-3" :visible="memberVisible" class="mt-2">
        <div class="py-2 collapse-inner" onClick="event.stopPropagation();">
          <b-link class="collapse-item" :class="{ active: addMember }" href="/members/create">Create Member</b-link>
          <b-link class="collapse-item" :class="{ active: allMember }" href="/members">All Members</b-link>
          <b-link class="collapse-item" :class="{ active: memberImport }" href="/members/import">Imports</b-link>
        </div>
      </b-collapse>
    </b-nav-item>

    <hr class="sidebar-divider" />

    <!-- Member Accounts -->
    <b-nav-item v-b-toggle.collapse-4 :class="{ active: memacc }">
      <font-awesome-icon :icon="['fas', 'user-circle']" /><span>Accounts</span>
      <b-collapse id="collapse-4" :visible="memaccVisible" class="mt-2">
        <div class="py-2 collapse-inner" onClick="event.stopPropagation();">
          <b-link class="collapse-item" :class="{ active: addMemAcc }" href="/members/account/create">Create
            Account</b-link>
          <b-link class="collapse-item" :class="{ active: allMemAcc }" href="/members/account">All Accounts</b-link>
        </div>
      </b-collapse>
    </b-nav-item>

    <!-- Divider -->
    <hr class="sidebar-divider">
    <div class="sidebar-heading">
      Transactions
    </div>
    <!-- Operations -->
    <b-nav-item v-b-toggle.collapse-5 :class="{ active: transaction }">
      <font-awesome-icon :icon="['fas', 'money-bill']" /><span>Deposit/Withdrawal</span>
      <b-collapse id="collapse-5" :visible="transVisible" class="mt-2">
        <div class="py-2 collapse-inner" onClick="event.stopPropagation();">
          <b-link class="collapse-item" :class="{ active: depositTransaction }" href="/transactions/deposit">Deposit</b-link>
          <b-link class="collapse-item" :class="{ active: withdrawalTransaction }" href="/transactions/withdrawal">Withdrawal</b-link>
          <b-link class="collapse-item" :class="{ active: bulkTransaction }" href="/transactions/deposits/bulk">Bulk Deposits</b-link>
          <b-link class="collapse-item" :class="{ active: bulkWithdrawals }" href="/transactions/withdrawals/bulk">Bulk Withdrawals</b-link>
          <b-link class="collapse-item" :class="{ active: allTransaction }"
            href="/transactions/all-deposit-withdrawal">All Deposits /<br /> Withdrawals</b-link>
        </div>
      </b-collapse>
    </b-nav-item>
    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block">

    <b-nav-item v-b-toggle.collapse-6 :class="{ active: operation }">
      <font-awesome-icon :icon="['fas', 'briefcase']" /><span>Operations</span>
      <b-collapse id="collapse-6" :visible="operationsVisible" class="mt-2">
        <div class="py-2 collapse-inner" onClick="event.stopPropagation();">
          <b-link class="collapse-item" :class="{ active: standing_order }" href="/standing-order">Standing
            Orders</b-link>
          <b-link class="collapse-item" :class="{ active: allTransaction }" href="/investment/fixed-deposit">Fixed Term
            Deposits</b-link>
          <b-link class="collapse-item" :class="{ active: allTransaction }"
            href="/reports/controls/user-coa-tracker">Collection Tracker</b-link>
        </div>
      </b-collapse>
    </b-nav-item>

    <hr class="sidebar-divider d-none d-md-block" />

    <b-nav-item v-b-toggle.collapse-7 :class="{ active: voucher }">
      <font-awesome-icon :icon="['fas', 'book']" /><span>Vouchers</span>
      <b-collapse id="collapse-7" :visible="voucherVisible" class="mt-2">
        <div class="py-2 collapse-inner" onClick="event.stopPropagation();">
          <b-link class="collapse-item" :class="{ active: addVoucher }" href="/voucher/create">Create Voucher</b-link>
          <b-link class="collapse-item" :class="{ active: voucherIndex }" href="/voucher">All Vouchers</b-link>
        </div>
      </b-collapse>
    </b-nav-item>

    <hr class="sidebar-divider d-none d-md-block" />

    <b-nav-item v-b-toggle.collapse-8 :class="{ active: journal }">
      <font-awesome-icon :icon="['fas', 'tasks']" /><span>Journals</span>
      <b-collapse id="collapse-8" :visible="journalVisible" class="mt-2">
        <div class="py-2 collapse-inner" onClick="event.stopPropagation();">
          <b-link class="collapse-item" :class="{ active: addJournal }" href="/journal/create">Create Journal</b-link>
          <b-link class="collapse-item" :class="{ active: journalIndex }" href="/journal">All Journals</b-link>
          <b-link class="collapse-item" :class="{ active: journalTransferIndex }" href="/journal/transfer">Journals
            Transfer</b-link>
        </div>
      </b-collapse>
    </b-nav-item>

    <hr class="sidebar-divider d-none d-md-block" />
    <div class="sidebar-heading">Loans</div>
    <!-- Loan -->
    <b-nav-item v-b-toggle.collapse-9 :class="{ active: loan }">
      <font-awesome-icon :icon="['fas', 'hand-holding']" /><span>Loans</span>
      <b-collapse id="collapse-9" :visible="loanVisible" class="mt-2">
        <div class="py-2 collapse-inner" onClick="event.stopPropagation();">
          <b-link class="collapse-item" :class="{ active: allLoans }" href="/loans">All Loans</b-link>
          <b-link class="collapse-item" :class="{ active: addLoan }" href="/loans/application">Loan Application</b-link>
          <b-link class="collapse-item" :class="{ active: repayLoan }" href="/loans/repayments">Loan Repayments</b-link>
          <b-link class="collapse-item" :class="{ active: loanCal }" href="/loans/calculator">Loan Calculator</b-link>
          <b-link class="collapse-item" :class="{ active: loanScheme }" href="/loans/scheme">Loan Schemes</b-link>
        </div>
      </b-collapse>
    </b-nav-item>

    <!-- Divider -->
    <hr class="sidebar-divider" />

    <!-- Heading -->
    <div class="sidebar-heading">Financials</div>

    <!-- User Management -->
    <b-nav-item v-b-toggle.collapse-1 :class="{ active: accounts }">
      <font-awesome-icon :icon="['fas', 'chart-bar']" /><span>Chart Of Accounts</span>
      <b-collapse :visible="accountsVisible" id="collapse-1">
        <div class="py-2 collapse-inner" onClick="event.stopPropagation();">
          <b-link class="collapse-item" :class="{ active: allAccountTypes }" href="/chart-of-accounts/account-types">COA
            Types</b-link>
          <b-link class="collapse-item" :class="{ active: allAccount }" href="/chart-of-accounts">Accounts</b-link>
        </div>
      </b-collapse>
    </b-nav-item>

    <hr class="sidebar-divider d-none d-md-block" />

    <!-- Heading -->
    <div class="sidebar-heading">user management</div>

    <!-- User Management -->
    <b-nav-item v-b-toggle.collapse-2 :class="{ active: users }">
      <font-awesome-icon :icon="['fas', 'user']" /><span>Users</span>
      <b-collapse :visible="userVisible" id="collapse-2">
        <div class="py-2 collapse-inner" onClick="event.stopPropagation();">
          <b-link class="collapse-item" :class="{ active: addUsers }" href="/users/create">Create User</b-link>
          <b-link class="collapse-item" :class="{ active: allUsers }" href="/users">All Users</b-link>
          <b-link class="collapse-item" :class="{ active: permissions }" href="/users/permissions">Roles /
            Permissions</b-link>
        </div>
      </b-collapse>
    </b-nav-item>

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block" />

    <div class="sidebar-heading">Settings</div>
    <b-nav-item v-b-toggle.collapse-10 :class="{ active: branch }">
      <font-awesome-icon :icon="['fas', 'code-branch']" /><span>Branches</span>
      <b-collapse id="collapse-10" :visible="branchVisible" class="mt-2">
        <div class="py-2 collapse-inner" onClick="event.stopPropagation();">
          <b-link class="collapse-item" :class="{ active: addBranch }" href="/branch/create">Create Branch</b-link>
          <b-link class="collapse-item" :class="{ active: allBranch }" href="/branch">All Branches</b-link>
        </div>
      </b-collapse>
    </b-nav-item>
    <hr class="sidebar-divider" />
    <!-- Insititute Details -->
    <li class="nav-item" :class="{ active: institution }">
      <a class="nav-link" href="/institution">
        <font-awesome-icon :icon="['fas', 'university']" />
        <span>Insitution Details</span>
      </a>
    </li>

    <hr class="sidebar-divider" />
    <!-- Financial Year -->
    <li class="nav-item" :class="{ active: financial_year }">
      <a class="nav-link" href="/financial-year">
        <font-awesome-icon :icon="['fas', 'calendar-week']" /><span>Financial Years</span>
      </a>
    </li>

    <hr class="sidebar-divider" />
    <!-- Account type -->
    <b-nav-item v-b-toggle.collapse-11 :class="{ active: accType }">
      <font-awesome-icon :icon="['fas', 'user-circle']" /><span>Account Types</span>
      <b-collapse id="collapse-11" :visible="accTypeVisible" class="mt-2">
        <div class="py-2 collapse-inner" onClick="event.stopPropagation();">
          <b-link class="collapse-item" :class="{ active: addAccType }" href="/members/account-type/create">Create
            Account Type</b-link>
          <b-link class="collapse-item" :class="{ active: allMemType }" href="/members/account-type">All Account
            Types</b-link>
        </div>
      </b-collapse>
    </b-nav-item>

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block" />
    <!-- Heading -->
    <div class="sidebar-heading">Reports</div>
    <!-- Settings -->
    <li class="nav-item" :class="{ active: reports }">
      <a class="nav-link" href="/reports">
        <font-awesome-icon :icon="['fas', 'book']" /><span>Reports</span>
      </a>
    </li>

    <!-- transactions -->
    <!-- <b-nav-item v-b-toggle.collapse-6 :class="{ active: transactions }">  
        <font-awesome-icon :icon="['fas', 'exchange-alt']" /><span>Transactions</span>
        <b-collapse id="collapse-6" :visible="transactionsVisible" class="mt-2">
            <div class="py-2 collapse-inner" onClick="event.stopPropagation();">
                <b-link class="collapse-item" :class="{ active: deposit }"  href="/deposit">Deposit</b-link>
                <b-link class="collapse-item" :class="{ active: withdrawals }"  href="/withdrawal">Withdrawals</b-link>
            </div>
        </b-collapse>
    </b-nav-item> -->

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block" />
  </ul>
  <!-- End of Sidebar -->
</template>
<script>
export default {
  name: "SidebarView",
  // This are properties for active sidebar
  props: [
    "home",
    "users",
    "allUsers",
    "addUsers",
    "userVisible",
    "roles",
    "permissions",
    "branch",
    "branchVisible",
    "addBranch",
    "allBranch",
    "members",
    "memberVisible",
    "addMember",
    "allMember",
    "allAccountTypes",
    "accounts",
    "allAccount",
    "accountsVisible",
    "loan",
    "allLoans",
    "loanVisible",
    "loanCal",
    "loanScheme",
    "deposit",
    "withdrawal",
    "memacc",
    "memaccVisible",
    "addMemAcc",
    "allMemAcc",
    "memberImport",
    "transVisible",
    "operation",
    "operationsVisible",
    "allTransaction",
    "bulkTransaction",
    'bulkWithdrawals',
    "transaction",
    "depositTransaction",
    "withdrawalTransaction",
    "addLoan",
    "repayLoan",
    "accType",
    "addAccType",
    "allMemType",
    "accTypeVisible",
    "institution",
    "financial_year",
    "reports",
    "fixed_deposit",
    "voucher",
    "voucherVisible",
    "addVoucher",
    "voucherIndex",
    "journal",
    "journalVisible",
    "addJournal",
    "journalIndex",
    "standing_order",
    "mobile",
    "journalTransferIndex",
  ],
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    if (!this.mobile) {
      this.$nextTick(() => {
        const element = this.$refs.sidebar;
        window.addEventListener("resize", this.onResize);
        if (this.windowWidth < 768) {
          element.classList.add("toggled");
          console.log(this.mobile);
        } else {
          element.classList.remove("toggled");
        }
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  // beforeDestroy() {
  //     window.removeEventListener('resize', this.handleResize);
  // },

  // methods: {
  //     handleResize() {
  //         if (window.innerWidth < 480) {
  //             this.toggled = true;
  //         }

  //     }
  // }
  // data() {
  //     return {
  //         toggled: false,
  //     };
  // },
  // computed: {
  //     sidebarClass() {
  //         return {
  //             'toggled': this.toggled,
  //         };
  //     },
  // },
  // methods: {
  //     toggleSidebar() {
  //         this.isSidebarToggled = !this.isSidebarToggled;
  //     },
  // },
  // mounted() {
  //     window.addEventListener('resize', this.handleResize);
  //     this.handleResize(); // Initial check
  // },
  // beforeDestroy() {
  //     window.removeEventListener('resize', this.handleResize);
  // },
  // methods: {
  //     handleResize() {
  //         if (window.innerWidth < 480 && !this.isSidebarToggled) {
  //             this.toggled = true;
  //         }
  //     },
  // },
};
</script>