<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 col-xl-3 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-primary py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div class="text-uppercase fw-bold text-sm mb-1 akkurate-green">
                    <span>Deposits</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(today_deposit) }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(monthly_deposit) }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase text-xs mb-1 akkurate-green">
                    <span>Annual</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(yearly_deposit) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-6 col-xl-3 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-success py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div class="text-uppercase fw-bold text-sm mb-1 akkurate-danger">
                    <span>Payments</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase text-xs mb-1 akkurate-danger">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-danger">
                    <span>{{ convertNumberToCurrency(today_withdrawal) }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase text-xs mb-1 akkurate-danger">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-danger">
                    <span>{{
                      convertNumberToCurrency(monthly_withdrawal)
                    }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase text-xs mb-1 akkurate-danger">
                    <span>Annual</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-danger">
                    <span>{{
                      convertNumberToCurrency(yearly_withdrawal)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-6 col-xl-3 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-info py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div class="text-uppercase fw-bold text-sm mb-1 akkurate-gray">
                    <span>Balance</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase text-xs mb-1 akkurate-gray">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-gray">
                    <span>{{ convertNumberToCurrency(today_balance) }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase text-xs mb-1 akkurate-gray">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-gray">
                    <span> {{ convertNumberToCurrency(monthly_balance) }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase text-xs mb-1 akkurate-gray">
                    <span>Annual</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-gray">
                    <span> {{ convertNumberToCurrency(yearly_balance) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-6 col-xl-3 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-warning py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div class="text-uppercase fw-bold text-sm mb-1 akkurate-blue-text">
                    <span>Pending</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-blue-text">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase text-xs mb-1 akkurate-blue-text">
                    <span>{{ today_pending_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-blue-text">
                    <span>{{ convertNumberToCurrency(today_pending) }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-blue-text">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase text-xs mb-1 akkurate-blue-text">
                    <span>{{ monthly_pending_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-blue-text">
                    <span>{{ convertNumberToCurrency(monthly_pending) }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-4">
                  <div class="text-uppercase text-xs mb-1 akkurate-blue-text">
                    <span>Annual</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="text-uppercase text-xs mb-1 akkurate-blue-text">
                    <span>{{ yearly_pending_count }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold text-xs mb-1 akkurate-blue-text">
                    <span>{{ convertNumberToCurrency(yearly_pending) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-sm-6 col-12">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="30%" height="20%"></b-skeleton>
              <b-skeleton width="50%" height="20%"></b-skeleton>
              <b-skeleton width="90%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box" style="height: 385px;">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 fw-bold akkurate-dark">
                Financial Performance Chart
              </h6>
            </div>
            <div class="card-body default-chart-size">
              <div>
                <PerformanceLineChart :lineChartData="lineChartData" />
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-xl-3 col-sm-6 col-12">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="30%" height="20%"></b-skeleton>
              <b-skeleton width="50%" height="20%"></b-skeleton>
              <b-skeleton width="90%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box" style="height: 385px;">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 fw-bold akkurate-dark">Financial Position</h6>
            </div>
            <div class="card-body default-chart-size">
              <div class="pie-chart-area">
                <DoughnutChart :chartData="chartDataBalanceSheet" />
              </div>
              <hr />
              <div class="row">
                <div class="col akkurate-blue-text">
                  <div class="small">Assests</div>
                  <div class="akkurate-small">Today:{{ assest_today }}</div>
                  <div class="akkurate-small">Month:{{ assest_month }}</div>
                  <div class="akkurate-small">YTD:{{ assest_year }}</div>
                </div>
                <div class="col akkurate-green">
                  <div class="small">Liability & Capital</div>
                  <div class="akkurate-small">{{ lib_today }}</div>
                  <div class="akkurate-small">{{ lib_month }}</div>
                  <div class="akkurate-small">{{ liability_capital_year }}</div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-xl-3 col-sm-6 col-12">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="30%" height="20%"></b-skeleton>
              <b-skeleton width="50%" height="20%"></b-skeleton>
              <b-skeleton width="90%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 fw-bold akkurate-dark">Income & Expenses</h6>
            </div>
            <div class="card-body default-chart-size">
              <div class="pie-chart-area">
                <DoughnutChart :chartData="chartDataIncome" />
              </div>
              <hr />
              <div class="row">
                <div class="col akkurate-danger">
                  <div class="small">Expenses</div>
                  <div class="akkurate-small">Today: {{ expenses_today }}</div>
                  <div class="akkurate-small">Month: {{ expenses_month }}</div>
                  <div class="text-xs">YTD:{{ expenses_year }}</div>
                </div>
                <div class="col akkurate-green">
                  <div class="small">Income</div>
                  <div class="akkurate-small"> {{ income_today }}</div>
                  <div class="akkurate-small">{{ income_month }}</div>
                  <div class="text-xs">{{ income_year }}</div>
                </div>
                <div class="col akkurate-blue-text">
                  <div class="small">P/L</div>
                  <div class="akkurate-small">{{ profit_loss_today }}</div>
                  <div class="akkurate-small">{{ profit_loss_month }}</div>
                  <div class="text-xs">{{ profit_loss_year }}</div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-4 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <b-card class="card-height">
            <bar-chart :labels="labels" :data="data" />
          </b-card>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-4 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box mb-4">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <b-card>
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <div class="small fw-bold">Controls Statistic</div>
                <div class="text-end">
                  <a class="text-decoration-none small akkurate-green" href="#">see more
                    <i class="fas fa-arrow-right akkurate-extra-small"></i></a>
                </div>
              </div>
            </template>
            <!-- A custom formatted footer cell for field 'name' -->
            <template #footer>
              <div class="d-flex justify-content-between">
                <span class="small">Total</span>
                <span class="small">{{ Number(total_shares).toFixed(2) }}</span>
                <span class="small">{{ Number(total_savings).toFixed(2) }}</span>
              </div>

            </template>
            <b-table class="small session-table-height" :fields="member_total_fields" :items="member_total_items" hover
              striped responsive small></b-table>
          </b-card>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-4 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box mb-4">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card mb-4 card-shape home-box">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-6">
                  <h6 class="m-0 fw-bold akkurate-dark">Collection Tracker</h6>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div class="card-body session-table-height">
              <b-table class="small" :fields="collect_fields" :items="collect_items" striped responsive
                :per-page="perPage" :current-page="currentPage" small>
              </b-table>
            </div>
            <div class="card-footer">
              <b-pagination class="text-end" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                aria-controls="daily-control-tb" align="right">
              </b-pagination>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-12 col-xl-12">
        <div class="card mb-4 card-shape home-box">
          <div class="card-header py-3">
            <div class="row">
              <div class="col-12">
                <h6 class="m-0 fw-bold akkurate-dark">Financial Highlight</h6>
              </div>
            </div>
          </div>
          <div class="card-body">
            <b-table class="small" :fields="fields" :items="items" striped responsive small>
              <template #thead-top>
                <b-tr>
                  <b-th width="40%"><span>{{ assets }}</span></b-th>
                  <b-th></b-th>
                  <b-th></b-th>
                  <b-th></b-th>
                  <b-th></b-th>
                </b-tr>
              </template>
              <template #custom-foot>
                <b-tr>
                  <b-th>TOTAL</b-th>
                  <b-th>{{ getActualAmount }}</b-th>
                  <b-th>100%</b-th>
                  <b-th>{{ getStandardAmount }}</b-th>
                  <b-th>100%</b-th>
                </b-tr>
              </template>
            </b-table>
          </div>
          <div class="card-body">
            <b-table class="small" :fields="fields" :items="lib_items" striped responsive small>
              <template #thead-top>
                <b-tr>
                  <b-th width="40%"><span>{{ liability }}</span></b-th>
                  <b-th></b-th>
                  <b-th></b-th>
                  <b-th></b-th>
                  <b-th></b-th>
                </b-tr>
              </template>
              <template #custom-foot>
                <b-tr>
                  <b-th>TOTAL</b-th>
                  <b-th>{{ getActualAmountLib }}</b-th>
                  <b-th>100%</b-th>
                  <b-th>{{ getStandardAmountLib }}</b-th>
                  <b-th>100%</b-th>
                </b-tr>
              </template>
            </b-table>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import DoughnutChart from "../Charts/DoughnutChart.vue";
import ApiService from "@/core/services/api.service";
import PerformanceLineChart from "../Charts/PerformanceLineChart.vue";
import BarChart from "../Charts/BarChart.vue";
export default {
  data() {
    return {
      today_deposit: 0,
      monthly_deposit: 0,
      yearly_deposit: 0,
      today_withdrawal: 0,
      monthly_withdrawal: 0,
      yearly_withdrawal: 0,
      today_balance: 0,
      monthly_balance: 0,
      yearly_balance: 0,
      today_pending_count: 0,
      today_pending: 0,
      monthly_pending_count: 0,
      monthly_pending: 0,
      yearly_pending_count: 0,
      yearly_pending: 0,
      balance_sheet_name: [],
      balance_sheet_value: [],
      color_value: [],
      chartDataBalanceSheet: {
        labels: [],
        datasets: [],
      },
      chartDataIncome: {
        labels: [],
        datasets: [],
      },
      lineChartData: {
        labels: [],
        datasets: [],
      },
      loading: false,
      labels: [],
      data: [],
      member_total_items: [],
      member_total_fields: [
        {
          key: "state",
          label: "Total members"
        },

        {
          key: "shares",
          label: "Shares"
        },
        {
          key: "savings",
          label: "All Savings"
        }
      ],
      total_shares: 0,
      total_savings: 0,
      items: [],
      lib_items: [],
      fields: [
        {
          key: "display_name",
          label: "Particulars",
        },
        {
          key: "actual",
          label: "Actual Amount",
        },
        {
          key: "actual_percent",
          label: "%",
        },
        {
          key: "standard_amount",
          label: "Standard Amount",
        },

        {
          key: "standard_percent",
          label: "%",
        },
      ],
      assets: "",
      liability: "",
      collect_fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "today_balance",
          label: "Today",
        },
        {
          key: "ytd_balance",
          label: "YTD",
        },
      ],
      collect_items: [],
      perPage: 5,
      currentPage: 1,
      assest_year: 0,
      assest_today: 0,
      lib_year: 0,
      liability_capital_year: 0,
      expenses_year: 0,
      income_year: 0,
      profit_loss_year: 0,
      lib_today: 0,
      capital_today: 0,
      liability_capital_today: 0,
      income_today: 0,
      expenses_today: 0,
      profit_loss_today: 0,
      assest_month: 0,
      lib_month: 0,
      liability_capital_month: 0,
      income_month: 0,
      expenses_month: 0,
      profit_loss_month: 0,
    };
  },
  components: {
    DoughnutChart,
    PerformanceLineChart,
    BarChart
  },
  created() {
    // Call your API here
    this.getGenMangDashboardApi();
  },
  methods: {
    async getGenMangDashboardApi() {
      // Your API call logic goes here
      console.log("GEN Man API called!");
      this.loading = true;
      await ApiService.query("/dashboard/general-manager")
        .then((response) => {
          const res = response.data;
          this.today_deposit = res?.deposit?.total_amount;
          this.monthly_deposit = res?.deposit?.monthly_amount;
          this.yearly_deposit = res?.deposit?.yearly_amount;
          this.today_withdrawal = res?.withdrawal?.total_amount;
          this.monthly_withdrawal = res?.withdrawal?.monthly_amount;
          this.yearly_withdrawal = res?.withdrawal?.yearly_amount;
          this.today_balance = res?.balance?.total_amount;
          this.monthly_balance = res?.balance?.monthly_amount;
          this.yearly_balance = res?.balance?.yearly_amount;
          this.today_pending_count = res?.pending?.total_count;
          this.today_pending = res?.pending.total_amount;
          this.monthly_pending_count = res.pending?.monthly_count;
          this.monthly_pending = res?.pending?.monthly_amount;
          this.yearly_pending_count = res.pending?.yearly_count;
          this.yearly_pending = res?.pending?.yearly_amount;
          this.loading = false;

          var balance_sheet_name = []
          var balance_sheet_value = []

          var profit_loss_name = []
          var profit_loss_value = []

          // Populate the arrays
          for (const [key, value] of Object.entries(res?.ps.balance_sheet_year)) {
            balance_sheet_name.push(key);
            balance_sheet_value.push(value);
          }

          // Populate the arrays
          for (const [key, value] of Object.entries(res?.ps.profit_loss_year)) {
            profit_loss_name.push(key);
            profit_loss_value.push(value);
          }

          this.chartDataBalanceSheet = {
            labels: balance_sheet_name,
            datasets: [
              {
                backgroundColor: ["#3681c2", "#018673", "#00D8FF",],
                data: balance_sheet_value,
              },
            ],
          };
          this.chartDataIncome = {
            labels: profit_loss_name,
            datasets: [
              {
                backgroundColor: ["#018673", "#E46651", "#3681c2",],
                data: profit_loss_value,
              },
            ],
          };
          //Year to Date
          this.assest_year = res.ps?.balance_sheet_year.total_assets
          this.lib_year = res.ps?.balance_sheet_year.total_liabilities;
          this.capital_year = res.ps?.balance_sheet_year.total_capital
          this.liability_capital_year = this.lib_year + this.capital_year;

          this.income_year = res.ps?.profit_loss_year.total_income;
          this.expenses_year = res.ps?.profit_loss_year.total_expenses;
          this.profit_loss_year = res.ps?.profit_loss_year.profit_and_loss;

          //Today
          this.assest_today = res.ps?.balance_sheet_today.total_assets;
          this.lib_today = res.ps?.balance_sheet_today.total_liabilities;
          this.capital_today = res.ps?.balance_sheet_today.total_capital;
          this.liability_capital_today = this.lib_today + this.capital_today;

          this.income_today = res.ps?.profit_loss_today.total_income;
          this.expenses_today = res.ps?.profit_loss_today.total_expenses;
          this.profit_loss_today = res.ps?.profit_loss_today.profit_and_loss;

          //Month
          this.assest_month = res.ps?.balance_sheet_month.total_assets;
          this.lib_month = res.ps?.balance_sheet_month.total_liabilities;
          this.capital_month = res.ps?.balance_sheet_month.total_capital;
          this.liability_capital_month = this.lib_month + this.capital_month;

          this.income_month = res.ps?.profit_loss_month.total_income;
          this.expenses_month = res.ps?.profit_loss_month.total_expenses;
          this.profit_loss_month = res.ps?.profit_loss_month.profit_and_loss;


          var duration = res.performance_chart.total_assest.weeks;
          var total_perform_assest_value = res.performance_chart.total_assest.weeklySums;
          var total_perform_liability_value = res.performance_chart.total_liability.weeklySums;
          var total_perform_capital_value = res.performance_chart.total_capital.weeklySums;
          var total_perform_income_value = res.performance_chart.total_income.weeklySums;
          var total_perform_expenses_value = res.performance_chart.total_expenses.weeklySums;
          var total_perform_surplus_value = res.performance_chart.total_surplus.weeklySums;
          this.lineChartData = {
            labels: duration,
            datasets: [
              {
                label: "Assest",
                data: total_perform_assest_value,
                fill: false,
                borderColor: "#01a790",
                backgroundColor: "#01a790",
                borderWidth: 2,
              },
              {
                label: "Liability",
                data: total_perform_liability_value,
                fill: false,
                borderColor: "#E46651",
                backgroundColor: "#E46651",
                borderWidth: 2,
              },
              {
                label: "Capital",
                data: total_perform_capital_value,
                fill: false,
                borderColor: "#DD1B16",
                backgroundColor: "#DD1B16",
                borderWidth: 2,
              },
              {
                label: "Income",
                data: total_perform_income_value,
                fill: false,
                borderColor: "#FF69B4",
                backgroundColor: "#FF69B4",
                borderWidth: 2,
              },
              {
                label: "Expenses",
                data: total_perform_expenses_value,
                fill: false,
                borderColor: "#41B883",
                backgroundColor: "#41B883",
                borderWidth: 2,
              },
              {
                label: "Surplus",
                data: total_perform_surplus_value,
                fill: false,
                borderColor: "#FF4500",
                backgroundColor: "#FF4500",
                borderWidth: 2,
              },
            ],
          };
          this.labels = response.data.branches.branch_names;
          this.data = response.data.branches.branch_total;
          this.member_total_items = response.data.member_stat.control_summary;
          this.items = res.ps.assets;
          this.lib_items = res.ps.liability;
          this.assets = 'ASSET';
          this.liability = ' LIABILITY/EQUITY';
          this.collect_items = response.data.collection_tracker;
          this.total_shares = response.data.member_stat.total_shares;
          this.total_savings = response.data.member_stat.total_savings;
          // console.log(response)
        })
        .catch(function (error) {
          // this.isLoading = false;
          // return error;
          console.log(error);
          this.loading = false;
        });
    },

    convertNumberToCurrency(number) {
      const formattedCurrency = number?.toLocaleString("en-US", {
        style: "currency",
        currency: "GHS", // Change this to your desired currency code (e.g., 'EUR', 'GBP', 'JPY', etc.)
      });
      return formattedCurrency;
    },
  },
  computed: {
    rows() {
      return this.collect_items.length;
    },
    getActualAmount() {
      let sum = 0;
      this.items.forEach((index) => {
        sum += Number(index.actual);
      });
      return sum.toFixed(2);
    },
    getActualAmountLib() {
      let sum = 0;
      this.lib_items.forEach((index) => {
        sum += Number(index.actual);
      });
      return sum.toFixed(2);
    },
    getStandardAmount() {
      let sum = 0;
      this.items.forEach((index) => {
        sum += Number(index.standard_amount);
      });
      return sum.toFixed(2);
    },
    getStandardAmountLib() {
      let sum = 0;
      this.lib_items.forEach((index) => {
        sum += Number(index.standard_amount);
      });
      return sum.toFixed(2);
    },
  },
};
</script>
<style scoped>
.session-table-height {
  height: 230px;
}

.card-height {
  height: 350px;
}


.text-blue {
  color: #0165d1;
}
</style>