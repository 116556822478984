import 'jquery';
import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueRouter from 'vue-router'
import routes from './routes'

import Star from '@/views/components/Star.vue'
import Errors from '@/views/components/Errors.vue'
import ApiService from "./core/services/api.service";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret, faEye, faEnvelope, faKey, faBackward, faForward, faLayerGroup, faHouseDamage, faArrowDown, faUsers, faUser, faTasks, faBook, faCodeBranch, faChartBar, faUniversity, faCalendarWeek, faHandHoldingDollar, faExchangeAlt, faPiggyBank, faMoneyBill, faUserCircle, faTrash, faEdit, faShare, faBriefcase, faWeightHanging, faWindowRestore, faSearch, faSave, faTimes, faCircleCheck, faMoneyBillTransfer, faCheck, faBan, faArrowRotateLeft, faCircle, faFileExcel, faDownload, faHandHolding, faCaretSquareDown, faUpload, faFilter, faPlus } from '@fortawesome/free-solid-svg-icons'

library.add(faUserSecret, faEye, faEnvelope, faKey, faBackward, faForward, faLayerGroup, faHouseDamage, faArrowDown, faUsers, faUser, faTasks, faBook, faCodeBranch, faChartBar, faUniversity, faCalendarWeek, faHandHoldingDollar, faExchangeAlt, faPiggyBank, faMoneyBill, faUserCircle, faTrash, faEdit, faShare, faBriefcase, faWeightHanging, faWindowRestore, faSearch, faSave, faTimes, faCircleCheck, faMoneyBillTransfer, faCheck, faBan, faArrowRotateLeft, faCircle, faFileExcel, faDownload, faHandHolding, faCaretSquareDown, faUpload, faFilter, faPlus)

import './assets/css/styles.css';



import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-vue/dist/bootstrap-vue.js'

import VueProgressBar from "vue-progressbar"
import swal from "sweetalert2";
import VueGoodTablePlugin from 'vue-good-table';

// import the styles
import 'vue-good-table/dist/vue-good-table.css'

//start vuetable

import Vuetable from 'vuetable-2/src/components/Vuetable'
import VueTablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VueTablePaginationDropDown from 'vuetable-2/src/components/VuetablePaginationDropdown'

import VeeValidate from "vee-validate";


//end vuetable

// API service init
ApiService.init();

window.swal = swal;
const toast = swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener("mouseenter", swal.stopTimer);
    toast.addEventListener("mouseleave", swal.resumeTimer);
  },
  background: '#ffffff',
});
Vue.use(VueProgressBar, {
  // color: 'rgb(143,255,199)',
  color: "blue",
  failedColor: "red",
  height: "6px"
});
Vue.use(VueGoodTablePlugin);

window.toast = toast;

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors"
});

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component("star", Star);
Vue.component("errors", Errors);

Vue.component("vuetable", Vuetable);
Vue.component("vuetable-pagination", VuetablePagination);
Vue.component("vuetable-pagination-dropdown", VueTablePaginationDropDown);
Vue.component("vuetable-pagination-info", VueTablePaginationInfo);
// Vue.component('search-bar', SearchBar);

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.mixin({
  // data: function () {
  //     return {
  //         api_url: config.api_url,
  //     }
  // },
  methods: {
    isEmpty: function (strIn) {
      if (strIn === undefined) {
        return true;
      }
      else if (strIn == null) {
        return true;
      }
      else if (strIn == "") {
        return true;
      }
      else {
        return false;
      }
    },
    getFormattedDateTime: function () {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");

      // Create the formatted date-time string separated with underscores
      const formattedDateTime = `${year}${month}${day}${hours}${minutes}${seconds}_`;

      // Update the data property
      return formattedDateTime;
    },
    formatNumber(num) {
      // if(num == null || num === undefined) return 0;
      // return parseFloat(num).toFixed(2)
      if (num) {
        return parseFloat(num).toFixed(2);
      }
      else {
        return 0;

      }
    },
    displayNumber(number) {
      if (number && number != null && number != undefined && number != "0") {
        // parseFloat
        let num = parseFloat(number);
        if (isNaN(num)) {
          return 0.00;
        }
        // return Number(number ? number.toFixed(2) : 0.00).toLocaleString()
        return Number(number).toLocaleString()
      }
      else {
        return 0.00;
      }
    },
    // displayNumber(number, num2)
    // {
    //   let numba = number + num2;
    //   return Number(number ? number.toFixed(2) : 0).toLocaleString()
    // },

    getOneMonthEailer() {
      let currentDate = new Date();
      // Subtract one month
      currentDate.setMonth(currentDate.getMonth() - 1);
      // Convert the date to ISO string and extract only the date part
      let oneMonthEarlier = currentDate.toISOString().substr(0, 10);
      return oneMonthEarlier;
    },
    //get beginning of the year
    getBeginningOfYear() {
      let currentDate = new Date();
      // Subtract one month
      currentDate.setMonth(0);
      currentDate.setDate(1);
      // Convert the date to ISO string and extract only the date part
      let beginningOfYear = currentDate.toISOString().substr(0, 10);
      return beginningOfYear;
    },
    getStartOfWeek() {
      const today = new Date();
      const startOfWeek = new Date(today);
      const day = startOfWeek.getDay();
      const diff = startOfWeek.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
      startOfWeek.setDate(diff);
      return startOfWeek.toISOString().substr(0, 10);
    }
  }
})


const router = new VueRouter({
  routes: routes,
  mode: 'history'
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


