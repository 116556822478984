var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wrapper"}},[_c('SidebarView',{attrs:{"members":true,"add-member":true,"member-visible":true}}),_c('div',{staticClass:"d-flex flex-column",attrs:{"id":"content-wrapper"}},[_c('div',{attrs:{"id":"content"}},[_c('TopbarView'),_c('form',{attrs:{"add_or_edit":_vm.add_or_edit},on:{"submit":function($event){$event.preventDefault();return _vm.submitMember()}}},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"card card-shape home-box"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('personal-details',{attrs:{"form":_vm.form,"add_or_edit":_vm.add_or_edit},on:{"responseFront":_vm.handleResponseFront,"responseBack":_vm.handleResponseBack,"responsePassportPic":_vm.responsePassportPic,"responseSignaturePic":_vm.responseSignaturePic}})],1)])])]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"card card-shape home-box"},[_vm._m(2),_c('div',{staticClass:"card-body"},[_c('employer-details',{attrs:{"form":_vm.form,"add_or_edit":_vm.add_or_edit}})],1)])])]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"card card-shape home-box"},[_vm._m(3),_c('div',{staticClass:"card-body"},[_c('financial-information',{attrs:{"form":_vm.form,"add_or_edit":_vm.add_or_edit}})],1)])])]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12 mt-2 mt-md-0 col-md-12"},[_c('div',{staticClass:"card card-shape home-box"},[_vm._m(4),_c('div',{staticClass:"card-body"},[_c('address-view',{attrs:{"region_options":_vm.regions,"form":_vm.form,"branch_options":_vm.branches,"add_or_edit":_vm.add_or_edit}})],1),_c('hr'),_c('div',{staticClass:"text-center py-3"})])])]),_vm._m(5)])])],1),_c('footer')])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row pb-2"},[_c('div',{staticClass:"col-md-3"},[_c('button',{staticClass:"btn akkurate-green-btn text-white btn-size my-2 me-2",attrs:{"type":"submit"}},[_vm._v(" Update ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3 d-flex flex-row align-items-center"},[_c('h6',{staticClass:"m-0 fw-bold text-green"},[_vm._v("Personal Details")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3 d-flex flex-row align-items-center"},[_c('h6',{staticClass:"m-0 fw-bold text-green"},[_vm._v("Employer Details")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3 d-flex flex-row align-items-center"},[_c('h6',{staticClass:"m-0 fw-bold text-green"},[_vm._v("Financial Information")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3 d-flex flex-row align-items-center"},[_c('h6',{staticClass:"m-0 fw-bold text-green"},[_vm._v("Address")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"col-md-3"},[_c('button',{staticClass:"btn akkurate-green-btn text-white btn-size my-2 me-2",attrs:{"type":"submit"}},[_vm._v(" Update ")])])])])
}]

export { render, staticRenderFns }