<template>
    <div>
        <b-modal :id="String(data.id + topup)" size="xl" @shown="modalShown" @ok="handleOk">
            <template #modal-title>
                <p class="m-0 fw-bold text-green">Loan Topup</p>
            </template>
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <form @submit.prevent="filterLoan">
                            <div class="row">
                                <!-- <div class="col-12 col-md-4">
                                <b-form-group
                                id="loan-re-date"
                                label-for="loan-re-date">
                                <multiselect class="mt-2 field-container" v-model="loan" :options="loan_options"></multiselect>
                                </b-form-group>
                                </div> -->
                                <div class="col-12 col-md-8">
                                    <b-form-group id="loan_code" label-for="loan_code">
                                        <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                            placeholder="Enter Loan Code" v-model="filter.loan_code"
                                            required></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="mt-auto col">
                                    <b-button type="submit" :disabled="isSearching"
                                        class="akkurate-green-btn w-100 mb-3">{{ search
                                        }}</b-button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card card-shape home-box">
                        <beat-loader-component v-if="isLoading" :isLoading="isLoading"></beat-loader-component>
                        <div class="card-header py-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <h6 class="m-0 fw-bold text-green">Loan Account Preview</h6>
                                </div>
                            </div>
                        </div>
                        <div class="card-body" style="background-color:#eaeff2">
                            <div class="row">
                                <div class="col-3 col-md-3">
                                    <img class="img-fluid img-visibility  w-100 h-100"
                                        src="@/assets/img/user-avater.jpg">
                                </div>
                                <div class="col-3 col-md-3">
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <p class="fw-bold">{{ getFullName }}</p>
                                        </div>
                                        <div class="col-12 col-md-12 small">
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Status:</p>
                                                <p class="mb-0">{{ loan.status }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Tenure:</p>
                                                <p class="mb-0">{{ loan.tenor }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Tenure Interval:</p>
                                                <p class="mb-0">{{ loan.tenor_interval }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Interest Rate:</p>
                                                <p class="mb-0">{{ loan.interest_rate }} / {{ loan.tenor_interval }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Interest Method:</p>
                                                <p class="mb-0">{{ loan.interest_method }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Interest Expected:</p>
                                                <p class="mb-0">{{ Number(loan.expected_interest).toFixed(2) }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Interest Balance:</p>
                                                <!-- <p class="mb-0">{{ loan.interest_balance - loan.interest_paid }}</p> -->
                                                <p class="mb-0">{{ Number(loan.interest_balance).toFixed(2) }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Interest Paid:</p>
                                                <p class="mb-0">{{ Number(loan.interest_paid).toFixed(2) }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Interest Due:</p>
                                                <p class="mb-0">{{ Number(loan.interest_due).toFixed(2) }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <p class="mb-0 fw-bold me-3">Payment Due:</p>
                                                <p class="mb-0">{{ getDuePayment }}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-3 col-md-3 small">
                                    <div class="row h-100">
                                        <div class="col-md-12">
                                            <div> <span class="fw-bold">Loan Type:</span> {{ loan?.loan_product.name }}
                                            </div>
                                            <div> <span class="fw-bold">Disbursement Date:</span> {{
                                                loan?.interest_start_date }}
                                            </div>
                                            <div> <span class="fw-bold">Maturity Date:</span> {{ loan?.maturity_date }}
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-auto">
                                            <div class="row">
                                                <div class="col-md-6 fw-bold">Principal:</div>
                                                <div class="col-md-6">{{ Number(loan.principal_amount).toFixed(2) }}
                                                </div>
                                                <div class="col-md-6 fw-bold">Principal Paid:</div>
                                                <div class="col-md-6">{{ Number(loan.principal_paid).toFixed(2) }}</div>
                                                <div class="col-md-6 fw-bold">Principal Bal:</div>
                                                <div class="col-md-6">{{ Number(loan.principal_balance).toFixed(2) }}
                                                </div>
                                                <div class="col-md-6 fw-bold">Principal Due:</div>
                                                <div class="col-md-6">{{ Number(loan.principal_due).toFixed(2) }}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 col-md-3 small">
                                    <div class="row h-100">
                                        <div class="col-md-12">
                                            <div> <span class="fw-bold">Loan Code:</span> {{ loan.loan_number }}</div>
                                            <div> <span class="fw-bold">Last Payment Date:</span> {{ loan.last_payment
                                                }}</div>
                                            <div> <span class="fw-bold">Delinquency:</span> {{ loan.delinquent }}</div>
                                        </div>
                                        <div class="col-md-12 mt-auto">
                                            <div class="row">
                                                <div class="col-md-6 fw-bold">Current Value:</div>
                                                <div class="col-md-6">
                                                    GHS {{ getCurrentValue }}
                                                </div>
                                                <div class="col-md-6 fw-bold">Loan Balance:</div>
                                                <div class="col-md-6">
                                                    GHS {{ getLoanBalance }}
                                                </div>
                                                <div class="col-md-6 fw-bold">Share Bal:</div>
                                                <div class="col-md-6">
                                                    GHS {{ Number(loan?.shares_account_balances).toFixed(2) }}
                                                </div>
                                                <div class="col-md-6 fw-bold">Saving Bal:</div>
                                                <div class="col-md-6">
                                                    GHS {{ Number(loan?.savings_account_balances).toFixed(2) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label class="akkurate-dark" for="topup_amount">Topup Amount <star></star></label>
                            <b-form-group id="topup_amount" label-for="topup_amount">
                                <b-form-input name="topup_amount" class="mt-2 field-container fields" type="text"
                                    placeholder="Enter Amount" v-model="form.topup_amount"
                                    v-validate="{ required: true }" :state="validateState('topup_amount')"
                                    aria-describedby="topup_amount" data-vv-as="Amount" required></b-form-input>
                                <b-form-invalid-feedback>{{ veeErrors.first('topup_amount')
                                    }}</b-form-invalid-feedback>
                                <errors v-if="form.errors.topup_amount">
                                    {{ form.errors.topup_amount[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label-for="reason" class="mb-2">
                                <template #label>
                                    <label class="akkurate-dark" for="reason">Reason <star></star></label>
                                </template>
                                <b-form-textarea class="mt-2 fields" id="reason" name="reason" v-model="form.reason"
                                    placeholder="Enter Reason..." v-validate="{ required: true }"
                                    :state="validateState('reason')" aria-describedby="reason" data-vv-as="Reason"
                                    required></b-form-textarea>
                                <b-form-invalid-feedback>{{ veeErrors.first('reason')
                                    }}</b-form-invalid-feedback>
                                <errors v-if="form.errors.reason">
                                    {{ form.errors.reason[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
                    class="float-left"
                    v-if="isLoading"
                    :isLoading="isLoading"
                    ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="ok()" type="submit" :disabled="isSaving"
                        class="akkurate-green-btn me-2 btn-size float-right">Topup</b-button>
                    <b-button @click="$bvModal.hide('topup')"
                        class="akkurate-grey btn-size me-2 float-right">Close</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
// import Multiselect from 'vue-multiselect'    
import ApiService from "@/core/services/api.service";
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
export default {
    components: {
        BeatLoaderComponent
    },
    props: ['data', 'topup'],
    data() {
        return {
            ...this.initialState(),
            isLoading: false,
            filter: {
                loan_code: "",
            },
            loan: {
                status: "",
                tenor: "",
                interest_rate: "",
                interest_method: "",
                expected_interest: "",
                interest_balance: "",
                interest_paid: "",
                interest_balance: "",
                loan_number: "",
                principal_amount: "",
                principal_paid: "",
                principal_balance: "",
                principal_due: "",
                loan_product: "",
                account: "",
                start_date: "",
                last_payment: "",
                interest_due: "",
            },
            search: "Search",
            isSearching: false,
            isSaving: false,
            total: "",
        }
    },
    computed: {
        getFullName() {
            const first_name = this.loan.account?.customer?.first_name != undefined ? this.loan.account?.customer?.first_name : "";
            const middle_name = this.loan.account?.customer?.middle_name != undefined ? this.loan.account?.customer?.middle_name : "";
            const last_name = this.loan.account?.customer?.last_name != undefined ? this.loan.account?.customer?.last_name : "";
            return first_name + ' ' + middle_name + ' ' + last_name;
        },
        getDuePayment() {
            const payment = Number(this.loan?.interest_due ?? 0) + Number(this.loan?.principal_due ?? 0);
            return payment.toFixed(2);
        },
        getCurrentValue() {
            const current = Number(this.loan?.principal_balance ?? 0) + Number(this.loan?.interest_due ?? 0);
            return current.toFixed(2)
        },
        getLoanBalance() {
            const balance = Number(this.loan?.interest_balance ?? 0) + Number(this.loan?.principal_balance ?? 0);
            return balance.toFixed(2)
        }
    },
    methods: {
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }
                this.storeTopup();

            });

        },
        async storeTopup() {
            this.isSaving = true;
            this.$Progress.start();
            this.form.loan_number = this.filter.loan_code;
            await ApiService.post("/loans/loanTopUp", this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    // this.form.reset();
                    this.form = this.initialState().form;
                    this.$bvModal.hide('topup')
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();

                    if (error.response.data.errors) {
                        this.form.errors = error.response.data.errors;
                    }
                    if (error.response.status == 400) {
                        swal.fire({
                            icon: "error",
                            title: error.response?.statusText,
                            text: error.response?.data?.message,
                            showCancelButton: false, // Show the "Cancel" button
                            confirmButtonText: 'OK', // Customize the text for the "OK" button
                            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                            customClass: {
                                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                            }
                            // footer: '<a href="#">Why do I have this issue?</a>'
                        });
                    }
                });
        },
        filterLoan() {
            this.search = "processing...";
            this.isSearching = true;
            this.isLoading = true;
            ApiService.get(`/loans/${this.filter.loan_code}`)
                .then((response) => {
                    // console.log(response.data.data)
                    this.loan = response.data.data;
                    this.search = "Search";
                    this.isSearching = false;
                    this.isLoading = false;
                }).catch((error) => {
                    this.search = "Search";
                    this.isSearching = false;
                    this.isLoading = false;
                    if (error.response.status == 404) {
                        swal.fire({
                            icon: "error",
                            title: error.response.statusText,
                            text: "Something went wrong!",
                            showCancelButton: false, // Show the "Cancel" button
                            confirmButtonText: 'OK', // Customize the text for the "OK" button
                            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                            customClass: {
                                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                            }
                            // footer: '<a href="#">Why do I have this issue?</a>'
                        });
                    }
                })
        },
        initialState() {
            return {
                form: {
                    loan_number: "",
                    errors: [],
                    source: "web"
                },
            }
        },
        modalShown() {
            if (this.data?.id != 'app_topup') {
                this.filter.loan_code = this.data?.loan?.loan_number;
                this.filterLoan();
            }
        }
    },

}
</script>