<template>
    <div id="wrapper">
        <SidebarView :all-loans="true" :loanVisible="true" :loan="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <form @submit.prevent="filterTable">
                                <loan-filter :form='form' :branch_option="branch_option"
                                    :schemes_option="schemes_option" @branch_arr="branch_array"
                                    @schemes_arr="schemes_array"></loan-filter>
                            </form>
                        </div>
                    </div>
                    <div class="card card-shape home-box">
                        <div class="card-header py-3 d-flex flex-row align-items-center">
                            <h6 class="m-0 fw-bold text-green">All Loans</h6>
                        </div>
                        <div class="card-body table-responsive">
                            <div>
                                <vue-good-table styleClass="vgt-table bordered table-dropdown" mode="remote"
                                    ref="loanTable" :columns="columns" :rows="rows" :isLoading.sync="isLoading"
                                    :totalRows="totalRecords" :sort-options="{
                                        enabled: false
                                    }" :pagination-options="{
                                            enabled: true,
                                            perPage: serverParams.perPage,
                                        }" @on-search="onSearch" @on-page-change="onPageChange"
                                    @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                                    @on-per-page-change="onPerPageChange">
                                    <template slot="table-row" slot-scope="props">
                                        <span class="d-flex" v-if="props.column?.field == 'action'">
                                            <loan-view :data="props.row"></loan-view>
                                            <div class="dropdown no-arrow d-inline">
                                                <a title="Loan Entries/Statement"
                                                    class="btn btn-sm akkurate-gray-btn text-white dropdown-toggle"
                                                    id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <span class="akkurate-small"> <font-awesome-icon
                                                            :icon="['fas', 'book']" /></span>
                                                </a>
                                                <div class="dropdown-menu shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenu">
                                                    <div class="dropdown-header">Statements</div>
                                                    <a class="dropdown-item"
                                                        v-b-modal="String(props.row?.id + 'entries')">Loan Entries</a>
                                                    <loan-entries :data="props.row" :entries="'entries'"
                                                        :institution="institution" :branch="props.row?.branch"
                                                        :user="user"></loan-entries>
                                                    <a class="dropdown-item"
                                                        v-b-modal="String(props.row?.id + 'statement')">Loan
                                                        Statement</a>
                                                    <loan-statement :data="props.row" :statement="'statement'"
                                                        :institution="institution"
                                                        :branch="props.row?.branch"></loan-statement>
                                                </div>
                                            </div>
                                            <div class="dropdown no-arrow d-inline">
                                                <a title="More Loan"
                                                    class="btn btn-sm akkurate-teal text-white dropdown-toggle"
                                                    id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <span class="akkurate-small"> <font-awesome-icon
                                                            :icon="['fas', 'money-bill-transfer']" /></span>
                                                </a>
                                                <div class="dropdown-menu shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenu">
                                                    <div class="dropdown-header">More Actions</div>
                                                    <a class="dropdown-item" v-if="props.row.loan?.status != 'PENDING'"
                                                        v-b-modal="String(props.row?.id + 'repayment')">Loan
                                                        Repayment</a>
                                                    <loan-repayment @reloadLoans="loadItems"
                                                        v-if="props.row.loan?.status != 'PENDING'"
                                                        :key="props.row.id + 'LoannRepaymt'" :data="props.row"
                                                        :institution="institution"
                                                        :repayment="'repayment'"></loan-repayment>

                                                    <a class="dropdown-item"
                                                        v-if="props.row.loan?.status != 'RUNNING' && props.row.loan?.status != 'EXPIRED'"
                                                        v-b-modal="String(props.row?.id + 'authorization')">Loan
                                                        Authorization</a>
                                                    <loan-authorization
                                                        v-if="props.row.loan?.status != 'RUNNING' && props.row.loan?.status != 'EXPIRED'"
                                                        :key="props.row.id + 'LoannAUTHORizatn'" :data="props.row"
                                                        :institution="institution" :authorization="'authorization'"
                                                        @approval="approval"></loan-authorization>

                                                    <!-- <a class="dropdown-item" v-if="props.row.loan?.status != 'RUNNING' && props.row.loan?.status != 'EXPIRED' && props.row.loan?.status != 'PENDING'" v-b-modal="String(props.row?.id + 'disbursement')">Disburse Loan</a>
                                                        <loan-disbursement v-if="props.row.loan?.status != 'RUNNING' && props.row.loan?.status != 'EXPIRED'  && props.row.loan?.status != 'PENDING'" :key="props.row.id + 'LoanDISBURSEMT'" :data="props.row" :institution="institution" :disbursement="'disbursement'"></loan-disbursement> -->

                                                    <a class="dropdown-item"
                                                        v-b-modal="String(props.row?.id + 'topup')">Loan Topup</a>
                                                    <loan-topup :data="props.row" :institution="institution"
                                                        :topup="'topup'"
                                                        :key="props.row.id + 'LoanTOPUPS'"></loan-topup>

                                                    <a class="dropdown-item"
                                                        v-if="props.row.loan?.status != 'RUNNING' && props.row.loan?.status != 'EXPIRED'"
                                                        v-b-modal="String(props.row?.id + 'adjustment')">Loan
                                                        Adjustment</a>
                                                    <loan-adjustment
                                                        v-if="props.row.loan?.status != 'RUNNING' && props.row.loan?.status != 'EXPIRED'"
                                                        :key="props.row.id + 'LoanADJUSTMT'" :data="props.row"
                                                        :institution="institution"
                                                        :adjustment="'adjustment'"></loan-adjustment>

                                                    <a class="dropdown-item"
                                                        v-b-modal="String(props.row?.id + 'status')">Change Status</a>
                                                    <loan-status :data="props.row" :institution="institution"
                                                        :status="'status'"></loan-status>
                                                </div>
                                            </div>
                                            <!-- delete -->
                                            <b-button title="Delete" class="btn btn-sm akkurate-red-btn text-end"
                                                v-if="(props.row?.loan?.status?.toUpperCase() == 'PENDING' || props.row?.loan?.status?.toUpperCase() == 'UNDER_REVIEW' || props.row?.loan?.status?.toUpperCase() == 'REJECTED')"
                                                @click="deleteLoan(props.row)" variant="primary">
                                                <span class="akkurate-small"> <i class="fas fa-trash"></i></span>
                                            </b-button>
                                        </span>
                                        <span v-else-if="props.column?.field == 'customer_full_name'">
                                            <span>{{ props.row?.customer?.first_name }} {{
                                                props.row?.customer?.last_name }}</span>

                                        </span>
                                        <!-- <span v-else-if="props.column?.field == 'total'">
                                                    <span>{{ props.row?.loan?.principal_balance + props.row?.loan?.interest_balance }}</span>
                                                </span> -->
                                        <span v-else-if="props.column?.field == 'loan.loan_balance'">
                                            <span>{{ displayNumber(props.row?.loan?.loan_balance) }}</span>
                                        </span>

                                    </template>

                                    <!-- <template slot="table-row" slot-scope="props">
                                            <span v-if="props.column.field == 'member_fullname'">
                                              <span>{{ props.row?.customer?.first_name }} {{ props.row?.customer?.last_name }}</span> 
                                            </span>
                                            <span v-else>
                                            {{ props.formattedRow[props.column.field] }}
                                            </span>
                                        </template> -->
                                </vue-good-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
</template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import LoanFilter from '../forms/LoanFilter.vue';
import LoanEntries from '../entries/LoanEntries.vue'
import LoanAdjustment from '../adjustment/LoanAdjustment.vue'
import LoanRepayment from '../repayment/LoanRepayment.vue'
import LoanDisbursement from '../disbursement/LoanDisbursement.vue'
import LoanView from '../view/LoanView.vue'
import ApiService from "@/core/services/api.service";
import LoanStatement from '../statements/LoanStatement.vue'
import LoanAuthorization from '../authorization/LoanAuthorization'
import LoanStatus from '../status/LoanStatus.vue'
import LoanTopup from '../topup/LoanTopup.vue'
export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        LoanFilter,
        LoanEntries,
        LoanAdjustment,
        LoanRepayment,
        LoanView,
        LoanStatement,
        LoanDisbursement,
        LoanAuthorization,
        LoanStatus,
        LoanTopup,
    },
    data() {
        return {
            totalRecords: 0,
            searchFilter: "",
            isLoading: true,
            isSaving: false,
            institution: {},
            statement: "statement",
            entries: "entries",
            form: {
                branch: "",
                filter: "",
                schemes: "",
                search: "",
                loan_number: "",
                branch_ids: "",
                schemes_ids: "",
            },
            user: [],
            branch_option: [],
            schemes_option: [],
            user_branch: [],
            columns: [

                {
                    label: 'Loan #',
                    field: 'loan.loan_number',
                },
                {
                    label: 'Loan Name',
                    field: 'loan.loan_product.name',
                },
                {
                    label: 'Borrower Name',
                    field: 'customer_full_name',
                },
                {
                    label: 'Principal',
                    field: 'loan.principal_amount',
                },
                {
                    label: 'Principal Bal.',
                    field: 'loan.principal_balance',
                },
                {
                    label: 'Expected Interest',
                    field: 'loan.expected_interest',
                },
                {
                    label: 'Interest Bal.',
                    field: 'loan.interest_balance',
                },
                {
                    label: 'Total Bal.',
                    field: 'loan.loan_balance',
                },
                {
                    label: 'Start Date',
                    field: 'loan.interest_start_date',
                },
                {
                    label: 'Status',
                    field: 'loan.status',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            serverParams: {
                // a map of column filters example: {name: 'john', age: '20'}
                columnFilters: {
                },
                sort: [
                    {
                        field: '', // example: 'name'
                        type: '' // 'asc' or 'desc'
                    }
                ],
                page: 1, // what page I want to show
                perPage: 20 // how many items I'm showing per page
            },
        }
    },
    mounted() {
        this.getLoanDropdown();
        this.getInsistution();
    },
    methods: {
        async getLoanDropdown() {
            this.$Progress.start();
            this.isLoading = true;
            await ApiService.get("/loans/paginate/dropdown")
                .then((response) => {
                    // console.log(response);
                    this.branch_option = response?.data.branches
                    this.schemes_option = response?.data?.schemes
                    this.user = response?.data?.user
                    this.isLoading = false;
                    this.$Progress.finish();
                }).catch((error) => {
                    // console.log(error);
                })
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.loadItems();
        },

        onSortChange(params) {
            this.updateParams({
                sort: [{
                    type: params.sortType,
                    field: this.columns[params.columnIndex].field,
                }],
            });
            this.loadItems();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },
        onSearch(event) {
            // console.log(event)
            this.loadItems(event.searchTerm)
        },
        filterTable() {
            // console.log(this.form);
            this.serverParams.page = 1
            this.loadItems();
        },

        // load items is what brings back the rows from server
        async loadItems(searchFilter = null) {
            //   getFromServer(this.serverParams).then(response => {
            //      this.totalRecords = response.totalRecords;
            //      this.rows = response.rows;
            //   });
            this.$Progress.start();
            this.isLoading = true;
            // console.log(this.form.schemes_ids);
            await ApiService.post("/loans/paginate",
                {
                    "page": this.serverParams.page,
                    "per_page": this.serverParams.perPage,
                    "scheme_ids": this.form.schemes_ids,
                    "branch_ids": this.form.branch_ids,
                    // "filter": searchFilter,
                    "filter": this.form.filter,
                    "loan_number": this.form?.loan_number
                })
                .then((response) => {
                    this.rows = response.data.data;
                    this.totalRecords = response.data.meta.total;
                    this.isLoading = false;
                    this.$Progress.finish();

                }).catch((error) => {
                    // console.log(error);
                })
                .finally((error) => {
                    // console.log(error);
                });
        },
        branch_array(event) {
            // console.log(event);
            this.form.branch_ids = [];
            this.form.branch_ids = event;
            //    console.log(this.form.branch_ids);
        },
        schemes_array(event) {
            this.form.schemes_ids = [];
            this.form.schemes_ids = event;
            // console.log(this.form.schemes_ids);
        },
        async getInsistution() {
            await ApiService.get('/settings/institution').then((response) => {
                // console.log(response);
                this.institution = response.data.data;
            }).catch((error) => {
                // console.log(error);
            })
        },
        approval() {
            this.loadItems();
        },
        deleteLoan(data) {

            swal.fire({
                title: "Delete Loan",
                text: 'Are you sure you want to delete loan number ' + data.loan.loan_number + ' ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "YES",
                allowOutsideClick: true
            }).then(result => {
                if (result.value) {

                    this.$Progress.start();
                    ApiService.delete('/loans/' + data.loan.id)
                        .then((response) => {
                            this.$Progress.finish();
                            this.loadItems();
                            // this.$bvToast.toast('Loan Deleted Successfully', {
                            //     title: 'Success',
                            //     variant: 'success',
                            //     solid: true
                            // })
                            swal.fire({
                                // title: response.statusText,
                                text: response.data.message,
                                icon: "success",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                            this.loadItems();
                        }).catch((error) => {
                            this.$Progress.fail();

                            swal.fire({
                                // title: response.statusText,
                                text: error.response.data.message,
                                icon: "error",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                        })
                }
            });

        }
    }
}

</script>