<template>
    <div class="row">
        <div class="col-md-4 col-4 text-center bd-highlight">
            <logo></logo>
            <p v-if="title" class="mt-2 akkurate-green fw-bold mt-auto p-2 bd-highlight">{{ title }}</p>
        </div>
        <div class="bd-highlight col-md-8 col-8 text-end">
            <p class="fw-bold text-uppercase mb-0">{{ institution?.name }}</p>
            <p class="fw-bold mb-2">{{ institution?.motto }}</p>
            <p class="mb-0 report-s-text">{{ institution?.postal }}</p>
            <p class="mb-0 report-s-text">{{ institution?.telephone }}</p>
            <p class="mb-2 report-s-text">{{ institution?.email }}</p>
            <div class="mt-4" v-if="current_date">
                <p class="fw-bold mb-0 text-end bd-highlight text-uppercase">{{ new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).format(Date.now()) }}</p>
            </div>
        </div> 
    </div>
</template>

<script>
import Logo from '../Logo.vue'
export default {
    // props:['institution', 'title'],
    props: {
        institution: {
        required: false
      },
      title: {
        required: false
      },
      current_date: {
        required: false,
        default: true
      },
    },
    components:{
        Logo
    }
}
</script>