<template>
    <form @submit.prevent="basicSearch">
        <div class="row">
            <div class="col">
                <label class="akkurate-dark">Standing Order Code</label>
                <b-form-group
                    id="code"
                    label-for="code">
                    <b-form-input
                        class="mb-3 mt-2 field-container fields"
                        type="number"
                        placeholder="Enter Code"
                        v-model="filter.code"
                        required
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="mt-auto col-md-3 col-3">
                <b-button type="submit" :disabled="action.disabled_search"  class="akkurate-green-btn w-100 mb-3">{{ action.search_btn }}</b-button>
            </div>
        </div>
    </form>
   
</template>
<script>
import Multiselect from 'vue-multiselect'
export default{
props:['filter','basicSearch','action'],
components:{
    Multiselect
},
}
</script>