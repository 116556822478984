<template>
  <form @submit.prevent="saveCollatoral">
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="row">
          <!-- <div class="col-md-4">
                    <b-form-group
                        label-for="type"
                        class="mb-3">
                        <template #label>
                            <label class="akkurate-dark small" for="type">Type</label>
                        </template>
                        <multiselect
                            v-model="form.type" 
                            deselect-label="Can't remove this value"
                            placeholder="Select one"
                            class="mt-2 field-container"
                            :options="type_options" 
                            selectLabel=""
deselectLabel="" 
                            :allow-empty="false">
                        </multiselect>
                    </b-form-group>
                </div> -->
          <div class="col-4">
            <b-form-group label-for="name" class="mb-3">
              <template #label>
                <label class="akkurate-dark small" for="name"
                  >Loan Number</label
                >
              </template>
              <b-form-input
                id="name"
                name="name"
                class="mt-2 field-container fields"
                v-model="getLoanNumber"
                type="text"
                disabled
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group label-for="name" class="mb-3">
              <template #label>
                <label class="akkurate-dark small" for="name">Name</label>
              </template>
              <b-form-input
                id="name"
                name="name"
                class="mt-2 field-container fields"
                v-model="form.product_name"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group label-for="value" class="mb-3">
              <template #label>
                <label class="akkurate-dark small" for="value">Value</label>
              </template>
              <b-form-input
                id="value"
                name="value"
                class="mt-2 field-container fields"
                v-model="form.product_value"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group label-for="vastatuslue" class="mb-3">
              <template #label>
                <label class="akkurate-dark small" for="status">Status</label>
              </template>
              <multiselect
                v-model="form.status"
                deselect-label="Can't remove this status"
                placeholder="Select one"
                class="mt-2 field-container"
                :options="status_options"
                selectLabel=""
                deselectLabel=""
                :allow-empty="false"
              >
              </multiselect>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group label-for="value" class="mb-3">
              <template #label>
                <label class="akkurate-dark small" for="value">Address</label>
              </template>
              <b-form-textarea
                class="mt-2 fields"
                id="address"
                v-model="form.address"
                placeholder="Enter Address..."
              >
              </b-form-textarea>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group label-for="risk_level" class="mb-3">
              <template #label>
                <label class="akkurate-dark small" for="risk_level"
                  >Risk Level</label
                >
              </template>
              <multiselect
                v-model="form.risk_level"
                deselect-label="Can't remove this risk level"
                placeholder="Select one"
                class="mt-2 field-container"
                :options="risk_level_options"
                selectLabel=""
                deselectLabel=""
                :allow-empty="false"
              >
              </multiselect>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group label-for="value" class="mb-3">
              <template #label>
                <label class="akkurate-dark small" for="value"
                  >Description</label
                >
              </template>
              <b-form-input
                id="value"
                name="value"
                class="mt-2 field-container fields"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-4" v-show="more_field">
            <b-form-group label-for="value" class="mb-3">
              <template #label>
                <label class="akkurate-dark small" for="value"
                  >Serial Number</label
                >
              </template>
              <b-form-input
                id="value"
                name="value"
                class="mt-2 field-container fields"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-4" v-show="more_field">
            <b-form-group label-for="value" class="mb-3">
              <template #label>
                <label class="akkurate-dark small" for="value"
                  >Model Number</label
                >
              </template>
              <b-form-input
                id="value"
                name="value"
                class="mt-2 field-container fields"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-4" v-show="more_field">
            <b-form-group label-for="value" class="mb-3">
              <template #label>
                <label class="akkurate-dark small" for="value">Color</label>
              </template>
              <b-form-input
                id="value"
                name="value"
                class="mt-2 field-container fields"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-4" v-show="more_field">
            <b-form-group label-for="value" class="mb-3">
              <template #label>
                <label class="akkurate-dark small" for="value"
                  >Date Manufactured</label
                >
              </template>
              <b-form-input
                id="value"
                name="value"
                class="mt-2 field-container fields"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-4" v-show="more_field">
            <b-form-group label-for="value" class="mb-3">
              <template #label>
                <label class="akkurate-dark small" for="value"
                  >Registration Number</label
                >
              </template>
              <b-form-input
                id="value"
                name="value"
                class="mt-2 field-container fields"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-4" v-show="more_field">
            <b-form-group label-for="value" class="mb-3">
              <template #label>
                <label class="akkurate-dark small" for="value">Mileage</label>
              </template>
              <b-form-input
                id="value"
                name="value"
                class="mt-2 field-container fields"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-4" v-show="more_field">
            <b-form-group label-for="value" class="mb-3">
              <template #label>
                <label class="akkurate-dark small" for="value"
                  >Engine Number</label
                >
              </template>
              <b-form-input
                id="value"
                name="value"
                class="mt-2 field-container fields"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div>
            <button
              @click="addMore"
              type="button"
              class="btn akkurate-green-btn text-white btn-size my-2 me-2"
            >
              {{ more_val }}
            </button>
          </div>

          <div></div>

          <div class="col-6">
            <label class="akkurate-dark small" for="value"
              >Document Upload</label
            >
            <document-import
              @getDocument="getDocument"
              ref="documentRef"
            ></document-import>
          </div>
          <div class="col-6">
            <label class="akkurate-dark small" for="value"
              >Picture Upload</label
            >
            <picture-import
              @getPictures="getPictures"
              ref="pictureRef"
            ></picture-import>
          </div>

          <div class="text-end py-3">
            <button
              type="submit"
              :disabled="isSaving"
              class="btn akkurate-green-btn text-white btn-size my-2 me-2"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Multiselect from "vue-multiselect";
import DocumentImport from "./imports/DocumentImport.vue";
import PictureImport from "./imports/PictureImport.vue";
import ApiService from "@/core/services/api.service";
export default {
  props: ["loan_number"],
  components: {
    Multiselect,
    DocumentImport,
    PictureImport,
  },
  data() {
    return {
      ...this.initialState(),
      type_options: ["Car", "House", "Salary"],
      status_options: [
        "Desposited to branch",
        "With Borrower",
        "Reposition initiated",
        "Repossessed",
        "Under Auction",
        "Sold",
        "Lost",
      ],
      risk_level_options: ["High Risk", "Medium Risk", "Low Risk", "No Risk"],
      selectedFiles: [],

      more_field: false,
      more_val: "More",
      isSaving: false,
      childData: null,
    };
  },
  computed: {
    getLoanNumber() {
      this.form.loan_id = this.loan_number;
      return this.form.loan_id;
    },
  },
  methods: {
    getDocument(data) {
      var document = {};
      data.forEach((element) => {
        document = element;
      });
      this.form.documents.push(document);
    },

    getPictures(data) {
      var picture = {};
      picture = data;
      data.forEach((element) => {
        picture = element;
      });

      this.form.pictures.push(picture);
    },
    addMore() {
      this.more_field = !this.more_field;
      if (this.more_field == false) {
        this.more_val = "More";
      } else {
        this.more_val = "Less";
      }
    },

    async saveCollatoral() {
      this.isSaving = true;
      this.$Progress.start();

      const data = new FormData();
      data.append("loan_id", this.form.loan_id);
      data.append("collateraltype_id", 1);
      data.append("product_name", this.form.product_name);
      data.append("product_value", this.form.product_value);
      data.append("status", this.form.status);
      data.append("address", this.form.address);
      data.append("risk_level", this.form.risk_level);
      data.append("description", this.form.description);
      this.form.documents.forEach((document) => {
        data.append("documents[]", document);
      });
      this.form.pictures.forEach((picture) => {
        data.append("pictures[]", picture);
      });
      await ApiService.post("/loans/loanCollaterals", data)
        .then((response) => {
          this.$Progress.finish();

          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.isSaving = false;
          // this.form.reset();
          this.form = this.initialState().form;
          this.emptyDocs();
          this.emptyPics();
        })
        .catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          if (error.response.data.errors) {
            this.form.errors = error.response.data.errors;
          }
        });
    },
    emptyDocs() {
      this.$refs.documentRef.emptyDocument();
    },
    emptyPics() {
      this.$refs.pictureRef.emptyPicture();
    },

    initialState() {
      return {
        form: {
          loan_id: "",
          collateraltype_id: "",
          product_name: "",
          product_value: "",
          status: "Desposited to branch",
          address: "",
          risk_level: "High Risk",
          documents: [],
          pictures: [],
        },
      };
    },
  },
};
</script>