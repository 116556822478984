<template>
  <div>
    <b-button class="akkurate-green-btn" v-b-modal.roles-modal>Add Role</b-button>
    <b-modal
      id="roles-modal"
      ref="modal"
      title="Add Roles"
      @ok="handleOk"
      @show="resetModal"
      @hidden="resetModal">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="nameState">
          <template #label>
            <label>Name <star></star></label>
          </template>
          <b-form-input
            id="name-input"
            class="mb-3 mt-2 field-container fields"
            v-model="form.display_name"
            required>
          </b-form-input>
        </b-form-group>
        <b-form-group
          label-for="name-input"
          invalid-feedback="Withdraw max thershold is required"
          :state="unapproved_withdrawal_max_amountState">
          <template #label>
            <label>Withdrawal Max Threshold <star></star></label>
          </template>
          <b-form-input
            id="name-input"
            class="mb-3 mt-2 field-container fields"
            v-model="form.unapproved_withdrawal_max_amount"
            >
          </b-form-input>

          <errors v-if="form.errors.first_name">
                  {{ form.errors.first_name[0] }}
          </errors>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok }">
        <div class="w-100">
          <b-button @click="ok()" type="submit" class="akkurate-green-btn btn-size me-2 float-right">Save</b-button>
          <b-button @click="$bvModal.hide('roles-modal')" type="button" class="akkurate-grey-btn btn-size border-0 me-2 float-right">Close</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "RolesModal",
  data(){
    return {
      form :{
        display_name:"",
        unapproved_withdrawal_max_amount:"",
      },
      unapproved_withdrawal_max_amountState:null,
      nameState:null,
      errors: []
    }
  },
  methods:{
    checkFormValidity() {
            const valid = this.$refs.form.checkValidity();
            this.nameState = valid;
            this.unapproved_withdrawal_max_amountState = valid;
            return valid
        },
        resetModal() {
            this.form.display_name = ''
            this.form.unapproved_withdrawal_max_amount = ''
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            this.$emit('saveRole',this.form);
            this.resetModal();
            // Hide the modal manually
            this.$nextTick(() => {
              // this.$bvModal.hide("roles-modal");
            });
        },
  }
};
</script>