<template>
    <div>
        <a class="dropdown-item" v-b-modal="data.account_number + transaction">Cheque</a>
       <b-modal
        :id="data.account_number + transaction"
        size="lg"
        @hidden="resetModal"
        @show="modalShown"
        @ok="handleOk">
        <template #modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <h5 class="akkurate-green fw-bold">{{ transaction }} <span class="ms-5"><i class="far fa-money-bill-alt"></i></span></h5>
            <b-button variant="white" @click="close()">
                &times;
            </b-button>
        </template>
        <form ref="form" @submit.stop.prevent="handleSubmit" >
            <div class="row">
                <div class="col-md-6">
                       <b-form-group
                           label-for="member"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark small" for="member">Member</label>
                           </template>
                           <b-form-input
                               id="member"
                               v-model="getFullNameAndID"
                               v-validate="{ required: false }"
                               :state="validateState('member')"
                               name="member"
                               class="mt-2 field-container fields"
                               type="text"
                               disabled
                           ></b-form-input>
                           <b-form-invalid-feedback >
                               The Balance field is required
                           </b-form-invalid-feedback>
                       </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group
                        label-for="account_number"
                        class="mb-3">
                        <template #label>
                            <label class="akkurate-dark small" for="account_number">Account Number</label>
                        </template>
                        <b-form-input
                            id="account_number"
                            v-model="data.account_number"
                            v-validate="{ required: false }"
                            :state="validateState('account_number')"
                            name="account_number"
                            class="mt-2 field-container fields"
                            type="text"
                            disabled
                        ></b-form-input>
                        <b-form-invalid-feedback >
                            The Balance field is required
                        </b-form-invalid-feedback>
                        <!-- <errors v-if="form.errors.account_balance">
                                {{ form.errors.account_balance[0] }}
                            </errors> -->
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group
                        label-for="teller"
                        class="mb-3">
                        <template #label>
                            <label class="akkurate-dark small" for="teller">Teller</label>
                        </template>
                        <b-form-input
                            id="teller"
                            name="teller"
                            class="mt-2 field-container fields"
                            v-validate="{ required: true }"
                            :state="validateState('teller')"
                            v-model="getTeller"
                            type="text"
                            disabled
                        ></b-form-input>
                        <b-form-invalid-feedback >
                            The Balance field is required
                        </b-form-invalid-feedback>
                        <!-- <errors v-if="form.errors.account_balance">
                                {{ form.errors.account_balance[0] }}
                            </errors> -->
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark small" for="transcation_date">Transaction Date <star></star></label>
                    <b-form-group
                    id="transcation_date"
                    label-for="transcation_date">
                    <b-form-input
                    class="mt-2 field-container fields"
                    v-validate="{ required: true }"
                    :state="validateState('transcation_date')"
                    name="transcation_date"
                    v-model="form.trans_date"
                    type="date"
                    
                    ></b-form-input>
                    <b-form-invalid-feedback >
                            The Trasaction Date field is required
                        </b-form-invalid-feedback>
                    <!-- <errors v-if="form.errors.date_of_birth">
                        {{ form.errors.date_of_birth[0] }}
                    </errors> -->
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group
                        label-for="amount"
                        class="mb-3">
                        <template #label>
                            <label class="akkurate-dark small" for="amount">Amount <star></star></label>
                        </template>
                        <b-form-input
                            id="amount"
                            v-validate="{ required: true }"
                            :state="validateState('amount')"
                            name="amount"
                            class="mt-2 field-container fields"
                            type="text"
                            v-model="form.trans_amount"
                        ></b-form-input>
                        <b-form-invalid-feedback >
                            The Amount field is required
                        </b-form-invalid-feedback>
                        <!-- <errors v-if="form.errors.account_balance">
                                {{ form.errors.account_balance[0] }}
                            </errors> -->
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <b-form-group
                        label-for="textarea-lazy"
                        class="mb-2">
                        <template #label>
                        <label class="akkurate-dark small" for="cheque_number">Cheque Number <star></star></label>
                        </template>
                        <b-form-input
                            id="cheque_number"
                            v-validate="{ required: true }"
                            :state="validateState('cheque_number')"
                            name="cheque_number"
                            class="mt-2 field-container fields"
                            type="text"
                            v-model="form.cheque_number"
                        ></b-form-input>
                        <b-form-invalid-feedback >
                            The Cheque Number field is required
                        </b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <b-form-group
                        label-for="bank_chart"
                        class="mb-2">
                        <template #label>
                        <label class="akkurate-dark small" for="bank_chart">Bank Chart <star></star></label>
                        </template>
                        <multiselect class="mt-2 field-container" 
                        v-model="form.bank_chart" 
                        :options="bank_chart_options"
                        placeholder="Select Bank Chart"
                        selectLabel=""
deselectLabel=""
                        name="bank_chart"
                        label="name">
                        </multiselect>
                        <b-form-invalid-feedback >
                            The Bank Chart field is required
                        </b-form-invalid-feedback>
                    </b-form-group>
                </div>  
                <div class="col-12 col-md-6">
                    <b-form-group
                        label-for="textarea-lazy"
                        class="mb-2">
                        <template #label>
                        <label class="akkurate-dark small" for="narration">Narration <star></star></label>
                        </template>
                        <b-form-textarea
                        class="mt-2 fields"
                        id="textarea-lazy"
                        v-validate="{ required: true }"
                        :state="validateState('narration')"
                        name="narration"
                        v-model="form.narration"
                        placeholder="Enter Narration..."
                        ></b-form-textarea>
                        <b-form-invalid-feedback >
                            The Narration field is required
                        </b-form-invalid-feedback>
                    </b-form-group>
                </div>
            </div>
           
           
              
            <hr/>
            <div class="row">
                <div class="col-12 col-md-12 text-center">
                    <label class="label">
                        <input type="file" @change="handleFileUpload" />
                        <span>Form of Identification</span>
                    </label>
                    <div v-if="selectedFile">
                    <label class="akkurate-dark small">Preview</label>
                        <img class="imagePreviewWrapper" :src="previewUrl" alt="File Preview" />
                        <b-button type="button" @click="uploadFile" :disabled="upload_processing" class="akkurate-green-btn">
                            <b-spinner v-if="spin_visible" small></b-spinner>
                            <i v-if="success_visible" class="fas fa-check loading"></i>
                            <i v-if="text_visible" class="fas fa-upload loading"></i> {{ upload }}
                        </b-button>

                    </div>
                </div>
            </div>
           </form>
          <template #modal-footer="{ ok }">
               <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
               <b-button @click="$bvModal.hide(data.account_number + transaction)" type="button" class="akkurate-grey-btn btn-size ms-2 float-right">Cancel</b-button>
               <b-button @click="ok()" type="submit" :disabled="isSaving" class="akkurate-green-btn btn-size float-right border-0">Save</b-button>       
               </div>
           </template>           
       </b-modal>
       <cheque-deposit ref="showChequeDeposit" :report_data="report_data"></cheque-deposit>
       <cheque-withdrawal ref="showChequeWithdrawal"  :report_data="report_data"></cheque-withdrawal>
      
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
import ChequeDeposit from '../../reports/cheque/ChequeDeposit.vue'
import ChequeWithdrawal from '../../reports/cheque/ChequeWithdrawal.vue'
export default {
    name: "Cheque",
    components: {
        Multiselect,
        ChequeDeposit,
        ChequeWithdrawal,
    },
    props: ["data", "transaction", 'institution', 'user','coa_subheads'],
    data() {
        return {
            selectedFile: null,
            previewUrl: null,
            status_options: ['Active', 'Inactive'],
            isSaving: false,
            add_or_edit: "add",
            is_processing: false,
            spin_visible: false,
            text_visible: true,
            success_visible: false,
            upload_processing: false,
            upload: "Upload",
            report_data: "",
            bank_chart_options:[],
            form: {
                account_number: this.data.account_number,
                trans_amount: "",
                source: "Web",
                network: null,
                narration: null,
                transaction_mode: "Cheque", //"Cash", "Cheque", "Momo", "Direct Debit"
                external_reference: null,
                cheque_no: "",
                trans_date: new Date().toISOString().substr(0, 10),
                momo_reference: null,
                currency: "GHS",
                bank_chart:"",
                cheque_number: "",
            },
        }
    },
    // mounted(){
    //     console.log(this.data);
    // },
    computed: {
        getFullNameAndID() {
            let first_name = this.data?.customer?.first_name != null ? this.data?.customer?.first_name : "";
            let middlename = this.data?.customer?.middlename != null ? this.data?.customer?.middlename : "";
            let last_name = this.data?.customer?.last_name != null ? this.data?.customer?.last_name : "";
            let code = this.data?.customer?.code != null ? this.data?.customer?.code : "";
            return first_name + ' ' + middlename + ' ' + last_name + ' - ' + code;
        },
        getTeller() {
            let first_name = this.user?.first_name != null ? this.user?.first_name : '';
            let middle_name = this.user?.middle_name != null ? this.user?.middle_name : '';
            let surname = this.user?.surname != null ? this.user?.surname : '';
            return first_name + ' ' + middle_name + ' ' + surname;
        },
       
    },
    methods: {
        getBackChart(){
            this.bank_chart_options = this.coa_subheads;
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedFile = file;
                this.previewFile(file);
            }
        },
        previewFile(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.previewUrl = reader.result;
            };
        },
        closeModal() {
            this.showDeposit = false;
            this.showWithdrawal = false;
        },
        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async uploadFile() {
            this.upload = "Processing..."
            this.spin_visible = true;
            this.text_visible = false;
            await this.timeout(3000);
            this.spin_visible = false;
            this.text_visible = false;
            this.success_visible = true
            this.upload_processing = true
            this.upload = "Successfully Uploaded"
            // console.log(this.data.account_number);
        },
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        resetModal() {
            this.form.trans_amount = "";
            this.form.cheque_no = "";
            this.form.bank_chart = "";
            this.form.narration = "";
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            this.$validator.validateAll().then(async (result) => {
                if (!result) {
                    console.log(result);
                    return;
                }
                let transaction = this.transaction.split(" ");
                this.$Progress.start();
                this.isSaving = true;
                switch (transaction[1]) {
                    case "Deposit":
                        await ApiService.post("/transactions/deposits/store", this.form)
                            .then((response) => {
                                this.$emit('reloadAccounts')
                                this.$Progress.finish();
                                this.isSaving = false;
                                swal.fire({
                                    title: response.data.transaction.trans_status,
                                    // title: "Successfully Saved",
                                    text: 'CODE : ' + response.data.transaction.code,
                                    // text: 'CODE : 12558896555',
                                    confirmButtonColor: "#018673",
                                    icon: 'success',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    showCancelButton: true, // Show the "Cancel" button
                                    confirmButtonText: 'OK', // Customize the text for the "OK" button
                                    cancelButtonText: 'Print', // Text for the custom button
                                    buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                    customClass: {
                                        confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                        cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                                    }
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.$bvModal.hide(this.data.account_number + this.transaction);
                                    } else if (result.isDismissed) {
                                        this.$bvModal.hide(this.data.account_number + this.transaction);
                                        this.$refs.showChequeDeposit.showModal();
                                        let data = response.data?.transaction
                                        this.report_data = {
                                            'trans_date': data.trans_date,
                                            'account_number': this.data?.account_number,
                                            'fullname': this.getFullNameAndID,
                                            'transaction_mode': data.transaction_mode,
                                            'transaction_type': data.transaction_type,
                                            'amount': data.trans_amount,
                                            'cheque_no': this.form.cheque_number,
                                            'bank_name': this.form.bank_chart?.name,
                                            'branch_name': this.data?.branch?.name,
                                            'narration': data.narration,
                                            'institution': this.institution,
                                            'receipt_no': data.account_id,
                                            'teller': this.getTeller,
                                        };
                                    }
                                })
                            }).catch((error) => {
                                this.$Progress.fail();
                                this.isSaving = false;
                                if (error.response.status == 422) {
                                    swal.fire({
                                        icon: "error",
                                        title: error.response.statusText,
                                        text: error.response?.data?.message,
                                        showCancelButton: false, // Show the "Cancel" button
                                        confirmButtonText: 'OK', // Customize the text for the "OK" button
                                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                        customClass: {
                                            confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                        }
                                        // footer: '<a href="#">Why do I have this issue?</a>'
                                    });
                                }
                            });
                        break;
                    case "Withdrawal":
                        await ApiService.post("/transactions/withdrawals/store", this.form)
                            .then((response) => {
                                this.$emit('reloadAccounts')
                                this.$Progress.finish();
                                this.isSaving = false;
                                swal.fire({
                                    title: response.data.transaction.trans_status,
                                    // title: "Successfull Saved",
                                    text: 'CODE : ' + response.data.transaction.code,
                                    text: 'CODE : 14345664565',
                                    confirmButtonColor: "#018673",
                                    icon: 'success',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    showCancelButton: true, // Show the "Cancel" button
                                    confirmButtonText: 'OK', // Customize the text for the "OK" button
                                    cancelButtonText: 'Print', // Text for the custom button
                                    buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                    customClass: {
                                        confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                        cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                                    }
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.$Progress.finish();
                                        this.isSaving = false;
                                        this.$bvModal.hide(this.data.account_number + this.transaction);
                                    } else if (result.isDismissed) {
                                        this.$Progress.finish();
                                        this.isSaving = false;
                                        this.$bvModal.hide(this.data.account_number + this.transaction);
                                        this.$refs.showChequeWithdrawal.showModal();
                                        let data = response.data?.transaction
                                        this.report_data = {
                                            'trans_date': data.trans_date,
                                            'account_number': this.data?.account_number,
                                            'fullname': this.getFullNameAndID,
                                            'transaction_mode': data.transaction_mode,
                                            'transaction_type': data.transaction_type,
                                            'amount': data.trans_amount,
                                            'cheque_no': this.form.cheque_number,
                                            'bank_name': this.form.bank_chart?.name,
                                            'branch_name': this.data?.branch?.name,
                                            'narration': data.narration,
                                            'institution': this.institution,
                                            'receipt_no': data.account_id,
                                            'teller': this.getTeller,
                                        };
                                    }
                                });
                            }).catch((error) => {
                                this.$Progress.fail();
                                this.isSaving = false;
                                if (error.response?.status == 422) {
                                    swal.fire({
                                        icon: "error",
                                        title: error.response.statusText,
                                        text: error.response?.data?.message,
                                        showCancelButton: false, // Show the "Cancel" button
                                        confirmButtonText: 'OK', // Customize the text for the "OK" button
                                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                        customClass: {
                                            confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                        }
                                        // footer: '<a href="#">Why do I have this issue?</a>'
                                    });
                                }

                            });
                        break;
                }


            });
            // Hide the modal manually

        },
        modalShown(){
            this.getBackChart();
        }
    }
}
</script>