<template>
    <div class="col-10">
        <div class="row">
        <div class="col">
            <label class="akkurate-dark" for="acc_number">Account Number <star></star></label>
            <b-form-group
            id="acc_number"
            label-for="acc_number">
            <b-form-input
            class="mb-3 mt-2 field-container fields"
            type="text"
            v-model="filter.account_number"
            placeholder="Enter Account number"
            required
            ></b-form-input>
            </b-form-group>
        </div>
        <!-- <div class="col-12 col-md-3">
            <label class="akkurate-dark" for="loan-re-date">Loan Kind <star></star></label>
                <b-form-group
                id="loan-re-date"
                label-for="loan-re-date">
                <multiselect class="mt-2 field-container" v-model="loan_kind" :options="kind_options"></multiselect>
                </b-form-group>
        </div> -->
        <div class="col">
            <label class="akkurate-dark" for="marital_status">Loan Scheme <star></star></label>
            <b-form-group
                id="marital_status"
                label-for="marital_status">
                <multiselect class="mt-2 field-container" label="name" v-model="filter.scheme" :options="scheme_options" :allow-empty="false"></multiselect>
            </b-form-group>
        </div>

        <!-- <div class="col">
            <label class="akkurate-dark" for="amount">Amount <star></star></label>
            <b-form-group
            id="amount"
            label-for="amount">
            <b-form-input
            class="mb-3 mt-2 field-container fields"
            type="number"
            placeholder="Enter Amount"
            ></b-form-input>
            </b-form-group>
        </div> -->
        
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
    name: "LoanForm",
    props:['filter','scheme_options'],
    components:{
        Multiselect
    },
    data() {
        return {
            loan_kind:"",
            kind_options:['New Loan', 'Loan Topup','Loan Refinancing'],
        }
    }
}
</script>