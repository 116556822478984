<template>
      <div id="wrapper">
        <SidebarView :financial_year="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <form>
                    <b-container>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="card card-shape home-box">
                                <div class="card-header py-3 d-flex flex-row align-items-center">
                                <h6  class="m-0 fw-bold text-green"> Add Financial Year</h6>
                                </div>
                                <div class="card-body">
                                    <form class="form" method="POST" @submit.prevent="storeOrEditFinancialYear">
                                        <financial-year-create :form="form"></financial-year-create>
                                        <hr />
                                        <div class="text-center py-3">
                                            <button type="submit" :disabled="isSaving" :add_or_edit="add_or_edit" class="btn akkurate-green-btn text-white btn-size my-2 me-2">{{ saveLabel }}</button>
                                            <button type="reset" class="btn akkurate-gray-btn text-white btn-size my-2 me-2" @click="resetAll">Reset</button>
                                        </div>
                                    </form>
                                
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="card card-shape home-box">
                                <div class="card-header py-3 d-flex flex-row align-items-center">
                                <h6  class="m-0 fw-bold text-green">Financial Year Details</h6>
                                </div>
                                <div class="card-body">
                                    <b-table small striped hover :busy="isBusy" :items="items" :fields="fields">
                                        <template #table-busy>
                                            <div class="text-center text-danger my-2">
                                                <beat-loader-component :isLoading="isLoading"></beat-loader-component>
                                            </div>
                                        </template>
                                        <!-- A custom formatted column -->
                                        <template #cell(period)="data">
                                           <span>{{ data.item.start_date }} - {{ data.item.end_date }}</span>
                                        </template>
                                        <template #cell(created_at)="data">
                                           <span>{{ formatDate(data.item.created_at) }}</span>
                                        </template>
                                        <template #cell(action)="data">
                                            <span class="d-flex">
                                            <a title="Edit" class="btn btn-sm akkurate-primary text-white" @click="editRow(data.item)">
                                            <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'edit']" /></span> 
                                            </a>
                                            <a title="Delete" class="btn btn-sm akkurate-danger text-white" @click="confirmDelete(data.item)">
                                                <span class="akkurate-small"><font-awesome-icon  :icon="['fas', 'trash']" /></span>
                                            </a>
                                        </span>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </div> 
                    </div>
                </b-container>
                </form>              
            </div>
            <footer></footer>
        </div>
    </div>

</template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue'
import FinancialYearCreate from '../create/FinancialYearCreate.vue'
import ApiService from "@/core/services/api.service";
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        FinancialYearCreate,
        BeatLoaderComponent
    },

    data() {
        return {
            ...this.initialState(),
            isSaving: null,
            fields: ['period', 'status', 'created_at', 'action'],
            items: [],
            isBusy: false,
            isLoading: false,
            saveLabel: "Save",
            add_or_edit: "add"

        }
    },
    async mounted() {
        this.getAllFinancialYear();
        // console.log(this.add_or_edit);
    },
    methods: {
        formatDate(value) {
            let objectDate = new Date(value);
            let day = objectDate.getDate();
            let month = objectDate.getMonth();
            let year = objectDate.getFullYear();
            let format1 = month + "/" + day + "/" + year;
            return format1;
        },
        async storeOrEditFinancialYear() {
            if (this.add_or_edit === "add") {
                this.isSaving = true;
                this.$Progress.start();
                // console.log(this.form);
                await ApiService.post('/setup/financial-years/store', this.form)
                    .then((response) => {
                        this.isLoadingSaving = false;
                        this.$Progress.finish();
                        toast.fire({
                            icon: "success",
                            title: response.data.message,
                        });
                        console.log(response);
                        this.isSaving = false;
                        // this.form.reset();
                        this.form = this.initialState().form;
                        this.getAllFinancialYear();
                    }).catch((error) => {
                        this.isSaving = false;
                        this.$Progress.fail();
                        if (error.response.data.errors) {
                            this.form.errors = error.response.data.errors;
                        }
                    })

            }
            if (this.add_or_edit === "edit") {
                this.isSaving = true;
                this.$Progress.start();
                await ApiService.put(`/setup/financial-years/update/${this.form.id}`, this.form)
                    .then((response) => {
                        this.isLoadingSaving = false;
                        this.$Progress.finish();
                        toast.fire({
                            icon: "success",
                            title: response.data.message,
                        });
                        console.log(response);
                        this.isSaving = false;
                        // this.form.reset();
                        this.form = this.initialState().form;
                        this.getAllFinancialYear();
                        this.resetAll();
                    }).catch((error) => {
                        this.isSaving = false;
                        this.$Progress.fail();
                        if (error.response.data.errors) {
                            this.form.errors = error.response.data.errors;
                        }
                    })
            }


        },
        editRow(values) {
            this.form.start_date = values.start_date;
            this.form.end_date = values.end_date;
            this.form.status = values.status;
            this.form.id = values.id;
            this.saveLabel = "Update";
            this.add_or_edit = "edit";
            // console.log(this.add_or_edit);
        },
        resetAll() {
            this.form.start_date = "";
            this.form.end_date = "";
            this.form.status = "INACTIVE";
            this.saveLabel = "Save";
            this.add_or_edit = "add";
            this.isSaving = false;
            // console.log(this.add_or_edit);
        },
        async getAllFinancialYear() {
            this.isBusy = true;
            this.isLoading = true;
            await ApiService.get('/setup/financial-years/all')
                .then(response => {
                    this.items = response.data.data;
                    this.isBusy = false;
                    this.isLoading = false;
                }).catch(() => {
                    this.isBusy = false;
                    this.isLoading = false;
                    // console.log(error);
                })
                .finally(() => {
                    this.isBusy = false;
                    this.isLoading = false;
                    // this.isLoading = false
                });
        },
        confirmDelete(value) {
            swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await ApiService.delete(`/setup/financial-years/delete/${value.id}`)
                        .then((response) => {
                            console.log(response.data.message);
                            swal.fire({
                                title: "Deleted!",
                                text: response.data.message,
                                icon: "success"
                            });
                            this.getAllFinancialYear();
                            this.resetAll();
                        }).catch((error) => {
                            console.log(error);
                        })

                }
            });


        },
        initialState() {
            return {
                form: {
                    id: "",
                    start_date: "",
                    end_date: "",
                    status: "INACTIVE",
                    errors: [],
                    add_or_edit: "add",
                },
            }
        }


    }
}

</script>