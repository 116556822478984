<template>
   <div>
     <b-button class="akkurate-green-btn akkurate-small akk mb-3" v-b-modal.account-type><i class="fas fa-plus"></i> Add Account</b-button>
     <b-modal
       id="account-type"
       ref="modal"
       size="lg"
       @show="resetModal"
       @hidden="resetModal"
       @ok="handleOk"
     >
     <template #modal-title>
         <div class="akkurate-green small fw-bold">Add Account Type</div>
     </template>
       <form ref="form" @submit.stop.prevent="handleSubmit">
         <div class="row">
            <div class="col-md-6">
               <b-form-group
               label-for="name-input"
               class="mb-3">
               <template #label>
                  <label class="akkurate-dark" for="name">Name <star></star></label>
               </template>
               <b-form-input
                  id="name-input"
                  v-model="form.name"
                  v-validate="{ required: true }"
                  :state="validateState('name')"
                  name="name"
                  class="mt-2 field-container fields"
                  required
               ></b-form-input>
               <b-form-invalid-feedback >
                 The name field is required
               </b-form-invalid-feedback>
               <errors v-if="form.errors.name">
                      {{ form.errors.name[0] }}
                </errors>
               </b-form-group>
            </div>
            <div class="col-md-6">
               <b-form-group
               label="Name"
               label-for="number-from-input"
               class="mb-3"
               >
               <template #label>
                  <label class="akkurate-dark" for="name">Number From <star></star></label>
               </template>
               <b-form-input
                  id="number-from-input"
                  v-model="form.number_from"
                  v-validate="{ required: true }"
                  :state="validateState('number_from')"
                  name="number_from"
                  class="mt-2 field-container fields"
                  type="number"
                  required
               ></b-form-input>
               <b-form-invalid-feedback >
                 The number from field is required
               </b-form-invalid-feedback>
               <errors v-if="form.errors.number_from">
                      {{ form.errors.number_from[0] }}
                </errors>

               </b-form-group>
            </div>
            <div class="col-md-6">
               <b-form-group
               label="Name"
               label-for="number-from-input"
               class="mb-3"
               >
               <template #label>
                  <label class="akkurate-dark" for="name">Number To <star></star></label>
               </template>
              
               <b-form-input
                  id="number-from-input"
                  v-model="form.number_to"
                  v-validate="{ required: true }"
                  :state="validateState('number_to')"
                  name="number_to"
                  class="mt-2 field-container fields"
                  required
                  type="number"
               ></b-form-input>
               <b-form-invalid-feedback >
                 The number to field is required
               </b-form-invalid-feedback>
               <errors v-if="form.errors.number_to">
                      {{ form.errors.number_to[0] }}
                </errors>
              

               </b-form-group>
            </div>

            <div class="col-md-6">

               <b-form-group
                  label-for="name-input"
                  invalid-feedback="Financial Statement is required"
                  class="mb-3"
                  :state="validateState('fin_statement')">
                  <template #label>
                  <label class="akkurate-dark" for="name">Financial Statement <star></star></label>
                  </template>
                  <multiselect
                  :disabled="add_or_edit == 'edit'"
                  v-validate="{ required: true }"
                  v-model="form.fin_statement"
                  name="fin_statement"
                  :state="validateState('fin_statement')"
                  placeholder="Search or select"
                  :options="statements"
                  class="mt-2 field-container"
                  ></multiselect>
                  <b-form-invalid-feedback >
                        The financial field field is required
                  </b-form-invalid-feedback>
                  <errors v-if="form.errors.fin_statement">
                         {{ form.errors.fin_statement[0] }}
                        </errors>
                  </b-form-group>
            </div>
         </div>
       </form>
       <template #modal-footer="{ ok }">
            <div class="w-100">
               <!-- begin loader -->
               <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component>
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
               <b-button @click="ok()" type="submit" class="btn-size akkurate-green-btn btn-size float-right">Save</b-button>
               <b-button @click="$bvModal.hide('account-type')" type="button" class="me-2 border-0 btn-size akkurate-grey-btn float-right">Close</b-button>
            </div>
      </template>
     </b-modal>
   </div>
 </template>
 
<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
export default {
   components: { Multiselect },
   data() {
      return {
         form: {
            name: "",
            number_from: "",
            number_to: "",
            fin_statement: "",
            errors: [],
         },
         statements: ["Balance Sheet", "Income Statement"],
         isSaving: false,
         add_or_edit: "add",
      };
   },
   methods: {
      validateState(ref) {
         if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
            return !this.veeErrors.has(ref);
         }
         return null;
      },
      resetModal() {
         this.form.name = ''
         this.form.number_from = ''
         this.form.number_to = ''
         this.form.fin_statement = ''
      },
      handleOk(bvModalEvent) {
         // Prevent modal from closing
         bvModalEvent.preventDefault()
         // Trigger submit handler
         this.handleSubmit()
      },
      handleSubmit() {
         // Exit when the form isn't valid
         this.$validator.validateAll().then(result => {
            if (!result) {
               return;
            }
            this.isSaving = true;
            this.$Progress.start();
            this.form.errors = [];
            // this.form
            ApiService.post("/finance/chart-of-accounts/account-types/store",
               this.form
            )
               .then((response) => {
                  this.isSaving = false;
                  this.$Progress.finish();

                  toast.fire({
                     icon: "success",
                     title: response.data.message,
                  });

                  this.$emit("account-type-saved", response.data.data);

                  // Hide Modal Manually
                  this.$nextTick(() => {
                     this.$bvModal.hide('account-type')
                  })

                  // window.location.reload()
                  //   this.isSaving = false;
                  //   this.form.phone_number = "";
                  //   this.form.email = "";
                  //   this.form.selected_payment_method = "";
               })
               .catch((error) => {
                  this.isSaving = false;
                  this.$Progress.fail();
                  this.form.errors = [];
                  if (error.response.data.errors) {
                     console.log(error.response.data.errors)
                     this.form.errors = error.response.data.errors;
                  }
                  // console.log(error.response.data)
               });

         });
         // Hide the modal manually

      }
   }
}
</script>