<template>
   <div>
      <b-modal :id="String(data.id + repayment)" @ok="handleOk" @shown="modalShown" size="xl">
         <template #modal-title>
            <div class="fw-bold text-green">Loan Repayment</div>
         </template>
         <div class="row">
            <div class="col-12">
               <form @submit.prevent="filterLoan">
                  <div class="row">
                     <div class="col-12 col-md-8">
                        <b-form-group id="loan_code" label-for="loan_code">
                           <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                              placeholder="Enter Loan Code" v-model="filter.loan_code" required></b-form-input>
                        </b-form-group>
                     </div>
                     <div class="mt-auto col">
                        <b-button type="submit" :disabled="isSearching"
                           class="akkurate-green-btn akkurate-auth-size w-100 mb-3">{{ search }}</b-button>
                     </div>
                  </div>
               </form>
               <div class="card card-shape home-box">
                  <div class="card-header py-3">
                     <div class="row">
                        <div class="col-12 col-md-12">
                           <h6 class="m-0 fw-bold text-green">Loan Account Preview</h6>
                        </div>
                     </div>
                  </div>
                  <div class="card-body" style="background-color:#eaeff2">
                     <div class="row">
                        <div class="col-3 col-md-3">
                           <img class="img-fluid img-visibility  w-100 h-100" src="@/assets/img/user-avater.jpg">
                        </div>
                        <div class="col-3 col-md-3">
                           <div class="row">
                              <div class="col-12 col-md-12">
                                 <p class="fw-bold">{{ getFullName }}</p>
                              </div>
                              <div class="col-12 col-md-12 small">
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Status:</p>
                                    <p class="mb-0">{{ loan.status }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Tenure:</p>
                                    <p class="mb-0">{{ loan.tenor }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Tenure Interval:</p>
                                    <p class="mb-0">{{ loan.tenor_interval }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Interest Rate:</p>
                                    <p class="mb-0">{{ loan.interest_rate }} / {{ loan.tenor_interval }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Interest Method:</p>
                                    <p class="mb-0">{{ loan.interest_method }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Interest Expected:</p>
                                    <p class="mb-0">{{ Number(loan.expected_interest).toFixed(2) }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Interest Balance:</p>
                                    <!-- <p class="mb-0">{{ loan.interest_balance - loan.interest_paid }}</p> -->
                                    <p class="mb-0">{{ Number(loan.interest_balance).toFixed(2) }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Interest Paid:</p>
                                    <p class="mb-0">{{ Number(loan.interest_paid).toFixed(2) }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Interest Due:</p>
                                    <p class="mb-0">{{ Number(loan.interest_due).toFixed(2) }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Payment Due:</p>
                                    <p class="mb-0">{{ getDuePayment }}</p>
                                 </div>
                              </div>

                           </div>
                        </div>
                        <div class="col-3 col-md-3 small">
                           <div class="row h-100">
                              <div class="col-md-12">
                                 <div> <span class="fw-bold">Loan Type:</span> {{ loan?.loan_product.name }}</div>
                                 <div> <span class="fw-bold">Disbursement Date:</span> {{
                                    loan?.interest_start_date }}
                                 </div>
                                 <div> <span class="fw-bold">Maturity Date:</span> {{ loan?.maturity_date }}</div>
                              </div>
                              <div class="col-md-12 mt-auto">
                                 <div class="row">
                                    <div class="col-md-6 fw-bold">Principal:</div>
                                    <div class="col-md-6">{{ Number(loan.principal_amount).toFixed(2) }}</div>
                                    <div class="col-md-6 fw-bold">Principal Paid:</div>
                                    <div class="col-md-6">{{ Number(loan.principal_paid).toFixed(2) }}</div>
                                    <div class="col-md-6 fw-bold">Principal Bal:</div>
                                    <div class="col-md-6">{{ Number(loan.principal_balance).toFixed(2) }}</div>
                                    <div class="col-md-6 fw-bold">Principal Due:</div>
                                    <div class="col-md-6">{{ Number(loan.principal_due).toFixed(2) }}</div>

                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-3 col-md-3 small">
                           <div class="row h-100">
                              <div class="col-md-12">
                                 <div> <span class="fw-bold">Loan Code:</span> {{ loan.loan_number }}</div>
                                 <div> <span class="fw-bold">Last Payment Date:</span> {{ loan.last_payment }}</div>
                                 <div> <span class="fw-bold">Delinquency:</span> {{ loan.delinquent }}</div>
                              </div>
                              <div class="col-md-12 mt-auto">
                                 <div class="row">
                                    <div class="col-md-6 fw-bold">Current Value:</div>
                                    <div class="col-md-6">
                                       GHS {{ getCurrentValue }}
                                    </div>
                                    <div class="col-md-6 fw-bold">Loan Balance:</div>
                                    <div class="col-md-6">
                                       GHS {{ getLoanBalance }}
                                    </div>
                                    <div class="col-md-6 fw-bold">Share Bal:</div>
                                    <div class="col-md-6">
                                       GHS {{ Number(loan?.shares_account_balances).toFixed(2) }}
                                    </div>
                                    <div class="col-md-6 fw-bold">Saving Bal:</div>
                                    <div class="col-md-6">
                                       GHS {{ Number(loan?.savings_account_balances).toFixed(2) }}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="card-body">
                     <div class="row">
                        <div class="col-6 col-md-6">
                           <form>
                              <div class="row">
                                 <div class="col-6 col-md-6">
                                    <label class="akkurate-dark" for="amount">Amount</label>
                                    <b-form-group id="amount" label-for="amount">
                                       <b-form-input id="amount" name="amount" class="mb-2 mt-2 field-container fields"
                                          type="number" placeholder="Enter Amount" v-model="form.amount"
                                          v-on:input="handleAmountInput" v-validate="{ required: true }"
                                          :state="validateState('amount')" aria-describedby="amount" data-vv-as="Amount"
                                          required></b-form-input>
                                       <b-form-invalid-feedback>{{ veeErrors.first('amount')
                                          }}</b-form-invalid-feedback>
                                       <errors v-if="form.errors.amount">
                                          {{ form.errors.amount[0] }}
                                       </errors>
                                    </b-form-group>
                                 </div>
                                 <div class="col-6 col-md-6">
                                    <label class="akkurate-dark" for="payment_method">Payment Mode</label>
                                    <b-form-group id="payment_method" name="payment_method"
                                       :state="validateState('payment_method')" label-for="payment_method">
                                       <multiselect id="payment_method" name="payment_method" @select="paymentType"
                                          class="mt-2 field-container" v-validate="{ required: true }"
                                          aria-describedby="payment_method" data-vv-as="Payment Method"
                                          v-model="form.payment_method" :allow-empty="false" :options="payment_options">
                                       </multiselect>
                                       <errors v-if="form.errors.payment_method">
                                          {{ form.errors.payment_method[0] }}
                                       </errors>
                                    </b-form-group>
                                    <b-form-invalid-feedback>{{ veeErrors.first('payment_method')
                                       }}</b-form-invalid-feedback>
                                 </div>
                                 <div class="col-6 col-md-6">
                                    <label class="akkurate-dark" for="date">Date</label>
                                    <b-form-group id="date" label-for="date">
                                       <b-form-input class="mb-3 mt-2 field-container fields" type="date"
                                          v-model="form.date" required></b-form-input>
                                    </b-form-group>
                                 </div>

                                 <div class="col-6 col-md-6" v-show="dmomo">
                                    <label class="akkurate-dark" for="mobile">Mobile Number</label>
                                    <b-form-group id="mobile" label-for="mobile">
                                       <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                          v-model="form.mobile_number" placeholder="Enter Mobile Number"></b-form-input>
                                    </b-form-group>
                                 </div>
                                 <div class="col-6 col-md-6" v-if="cheque" v-show="cheque">
                                    <label class="akkurate-dark" for="check_number">Cheque No</label>
                                    <b-form-group id="check_number" label-for="check_number">
                                       <b-form-input id="check_number" name="check_number"
                                          class="mt-2 field-container fields" type="text" v-model="form.check_number"
                                          v-validate="{ required: true }" :state="validateState('check_number')"
                                          data-vv-as="Chaque Number" placeholder="Enter Check Number"
                                          required></b-form-input>
                                       <b-form-invalid-feedback id="input-1-live-feedback">{{
                                          veeErrors.first('check_number')
                                       }}</b-form-invalid-feedback>
                                    </b-form-group>
                                 </div>

                                 <div class="col-6 col-md-6" v-show="cheque">
                                    <label class="akkurate-dark" for="bank_gl">Bank GL</label>
                                    <b-form-group id="bank_gl" label-for="bank_gl">
                                       <multiselect class="mt-2 field-container" label="name"
                                          v-model="form.coa_subhead_id" :options="bank_options">
                                       </multiselect>
                                    </b-form-group>
                                 </div>

                                 <div class="col-6 col-md-6" v-show="marchmomo">
                                    <label class="akkurate-dark" for="momo_transaction_id">Momo Transaction ID</label>
                                    <b-form-group id="momo_transaction_id" label-for="momo_transaction_id">
                                       <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                          v-model="form.momo_transaction_id" placeholder="Enter Transaction ID"
                                          required></b-form-input>
                                    </b-form-group>
                                 </div>
                                 <div class="col-6 col-md-6" v-show="setoff">
                                    <label class="akkurate-dark" for="note">Product</label>
                                    <!-- <b-form-group id="id" label-for="id">
                                          <b-form-input class="mb-3 mt-2 field-container fields" type="text" placeholder="Enter ID"
                                             required></b-form-input>
                                       </b-form-group> -->
                                    <multiselect class="mt-2 field-container" label="name" v-model="form.product"
                                       :options="products" :custom-label="customLabel" selectLabel="" deselectLabel=""
                                       track-by="id">
                                    </multiselect>
                                 </div>
                                 <div class="col-6 col-md-6">
                                    <label class="akkurate-dark" for="note">Note</label>
                                    <b-form-group id="note" label-for="note">
                                       <b-form-textarea class="mt-2 fields" id="note" name="note" v-model="form.note"
                                          v-validate="{ required: true }" :state="validateState('note')"
                                          aria-describedby="note" placeholder="Enter Note..."
                                          data-vv-as="Note"></b-form-textarea>
                                       <b-form-invalid-feedback>{{ veeErrors.first('note') }}</b-form-invalid-feedback>
                                       <errors v-if="form.errors.note">
                                          {{ form.errors.note[0] }}
                                       </errors>
                                    </b-form-group>
                                 </div>
                                 <div class="col-md-12" v-show="cash">
                                    <label>Denomination</label>
                                    <div v-for="(field, index) in form.denominations" :key="index">
                                       <denomination :form="field" @removeBtn="removeField(index)"
                                          :denom_options="denom_options"></denomination>
                                    </div>
                                 </div>
                                 <div class="col-md-4" v-show="cash">
                                    <a @click="addField" type="button" class="btn akkurate-green-btn text-white"><span
                                          class="small"> Add Demonination</span></a>
                                 </div>
                              </div>
                           </form>
                        </div>
                        <div class="col-6 col-md-6">
                           <!-- <b-table striped hover :fields="fields" :items="items">

                                 <template #cell(payment_terms)="data">
                                             <b>{{ data.value.title }}</b> <b class="fw-light">{{ data.value.value }}</b>
                                          </template>

                                 <template #cell(after_payments)="data">
                                             <b>{{ data.value.title }}</b> <b class="fw-light">{{ data.value.value }}</b>
                                          </template>
                              </b-table> -->
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <template #modal-footer="{ ok }">
            <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
                  class="float-left"
                  v-if="isLoading"
                  :isLoading="isLoading"
                  ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
               <b-button @click="ok()" type="submit" :disabled="isSaving"
                  class="akkurate-green-btn me-2 btn-size float-right">Pay</b-button>
            </div>
         </template>
      </b-modal>
      <cash-repayment ref="showCashRepayment" :reportData="reportData"></cash-repayment>
      <direct-momo-debit-repayment ref="showDirectDebitRepayment"
         :reportData="reportData"></direct-momo-debit-repayment>
      <momo-merchant-repayment ref="showMerchantRepayment" :reportData="reportData"></momo-merchant-repayment>
      <cheque-repayment ref="showChequeRepayment" :reportData="reportData"></cheque-repayment>
      <set-off-repayment ref="showSetoffRepayment" :reportData="reportData"></set-off-repayment>

   </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import CashRepayment from '../reports/cash/CashRepayment.vue'
import DirectMomoDebitRepayment from '../reports/direct-debit/DirectMomoDebitRepayment.vue'
import MomoMerchantRepayment from '../reports/momo/MomoMerchantRepayment.vue'
import ChequeRepayment from '../reports/cheque/ChequeRepayment.vue'
import SetOffRepayment from '../reports/set-off/SetOffRepayment.vue'
import ApiService from "@/core/services/api.service";
import Denomination from '@/views/main/pages/transactions/form/modal/Denomination.vue'
export default {
   name: "LoanRepayment",
   props: ['data', 'institution', 'repayment'],
   components: {
      Multiselect,
      CashRepayment,
      DirectMomoDebitRepayment,
      MomoMerchantRepayment,
      ChequeRepayment,
      SetOffRepayment,
      Denomination
   },
   data() {
      return {
         ...this.initialState(),
         filter: {
            loan_code: "",
         },
         loan: {
            status: "",
            tenor: "",
            interest_rate: "",
            interest_method: "",
            expected_interest: "",
            interest_balance: "",
            interest_paid: "",
            interest_balance: "",
            loan_number: "",
            principal_amount: "",
            principal_paid: "",
            principal_balance: "",
            principal_due: "",
            loan_product: "",
            account: "",
            start_date: "",
            last_payment: "",
            delinquent: "",
            new_interest_due: "",
         },
         denom_options: ['1', '2', '5', '10', '20', '50', '100', '200'],
         full_name: "",
         search: "Search",
         isSearching: false,
         isSaving: false,
         reportData: [],
         products: [],

         // loan: "Loan Code",
         // loan_options: ['Loan Code'],
         // product: "Saving",
         // product_options: ["Saving", "Current"],

         // payment_options: ['Cash', 'MOMO Merchant', 'Direct MOMO Debit', 'Cheque', 'Set-Off'],
         payment_options: ['Cash', 'Cheque', 'Set-Off'],
         bank: "",
         bank_options: ['Account one', 'Account two', 'Account three'],
         cash: true,
         marchmomo: false,
         dmomo: false,
         cheque: false,
         setoff: false,
         // note: false,


         fields: [
            { key: 'payment_terms', label: 'Payment Terms' },
            { key: 'after_payments', label: 'After Payment' },
         ],
         items: [
            { payment_terms: { title: 'Interest Amount', value: "" }, after_payments: { title: 'Interest Outstanding', value: "" } },
            { payment_terms: { title: 'Principal Amount', value: "" }, after_payments: { title: 'Principal Outstanding', value: "" } },
            { payment_terms: { title: 'Total Repayment', value: "" }, after_payments: { title: 'Total Outstanding', value: "" } },
         ]
      }
   },
   computed: {
      getFullName() {
         // console.log(this.loan);
         const first_name = this.loan?.customer?.first_name != undefined ? this.loan?.customer?.first_name : "";
         const middle_name = this.loan?.customer?.middle_name != undefined ? this.loan?.customer?.middle_name : "";
         const last_name = this.loan?.customer?.last_name != undefined ? this.loan?.customer?.last_name : "";
         return first_name + ' ' + middle_name + ' ' + last_name;

      },
      getDuePayment() {
         const payment = Number(this.loan?.interest_due ?? 0) + Number(this.loan?.principal_due ?? 0);
         return payment.toFixed(2);
      },
      getCurrentValue() {
         const current = Number(this.loan?.principal_balance ?? 0) + Number(this.loan?.interest_due ?? 0);
         return current.toFixed(2)
      },
      getLoanBalance() {
         const balance = Number(this.loan?.interest_balance ?? 0) + Number(this.loan?.principal_balance ?? 0);
         return balance.toFixed(2)
      }
   },
   methods: {
      customLabel({ account_type, account_number }) {
         return `${account_number} - ${account_type?.name}`
      },
      addField() {
         this.form.denominations.push({ denom_amount: '1', denom_frequency: '', sub_total: '' });
      },
      removeField(index) {
         this.form.denominations.splice(index, 1);
      },
      validateState(ref) {
         if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
            return !this.veeErrors.has(ref);
         }
         return null;
      },
      handleOk(bvModalEvent) {
         // Prevent modal from closing
         bvModalEvent.preventDefault();
         // Trigger submit handler
         this.handleSubmit();
      },
      handleSubmit() {
         // Exit when the form isn't valid
         this.$validator.validateAll().then(result => {
            if (!result) {
               // console.log(result);
               return;
            }
            switch (this.form.payment_method) {
               case "Cash":
                  this.saveCash();
                  break;
               case "MOMO Merchant":
                  this.saveMomoMarchant();
                  break;
               case "Direct Momo Debit":
                  this.saveDirectMomoDirect();
                  break;
               case "Cheque":
                  this.saveCheque();
                  break;
               case "Set-Off":
                  this.saveSetOff();
                  break;
            }
         });
         // Hide the modal manually
      },
      filterLoan() {
         this.search = "processing";
         this.isSearching = true;
         ApiService.get(`/loans/loanRepayments/dropdown/${this.filter.loan_code}`)
            .then((response) => {
               // console.log(response)
               this.bank_options = response.data?.coa_subheads
               this.loan = response.data?.loan;
               this.products = response.data?.accounts;
               this.search = "Search";
               this.isSearching = false;
               // console.log(this.items[0].after_payments.value = this);
               // this.items[0].payment_terms.value = this.loan.interest_paid
               // this.items[1].payment_terms.value = this.loan.principal_paid
               // this.items[2].payment_terms.value = this.loan.principal_amount
               //   console.log();
            }).catch((error) => {
               this.search = "Search";
               this.isSearching = false;
               if (error.response.status == 404) {
                  swal.fire({
                     icon: "error",
                     title: error.response.statusText,
                     text: "Something went wrong!",
                     showCancelButton: false, // Show the "Cancel" button
                     confirmButtonText: 'OK', // Customize the text for the "OK" button
                     buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                     customClass: {
                        confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                     }
                     // footer: '<a href="#">Why do I have this issue?</a>'
                  });
               }
            })
      },
      handleAmountInput() {
         const interest_due = this.getInterestDue;
         // console.log(last_payment_date);
         // console.log(this.loan.delinquent);
         // console.log(this.loan.principal_balance);
         // const interest_due = this.getInterestDue;
         // console.log(interest_due);

         var interest_amt = this.form.amount;
         var total = this.form.amount;
         var principal_amt = 0;


         if (this.form.amount > interest_due) {
            principal_amt = this.form.amount - interest_due;
            interest_amt = this.form.amount - principal_amt;
            total = principal_amt + interest_due;
         }

         this.items[0].payment_terms.value = Math.round(interest_amt * 100) / 100;
         this.items[1].payment_terms.value = Math.round(principal_amt * 100) / 100;
         this.items[2].payment_terms.value = Math.round(total * 100) / 100;
         // const outstanding = this.loan.principal_balance - this.form.amount;

         var interest_outstanding = this.loan.interest_balance - Math.round(interest_amt * 100) / 100;;

         this.items[0].after_payments.value = interest_outstanding;
         this.items[1].after_payments.value = this.loan.interest_balance - interest_outstanding;
         this.items[2].after_payments.value = this.loan.interest_balance;

      },

      paymentType(value) {
         switch (value) {
            case "Cash":
               this.cash = true;
               this.dmomo = false;
               this.marchmomo = false;
               this.cheque = false;
               this.setoff = false;
               this.form.denominations = [
                  { denom_amount: '1', denom_frequency: '', sub_total: '' }
               ];
               this.form.mobile_number = "";
               this.form.check_number = "";
               this.form.momo_transaction_id = "";
               this.form.coa_subhead_id = ""
               this.form.product_id = ""
               break;
            case "MOMO Merchant":
               this.dmomo = true;
               this.marchmomo = true;
               this.cheque = false;
               this.setoff = false;
               this.cash = false;
               this.form.denominations = []
               this.form.check_number = "";
               this.form.coa_subhead_id = ""
               this.form.product_id = ""
               break;
            case "Direct MOMO Debit":
               this.dmomo = true;
               this.marchmomo = false;
               this.cheque = false;
               this.setoff = false;
               this.cash = false;
               this.form.denominations = []
               this.form.check_number = "";
               this.form.coa_subhead_id = ""
               this.form.product_id = ""
               break;

            case "Cheque":
               this.dmomo = false;
               this.cheque = true;
               this.marchmomo = false;
               this.setoff = false;
               this.cash = false;
               this.form.denominations = []
               this.form.mobile_number = "";
               this.form.momo_transaction_id = "";
               this.form.product_id = ""
               break;
            case "Set-Off":
               this.dmomo = false;
               this.cheque = false;
               this.marchmomo = false;
               this.setoff = true;
               this.cash = false;
               this.form.denominations = []
               this.form.mobile_number = "";
               this.form.check_number = "";
               this.form.momo_transaction_id = "";
               break;
            default:
               this.dmomo = false;
               this.marchmomo = false;
               this.cheque = false
               this.note = false;
               this.setoff = false;
               this.cash = false;
               this.form.denominations = []
               break;

         }
      },
      async saveCash() {
         //First Save Cash Repayment then Show swal Below;
         this.isSaving = true;
         this.$Progress.start();
         this.form.loan_number = this.loan.loan_number
         this.form.payment_method = "Cash"
         this.form.product_id = null;
         // console.log(this.form);
         await ApiService.post('/loans/loanRepayments', this.form)
            .then((response) => {
               let res = response?.data;
               // console.log(res);
               this.isLoadingSaving = false;
               this.$Progress.finish();
               this.reportData = {
                  'transaction': res?.transaction,
                  'loan': res?.loan,
                  'denominations': this.form.denominations,
                  'institution': this.institution,
               };

               this.$emit("reloadLoans");

               this.isSaving = false;
               swal.fire({
                  title: 'Success',
                  text: response.data.message,
                  // text: "Saved successfully",
                  confirmButtonColor: "#018673",
                  icon: 'success',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showCancelButton: true, // Show the "Cancel" button
                  confirmButtonText: 'OK', // Customize the text for the "OK" button
                  cancelButtonText: 'Print', // Text for the custom button
                  buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                  customClass: {
                     confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                     cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                  }
               }).then((result) => {

                  if (result.isConfirmed) {
                     this.$bvModal.hide('loan_repayment');
                  } else if (result.isDismissed) {
                     if (this.$refs.showCashRepayment) {
                        this.$refs.showCashRepayment.showModal();
                        this.$bvModal.hide('loan_repayment');
                     } else {
                        console.error("Child component not available yet.");
                     }
                     // if (this.$refs['cashModal']) {
                     //    this.$refs['cashModal'].showModal();
                     // } else {
                     //    console.error("unable to open modal")
                     // }
                  }
               })
            }).catch((error) => {
               this.isSaving = false;
               this.$Progress.fail();
               // if (error.response?.status == 422) {
               swal.fire({
                  icon: "error",
                  title: "Error",
                  text: error.response?.data?.message,
                  showCancelButton: false, // Show the "Cancel" button
                  confirmButtonText: 'OK', // Customize the text for the "OK" button
                  buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                  customClass: {
                     confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                  }
                  // footer: '<a href="#">Why do I have this issue?</a>'
               });
               // }
            })


      },

      async saveMomoMarchant() {
         //First Save Cash Repayment then Show swal Below;
         // this.isSaving = true;
         this.$Progress.start();
         this.form.loan_number = this.loan.loan_number
         this.form.payment_method = "MOMO Merchant"
         this.isSaving = true;
         this.form.product_id = null;
         await ApiService.post('/loans/loanRepayments', this.form)
            .then((response) => {
               let res = response?.data;
               // console.log(res);
               this.isLoadingSaving = false;
               this.$Progress.finish();
               this.reportData = {
                  'transaction': res?.transaction,
                  'loan': res?.loan,
                  'denominations': this.form.denominations,
                  'institution': this.institution,
               };

               swal.fire({
                  title: 'Success',
                  text: response.data.message,
                  // text: "Saved successfully",
                  confirmButtonColor: "#018673",
                  icon: 'success',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showCancelButton: true, // Show the "Cancel" button
                  confirmButtonText: 'OK', // Customize the text for the "OK" button
                  cancelButtonText: 'Print', // Text for the custom button
                  buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                  customClass: {
                     confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                     cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                  }
               }).then((result) => {
                  if (result.isConfirmed) {
                     this.$bvModal.hide('loan_repayment');
                  } else if (result.isDismissed) {
                     if (this.$refs.showMerchantRepayment) {
                        this.$refs.showMerchantRepayment.showModal();
                        this.$bvModal.hide('loan_repayment');
                     } else {
                        console.error("Child component not available yet.");
                     }
                     // if (this.$refs['cashModal']) {
                     //    this.$refs['cashModal'].showModal();
                     // } else {
                     //    console.error("unable to open modal")
                     // }
                  }
               })
            }).catch((error) => {
               this.isSaving = false;
               this.$Progress.fail();
               // if (error.response?.status == 422) {
               swal.fire({
                  icon: "error",
                  title: "Error",
                  text: error.response?.data?.message,
                  showCancelButton: false, // Show the "Cancel" button
                  confirmButtonText: 'OK', // Customize the text for the "OK" button
                  buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                  customClass: {
                     confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                  }
                  // footer: '<a href="#">Why do I have this issue?</a>'
               });
               // }
            })

      },

      async saveDirectMomoDirect() {
         //First Save Cash Repayment then Show swal Below;
         // this.isSaving = true;
         this.$Progress.start();
         this.form.loan_number = this.loan.loan_number
         this.form.payment_method = "Direct MOMO Debit"
         this.isSaving = true;
         this.form.product_id = null;
         await ApiService.post('/loans/loanRepayments', this.form)
            .then((response) => {
               let res = response?.data;
               // console.log(res);
               this.isLoadingSaving = false;
               this.$Progress.finish();
               this.reportData = {
                  'transaction': res?.transaction,
                  'loan': res?.loan,
                  'denominations': this.form.denominations,
                  'institution': this.institution,
               };

               swal.fire({
                  title: 'Success',
                  text: response.data.message,
                  // text: "Saved successfully",
                  confirmButtonColor: "#018673",
                  icon: 'success',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showCancelButton: true, // Show the "Cancel" button
                  confirmButtonText: 'OK', // Customize the text for the "OK" button
                  cancelButtonText: 'Print', // Text for the custom button
                  buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                  customClass: {
                     confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                     cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                  }
               }).then((result) => {
                  if (result.isConfirmed) {
                     this.$bvModal.hide('loan_repayment');
                  } else if (result.isDismissed) {
                     if (this.$refs.showMerchantRepayment) {
                        this.$refs.showMerchantRepayment.showModal();
                        this.$bvModal.hide('loan_repayment');
                     } else {
                        console.error("Child component not available yet.");
                     }
                     // if (this.$refs['cashModal']) {
                     //    this.$refs['cashModal'].showModal();
                     // } else {
                     //    console.error("unable to open modal")
                     // }
                  }
               })
            }).catch((error) => {
               this.isSaving = false;
               this.$Progress.fail();
               if (error.response?.status == 422) {
                  swal.fire({
                     icon: "error",
                     title: "Error",
                     text: error.response?.data?.message,
                     showCancelButton: false, // Show the "Cancel" button
                     confirmButtonText: 'OK', // Customize the text for the "OK" button
                     buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                     customClass: {
                        confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                     }
                     // footer: '<a href="#">Why do I have this issue?</a>'
                  });
               }
            })
      },

      async saveCheque() {
         //First Save Cash Repayment then Show swal Below;
         this.isSaving = true;
         this.$Progress.start();
         this.form.loan_number = this.loan.loan_number
         this.form.payment_method = "Cheque"
         this.form.bank = this.form.coa_subhead_id?.name;
         // console.log(this.form);
         if (this.form.coa_subhead_id) {
            this.form.coa_subhead_id = this.form.coa_subhead_id.id;
         }
         this.form.product_id = null;
         await ApiService.post('/loans/loanRepayments', this.form)
            .then((response) => {
               let res = response?.data;
               // console.log(res);
               this.isLoadingSaving = false;
               this.$Progress.finish();
               this.reportData = {
                  'transaction': res?.transaction,
                  'loan': res?.loan,
                  'denominations': this.form.denominations,
                  'institution': this.institution,
               };

               swal.fire({
                  title: 'Success',
                  text: response.data.message,
                  // text: "Saved successfully",
                  confirmButtonColor: "#018673",
                  icon: 'success',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showCancelButton: true, // Show the "Cancel" button
                  confirmButtonText: 'OK', // Customize the text for the "OK" button
                  cancelButtonText: 'Print', // Text for the custom button
                  buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                  customClass: {
                     confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                     cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                  }
               }).then((result) => {
                  if (result.isConfirmed) {
                     this.$bvModal.hide('loan_repayment');
                  } else if (result.isDismissed) {
                     if (this.$refs.showCheque) {
                        this.$refs.showCheque.showModal();
                        this.$bvModal.hide('loan_repayment');
                     } else {
                        console.error("Child component not available yet.");
                     }
                     // if (this.$refs['cashModal']) {
                     //    this.$refs['cashModal'].showModal();
                     // } else {
                     //    console.error("unable to open modal")
                     // }
                  }
               })
            }).catch((error) => {
               this.isSaving = false;
               this.$Progress.fail();
               // if (error.response?.status == 422) {
               swal.fire({
                  icon: "error",
                  title: "Error",
                  text: error.response?.data?.message,
                  showCancelButton: false, // Show the "Cancel" button
                  confirmButtonText: 'OK', // Customize the text for the "OK" button
                  buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                  customClass: {
                     confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                  }
                  // footer: '<a href="#">Why do I have this issue?</a>'
               });
               // }
            })
      },
      async saveSetOff() {
         // console.log(this.loan.account.id);
         //First Save Cash Repayment then Show swal Below;
         this.isSaving = true;
         this.$Progress.start();
         this.form.loan_number = this.loan.loan_number
         this.form.payment_method = "Set-Off"
         this.isSaving = true;
         if (this.form.product) {
            this.form.product_id = this.form.product.id;
         }
         else {
            this.form.product_id = null;
         }
         await ApiService.post('/loans/loanRepayments', this.form)
            .then((response) => {
               let res = response?.data;
               // console.log(res);
               this.isLoadingSaving = false;
               this.$Progress.finish();
               this.reportData = {
                  'transaction': res?.transaction,
                  'loan': res?.loan,
                  'denominations': this.form.denominations,
                  'institution': this.institution,
               };

               swal.fire({
                  title: 'Success',
                  text: response.data.message,
                  // text: "Saved successfully",
                  confirmButtonColor: "#018673",
                  icon: 'success',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showCancelButton: true, // Show the "Cancel" button
                  confirmButtonText: 'OK', // Customize the text for the "OK" button
                  cancelButtonText: 'Print', // Text for the custom button
                  buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                  customClass: {
                     confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                     cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                  }
               }).then((result) => {
                  if (result.isConfirmed) {
                     this.$bvModal.hide('loan_repayment');
                  } else if (result.isDismissed) {
                     if (this.$refs.showMerchantRepayment) {
                        this.$refs.showMerchantRepayment.showModal();
                        this.$bvModal.hide('loan_repayment');
                     } else {
                        console.error("Child component not available yet.");
                     }
                     // if (this.$refs['cashModal']) {
                     //    this.$refs['cashModal'].showModal();
                     // } else {
                     //    console.error("unable to open modal")
                     // }
                  }
               })
            }).catch((error) => {
               this.isSaving = false;
               this.$Progress.fail();
               // if (error.response?.status == 422) {
               swal.fire({
                  icon: "error",
                  title: "Error",
                  text: error.response?.data?.message,
                  showCancelButton: false, // Show the "Cancel" button
                  confirmButtonText: 'OK', // Customize the text for the "OK" button
                  buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                  customClass: {
                     confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                  }
                  // footer: '<a href="#">Why do I have this issue?</a>'
               });
               // }
            })
      },
      initialState() {
         return {
            form: {
               amount: "",
               payment_method: "Cash",
               date: new Date().toLocaleDateString('en-CA'),
               loan_number: "",
               source: "Web",
               note: "",
               bank: "",
               mobile_number: "",
               check_number: "",
               momo_transaction_id: "",
               denominations: [
                  { denom_amount: '1', denom_frequency: '', sub_total: '' }
               ],
               coa_subhead_id: null,
               product_id: null,
               product: null,
               errors: [],
            },
         }
      },
      modalShown() {
         if (this.data?.id != 'app_repayment') {
            this.filter.loan_code = this.data?.loan?.loan_number;
            this.filterLoan();
         }
      }
   }
}
</script>